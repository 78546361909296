import React, { useEffect, useState } from 'react';
import { App, Typography } from 'antd';
import {
  LikeOutlined, LikeFilled,
  DislikeOutlined, DislikeFilled
} from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import AnonPopconfirm from '../../auth/components/AnonPopconfirm';
import styles from '../styles/LikeDislikePair.module.scss';

const { Text } = Typography;

function LikeDislikePair({
  commentId, liked, likesCount, disliked, dislikesCount,
  likeComment, removeCommentLike, dislikeComment,
  removeCommentDislike, userCanInteract, continueAnonymously
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [localLiked, setLiked] = useState(false);
  const [localDisliked, setDisliked] = useState(false);
  const [localLikesCount, setLikesCount] = useState(0);
  const [localDislikesCount, setDislikesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLiked(!!liked);
  }, [liked]);

  useEffect(() => {
    setDisliked(!!disliked);
  }, [disliked]);

  useEffect(() => {
    setLikesCount(likesCount);
  }, [likesCount]);

  useEffect(() => {
    setDislikesCount(dislikesCount);
  }, [dislikesCount]);

  const chooseLikeOption = async () => {
    setLoading(true);

    const loadingKey = 'updateLikeCommentRequest';
    const successKey = 'updateLikeCommentSuccess';
    const errorKey = 'updateLikeCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${localLiked ? 'Un-l' : 'L'}iking comment...`;
    const successMsg = `Comment successfully ${localLiked ? 'Un-l' : 'l'}iked`;
    message.loading({ content: loadingMsg, key: loadingKey });
    try {
      if (localLiked) {
        await removeCommentLike(commentId);
        setLikesCount(localLikesCount - 1);
      } else {
        await likeComment(commentId);
        if (localDisliked) {
          setDislikesCount(localDislikesCount - 1);
          setDisliked(false);
        }
        setLikesCount(localLikesCount + 1);
      }
      setLiked(!localLiked);
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const chooseDislikeOption = async () => {
    setLoading(true);

    const loadingKey = 'updateDislikeCommentRequest';
    const successKey = 'updateDislikeCommentSuccess';
    const errorKey = 'updateDislikeCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${localDisliked ? 'Un-d' : 'D'}isliking comment...`;
    const successMsg = `Comment successfully ${localDisliked ? 'Un-d' : 'd'}isliked`;
    message.loading({ content: loadingMsg, key: loadingKey });
    try {
      if (localDisliked) {
        await removeCommentDislike(commentId);
        setDislikesCount(localDislikesCount - 1);
      } else {
        await dislikeComment(commentId);
        if (localLiked) {
          setLikesCount(localLikesCount - 1);
          setLiked(false);
        }
        setDislikesCount(localDislikesCount + 1);
      }
      setDisliked(!localDisliked);
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const handleContinueAnonymously = (func) => {
    setLoading(true);
    continueAnonymously(func);
  };

  return (
    <>
      <div className={styles.voteLike}>
        <AnonPopconfirm
          disabled={loading || !userCanInteract || userState.isLoggedIn}
          onConfirm={() => handleContinueAnonymously(chooseLikeOption)}
        >
          {localLiked ? (
            <LikeFilled
              className={`${styles.icon} ${loading || !userCanInteract ? styles.iconDisabled : ''}`}
              onClick={(!loading && userCanInteract && userState.isLoggedIn)
                ? () => continueAnonymously(chooseLikeOption) : null}
              disabled={loading || !userCanInteract}
            />
          ) : (
            <LikeOutlined
              className={`${styles.icon} ${loading || !userCanInteract ? styles.iconDisabled : ''}`}
              onClick={(!loading && userCanInteract && userState.isLoggedIn)
                ? () => continueAnonymously(chooseLikeOption) : null}
              disabled={loading || !userCanInteract}
            />
          )}
        </AnonPopconfirm>
        <Text className={styles.count}>{localLikesCount}</Text>
      </div>
      <div className={styles.voteDislike}>
        <AnonPopconfirm
          disabled={loading || !userCanInteract || userState.isLoggedIn}
          onConfirm={() => handleContinueAnonymously(chooseDislikeOption)}
        >
          {localDisliked ? (
            <DislikeFilled
              className={`${styles.icon} ${loading || !userCanInteract ? styles.iconDisabled : ''}`}
              onClick={(!loading && userCanInteract && userState.isLoggedIn)
                ? () => continueAnonymously(chooseDislikeOption) : null}
              disabled={loading || !userCanInteract}
            />
          ) : (
            <DislikeOutlined
              className={`${styles.icon} ${loading || !userCanInteract ? styles.iconDisabled : ''}`}
              onClick={(!loading && userCanInteract && userState.isLoggedIn)
                ? () => continueAnonymously(chooseDislikeOption) : null}
              disabled={loading || !userCanInteract}
            />
          )}
        </AnonPopconfirm>
        <Text className={styles.count}>{localDislikesCount}</Text>
      </div>
    </>
  );
}

LikeDislikePair.displayName = 'Like Dislike Pair';
// LikeDislikePair.defaultProps = {

// }

export default LikeDislikePair;
