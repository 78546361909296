import React, {
  useContext, useEffect, useState
} from 'react';
import {
  Affix, Select, Switch, Button, Skeleton, Spin, App
} from 'antd';
import { IconMessageDots } from '@tabler/icons-react';
import EmbedContext from '../../embed/providers/EmbedProvider';
import { useIsXl } from '../../common/state/responsiveChecks';
import QuteeCommentThread from '../../qutee/components/QuteeCommentThread';
import CompactFilters from './CompactFilters';
import styles from '../styles/CommentList.module.scss';

const { Option } = Select;

const RECENT_SORT = 'recent';
const POPULAR_SORT = 'popular';
// const QCRED_SORT = 'qcred';

function CommentList({
  commentBinId, threadList, labelPrompt, labels, filters, setFilters, filterData, setFilterData,
  isLoadingFilters, setLoadingFilters, sizeComments, setSizeComments, foundNewComments,
  newCommentsCount, hideCommentBinResults, hideComments, reloadComments, isLoadingComments,
  isEndComments, isRefreshingComments, totalCommentCount, filterCommentCount, sort, setSort,
  loadReplies, continueThread, createComment, updateComment, deleteComment,
  likeComment, removeCommentLike, dislikeComment, removeCommentDislike,
  reportComment, removeCommentReport,
  userCanInteract, cantInteractReason, userCanEditQutee, checkUserCanEditComment,
  showHeading, allowAnonymous, continueAnonymously, isPreview,
  refreshProgress, topNavOffset, defaultCollapsedReplies
}) {
  const { message } = App.useApp();
  const embedConfig = useContext(EmbedContext);
  const isLargeScreen = useIsXl();
  const [collapseReplies, setCollapseReplies] = useState(defaultCollapsedReplies);
  const [numNewComments, setNumNewComments] = useState(0);
  const [showNewCheck, setShowNewCheck] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [filterAffixed, setFilterAffixed] = useState(false);

  const { useProgressAffix, affixTargetContainerId } = embedConfig;

  useEffect(() => {
    setNumNewComments(newCommentsCount);
    if (foundNewComments) {
      setShowNewCheck(true);
    } else {
      setShowNewCheck(false);
    }
  }, [newCommentsCount, foundNewComments]);

  const handleSort = (value) => {
    setSort(value);
  };

  const reloadWithNewComments = async () => {
    const loadingKey = 'loadNewCommentsRequest';
    const successKey = 'loadNewCommentsSuccess';
    const errorKey = 'loadNewCommentsError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoadingNew(true);
    message.loading({ content: 'Loading new comments...', key: loadingKey });
    try {
      await reloadComments();
      setShowNewCheck(false);
      message.destroy(loadingKey);
      message.success({ content: 'new comments successfully loaded', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoadingNew(false);
    }
  };

  const getHeading = (numThreads) => {
    const collapseSwitch = (
      <Switch
        className={styles.collapseSwitch}
        checkedChildren="Showing Replies"
        unCheckedChildren="Hiding Replies"
        checked={!collapseReplies}
        onClick={() => setCollapseReplies(!collapseReplies)}
      />
    );
    const sortDropdown = (
      <Select
        className={styles.sort}
        defaultValue={sort}
        onChange={handleSort}
        bordered
        size="small"
      >
        <Option value={POPULAR_SORT}>Most Popular</Option>
        <Option value={RECENT_SORT}>Most Recent</Option>
        {/* <Option value={QCRED_SORT}>Highest Qcred First</Option> */}
      </Select>
    );

    const getCount = () => {
      if (filters == null) {
        return null;
      }
      if (filters && (filterCommentCount != null)) {
        return filterCommentCount;
      }
      if (!filters && (totalCommentCount != null)) {
        return totalCommentCount;
      }
      return null;
    };

    const getCountText = () => {
      if (filters == null) {
        return null;
      }
      if (filters && (filterCommentCount != null)) {
        return (
          <span className={styles.count}>
            {`${filterCommentCount} Matched Comments`}
          </span>
        );
      }
      if (!filters && (totalCommentCount != null)) {
        return (
          <span className={styles.count}>
            {`${totalCommentCount} Comments`}
          </span>
        );
      }
      return null;
    };

    const getNewCommentCheckDisplay = () => {
      // let text = `${numNewComments} new comment${numNewComments > 1 ? 's' : ''}`;
      // if (hideCommentBinResults) {
      //   text = 'New comments available';
      // }
      const newCommentsCheck = (
        <div className={styles.newComments}>
          {/* <span className={styles.newCommentsText}>
            {text}
          </span> */}
          <Button
            className={styles.newCommentsButton}
            type="dashed"
            disabled={isLoadingComments || isRefreshingComments}
            loading={isLoadingComments || isRefreshingComments}
            onClick={() => reloadWithNewComments()}
          >
            Load New Comments
          </Button>
        </div>
      );

      return newCommentsCheck;
    };

    const filtersSection = (
      <CompactFilters
        commentBinId={commentBinId}
        filter={filters}
        setFilter={setFilters}
        filterData={filterData}
        setFilterData={setFilterData}
        isLoadingFilters={isLoadingFilters}
        setLoadingFilters={setLoadingFilters}
        totalCommentCount={totalCommentCount}
        filterCommentCount={filterCommentCount}
        selectedTagsOnly={filterAffixed}
        userCanEditQutee={userCanEditQutee}
      />
    );

    if (isLargeScreen) {
      return (
        <div className={styles.header}>
          <div className={styles.filters}>
            {filtersSection}
          </div>
          <div className={styles.headerInner}>
            <div className={styles.countAndCheck}>
              {(getCountText() != null) && (
                <div className={styles.countContainer}>
                  {getCountText()}
                </div>
              )}
              {showNewCheck ? (
                <div>
                  {loadingNew ? <Spin /> : getNewCommentCheckDisplay()}
                </div>
              ) : null}
            </div>
            <div>
              {numThreads ? (
                <div className={styles.options1}>
                  {collapseSwitch}
                  {sortDropdown}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div>
          {useProgressAffix
            ? (
              <div className={styles.filterAffix}>
                <Affix
                  offsetTop={topNavOffset}
                  target={(affixTargetContainerId
                    && document.getElementById(affixTargetContainerId))
                    ? () => (document.getElementById(affixTargetContainerId))
                    : undefined}
                  onChange={(affixed) => setFilterAffixed(affixed)}
                >
                  <div className="antd-affix-wrapper">
                    {filtersSection}
                  </div>
                </Affix>
              </div>
            ) : filtersSection}
        </div>
        <div className={styles.options2}>
          <div className={styles.countAndCheck}>
            {(getCount() != null) && (
              <span className={styles.commentCount}>
                {`${getCount()} Results`}
              </span>
            )}
            {showNewCheck ? (
              <div>
                {loadingNew ? <Spin /> : getNewCommentCheckDisplay()}
              </div>
            ) : null}
          </div>
          {numThreads ? sortDropdown : null}
        </div>
      </>
    );
  };

  const getThreads = () => {
    const list = [];
    if (threadList) {
      threadList.forEach((thread) => {
        const component = (
          <QuteeCommentThread
            key={thread?.comment_id}
            commentBinId={commentBinId}
            thread={thread}
            labelPrompt={labelPrompt}
            labels={labels}
            replies={thread?.replies}
            filters={filters}
            filterData={filterData}
            visualDepth={0}
            collapseReplies={collapseReplies}
            loadReplies={loadReplies}
            continueThread={continueThread}
            createComment={createComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            likeComment={likeComment}
            removeCommentLike={removeCommentLike}
            dislikeComment={dislikeComment}
            removeCommentDislike={removeCommentDislike}
            reportComment={reportComment}
            removeCommentReport={removeCommentReport}
            userCanInteract={userCanInteract}
            cantInteractReason={cantInteractReason}
            userCanEditQutee={userCanEditQutee}
            checkUserCanEditComment={checkUserCanEditComment}
            allowAnonymous={allowAnonymous}
            continueAnonymously={continueAnonymously}
            isPreview={isPreview}
            refreshProgress={refreshProgress}
          />
        );
        list.push(component);
      });
    }

    return (
      <div>
        {showHeading && (!hideComments || userCanEditQutee)
          ? getHeading(list?.length) : null}
        <div className={styles.threads}>
          {list}
        </div>
        {!isEndComments && !isLoadingComments ? (
          <Button
            className={styles.loadMore}
            type="text"
            disabled={isLoadingComments || isRefreshingComments || isEndComments}
            onClick={() => setSizeComments(sizeComments + 1)}
          >
            <IconMessageDots className={styles.loadIcon} />
            <span className={styles.loadLink}>Load More Comments</span>
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {getThreads()}
      <Skeleton
        loading={isLoadingComments}
        active
        avatar
        title
        paragraph={{ rows: 4 }}
      />
    </div>
  );
}

CommentList.displayName = 'Qutee Comment List';
CommentList.defaultProps = {
  newCommentsCount: null,
  reloadComments: null,
  setMobileDisplayFilters: undefined,
  topNavOffset: undefined,
  hideComments: false,
  defaultCollapsedReplies: true
};

export default CommentList;
