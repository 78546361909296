import React from 'react';
import { Modal } from 'antd';

function ThreadModal({
  children, visible, setVisible, title, useDefaultFooter, footer,
  handleOk, okText, cancelText, okButtonProps, className
}) {
  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={title}
      open={visible}
      width="95%"
      // undefined makes it use default
      footer={useDefaultFooter ? undefined : footer}
      okButtonProps={okButtonProps}
      okText={okText}
      onOk={handleOk}
      cancelText={cancelText}
      onCancel={handleCancel}
      centered
      destroyOnClose
      className={className}
    >
      {children}
    </Modal>
  );
}

ThreadModal.defaultProps = {
  title: 'Viewing Replies',
  useDefaultFooter: false,
  footer: null,
  handleOk: undefined,
  okButtonProps: undefined,
  okText: 'Ok',
  cancelText: 'Cancel',
  className: undefined
};

export default ThreadModal;
