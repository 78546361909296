import React, { useState, useEffect, useRef } from 'react';
import { useUserContext } from '../../user/providers/UserProvider';
import PollTitle from './PollTitle';
import PollOptionList from './PollOptionList';
import PollControls from './PollControls';
import styles from '../styles/Poll.module.scss';

function Poll({
  pollId, title, published, options, selectedOptionIds, onlyResults,
  noResults, disableVoteMessage, disabled, disabledOptionTooltipText, userCanEdit, userCanDelete,
  userCanPublish, lockEdits, showEditToggle, onUpdateTitle,
  onPublishPoll, onDeletePoll, onAddOption, onRepositionOption,
  onRemoveOption, onUpdateOption, onSelectOption, onDeselectOption, afterVoteHook,
  loadingBallot, mutateProgress, isMultipleChoice, totalBallots, onUpdateBallotChoice, externalRef
}) {
  const { state: userState } = useUserContext();
  const [isPublished, setPublished] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingBallot, setLoadingBallot] = useState(loadingBallot);
  // This is used to show multiple choice ballots on initial load, if any are selected
  const forceShowMultiChoiceResults = useRef(true);

  const profileId = userState?.profile?.profile_id;

  // Turn off forcing of multi choice results on selection
  const onSelectOptionEnhanced = async (...args) => {
    forceShowMultiChoiceResults.current = false;
    await onSelectOption(...args);
    forceShowMultiChoiceResults.current = false;
  };

  // Turn off forcing of multi choice results on deselection
  const onDeselectOptionEnhanced = async (...args) => {
    forceShowMultiChoiceResults.current = false;
    await onDeselectOption(...args);
    forceShowMultiChoiceResults.current = false;
  };

  useEffect(() => {
    setEditing(false);
  }, [userCanEdit, userCanDelete, userCanPublish]);

  useEffect(() => {
    setPublished(!!published);
  }, [published]);

  useEffect(() => {
    setDisabled(!!disabled);
  }, [disabled]);

  useEffect(() => {
    setLoadingBallot(loadingBallot);
  }, [loadingBallot]);

  useEffect(() => {
    forceShowMultiChoiceResults.current = true;
  }, [profileId]);

  useEffect(() => {
    if (onlyResults) {
      setShowResults(true);
      return;
    }

    if (noResults) {
      setShowResults(false);
      return;
    }

    // Only show results for multiple choice if it's initial load and any are selected
    if (isMultipleChoice && forceShowMultiChoiceResults.current) {
      if (selectedOptionIds?.length) {
        setShowResults(true);
        forceShowMultiChoiceResults.current = false;
        return;
      }
    }

    if (!selectedOptionIds?.length) {
      setShowResults(false);
      return;
    }
    // Below here will always have selected options
    if (!isMultipleChoice) {
      setShowResults(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedOptionIds), onlyResults, noResults, isMultipleChoice]);
  // ^^^ stringify array because otherwise useEffect considers the array different
  // every render, even if the contents of the array are the same

  if (!pollId) {
    return null;
  }

  return (
    <div ref={externalRef} className={styles.poll}>
      <PollTitle
        pollId={pollId}
        title={title}
        isEditing={isEditing}
        lockEdits={lockEdits}
        isDisabled={isDisabled}
        isLoading={isLoading}
        setLoading={setLoading}
        onUpdateTitle={onUpdateTitle}
        isMultipleChoice={isMultipleChoice}
      />
      <PollOptionList
        pollId={pollId}
        options={options}
        selectedOptionIds={selectedOptionIds}
        totalBallots={totalBallots}
        onlyResults={onlyResults}
        disableVoteMessage={disableVoteMessage}
        isEditing={isEditing}
        lockEdits={lockEdits}
        showResults={showResults}
        isDisabled={isDisabled}
        disabledOptionTooltipText={disabledOptionTooltipText}
        isLoading={isLoading}
        isLoadingBallot={isLoadingBallot}
        setLoading={setLoading}
        onAddOption={onAddOption}
        onRepositionOption={onRepositionOption}
        onRemoveOption={onRemoveOption}
        onUpdateOption={onUpdateOption}
        onSelectOption={onSelectOptionEnhanced}
        onDeselectOption={onDeselectOptionEnhanced}
        afterVoteHook={afterVoteHook}
        mutateProgress={mutateProgress}
        isMultipleChoice={isMultipleChoice}
      />
      <PollControls
        pollId={pollId}
        onlyResults={onlyResults}
        noResults={noResults || totalBallots === null}
        userCanEdit={userCanEdit}
        userCanDelete={userCanDelete}
        userCanPublish={userCanPublish}
        showEditToggle={showEditToggle}
        isEditing={isEditing}
        setEditing={setEditing}
        isPublished={isPublished}
        setPublished={setPublished}
        showResults={showResults}
        setShowResults={setShowResults}
        isDisabled={isDisabled}
        isLoading={isLoading}
        setLoading={setLoading}
        onPublishPoll={onPublishPoll}
        onDeletePoll={onDeletePoll}
        isMultipleChoice={isMultipleChoice}
        totalBallots={totalBallots}
        onUpdateBallotChoice={onUpdateBallotChoice}
      />
    </div>
  );
}

Poll.displayName = 'Poll';
Poll.defaultProps = {
  disabled: false,
  onlyResults: false,
  noResults: false,
  userCanEdit: false,
  userCanDelete: false,
  userCanPublish: false,
  lockEdits: false,
  showEditToggle: false,
  onUpdateTitle: () => true,
  onPublishPoll: () => true,
  onDeletePoll: () => true,
  onAddOption: () => true,
  onRepositionOption: () => true,
  onUpdateOption: () => true,
  onRemoveOption: () => true,
  onSelectOption: () => true,
  onDeselectOption: () => true,
  afterVoteHook: null,
  disableVoteMessage: false,
  isLoadingBallot: false,
  externalRef: undefined
};

export default Poll;
