import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import AuthModal from './AuthModal';
import styles from '../styles/AnonPopconfirm.module.scss';

function AnonPopconfirm({
  disabled, onConfirm, onCancel, overlayClassName,
  open, extraText, overwriteText, okText, children
}) {
  const [showModal, setShowModal] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);

  const DEFAULT_TEXT = 'With your submission you agree to our terms of service and privacy policy.';

  const handleOpenChange = (isOpen) => {
    if (typeof onCancel !== 'function') {
      setForceOpen(isOpen);
    }
  };

  const openModal = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    } else {
      setForceOpen(false);
    }
    setShowModal(true);
  };

  const TITLE = (
    <div>
      <div className={styles.agree}>
        {overwriteText ? (
          <div>
            {overwriteText}
          </div>
        ) : (
          <>
            <div>
              {extraText}
            </div>
            <div>
              {DEFAULT_TEXT}
            </div>
          </>
        )}
      </div>
      <div>
        <Button
          type="link"
          onClick={() => openModal()}
        >
          Sign in instead
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Popconfirm
        overlayClassName={overlayClassName}
        title={TITLE}
        disabled={disabled}
        onConfirm={onConfirm}
        onCancel={onCancel}
        open={open || forceOpen}
        onOpenChange={handleOpenChange}
        okText={okText || 'Continue without account'}
      >
        {children}
      </Popconfirm>
      <AuthModal
        isVisible={showModal}
        setVisible={setShowModal}
        signInSuccessCallback={() => setShowModal(false)}
        signUpSuccessCallback={() => setShowModal(false)}
        cancelCallback={() => setShowModal(false)}
        startingTab="signin"
        closable
        allowAnonymous={false}
      />
    </>
  );
}

AnonPopconfirm.displayName = 'Anon Popconfirm';
AnonPopconfirm.defaultProps = {
  disabled: false,
  onConfirm: undefined,
  onCancel: undefined,
  overlayClassName: undefined,
  open: undefined,
  extraText: undefined,
  overwriteText: undefined,
  okText: undefined,
  children: undefined
};

export default AnonPopconfirm;
