import React from 'react';
import { Result, Button, Typography } from 'antd';
import config from '../infra/env.config';
import Image from './Image';
import errorImg from '../../assets/disconnect-ohno.png';

const { Paragraph } = Typography;

class AppError extends React.Component {
  static reload() {
    window.location.reload();
  }

  static support() {
    const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  }

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      return (
        <div>
          <Result
            title="Unknown Error"
            subTitle={(
              <div>
                Sorry, something went wrong.
                <details>
                  <Paragraph>
                    <pre>{error && error.toString().trim()}</pre>
                    <pre>{errorInfo.componentStack.trim()}</pre>
                  </Paragraph>
                </details>
              </div>
            )}
            extra={(
              <>
                <Button type="primary" onClick={() => AppError.reload()}>Reload Page</Button>
                <Button onClick={() => AppError.support()}>Contact Support</Button>
              </>
)}
            icon={<Image src={errorImg} width={400} />}
          />
        </div>
      );
    }
    return children;
  }
}

export default AppError;
