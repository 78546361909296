const production = {
  environment: 'prod',
  api: {
    BASE_URL: 'https://v49l8n8ybi.execute-api.us-east-1.amazonaws.com/prod',
    DEFAULT_TENANT_ID: 'socialasking-8r6p6',
    TENANT_ID: null
  },
  firebase: {
    API_KEY: 'AIzaSyCymqzXTnuf4degYYqNNTZDQ6cF301Q_UI',
    AUTH_DOMAIN: 'oauth.socialasking.com'
  },
  site: {
    DOMAIN: 'https://embeds-v2.qutee.com/',
    PRIVACY_POLICY_URL: 'https://www.socialasking.com/privacy-policy/',
    TERMS_OF_SERVICE_URL: 'https://www.socialasking.com/terms-of-service/',
    SUPPORT_URL: 'https://socialasking.atlassian.net/servicedesk/customer/portal/1',
    CUSTOM_CSS_URL: 'https://css.socialasking.com/prod/',
    DEFAULT_CSS_URL: 'https://css.socialasking.com/default.css'
  }
};

const development = {
  environment: 'dev',
  api: {
    BASE_URL: 'https://ft2om1ik6i.execute-api.us-east-1.amazonaws.com/dev',
    DEFAULT_TENANT_ID: 'socialasking-cirml',
    TENANT_ID: null
  },
  firebase: {
    API_KEY: 'AIzaSyApfu59gsj76jQubMV25bErJ-icNSJoViM',
    AUTH_DOMAIN: 'groovy-groove-279820.firebaseapp.com'
  },
  site: {
    DOMAIN: 'https://pollembed-devv2.qutee.com/',
    PRIVACY_POLICY_URL: 'https://www.socialasking.com/privacy-policy/',
    TERMS_OF_SERVICE_URL: 'https://www.socialasking.com/terms-of-service/',
    SUPPORT_URL: 'https://socialasking.atlassian.net/servicedesk/customer/portal/1',
    CUSTOM_CSS_URL: 'https://css.socialasking.com/dev/',
    DEFAULT_CSS_URL: 'https://css.socialasking.com/default.css'
  }
};

const localdev = {
  environment: 'localdev',
  api: {
    BASE_URL: 'https://dnwyitmsba.execute-api.us-east-1.amazonaws.com/localdev',
    DEFAULT_TENANT_ID: 'socialasking-cirml',
    TENANT_ID: null
  },
  firebase: {
    API_KEY: 'AIzaSyAq3iCQE00YZCj7XTEIE7XRxNjivCMO0Lc',
    AUTH_DOMAIN: 'qutee-v2-localdev.firebaseapp.com',
    DATABASE_URL: 'https://qutee-v2-localdev.firebaseio.com',
    PROJECT_ID: 'qutee-v2-localdev',
    STORAGE_BUCKET: 'qutee-v2-localdev.appspot.com',
    MESSAGING_SENDER_ID: '842446404315',
    APP_ID: '1:842446404315:web:e195ccd85538595ce3aafe'
  },
  site: {
    DOMAIN: 'https://localhost:3021',
    PRIVACY_POLICY_URL: 'https://www.socialasking.com/privacy-policy/',
    TERMS_OF_SERVICE_URL: 'https://www.socialasking.com/terms-of-service/',
    SUPPORT_URL: 'https://socialasking.atlassian.net/servicedesk/customer/portal/1',
    DEFAULT_CSS_URL: 'https://css.socialasking.com/default.css',
    CUSTOM_CSS_URL: 'https://css.socialasking.com/localdev/'
  }
};

const apiSSL = typeof process.env.REACT_APP_API_SSL !== 'undefined'
  && process.env.REACT_APP_API_SSL === 'true';

const local = {
  environment: 'local',
  api: {
    BASE_URL: `${apiSSL ? 'https' : 'http'}://localhost:3000`,
    DEFAULT_TENANT_ID: 'socialasking-cirml',
    TENANT_ID: null
  },
  firebase: {
    API_KEY: 'AIzaSyAkZXmQNDShtpyckSm6SlbAUPPfCEnSbBM',
    AUTH_DOMAIN: 'qutee-v2-local.firebaseapp.com',
    DATABASE_URL: 'https://qutee-v2-local.firebaseio.com',
    PROJECT_ID: 'qutee-v2-local',
    STORAGE_BUCKET: 'qutee-v2-local.appspot.com',
    MESSAGING_SENDER_ID: '264038928966',
    APP_ID: '1:264038928966:web:7280c989b6b6bbde85b047'
  },
  site: {
    DOMAIN: 'https://localhost:3021',
    PRIVACY_POLICY_URL: 'https://www.socialasking.com/privacy-policy/',
    TERMS_OF_SERVICE_URL: 'https://www.socialasking.com/terms-of-service/',
    SUPPORT_URL: 'https://socialasking.atlassian.net/servicedesk/customer/portal/1',
    CUSTOM_CSS_URL: 'https://css.socialasking.com/local/',
    DEFAULT_CSS_URL: 'https://css.socialasking.com/default.css'
  }
};

// choosing the right environment
const getDefaultConfig = () => {
  if (typeof process.env.REACT_APP_ENV !== 'undefined' && process.env.REACT_APP_ENV === 'local') {
    return local;
  }

  if (process.env.REACT_APP_ENV === 'localdev') {
    return localdev;
  }

  if (process.env.REACT_APP_ENV === 'development') {
    return development;
  }

  if (process.env.REACT_APP_ENV === 'production') {
    return production;
  }

  return development;
};

const config = getDefaultConfig();

export default config;
export const setApiTenant = (tenant) => {
  if (!tenant) {
    config.api.TENANT_ID = config.api.DEFAULT_TENANT_ID;
  } else {
    config.api.TENANT_ID = tenant;
  }
};

export const getApiTenant = () => config.api.TENANT_ID;

export const isLocal = () => {
  if (config.environment === 'local' || config.environment === 'localdev') {
    return true;
  }
  return false;
};

export const isProduction = () => {
  if (config.environment === 'prod') {
    return true;
  }
  return false;
};
