import useSWR from 'swr';

function useGaugeTallies(active, gaugeIds) {
  const getKey = () => {
    if (!active || !gaugeIds || !gaugeIds.length) {
      return null;
    }
    const filterIds = gaugeIds.join(',');
    const filter = `?filter[gauge_id]=in:${filterIds}`;

    return `gauges/tally${filter}`;
  };
  const { data, error, mutate } = useSWR(getKey);

  return {
    tallies: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useGaugeTallies;
