import useSWR from 'swr';

function useQuteeGauges(quteeId, profile) {
  const {
    data, error, isLoading, isValidating, mutate
  } = useSWR(quteeId
    ? [`qutees/${quteeId}/gauges?limit=25&expand=score,rating`, profile ? profile.profile_id : null]
    : null);

  return {
    gauges: data ? data.results : null,
    mutate,
    isLoading: !error && !data,
    isLoadingInitial: isLoading,
    isValidating,
    isError: error
  };
}

export default useQuteeGauges;
