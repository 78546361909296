import React, { useState, useEffect } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { Skeleton, Result } from 'antd';
import { subject } from '@casl/ability';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException } from '../../common/utils';
import usePoll from '../../poll/state/usePoll';
import usePollOptions from '../../poll/state/usePollOptions';
import useMyPollBallot from '../../poll/state/useMyPollBallot';
import useFirstLoadPoll from '../../poll/state/useFirstLoadPoll';
import Poll from '../../poll/components/Poll';

// This component is for connecting the poll component with the App's business logic
function PollContainer({
  pollId, afterVoteHook, disableVoteMessage,
  initialPoll, initialOptions, initialUserBallots,
  setShouldRefreshPolls, isMainEngagement, setAllowAnonymous,
  isLoadingInitial, mutateProgress, externalRef
}) {
  const { state: userState } = useUserContext();
  // the list of simple option objects to use in the component
  const [optionList, setOptionList] = useState([]);
  // first load should do the poll, its options, and user ballots all at once
  // then hand them off to individual pieces for mutation based on user interaction
  const [useFirst, setUseFirst] = useState(true);

  const {
    poll: firstLoadPoll
    // options: firstLoadOptions,
    // ballot: firstLoadBallot
  } = useFirstLoadPoll(pollId, initialPoll, userState.profile);
  const {
    poll,
    mutate: pollMutate,
    isLoading: isLoadingPoll,
    isError: isErrorPoll
  } = usePoll(pollId, initialPoll);

  const canView = userState.isVerified && userState.ability.can('view', subject('Poll', poll));
  const canEdit = userState.isVerified && userState.ability.can('update', subject('Poll', poll));

  const hideResultsSetting = !canView && poll && poll.settings && poll.settings.results_hidden;
  const resultsOnly = !canView && poll && poll.status === 'inactive';
  const voteBeforeResults = !canView && poll && poll.settings && poll.settings.vote_before_results;
  const allowAnonymous = poll && poll.settings && !poll.settings.prevent_anonymous_response;

  const isMultipleChoice = poll?.ballot_choice === 'multiple';

  useEffect(() => {
    if (isMainEngagement && (typeof setAllowAnonymous === 'function')) {
      setAllowAnonymous(allowAnonymous);
    }
  }, [allowAnonymous, isMainEngagement, setAllowAnonymous]);

  const {
    options,
    mutate: optionsMutate,
    isLoading: isLoadingOptions,
    isError: isErrorOptions
  } = usePollOptions(pollId, initialOptions);
  const {
    myBallots,
    mutate: ballotMutate,
    isError: isErrorBallot,
    isLoading: isLoadingBallot
  } = useMyPollBallot(
    pollId,
    initialUserBallots,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  useEffect(() => {
    if (useFirst && firstLoadPoll) {
      const { options: firstOptions, ballot: firstBallot, ...remainingPoll } = firstLoadPoll;
      pollMutate(remainingPoll, false);
      optionsMutate([...firstOptions], false);
      if (firstBallot) {
        ballotMutate({ ...firstBallot }, false);
      }
      setUseFirst(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoadPoll, useFirst]);

  // Convert options to a simpler standardized list for the component
  useEffect(() => {
    if (options && !isLoadingOptions && !isErrorOptions) {
      const list = [];
      options.forEach((option) => {
        const simpleOption = {
          id: parseInt(option.poll_option_id, 10),
          text: option.name,
          count: option.ballot_count == null
            ? null : parseInt(option.ballot_count, 10),
          user_count: option.ballot_user_count == null
            ? null : parseInt(option.ballot_user_count, 10)
        };
        list.push(simpleOption);
      });
      setOptionList(list);
    }
  }, [options, isLoadingOptions, isErrorOptions]);

  // TODO: How to clean this section up so it's more organized?
  // How to effectively make an external file for holding API calls
  // that utilize hooks?
  const { patch: patchTitle, response: patchTitleResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchPublished, response: patchPublishedResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchBallotChoice, response: patchBallotChoiceResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { delete: deletePoll, response: deletePollResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE
  });
  const { post: postOption, response: postOptionResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: repositionOption, response: repositionOptionResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE
  });
  const { delete: deleteOption, response: deleteOptionResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE
  });
  const { patch: updateOption, response: updateOptionResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE
  });
  const { post: postBallot, response: postBallotResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: updateBallot, response: updateBallotResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { delete: deleteBallot, response: deleteBallotResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const onUpdateTitle = async (pollId, newTitle) => {
    const data = {
      title: newTitle
    };
    const original = { ...poll };
    pollMutate({ ...poll, ...data }, false);
    const updatedPoll = await patchTitle(`${pollId}`, data);
    if (patchTitleResponse.ok) {
      pollMutate(updatedPoll);
    } else {
      pollMutate(original);
      throw new ApiException(updatedPoll);
    }
  };

  const onPublishPoll = async (pollId) => {
    const data = {
      status: 'active'
    };
    const original = { ...poll };
    pollMutate({ ...poll, ...data }, false);
    const updatedPoll = await patchPublished(`${pollId}`, data);
    if (patchPublishedResponse.ok) {
      pollMutate(updatedPoll);
    } else {
      pollMutate(original);
      throw new ApiException(updatedPoll);
    }
  };

  const onUpdateBallotChoice = async (pollId, isMultipleChoice) => {
    const data = {
      ballot_choice: isMultipleChoice ? 'multiple' : 'single'
    };
    const original = { ...poll };
    pollMutate({ ...poll, ...data }, false);
    const updatedPoll = await patchBallotChoice(`${pollId}`, data);
    if (patchBallotChoiceResponse.ok) {
      pollMutate(updatedPoll);
    } else {
      pollMutate(original);
      throw new ApiException(updatedPoll);
    }
  };

  // TODO: What should we do after poll deletion? Give them a chance to recover it?
  const onDeletePoll = async (pollId) => {
    const data = {
      deleted: true
    };
    const original = { ...poll };
    pollMutate({ ...poll, ...data }, false);
    const deletedPoll = await deletePoll(`${pollId}`);
    if (deletePollResponse.ok) {
      pollMutate();
      setShouldRefreshPolls(true);
    } else {
      pollMutate(original);
      throw new ApiException(deletedPoll);
    }
  };

  const onAddOption = async (pollId, optionText) => {
    const data = {
      name: optionText
    };
    const newOption = await postOption(`${pollId}/options`, data);
    if (postOptionResponse.ok) {
      const newList = [...options];
      newList.push(newOption);
      optionsMutate(newList);
    } else {
      throw new ApiException(newOption);
    }
  };

  const onRepositionOption = async (pollId, optionId, position) => {
    const data = {
      // The component internally uses 0-based positions
      // We use 1-based positions in the API
      position: position + 1
    };
    const result = await repositionOption(`${pollId}/options/${optionId}`, data);
    if (repositionOptionResponse.ok) {
      optionsMutate();
    } else {
      throw new ApiException(result);
    }
  };

  const onRemoveOption = async (pollId, optionId) => {
    const result = await deleteOption(`${pollId}/options/${optionId}`);
    if (deleteOptionResponse.ok) {
      optionsMutate();
    } else {
      throw new ApiException(result);
    }
  };

  const onUpdateOption = async (pollId, optionId, text) => {
    const data = {
      name: text
    };
    const result = await updateOption(`${pollId}/options/${optionId}`, data);
    if (updateOptionResponse.ok) {
      optionsMutate();
    } else {
      throw new ApiException(result);
    }
  };

  const onSelectOption = async (pollId, optionId) => {
    const data = {
      poll_option_id: optionId
    };
    if (myBallots?.length && !isMultipleChoice) {
      const ballotId = myBallots[0].poll_ballot_id;
      const updatedBallot = await updateBallot(`${pollId}/ballots/${ballotId}`, data);
      if (updateBallotResponse.ok) {
        ballotMutate(updatedBallot);
        optionsMutate();
        pollMutate();
      } else {
        throw new ApiException(updatedBallot);
      }
    } else {
      const newBallot = await postBallot(`${pollId}/ballots`, data);
      if (postBallotResponse.ok) {
        ballotMutate(newBallot);
        optionsMutate();
        pollMutate();
      } else {
        throw new ApiException(newBallot);
      }
    }
  };

  const onDeselectOption = async (pollId, optionId) => {
    const ballotId = myBallots?.filter((ballot) => ballot.poll_option_id === optionId)[0]
      ?.poll_ballot_id;
    const result = await deleteBallot(`${pollId}/ballots/${ballotId}`);
    if (deleteBallotResponse.ok) {
      ballotMutate({});
      optionsMutate();
      pollMutate();
    } else {
      throw new ApiException(result);
    }
  };

  const shouldHideResults = () => hideResultsSetting || (voteBeforeResults && !myBallots?.length);

  const canUserInteract = () => {
    if (userState.isPending) {
      return false;
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return false;
    }
    if (resultsOnly) {
      return false;
    }
    if (isLoadingInitial) {
      return false;
    }
    return true;
  };

  const getCantInteractReason = () => {
    if (userState.isPending) {
      return 'You must verify your email before voting.';
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return 'Anonymous users are not allowed to vote on this poll.';
    }
    if (resultsOnly) {
      return 'This poll is no longer accepting responses.';
    }
    if (isLoadingInitial) {
      return 'Loading data';
    }
    return '';
  };

  const getSelectedOptionIds = () => {
    if (isErrorBallot) {
      return null;
    }
    if (myBallots?.length) {
      const optionIds = myBallots.reduce((acc, cur) => {
        acc.push(cur.poll_option_id);
        return acc;
      }, []);
      return optionIds;
    }
    if (isLoadingBallot) {
      return null;
    }
    return [];
  };

  const isLoading = () => (
    isLoadingPoll
  );

  const hasError = () => isErrorPoll || isErrorOptions;

  if (hasError() && !isLoading()) {
    return <Result status="error" title="Poll Failed To Load" />;
  }
  if (isLoading()) {
    return <Skeleton active />;
  }

  return (
    <Poll
      externalRef={externalRef}
      pollId={poll.poll_id}
      title={poll.title}
      published={poll && (poll.status === 'active')}
      options={optionList}
      selectedOptionIds={getSelectedOptionIds()}
      onlyResults={resultsOnly}
      noResults={shouldHideResults()}
      disableVoteMessage={disableVoteMessage}
        // User roles are resolved here to determine capabilities,
        // along with other business logic determinations
      disabled={!canUserInteract()}
      disabledOptionTooltipText={getCantInteractReason()}
      userCanEdit={canEdit}
      userCanDelete={userState.isVerified && userState.ability.can('update', subject('Poll', poll), 'deleted')}
      userCanPublish={canEdit}
      showEditToggle
      onUpdateTitle={onUpdateTitle}
      onPublishPoll={onPublishPoll}
      onUpdateBallotChoice={onUpdateBallotChoice}
      onDeletePoll={onDeletePoll}
      onAddOption={onAddOption}
      onRepositionOption={onRepositionOption}
      onRemoveOption={onRemoveOption}
      onUpdateOption={onUpdateOption}
      onSelectOption={onSelectOption}
      onDeselectOption={onDeselectOption}
      afterVoteHook={afterVoteHook}
      loadingBallot={isLoadingBallot}
      mutateProgress={mutateProgress}
      isMultipleChoice={isMultipleChoice}
      totalBallots={isMultipleChoice ? poll?.ballot_user_count : poll?.ballot_count}
    />
  );
}

PollContainer.defaultProps = {
  mode: null,
  afterVoteHook: null,
  disableVoteMessage: false,
  initialPoll: null,
  initialOptions: null,
  initialUserBallots: null,
  setShouldRefreshPolls: () => {},
  isMainEngagement: false,
  setAllowAnonymous: null,
  isLoadingInitial: false,
  mutateProgress: null,
  externalRef: undefined
};

export default PollContainer;
