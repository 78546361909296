import useSWR from 'swr';

function useFirstLoadGauge(gaugeId, initial, profileId) {
  const { data, error, mutate } = useSWR((gaugeId && !initial)
    ? [`gauges/${gaugeId}?expand=score,rating`, profileId]
    : null);

  return {
    gauge: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useFirstLoadGauge;
