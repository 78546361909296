import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import {
  Button, Form, Input, App, Typography, Collapse, Checkbox
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from '../styles/CreatePoll.module.scss';

const { Title } = Typography;
const { Panel } = Collapse;

function CreatePoll({
  quteeId, onCreatePoll
}) {
  const { message } = App.useApp();
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const { input } = inputRef.current;
      // @ts-ignore
      input.focus();
    }
  }, []);

  const resetForm = () => {
    form.resetFields();
  };

  const submitPoll = async (values) => {
    const loadingKey = 'createPollRequest';
    const successKey = 'createPollSuccess';
    const errorKey = 'createPollError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Submitting new poll...', key: loadingKey });
    try {
      await onCreatePoll(quteeId, {
        title: values.title, options: values.options, multiselect: values.multiselect
      });
      resetForm();
      message.destroy(loadingKey);
      message.success({ content: 'Poll successfully created', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setValid(!hasErrors);
  };

  const forceValidation = useCallback((async () => {
    form.validateFields()
      .then(() => {
        setValid(true);
      })
      .catch(() => {
        setValid(false);
      });
  }), [form]);

  return (
    <div className={styles.container}>
      <Form
        className={styles.form}
        name="create-poll-form"
        form={form}
        onFieldsChange={handleFormChange}
        onFinish={submitPoll}
        layout="vertical"
      >
        <Title className={styles.instruction} level={4}>
          Add a new poll
        </Title>
        <Form.Item
          label="Poll prompt"
          name="title"
          rules={[
            {
              required: true,
              whitespace: true,
              type: 'string',
              message: 'Please enter a prompt for the poll'
            }
          ]}
        >
          <Input
            ref={inputRef}
            disabled={isLoading}
            autoFocus
          />
        </Form.Item>
        <Collapse
          ghost
        >
          <Panel header="Advanced Settings" key="advanced">
            <div className={styles.checkboxContainer}>
              <Form.Item
                name="multiselect"
                valuePropName="checked"
              >
                <Checkbox>
                  Enable multi-selection
                </Checkbox>
              </Form.Item>
            </div>
            <Form.List
              name="options"
              rules={[
                {
                  validator: async (_, names) => {
                    if (names) {
                      if ((names.length < 2)) {
                        return Promise.reject(new Error('Poll must have at least two options'));
                      }
                      if (new Set(names).size !== names.length) {
                        return Promise.reject(new Error('Options should have unique values'));
                      }
                      if (names.filter((name) => !name).length) {
                        return Promise.reject(new Error('Options cannot have an empty value'));
                      }
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <div className={styles.options}>
                    {fields.map((field, index) => (
                      <div className={styles.optionContainer} key={field.key}>
                        <div className={styles.option}>
                          <Form.Item
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            label={`Option ${index + 1}`}
                          >
                            <Input
                              disabled={isLoading}
                              maxLength={50}
                              autoFocus
                              onChange={() => forceValidation()}
                            />
                          </Form.Item>
                          <CloseOutlined
                            className={isLoading ? styles.removeDisabled : styles.remove}
                            onClick={() => {
                              if (!isLoading) {
                                remove(field.name);
                                forceValidation();
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.optionErrors}>
                    <Form.ErrorList errors={errors} />
                  </div>
                  <div>
                    <Form.Item>
                      <Button
                        className={styles.add}
                        type="text"
                        size="large"
                        onClick={async () => { add(); forceValidation(); }}
                        disabled={isLoading}
                      >
                        + Add Poll Option
                      </Button>
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>
          </Panel>
        </Collapse>
        <div className={styles.submitContainer}>
          <Form.Item>
            <Button
              className={(isLoading || !isValid) ? styles.submit : styles.submitValid}
              type="primary"
              size="large"
              htmlType="submit"
              disabled={isLoading || !isValid}
            >
              Create New Poll
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

CreatePoll.displayName = 'CreatePoll';
CreatePoll.defaultProps = {
  isEditing: false,
  lockEdits: false,
  isDisabled: false,
  onUpdate: () => true
};

export default CreatePoll;
