import React, { useState } from 'react';
import {
  App, Typography
} from 'antd';
import styles from '../styles/CommentBinPrompt.module.scss';

const { Title } = Typography;

function CommentBinPrompt({
  commentBinId, commentPrompt, allowEditing, updateCommentBin
}) {
  const { message } = App.useApp();
  const [isLoading, setLoading] = useState(false);

  const placeholder = '(Prompt not set)';

  const changeCommentPrompt = async (prompt) => {
    const loadingKey = 'updateCommentPromptRequest';
    const successKey = 'updateCommentPromptSuccess';
    const errorKey = 'updateCommentPromptError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating comment prompt...', key: loadingKey });
    try {
      await updateCommentBin(commentBinId, prompt);
      message.destroy(loadingKey);
      message.success({ content: 'Comment prompt successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Title
        className={styles.prompt}
        level={4}
        editable={allowEditing && !isLoading ? { text: commentPrompt || '', onChange: changeCommentPrompt } : false}
      >
        {commentPrompt || (allowEditing && placeholder)}
      </Title>
    </div>
  );
}

CommentBinPrompt.displayName = 'Comment Bin Prompt';
CommentBinPrompt.defaultProps = {
  allowEditing: false,
  isEditMode: false
};

export default CommentBinPrompt;
