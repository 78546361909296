import React, { useEffect, useState, useContext } from 'react';
import {
  Button, App, Result
} from 'antd';
import AuthLoader from './AuthLoader';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import { sendVerificationEmail, verifyEmail } from '../state/api';

function VerifyEmail({ actionCode, afterActionHook, notLoggedInHook }) {
  const { message } = App.useApp();
  const { state: userState, dispatch: userDispatch } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const [isLoading, setLoading] = useState(false);
  const [shouldResend, setShouldResend] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);
  const [hasVerified, setHasVerified] = useState(false);
  const [isLoadingEmailRequest, setLoadingEmailRequest] = useState(false);

  const { actionUrl, continueUrl } = embedConfig;

  const handleVerificationError = (error, key) => {
    console.log('Verification error');
    console.log(error);
    message.error({ content: error, key, duration: 5 });
    setShouldResend(true);
  };

  const followContinueUrl = () => {
    if (continueUrl) {
      const curUrl = new URL(window.location.toString());
      const contUrl = new URL(continueUrl);
      const contPath = `${contUrl.pathname}${contUrl.search}${contUrl.hash}`;
      const checkBase = `${curUrl.origin}${curUrl.pathname}`;
      const contBase = `${contUrl.origin}${contUrl.pathname}`;
      if (contBase === checkBase) {
        embedConfig.authUrl = continueUrl;
        window.history.replaceState({}, document.title, `${contPath}`);
        // @ts-ignore
        // window.location = contUrl.toString();
      } else {
        const newWindow = window.open(continueUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      }
    }
  };

  const verifyUser = async () => {
    const loadingKey = 'verifyEmailRequest';
    const successKey = 'verifyEmailSuccess';
    const errorKey = 'verifyEmailError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Verifying email...', key: loadingKey });
    try {
      const userInfo = await verifyEmail({ code: actionCode });
      message.destroy(loadingKey);
      message.success({ content: 'Verification successful', key: successKey });
      setHasVerified(true);
      if (typeof afterActionHook === 'function') {
        afterActionHook();
      }
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
      followContinueUrl();
    } catch (err) {
      message.destroy(loadingKey);
      handleVerificationError(err, errorKey);
    } finally {
      setLoading(false);
    }
  };

  // TODO: Accomplish this in a better way
  useEffect(() => {
    const proceed = () => {
      if (typeof afterActionHook === 'function') {
        afterActionHook();
      }
      followContinueUrl();
    };

    if (!actionCode) {
      proceed();
    }
    const infoKey = 'checkVerifiedInfo';
    const errorKey = 'checkVerifiedError';
    message.destroy(infoKey);
    message.destroy(errorKey);

    if (!userState.isPreFirstLoad) {
      if (userState.isLoggedIn && !userState.isVerified) {
        verifyUser();
      } else if (userState.isLoggedIn && hasVerified) {
        proceed();
      } else if (userState.isLoggedIn) {
        message.info({ content: 'User already verified', key: infoKey });
        proceed();
      } else {
        message.error({ content: 'Please log in to verify your account', key: errorKey, duration: 5 });
        if (typeof notLoggedInHook === 'function') {
          notLoggedInHook();
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCode, userState]);

  const resendVerificationEmail = async () => {
    const loadingKey = 'sendEmailRequest';
    const successKey = 'sendEmailSuccess';
    const errorKey = 'sendEmailError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Sending verification email...', key: loadingKey });
    setLoadingEmailRequest(true);
    try {
      await sendVerificationEmail(continueUrl, actionUrl);
      setEmailSent(true);
      message.destroy(loadingKey);
      message.success({ content: 'Verification email successfully sent', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoadingEmailRequest(false);
    }
  };

  if (isLoading || userState.isPreFirstLoad) {
    return <AuthLoader />;
  }
  if (userState.isLoggedIn) {
    return (
      <div>
        {isLoading && <AuthLoader />}
        <div>
          {shouldResend
            && (
              <Result
                status="error"
                title="Invalid Confirmation Code"
                subTitle="The code in the email link is expired or invalid.
              Click to resend a verification email and follow the enclosed link."
                extra={[
                  <Button
                    key="resend"
                    loading={isLoadingEmailRequest}
                    onClick={resendVerificationEmail}
                    disabled={isEmailSent}
                  >
                    Resend
                  </Button>
                ]}
              />

            )}
          {hasVerified
            && (
              <Result
                status="success"
                title="Email Verified"
                subTitle="Your account is succesfully verified. You may close this window."
                extra={[
                  <Button key="return" type="link" href={continueUrl}>
                    Return to previous page.
                  </Button>
                ]}
              />
            )}
        </div>
      </div>
    );
  }

  return null;
}

VerifyEmail.defaultProps = {
  afterActionHook: () => {},
  notLoggedInHook: () => {}
};

export default VerifyEmail;
