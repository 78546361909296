import React, { useContext } from 'react';
import { Alert, Button, App } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';

import EmbedContext from '../../embed/providers/EmbedProvider';
import { useIsSm } from '../state/responsiveChecks';
import { sendVerificationEmail, apiLogin } from '../../auth/state/api';
import styles from './styles/VerificationBanner.module.scss';

function VerificationBanner() {
  const { message } = App.useApp();
  const embedConfig = useContext(EmbedContext);
  const { dispatch: userDispatch } = useUserContext();
  const { actionUrl, continueUrl } = embedConfig;

  const isSmallScreen = !useIsSm();

  const handleResendError = (err, key) => {
    switch (err.name) {
      case 'Rate_Limit_Error':
        message.error({
          content: 'Verification email already sent. Please check your emails, or wait for one minute before resending.', key, duration: 8
        });
        break;
      default:
        message.error({ content: err?.details?.issue || err.message, key, duration: 5 });
    }
  };

  const resend = async () => {
    const loadingKey = 'newEmailRequest';
    const successKey = 'newEmailSuccess';
    const errorKey = 'newEmailError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Requesting new email...', key: loadingKey });
    try {
      await sendVerificationEmail(continueUrl, actionUrl);
      message.destroy(loadingKey);
      message.success({ content: 'Verification email successfully sent', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      handleResendError(err, errorKey);
    }
  };

  const confirmVerified = async () => {
    const loadingKey = 'confirmVerifiedRequest';
    const successKey = 'confirmedVerifiedSuccess';
    const errorKey = 'confirmedVerifiedError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Confirming verification status...', key: loadingKey });
    try {
      const userInfo = await apiLogin();
      message.destroy(loadingKey);
      if (userInfo?.profile?.account_status === 'pending') {
        message.error({ content: 'User is not verified', key: errorKey, duration: 5 });
      } else {
        message.success({ content: 'Verification confirmed', key: successKey });
        userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
        const { href } = window.location;
        if (continueUrl && continueUrl !== href) {
          const newWindow = window.open(continueUrl, '_blank', 'noopener,noreferrer');
          if (newWindow) {
            newWindow.opener = null;
          }
        }
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const getAlertContent = () => {
    const mainText = (
      <span className={styles.message}>
        Please verify your email address via the link emailed to you in order to access full
        site capabilities. If you do not see the email, check your spam folder and mark as not spam.
      </span>
    );

    const resendButton = (
      <Button
        type="primary"
        key="resend"
        onClick={resend}
      >
        Resend email
      </Button>
    );

    const verifiedButton = (
      <Button
        type="default"
        className={styles.confirm}
        key="confirm"
        onClick={confirmVerified}
      >
        I have verified
      </Button>
    );

    if (isSmallScreen) {
      return (
        <div>
          <div>{mainText}</div>
          <div className={styles.buttons}>
            {resendButton}
            {verifiedButton}
          </div>
        </div>
      );
    }

    return (
      <div>
        {mainText}
        {resendButton}
        {verifiedButton}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Alert
        showIcon
        icon={<MailOutlined className={styles.icon} />}
        type="warning"
        message={getAlertContent()}
      />
    </div>
  );
}

export default VerificationBanner;
