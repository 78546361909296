import React, {
  useRef, useState, useEffect
} from 'react';
import { Button, App, Typography } from 'antd';
import {
  RightOutlined, DownOutlined
} from '@ant-design/icons';
import { IconMessageDots } from '@tabler/icons-react';
import AnimateHeight from 'react-animate-height';
import QuteeComment from './QuteeComment';
import ThreadModal from '../../commentBin/components/ThreadModal';
import styles from '../styles/QuteeCommentThread.module.scss';

const { Text } = Typography;

/**
 * @typedef {Object} CommentThread
 */

/**
 * The container for a single comment thread within the qutee's comment listing.
 * @param {object} props
 * @param {number} props.commentBinId
 * @param {CommentThread} props.thread A comment with a reply thread
 * @param {number} props.visualDepth
 * @param {boolean} props.collapseReplies
 * @param {string} props.labelPrompt
 * @param {object[]} props.labels
 * @param {object[]} props.replies
 * @param {string} props.filters
 * @param {object} props.filterData
 * @param {function} props.loadReplies
 * @param {function} props.continueThread
 * @param {function} props.createComment
 * @param {function} props.updateComment
 * @param {function} props.deleteComment
 * @param {function} props.likeComment
 * @param {function} props.removeCommentLike
 * @param {function} props.dislikeComment
 * @param {function} props.removeCommentDislike
 * @param {function} props.reportComment
 * @param {function} props.removeCommentReport
 * @param {boolean} props.userCanInteract
 * @param {boolean} props.userCanEditQutee
 * @param {function} props.checkUserCanEditComment
 * @param {boolean} props.allowAnonymous
 * @param {string} props.cantInteractReason
 * @param {function} props.continueAnonymously
 * @param {boolean} props.isPreview
 * @param {function} props.refreshProgress
 */
function QuteeCommentThread({
  commentBinId, thread, visualDepth, collapseReplies, labelPrompt,
  labels, replies: initialReplies, filters, filterData, loadReplies, continueThread,
  createComment, updateComment, deleteComment, likeComment, removeCommentLike,
  dislikeComment, removeCommentDislike, userCanInteract, cantInteractReason,
  userCanEditQutee, checkUserCanEditComment, allowAnonymous, continueAnonymously,
  isPreview, refreshProgress, reportComment, removeCommentReport
}) {
  const PAGE_SIZE = 10;
  const TRANSITION_TIME_MS = 400;
  const CLOSED_HEIGHT_PX = 50;
  const {
    replies_count: repliesCount, pinned, starred, comment_id: commentId,
    has_deeper_replies: hasDeeperReplies, deleted, has_more_replies: hasMoreRepliesInitial
  } = thread;

  const checkCollapsed = () => (visualDepth > 0) && collapseReplies;

  const { message } = App.useApp();
  const [replies, setReplies] = useState([]);
  const [isCollapsing, setCollapsing] = useState(false);
  const [isClosed, setClosed] = useState(checkCollapsed);
  const [isHovering, setHovering] = useState(false);
  const [isPinned, setPinned] = useState(false);
  const [isStarred, setStarred] = useState(false);
  const [repliesPage, setRepliesPage] = useState(1);
  // Override for handling initial state when counts hidden, as well as
  // when reaching the end of loading more replies
  const [hasMoreOverride, setHasMoreOverride] = useState(hasMoreRepliesInitial);
  const [hasMoreReplies, setHasMoreReplies] = useState(
    replies && ((replies.length < repliesCount) || hasMoreOverride)
  );
  const [isLoadingReplies, setisLoadingReplies] = useState(false);
  const [continueModalVisible, setContinueModalVisible] = useState(false);
  const [continuation, setContinuation] = useState(null);
  const [heightCss, setHeightCss] = useState('100%');
  const [isDeleted, setDeleted] = useState(deleted);
  const element = useRef();

  useEffect(() => {
    setReplies(initialReplies);
  }, [initialReplies]);

  useEffect(() => {
    setPinned(!!pinned);
  }, [pinned]);

  useEffect(() => {
    setStarred(!!starred);
  }, [starred]);

  useEffect(() => {
    setHasMoreReplies(replies && ((replies.length < repliesCount) || hasMoreOverride));
  }, [replies, repliesCount, hasMoreOverride]);

  useEffect(() => {
    setClosed(checkCollapsed());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseReplies]);

  useEffect(() => {
    setDeleted(!!deleted);
  }, [deleted]);

  // State handlers
  const removeComment = async (commentId) => {
    const loadingKey = 'deleteCommentRequest';
    const successKey = 'deleteCommentSuccess';
    const errorKey = 'deleteCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Deleting comment...', key: loadingKey });
    try {
      await deleteComment(commentId);
      setDeleted(true);
      message.destroy(loadingKey);
      message.success({ content: 'The comment has been deleted', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const handleContinueThread = async (parentId) => {
    const errorKey = 'continueThreadError';
    message.destroy(errorKey);
    try {
      const deeperThreads = await continueThread(parentId);
      setContinuation(deeperThreads);
      setContinueModalVisible(true);
    } catch (err) {
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const buildReplies = () => {
    const descendants = [];
    replies.forEach((reply) => {
      const component = (
        <QuteeCommentThread
          key={reply.comment_id}
          commentBinId={commentBinId}
          thread={reply}
          labelPrompt={labelPrompt}
          labels={labels}
          replies={reply.replies}
          filters={filters}
          filterData={filterData}
          visualDepth={visualDepth + 1}
          collapseReplies={collapseReplies}
          loadReplies={loadReplies}
          continueThread={continueThread}
          createComment={createComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          likeComment={likeComment}
          removeCommentLike={removeCommentLike}
          dislikeComment={dislikeComment}
          removeCommentDislike={removeCommentDislike}
          reportComment={reportComment}
          removeCommentReport={removeCommentReport}
          userCanInteract={userCanInteract}
          cantInteractReason={cantInteractReason}
          userCanEditQutee={userCanEditQutee}
          checkUserCanEditComment={checkUserCanEditComment}
          allowAnonymous={allowAnonymous}
          continueAnonymously={continueAnonymously}
          isPreview={isPreview}
          refreshProgress={refreshProgress}
        />
      );
      descendants.push(component);
    });

    return descendants;
  };

  const finishCollapseChange = () => {
    setCollapsing(false);
    if (element && element.current && !isClosed) {
      // @ts-ignore
      const { top } = element.current.getBoundingClientRect();
      // Scroll to collapsed comment if it's out of view
      if (!(top >= 0 && top <= window.innerHeight)) {
        // @ts-ignore
        element.current.scrollIntoView();
      }
    }
  };

  const changeOpen = () => {
    setCollapsing(true);
    if (isClosed) {
      setHeightCss('100%');
    } else {
      setHeightCss(`${CLOSED_HEIGHT_PX}px`);
    }
    setTimeout(() => {
      finishCollapseChange();
    }, TRANSITION_TIME_MS);
    setClosed(!isClosed);
  };

  const loadRepliesHandler = async () => {
    setisLoadingReplies(true);
    const setNewReplies = async () => {
      const errorKey = 'loadNewRepliesError';
      message.destroy(errorKey);
      try {
        const [loadedReplies, hasMore] = await loadReplies(
          commentBinId,
          commentId,
          repliesPage,
          PAGE_SIZE
        );
        const existingReplyIds = replies.reduce((acc, reply) => {
          acc.push(reply.comment_id);
          return acc;
        }, []);
        const newReplies = [
          ...replies,
          ...(loadedReplies.filter((reply) => !existingReplyIds.includes(reply.comment_id)))
        ];
        setRepliesPage(repliesPage + 1);
        setReplies(newReplies);
        setHasMoreOverride(hasMore);
      } catch (err) {
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      } finally {
        setisLoadingReplies(false);
      }
    };
    setNewReplies();
  };

  const createReply = async (commentBinId, entryText, labelId, parentId, authorName, confirmAppropriate) => {
    const reply = await createComment(commentBinId, entryText, labelId, parentId, authorName, confirmAppropriate);
    const newReplies = [reply];
    if (replies?.length) {
      newReplies.push(...replies);
    }
    setReplies(newReplies);
  };

  const getDepthLineClassName = () => {
    let name = '';
    if (isPinned) {
      name = styles.depthLinePinned;
    } else if (visualDepth % 2 !== 0) {
      name = styles.depthLineAlternate;
    } else {
      name = styles.depthLine;
    }

    return name;
  };

  const getThreadClassName = () => {
    let name = '';
    if (isPinned) {
      name = styles.threadPinned;
    } else if (visualDepth % 2 !== 0) {
      name = styles.threadAlternate;
    } else {
      name = styles.thread;
    }

    return name;
  };

  const getCollapseContainerClassName = () => {
    let name = '';
    if (isPinned) {
      name = styles.collapseContainerPinned;
    } else if (visualDepth % 2 !== 0) {
      name = styles.collapseContainerAlternate;
    } else {
      name = styles.collapseContainer;
    }
    if (isHovering) {
      name = `${name} hovering`;
    }
    return name;
  };

  return (
    <div>
      <AnimateHeight
        className={styles.container}
        height={isClosed ? CLOSED_HEIGHT_PX : '100%'}
        duration={TRANSITION_TIME_MS}
      >
        <div
          className={getThreadClassName()}
          ref={element}
        >
          <div
            className={getCollapseContainerClassName()}
            style={{ height: `${heightCss}`, transition: `height ${TRANSITION_TIME_MS}ms` }}
            onClick={changeOpen}
            onKeyPress={changeOpen}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            role="button"
            tabIndex={0}
          >
            {(isHovering || isClosed) // always show arrow on closed ones.
              && (
                isClosed ? <RightOutlined className={styles.collapseArrow} />
                  : <DownOutlined className={styles.collapseArrow} />
              )}
          </div>
          <hr className={getDepthLineClassName()} />
          <QuteeComment
            key={thread.comment_id}
            commentBinId={commentBinId}
            comment={thread}
            labelPrompt={labelPrompt}
            labels={labels}
            filterData={filterData}
            isClosed={isClosed && !isCollapsing}
            isPinned={isPinned}
            setPinned={setPinned}
            isStarred={isStarred}
            setStarred={setStarred}
            createReply={createReply}
            updateComment={updateComment}
            likeComment={likeComment}
            deleteComment={removeComment}
            removeCommentLike={removeCommentLike}
            dislikeComment={dislikeComment}
            removeCommentDislike={removeCommentDislike}
            reportComment={reportComment}
            removeCommentReport={removeCommentReport}
            userCanInteract={userCanInteract}
            cantInteractReason={cantInteractReason}
            userCanEditQutee={userCanEditQutee}
            checkUserCanEditComment={checkUserCanEditComment}
            mobileOpenHandler={changeOpen}
            allowAnonymous={allowAnonymous}
            continueAnonymously={continueAnonymously}
            isPreview={isPreview}
            refreshProgress={refreshProgress}
            deleted={isDeleted}
          />
          {!isClosed && hasDeeperReplies && (
            <Button type="link" onClick={() => handleContinueThread(thread.comment_id)}>
              Continue thread
            </Button>
          )}
          {((!isClosed || (isClosed && isCollapsing)) && replies?.length > 0) && (
          <div className={styles.replies}>
            {buildReplies()}
            {hasMoreReplies && (
            <Button
              className={styles.loadReplies}
              type="text"
              onClick={() => !isLoadingReplies && loadRepliesHandler()}
              disabled={isLoadingReplies}
            >
              <IconMessageDots className={styles.loadMoreIcon} />

              <Text className={styles.loadMoreLink}>
                Load more replies
                {repliesCount ? ` (${repliesCount - replies.length})` : null}
              </Text>
            </Button>
            )}
          </div>
          )}
        </div>
      </AnimateHeight>
      <ThreadModal
        visible={continueModalVisible}
        setVisible={setContinueModalVisible}
      >
        {continuation}
      </ThreadModal>
    </div>
  );
}

QuteeCommentThread.displayName = 'Qutee Comment Thread';
// QuteeCommentThread.defaultProps = {

// };

export default QuteeCommentThread;
