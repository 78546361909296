import {
  Button, App, Switch
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import React, { useContext, useEffect, useState } from 'react';
import StyleEditor from 'react-style-editor';
import useFetch, { CachePolicies } from 'use-http';
import EmbedContext from '../../embed/providers/EmbedProvider';
import styles from '../styles/CustomStyleEditor.module.scss';
import config from '../../common/infra/env.config';
import { ApiException } from '../../common/utils';

function CustomStyleEditor() {
  const { message } = App.useApp();
  const embedConfig = useContext(EmbedContext);
  const { id: quteeId } = embedConfig;
  const [css, setCss] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentEditor, setCurrentEditor] = useState('css');
  const customStyleUrl = `${config.site.CUSTOM_CSS_URL}${quteeId}.custom.css`;
  const defaultStyleUrl = config.site.DEFAULT_CSS_URL;

  const { post: postCss, response: postCssResponse } = useFetch('qutees', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      'Content-Type': 'text/css'
    }
  });

  const updateUrl = (bustCache) => {
    if (bustCache) {
      const timestamp = Math.floor(Date.now() / 1000);
      // @ts-ignore
      document.getElementById('sa-custom-style').href = `${customStyleUrl}?${timestamp}`;
    } else {
      // @ts-ignore
      document.getElementById('sa-custom-style').href = `${customStyleUrl}`;
    }
  };

  const saveNewCSS = async () => {
    const loadingKey = 'saveCSSRequest';
    const successKey = 'saveCSSSuccess';
    const errorKey = 'saveCSSError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    // do api call
    setLoading(true);
    message.info({ content: 'Saving CSS...', key: loadingKey });
    const result = await postCss(`${quteeId}/style`, css);
    if (postCssResponse.ok) {
      updateUrl(true);
      setLoading(false);
      message.destroy(loadingKey);
      message.success({ content: 'CSS saved successfully.', key: successKey });
      return;
    }
    setLoading(false);
    message.destroy(loadingKey);
    const err = new ApiException(result);
    message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
  };

  const load = async (url) => {
    const response = await fetch(url, {
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
    if (!response.ok) {
      // error happened;
      console.log(`Error happened:${response.statusText}`);
      return;
    }
    const txt = await response.text();
    setCss(txt);
  };

  const handleStyleChange = (val) => {
    setCss(val);
  };

  const getEditor = () => {
    if (currentEditor === 'css') {
      return (
        <StyleEditor value={css} onChange={handleStyleChange} />
      );
    }
    return (
      <TextArea
        className={styles.code}
        rows={20}
        onChange={(e) => handleStyleChange(e.target.value)}
        value={css}
      />

    );
  };

  const toggleView = () => {
    if (currentEditor === 'css') {
      setCurrentEditor('raw');
    } else {
      setCurrentEditor('css');
    }
  };

  useEffect(() => {
    const updateAndLoad = async () => {
      setLoading(true);
      updateUrl(false);
      await load(customStyleUrl);
      setLoading(false);
    };
    updateAndLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadExisting = async () => {
    setLoading(true);
    await load(customStyleUrl);
    setLoading(false);
  };

  const restoreDefaults = async () => {
    setLoading(true);
    load(defaultStyleUrl);
    setLoading(false);
  };

  return (
    <div>
      <Title level={5}>Custom CSS Rules</Title>
      <Switch defaultChecked onChange={toggleView} className={styles.switch} />
      <p className={styles.label}>Advanced Editor: </p>
      <div className={`${styles.editorContainer} ${currentEditor === 'raw' ? styles.raw : ''}`}>
        {getEditor()}
      </div>
      <div className={styles.buttonGroup}>
        <Button key="defaults" type="dashed" loading={loading} onClick={restoreDefaults}>
          Restore Defaults
        </Button>
        <div>
          <Button key="back" loading={loading} onClick={reloadExisting}>
            Undo Changes
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={saveNewCSS} className={styles.saveButton}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomStyleEditor;
