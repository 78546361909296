import useSWR from 'swr';

function useGaugeTally(gaugeId) {
  const { data, error, mutate } = useSWR(gaugeId ? `gauges/${gaugeId}/tally` : null);

  return {
    tally: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useGaugeTally;
