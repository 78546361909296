import React, { useState, useContext } from 'react';
import {
  Button, Form, Input, App
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { sendPasswordResetEmail } from '../state/api';
import EmbedContext from '../../embed/providers/EmbedProvider';
import AuthLoader from './AuthLoader';
import styles from '../styles/ForgotPassword.module.scss';

function ForgotPassword({ setVisible }) {
  const { message } = App.useApp();
  const embedConfig = useContext(EmbedContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { actionUrl, continueUrl } = embedConfig;

  const resetForm = () => {
    form.resetFields();
  };

  const sendEmail = async (values) => {
    const { email } = values;

    const loadingKey = 'sendEmailRequest';
    const successKey = 'sendEmailSuccess';
    const errorKey = 'sendEmailError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Sending verification email...', key: loadingKey });
    try {
      await sendPasswordResetEmail(email, continueUrl, actionUrl);
      message.destroy(loadingKey);
      message.success({ content: 'Password reset email sent', key: successKey });
      resetForm();
      if (setVisible) {
        setVisible(false);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    resetForm();
    setVisible(false);
  };

  return (
    <div className={styles.forgot}>
      <h1>Forgot Password</h1>
      <MailOutlined className={styles.icon} />
      <p className={styles.description}>
        Enter your email below and we will send you instructions on how to reset your password.
      </p>
      <Form name="forgot" form={form} onFinish={sendEmail}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email'
            },
            {
              type: 'email',
              message: 'Use a valid email address format (test@example.com)'
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        {loading && <AuthLoader />}
        <div className={styles.buttons}>
          <Form.Item>
            <Button onClick={goBack} htmlType="button">Cancel</Button>
            <Button
              type="primary"
              className={styles.reset}
              htmlType="submit"
              disabled={loading}
            >
              Send
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default ForgotPassword;
