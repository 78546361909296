import React from 'react';
import GaugeLabel from './GaugeLabel';
import styles from '../styles/GaugeControls.module.scss';

function GaugeControls({
  gaugeId, highLabel: high, lowLabel: low,
  userCanEdit, onUpdateLabel
}) {
  return (
    <div className={styles.GaugeContainer}>
      <GaugeLabel
        gaugeId={gaugeId}
        label={high}
        isLowLabel={false}
        onUpdateLabel={onUpdateLabel}
        userCanEdit={userCanEdit}
        showColor
      />
      <GaugeLabel
        gaugeId={gaugeId}
        label={low}
        isLowLabel
        onUpdateLabel={onUpdateLabel}
        userCanEdit={userCanEdit}
        showColor
      />
    </div>
  );
}

export default GaugeControls;
