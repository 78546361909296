import useSWR from 'swr';

function useCommentBinSettings(commentBinId) {
  const { data, error, mutate } = useSWR(
    commentBinId ? `comment-bins/${commentBinId}/settings` : null
  );

  return {
    settings: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useCommentBinSettings;
