import React from 'react';
import { Typography } from 'antd';
import styles from '../styles/DigitStatistic.module.scss';

const { Text } = Typography;

function DigitStatistic({
  votes, selected, disabled
}) {
  return (
    <div>
      <Text className={`${styles.statistic} ${!selected ? styles.dark : styles.light}`} disabled={disabled}>
        <div>{votes}</div>
        <div>{`vote${votes !== 1 ? 's' : ''}`}</div>
      </Text>
    </div>
  );
}

DigitStatistic.displayName = 'DigitStatistic';
DigitStatistic.defaultProps = {
  votes: 0,
  selected: false,
  disabled: false
};

export default DigitStatistic;
