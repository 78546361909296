import React, { useEffect, useState } from 'react';
import { App, Typography } from 'antd';
import styles from '../styles/GaugeLabel.module.scss';

const { Text } = Typography;

function GaugeLabel({
  gaugeId, label, isLowLabel, showColor, onUpdateLabel, userCanEdit
}) {
  const { message } = App.useApp();
  const [labelText, setLabelText] = useState(label);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLabelText(label);
  }, [label]);

  const saveLabel = async (value) => {
    const loadingKey = 'updateLabelRequest';
    const successKey = 'updateLabelSuccess';
    const errorKey = 'updateLabelError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    if (!value || !value.length) {
      message.error({ content: 'Label may not be blank', key: errorKey, duration: 5 });
      return;
    }
    const labelType = isLowLabel ? 'low_label' : 'high_label';

    setLoading(true);
    message.loading({ content: 'Updating gauge label...', key: loadingKey });
    try {
      await onUpdateLabel(gaugeId, labelType, value);
      setLabelText(value);
      message.destroy(loadingKey);
      message.success({ content: 'Label successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={showColor ? styles.containerWithColor : styles.container}>
      {showColor && <span className={isLowLabel ? styles.colorLow : styles.colorHigh} />}
      <Text
        className={styles.text}
        editable={!isLoading && userCanEdit ? { onChange: saveLabel } : false}
      >
        {labelText}
      </Text>
    </div>
  );
}

GaugeLabel.defaultProps = {
  showColor: false
};

export default GaugeLabel;
