// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  AutoComplete, Button, Checkbox, Divider, Input, App, Modal,
  Spin, Tabs, Tag, Tooltip
} from 'antd';
import {
  DownOutlined, SearchOutlined, StarOutlined, StarFilled,
  EyeInvisibleOutlined, EyeInvisibleFilled
} from '@ant-design/icons';
import { useDeepCompareEffect } from 'react-use';
import { ApiException } from '../../common/utils';
import { useUserContext } from '../../user/providers/UserProvider';
import { useIsXl } from '../../common/state/responsiveChecks';
import useCommentBinSettings from '../state/useCommentBinSettings';
import useLabels from '../state/useLabels';
import useTopics from '../state/useTopics';
import useSentiments from '../state/useSentiments';
import styles from '../styles/CompactFilters.module.scss';

const { Search } = Input;

/**
 * @typedef {Object} FilterList
 * @property {number} id An identifier for the filter item
 * @property {string} name The text for the filter item
 * @property {number=} count The number of items found for the filter item
 * @property {string} type The type of filter item
 */

/**
 * A compact view for filters.
 * @param {Object} props Props for the component
 * @param {number} props.commentBinId The id of the comment bin to which the filters apply
 * @param {string} props.filter The filter data in string form
 * @param {Function} props.setFilter A function to set the filter string
 * @param {Object} props.filterData The filter data in object form
 * @param {Function} props.setFilterData A function to set the filter data in object form
 * @param {Boolean} props.isLoadingFilters Whether filters are loading
 * @param {Function} props.setLoadingFilters A function to set whether filters are loading
 * @param {number} props.totalCommentCount The total number of comments, when not filtered
 * @param {number} props.filterCommentCount The number of comments with filters applied
 * @param {Boolean} props.selectedTagsOnly Whether to only show the selected tags,
 * and not recommended topics
 * @param {Boolean} props.userCanEditQutee Whether the user has edit permissions for the sayso
 */
// TODO: Redesign to accept arbitrary filter data
function CompactFilters({
  commentBinId, filter, setFilter, filterData, setFilterData,
  isLoadingFilters, setLoadingFilters, totalCommentCount,
  filterCommentCount, selectedTagsOnly, userCanEditQutee
}) {
  const SEARCH_TYPE = 'search';
  const LABEL_TYPE = 'label';
  const TOPIC_TYPE = 'topic';
  const SENTIMENT_TYPE = 'sentiment';

  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const isFirstRun = useRef(true);
  const [searchValue, setSearchValue] = useState({});
  const [searchHistory, setSearchHistory] = useState({});
  const [topicSelections, setTopicSelections] = useState({});
  const [sentimentSelections, setSentimentSelections] = useState({});
  const [activeTags, setActiveTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tabKey, setTabKey] = useState('labels');
  const searchRef = useRef(null);
  const isLargeScreen = useIsXl();
  const [labelFilters, setLabelFilters] = useState([]);
  const [topicFilters, setTopicFilters] = useState([]);
  const [sentimentFilters, setSentimentFilters] = useState([]);

  useEffect(() => {
    if (!commentBinId) {
      return;
    }
    if (!Object.prototype.hasOwnProperty.call(searchValue, commentBinId)) {
      setSearchValue((old) => ({
        ...old,
        [commentBinId]: ''
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(searchHistory, commentBinId)) {
      setSearchHistory((old) => ({
        ...old,
        [commentBinId]: []
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(topicSelections, commentBinId)) {
      setTopicSelections((old) => ({
        ...old,
        [commentBinId]: []
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(sentimentSelections, commentBinId)) {
      setSentimentSelections((old) => ({
        ...old,
        [commentBinId]: []
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentBinId]);

  const activeSearchValue = commentBinId ? searchValue[commentBinId] : '';
  const activeSearchHistory = commentBinId ? searchHistory[commentBinId] : [];
  const activeTopicSelections = commentBinId ? topicSelections[commentBinId] : [];
  const activeSentimentSelections = commentBinId ? sentimentSelections[commentBinId] : [];

  const changeSearchValue = (value) => {
    setSearchValue((old) => ({
      ...old,
      [commentBinId]: value
    }));
  };
  const changeSearchHistory = (list) => {
    setSearchHistory((old) => ({
      ...old,
      [commentBinId]: list
    }));
  };
  const changeTopicSelections = (list) => {
    setTopicSelections((old) => ({
      ...old,
      [commentBinId]: list
    }));
  };
  const changeSentimentSelections = (list) => {
    setSentimentSelections((old) => ({
      ...old,
      [commentBinId]: list
    }));
  };

  const {
    settings: commentBinSettings
  } = useCommentBinSettings(commentBinId);
  const {
    labels,
    isLoading: isLoadingLabels
  } = useLabels(
    commentBinId,
    filter,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );
  const {
    topics,
    isLoadingMore: isLoadingMoreTopics,
    isReachingEnd: isEndTopics,
    size: sizeTopics,
    setSize: setSizeTopics,
    mutate: mutateTopics
  } = useTopics(
    commentBinId,
    filter,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );
  const {
    sentiments,
    isLoadingMore: isLoadingMoreSentiments,
    isReachingEnd: isEndSentiments,
    size: sizeSentiments,
    setSize: setSizeSentiments,
    mutate: mutateSentiments
  } = useSentiments(
    commentBinId,
    filter,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const hideSearchFilter = !userCanEditQutee && commentBinSettings
    && commentBinSettings.comments_prevent_search;
  const hideLabelFilter = !userCanEditQutee && commentBinSettings
    && commentBinSettings.labels_hidden;
  const hideTopicFilter = !userCanEditQutee && commentBinSettings
    && commentBinSettings.topics_hidden;
  const hideSentimentFilter = !userCanEditQutee && commentBinSettings
    && commentBinSettings.sentiments_hidden;
  const hideAllFilters = !userCanEditQutee && hideSearchFilter && hideLabelFilter
    && hideTopicFilter && hideSentimentFilter;

  useDeepCompareEffect(() => {
    if (Array.isArray(labels)) {
      setLabelFilters(labels.map((item) => (
        {
          name: item.name,
          count: item.comment_count,
          id: item.label_id,
          type: LABEL_TYPE
        }
      )));
    }
  }, [labels || {}]);

  useDeepCompareEffect(() => {
    if (Array.isArray(topics) && (topics.filter((t) => t != null)).length) {
      setTopicFilters(topics.map((item) => (
        {
          name: item.topic_name,
          count: item.comment_count,
          id: item.topic_number,
          type: TOPIC_TYPE,
          starred: item.starred,
          hidden: item.prevent_reporting
        }
      )));
    }
  }, [topics || {}]);

  useDeepCompareEffect(() => {
    if (Array.isArray(sentiments) && (sentiments.filter((s) => s != null)).length) {
      setSentimentFilters(sentiments.map((item) => (
        {
          name: item.sentiment_name,
          count: item.comment_count,
          id: item.sentiment_number,
          type: SENTIMENT_TYPE,
          starred: item.starred,
          hidden: item.prevent_reporting
        }
      )));
    }
  }, [sentiments || {}]);

  useEffect(() => {
    if (typeof setLoadingFilters !== 'function') {
      return;
    }
    if (isLoadingLabels || isLoadingMoreTopics || isLoadingMoreSentiments) {
      setLoadingFilters(true);
    } else {
      setLoadingFilters(false);
    }
  }, [isLoadingLabels, isLoadingMoreTopics, isLoadingMoreSentiments, setLoadingFilters]);

  const { get: getCommentCount, response: getCommentCountResponse } = useFetch('comment-bins');

  const { patch: patchTopic, response: patchTopicResponse } = useFetch('comment-bins', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const { patch: patchSentiment, response: patchSentimentResponse } = useFetch('comment-bins', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const countComments = async (commentBinId, term) => {
    const response = await getCommentCount(`${commentBinId}/count-comments?filter[search_term]=${term}`);
    if (getCommentCountResponse.ok) {
      return response.count || null;
    }
    throw new ApiException(response);
  };

  const starTopic = async (commentBinId, topicId, starred) => {
    const data = {
      starred
    };

    const loadingKey = 'updateStarTopicRequest';
    const successKey = 'updateStarTopicSuccess';
    const errorKey = 'updateStarTopicError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${starred ? 'Starring' : 'Unstarring'} topic...`;
    const successMsg = `Topic successfully ${starred ? '' : 'un'}starred`;

    message.loading({ content: loadingMsg, key: loadingKey });

    mutateTopics(
      (orig) => orig.map((page) => ({
        results: page.results.map((t) => (t.topic_number === topicId ? {
          ...t,
          ...data
        } : t))
      })),
      { revalidate: false }
    );
    const response = await patchTopic(`${commentBinId}/topics/${topicId}`, data);
    if (patchTopicResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } else {
      const err = new ApiException(response);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
    mutateTopics();
  };

  const hideTopic = async (commentBinId, topicId, hidden) => {
    const data = {
      prevent_reporting: hidden
    };

    const loadingKey = 'updateHideTopicRequest';
    const successKey = 'updateHideTopicSuccess';
    const errorKey = 'updateHideTopicError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${hidden ? 'Hiding' : 'Unhiding'} topic...`;
    const successMsg = `Topic successfully ${hidden ? '' : 'un'}hidden`;

    message.loading({ content: loadingMsg, key: loadingKey });

    mutateTopics(
      (orig) => orig.map((page) => ({
        results: page.results.map((t) => (t.topic_number === topicId ? {
          ...t,
          ...data
        } : t))
      })),
      { revalidate: false }
    );
    const response = await patchTopic(`${commentBinId}/topics/${topicId}`, data);
    if (patchTopicResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } else {
      const err = new ApiException(response);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
    mutateTopics();
  };

  const starSentiment = async (commentBinId, sentimentId, starred) => {
    const data = {
      starred
    };
    const loadingKey = 'updateStarSentimentRequest';
    const successKey = 'updateStarSentimentSuccess';
    const errorKey = 'updateStarSentimentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${starred ? 'Starring' : 'Unstarring'} descriptor...`;
    const successMsg = `Descriptor successfully ${starred ? '' : 'un'}starred`;

    message.loading({ content: loadingMsg, key: loadingKey });

    mutateSentiments(
      (orig) => orig.map((page) => ({
        results: page.results.map((s) => (s.sentiment_number === sentimentId ? {
          ...s,
          ...data
        } : s))
      })),
      { revalidate: false }
    );
    const response = await patchSentiment(`${commentBinId}/sentiments/${sentimentId}`, data);
    if (patchSentimentResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } else {
      const err = new ApiException(response);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
    mutateSentiments();
  };

  const hideSentiment = async (commentBinId, sentimentId, hidden) => {
    const data = {
      prevent_reporting: hidden
    };

    const loadingKey = 'updateHideSentimentRequest';
    const successKey = 'updateHideSentimentSuccess';
    const errorKey = 'updateHideSentimentError';

    const loadingMsg = `${hidden ? 'Hiding' : 'Unhiding'} descriptor...`;
    const successMsg = `Descriptor successfully ${hidden ? '' : 'un'}hidden`;

    message.loading({ content: loadingMsg, key: loadingKey });

    mutateSentiments(
      (orig) => orig.map((page) => ({
        results: page.results.map((s) => (s.sentiment_number === sentimentId ? {
          ...s,
          ...data
        } : s))
      })),
      { revalidate: false }
    );
    const response = await patchSentiment(`${commentBinId}/sentiments/${sentimentId}`, data);
    if (patchSentimentResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } else {
      const err = new ApiException(response);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
    mutateSentiments();
  };

  const updateFilters = async () => {
    if (!filterData) {
      return;
    }
    let queryString = '';
    // TODO: Create a more robust way to filter by search terms (e.g. handling special characters)
    if (Object.keys(filterData?.search_term || {})?.length) {
      queryString += `&filter[search_term]=${filterData.search_term.id}`;
    }

    if (filterData.connections.length) {
      queryString += `&filter[connection]=in:${filterData.connections.join(',')}`;
    }

    if (filterData.labels.length) {
      const labelIds = filterData.labels.reduce((acc, cur) => {
        acc.push(cur.id);
        return acc;
      }, []);
      queryString += `&filter[label_id]=in:${labelIds.join(',')}`;
    }

    if (filterData.topics) {
      if (filterData.topics.length) {
        const topicIds = filterData.topics.reduce((acc, cur) => {
          acc.push(cur.id);
          return acc;
        }, []);
        queryString += `&filter[topic_number]=in:${topicIds.join(',')}`;
      }
    }

    if (filterData.sentiments) {
      if (filterData.sentiments.length) {
        const sentimentIds = filterData.sentiments.reduce((acc, cur) => {
          acc.push(cur.id);
          return acc;
        }, []);
        queryString += `&filter[sentiment_number]=in:${sentimentIds.join(',')}`;
      }
    }

    if (queryString.length && queryString.indexOf('&') === 0) {
      queryString = queryString.substring(1);
    }

    setFilter(queryString);
  };

  const updateActiveTags = (data) => {
    const newTags = [];
    if (Object.keys(data?.search_term || {}).length) {
      newTags.push(data.search_term);
    }
    if (data?.labels?.length) {
      newTags.push(...data.labels);
    }
    if (data?.topics?.length) {
      newTags.push(...data.topics);
    }
    if (data?.sentiments?.length) {
      newTags.push(...data.sentiments);
    }
    setActiveTags(newTags);
  };

  useEffect(() => {
    if (filterData) {
      changeTopicSelections(filterData.topics);
      changeSentimentSelections(filterData.sentiments);
      updateActiveTags(filterData);
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }

      const updateData = async () => {
        await updateFilters();
      };
      updateData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    if (showModal && (tabKey === 'search')) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          // @ts-ignore
          searchRef.current.focus();
        }
      }, 100);
    }
  }, [showModal, tabKey]);

  const handleChecked = (checked, item, list, allowMultiple = true) => {
    if (checked) {
      let newList = [];
      if (allowMultiple) {
        newList = [...list];
      }

      newList.unshift(item);
      return newList;
    }

    let newList = [];
    if (allowMultiple) {
      newList = list.filter((other) => (other.id !== item.id));
    }
    return newList;
  };

  const resetFilters = () => {
    setFilterData({
      connections: [], labels: [], topics: [], sentiments: [], search_term: {}
    });
    changeTopicSelections([]);
    changeSentimentSelections([]);
  };

  const setSearchFilters = async (term) => {
    const updateSearchList = async () => {
      const newHistory = [...activeSearchHistory];
      const existing = newHistory.find((item) => item.id === term);
      if (!existing) {
        const newItem = {
          id: term,
          name: term,
          type: SEARCH_TYPE
        };
        try {
          newItem.count = await countComments(commentBinId, term);
        } finally {
          newHistory.unshift(newItem);
          changeSearchHistory(newHistory);
        }
        return newItem;
      }
      return existing;
    };
    const searchTerm = await updateSearchList();

    setFilterData(
      {
        connections: filterData.connections,
        labels: filterData.labels,
        topics: filterData.topics,
        sentiments: filterData.sentiments,
        search_term: searchTerm
      }
    );
  };

  const handleSearch = async (value, option) => {
    changeSearchValue('');
    // Selecting a label/topic/sentiment option
    if (option?.original) {
      const item = option.original;
      let list = null;
      switch (item.type) {
        case LABEL_TYPE:
          list = filterData.labels;
          break;
        case TOPIC_TYPE:
          list = activeTopicSelections;
          break;
        case SENTIMENT_TYPE:
          list = activeSentimentSelections;
          break;
        default:
      }
      // If the item is already selected, do nothing.
      // Currently don't support de-selecting via search.
      if (!(list.filter((i) => i.id === item.id)).length) {
        const updatedItems = handleChecked(true, item, list, item.type !== LABEL_TYPE);
        const newData = {
          connections: filterData.connections,
          labels: item.type === LABEL_TYPE ? updatedItems : filterData.labels,
          topics: item.type === TOPIC_TYPE ? updatedItems : filterData.topics,
          sentiments: item.type === SENTIMENT_TYPE ? updatedItems : filterData.sentiments,
          search_term: filterData.search_term
        };
        setFilterData(newData);
      }
    // Selecting a custom search string
    } else {
      await setSearchFilters(value);
    }
  };

  const removeFilterItem = (item) => {
    const newFilterData = {
      connections: filterData.connections,
      labels: filterData.labels,
      topics: filterData.topics,
      sentiments: filterData.sentiments,
      search_term: filterData.search_term
    };

    if (item.type === SEARCH_TYPE) {
      newFilterData.search_term = {};
    }
    if (item.type === LABEL_TYPE) {
      const newLabels = filterData.labels.filter((l) => l.id !== item.id);
      newFilterData.labels = newLabels;
    }
    if (item.type === TOPIC_TYPE) {
      const newTopics = filterData.topics.filter((t) => t.id !== item.id);
      newFilterData.topics = newTopics;
    }
    if (item.type === SENTIMENT_TYPE) {
      const newSentiments = filterData.sentiments.filter((s) => s.id !== item.id);
      newFilterData.sentiments = newSentiments;
    }
    setFilterData(newFilterData);
  };

  const clearSearchTerm = () => {
    setFilterData(
      {
        connections: filterData.connections,
        labels: filterData.labels,
        topics: filterData.topics,
        sentiments: filterData.sentiments,
        search_term: {}
      }
    );
  };

  const clearSearchHistory = () => {
    // Still include currently selected search term, if any
    const cleared = activeSearchHistory.filter((item) => item.id === filterData.search_term?.id);
    changeSearchHistory(cleared);
  };

  const getFilterSelections = (
    group,
    groupName,
    selected,
    onChange,
    isChecked,
    isLoading,
    loadMore,
    doubleColumn = false
  ) => {
    const displayFilterList = (item) => (
      <li className={styles.item} key={item.name}>
        <Checkbox
          className={groupName === LABEL_TYPE ? styles.round : undefined}
          onChange={onChange ? (e) => { onChange(e.target.checked, item); } : null}
          checked={isChecked(item.id)}
          disabled={isLoading || (!isChecked(item.id) && item.count === 0)}
        >
          <span
            className={`${styles.text} ${isChecked(item.id) ? styles.selected : ''}`}
          >
            {`${item.name}${(item.count || item.count === 0) ? ` (${item.count})` : ''}`}
          </span>
        </Checkbox>
        {userCanEditQutee && (groupName === TOPIC_TYPE || groupName === SENTIMENT_TYPE) ? (
          <span className={styles.itemExtras}>
            {item.starred ? (
              <StarFilled
                className={`${styles.starIcon} ${styles.starFilledIcon}`}
                onClick={() => (groupName === TOPIC_TYPE
                  ? starTopic(commentBinId, item.id, false)
                  : starSentiment(commentBinId, item.id, false))}
              />
            ) : (
              <StarOutlined
                className={`${styles.starIcon} ${styles.starUnfilledIcon}`}
                onClick={() => (groupName === TOPIC_TYPE
                  ? starTopic(commentBinId, item.id, true)
                  : starSentiment(commentBinId, item.id, true))}
              />
            )}
            {item.hidden ? (
              <EyeInvisibleFilled
                className={`${styles.hideIcon} ${styles.hideFilledIcon}`}
                onClick={() => (groupName === TOPIC_TYPE
                  ? hideTopic(commentBinId, item.id, false)
                  : hideSentiment(commentBinId, item.id, false))}
              />
            ) : (
              <EyeInvisibleOutlined
                className={`${styles.hideIcon} ${styles.hideUnfilledIcon}`}
                onClick={() => (groupName === TOPIC_TYPE
                  ? hideTopic(commentBinId, item.id, true)
                  : hideSentiment(commentBinId, item.id, true))}
              />
            )}
          </span>
        ) : null}
        {!userCanEditQutee && (groupName === TOPIC_TYPE || groupName === SENTIMENT_TYPE)
          && item.starred ? (
            <span className={styles.itemExtras}>
              <Tooltip
                title="The author has chosen this topic as important or notable"
              >
                <StarFilled className={styles.starIcon} />
              </Tooltip>
            </span>
          ) : null}
      </li>
    );

    let unselected = group;
    if (unselected?.length && selected?.length) {
      unselected = group.filter((item) => !selected.find((item2) => item.id === item2.id));
    }

    const items = [];
    if (selected?.length) {
      const freshSelected = selected.map((sel) => {
        const freshExists = group?.filter((f) => f.type === sel.type && f.id === sel.id);
        if (freshExists?.length) {
          return freshExists[0];
        }
        return sel;
      });
      // const freshSelected = group?.filter((f) => selected.some(
      //   (item) => item.type === groupName && item.id === f.id
      // ));
      if (freshSelected?.length) {
        items.push(...freshSelected.map(displayFilterList));
      }
    }
    if (unselected?.length) {
      items.push(...unselected.map(displayFilterList));
    }
    return (
      <>
        <Spin spinning={isLoading}>
          <ul className={doubleColumn ? styles.doubleList : styles.list}>
            {items}
          </ul>
        </Spin>
        {loadMore}
      </>
    );
  };

  const getSearchFilters = () => {
    const onChange = (checked, item) => {
      if (checked) {
        setSearchFilters(item.name, true);
      } else {
        clearSearchTerm();
      }
    };
    const isChecked = (id) => (filterData.search_term?.id === id);
    return getFilterSelections(
      activeSearchHistory,
      SEARCH_TYPE,
      null,
      onChange,
      isChecked,
      false,
      null,
      false
    );
  };

  const getLabelFilters = () => {
    const onChange = (checked, label) => {
      const newLabels = handleChecked(checked, label, filterData.labels, false);
      setFilterData(
        {
          connections: filterData.connections,
          labels: newLabels,
          topics: filterData.topics,
          sentiments: filterData.sentiments,
          search_term: filterData.search_term
        }
      );
    };
    const labelIds = filterData?.labels?.reduce((acc, l) => {
      acc.push(l.id);
      return acc;
    }, []);
    const isChecked = (id) => (labelIds?.indexOf(id) > -1);
    return getFilterSelections(
      labelFilters,
      LABEL_TYPE,
      null,
      onChange,
      isChecked,
      isLoadingLabels,
      null,
      false
    );
  };

  const getTopicFilters = (doubleColumn = false) => {
    const onChange = (checked, topic) => {
      const newTopics = handleChecked(checked, topic, activeTopicSelections);
      changeTopicSelections(newTopics);
      setFilterData(
        {
          connections: filterData.connections,
          labels: filterData.labels,
          topics: newTopics,
          sentiments: filterData.sentiments,
          search_term: filterData.search_term
        }
      );
    };

    const loadMore = !isEndTopics ? (
      <Button
        className={styles.loadMore}
        type="text"
        disabled={isLoadingMoreTopics || isEndTopics}
        onClick={() => setSizeTopics(sizeTopics + 1)}
      >
        <u>+ More Topics</u>
      </Button>
    ) : null;

    const topicIds = filterData?.topics?.reduce((acc, t) => {
      acc.push(t.id);
      return acc;
    }, []);
    const isChecked = (id) => (topicIds?.indexOf(id) > -1);
    return getFilterSelections(
      topicFilters,
      TOPIC_TYPE,
      activeTopicSelections,
      onChange,
      isChecked,
      isLoadingMoreTopics,
      loadMore,
      doubleColumn
    );
  };

  const getSentimentFilters = () => {
    const onChange = (checked, sentiment) => {
      const newSentiments = handleChecked(checked, sentiment, activeSentimentSelections);
      changeSentimentSelections(newSentiments);
      setFilterData(
        {
          connections: filterData.connections,
          labels: filterData.labels,
          topics: filterData.topics,
          sentiments: newSentiments,
          search_term: filterData.search_term
        }
      );
    };

    const loadMore = !isEndSentiments ? (
      <Button
        className={styles.loadMore}
        type="text"
        disabled={isLoadingMoreSentiments || isEndSentiments}
        onClick={() => setSizeSentiments(sizeSentiments + 1)}
      >
        <u>+ More Descriptors</u>
      </Button>
    ) : null;

    const sentimentIds = filterData?.sentiments?.reduce((acc, s) => {
      acc.push(s.id);
      return acc;
    }, []);
    const isChecked = (id) => (sentimentIds?.indexOf(id) > -1);
    return getFilterSelections(
      sentimentFilters,
      SENTIMENT_TYPE,
      activeSentimentSelections,
      onChange,
      isChecked,
      isLoadingMoreSentiments,
      loadMore,
      false
    );
  };

  const getTags = (includeSearch = true, includeRecommended = true, includeAll = true) => {
    const tags = [];
    const possibleTags = includeSearch ? activeTags
      : activeTags?.filter((f) => f.type !== SEARCH_TYPE);
    const activeTagsCount = activeTags?.length;
    const possibleTagsCount = possibleTags?.length;

    if (activeTagsCount) {
      tags.push(...possibleTags.map((item) => {
        let group = null;
        switch (item.type) {
          case LABEL_TYPE:
            group = labelFilters;
            break;
          case TOPIC_TYPE:
            group = topicFilters;
            break;
          case SENTIMENT_TYPE:
            group = sentimentFilters;
            break;
          default:
            break;
        }
        const freshExists = group?.filter((f) => f.type === item.type && f.id === item.id);
        let tag = item;
        if (freshExists?.length) {
          [tag] = freshExists;
        }
        return (
          <Tag
            className={styles.activeTag}
            key={`${tag.type}-${tag.id}`}
            closable={!isLoadingFilters}
            onClose={(e) => { e.preventDefault(); removeFilterItem(tag); }}
          >
            {`${tag.type === SEARCH_TYPE ? `"${tag.name}"` : tag.name}${tag.count ? ` (${tag.count})` : ''}`}
          </Tag>
        );
      }));
    } else if (includeAll) {
      tags.push((
        <Tag className={styles.allTag} key="all" closable={false}>
          {`All${totalCommentCount ? ` (${totalCommentCount})` : ''}`}
        </Tag>
      ));
    }

    if (!selectedTagsOnly && includeRecommended && !hideTopicFilter) {
      const selectTopic = (item) => {
        const newTopics = handleChecked(true, item, activeTopicSelections);
        changeTopicSelections(newTopics);
        changeSentimentSelections([]);
        setFilterData(
          {
            connections: filterData.connections,
            labels: filterData.labels,
            topics: newTopics,
            sentiments: filterData.sentiments,
            search_term: filterData.search_term
          }
        );
      };

      const topicTags = topicFilters?.filter((f) => !possibleTags.some(
        (item) => item.type === TOPIC_TYPE && item.id === f.id
      ))
        ?.slice(0, 8 - possibleTagsCount);
      if (topicTags?.length) {
        tags.push(...topicTags.map((item) => (
          <Tag
            className={styles.tag}
            key={`${item.type}-${item.id}`}
            onClick={() => selectTopic(item)}
          >
            {`${item.name}${item.count ? ` (${item.count})` : ''}`}
          </Tag>
        )));
      }
    }

    if (activeTagsCount && includeAll) {
      tags.push((
        <Tag
          className={styles.allTagInactive}
          key="all"
          closable={false}
          onClick={() => resetFilters()}
        >
          {`All${totalCommentCount ? ` (${totalCommentCount})` : ''}`}
        </Tag>
      ));
    }

    return tags;
  };

  const getCountText = () => {
    if (filter == null) {
      return null;
    }
    if (filter && (filterCommentCount != null)) {
      return (
        <Spin wrapperClassName={styles.matchedCount} spinning={isLoadingFilters}>
          <span>
            {`${filterCommentCount} Matched Comments`}
          </span>
        </Spin>
      );
    }
    if (!filter && (totalCommentCount != null)) {
      return (
        <Spin wrapperClassName={styles.matchedCount} spinning={isLoadingFilters}>
          <span>
            {`${totalCommentCount} Comments`}
          </span>
        </Spin>
      );
    }
    return null;
  };

  const openModal = (key) => {
    if (key) {
      setTabKey(key);
    }
    setShowModal(true);
  };

  const getModalFooter = () => {
    const resetButton = (
      <Button
        type="default"
        onClick={() => { resetFilters(); }}
      >
        Reset Filters
      </Button>
    );
    const filterButton = (
      <Button
        className={styles.modalOk}
        type="primary"
        onClick={() => { setShowModal(false); }}
      >
        Filter Comments
      </Button>
    );

    if (isLargeScreen) {
      return (
        <div>
          {getCountText()}
          {resetButton}
          {filterButton}
        </div>
      );
    }
    return (
      <div>
        {getCountText()}
        <div>
          {resetButton}
          {filterButton}
        </div>
      </div>
    );
  };

  const acLabels = labelFilters.map((label) => ({
    label: label.name,
    value: label.name.toLowerCase(),
    key: `${label.type}-${label.name}`,
    original: { ...label }
  }))
    .filter((option) => option.label.toUpperCase()
      .indexOf(activeSearchValue.toUpperCase()) !== -1);
  const acTopics = topicFilters.map((topic) => ({
    label: topic.name,
    value: topic.name.toLowerCase(),
    key: `${topic.type}-${topic.name}`,
    original: { ...topic }
  }))
    .filter((option) => option.label.toUpperCase()
      .indexOf(activeSearchValue.toUpperCase()) !== -1);
  const acSentiments = sentimentFilters.map((sentiment) => ({
    label: sentiment.name,
    value: sentiment.name.toLowerCase(),
    key: `${sentiment.type}-${sentiment.name}`,
    original: { ...sentiment }
  }))
    .filter((option) => option.label.toUpperCase()
      .indexOf(activeSearchValue.toUpperCase()) !== -1);
  const autoCompleteOptions = [];
  if (activeSearchValue) {
    autoCompleteOptions.push({
      label: activeSearchValue, value: activeSearchValue.toLowerCase(), key: activeSearchValue
    });
  }
  if (activeSearchValue && acLabels.length) {
    autoCompleteOptions.push({
      label: 'Labels',
      options: acLabels
    });
  }
  if (activeSearchValue && acTopics.length) {
    autoCompleteOptions.push({
      label: 'Topics',
      options: acTopics
    });
  }
  if (activeSearchValue && acSentiments.length) {
    autoCompleteOptions.push({
      label: 'Descriptors',
      options: acSentiments
    });
  }

  const getFilterModalTabs = () => {
    const tabs = [];
    if (!hideLabelFilter) {
      tabs.push({
        key: 'labels',
        label: 'Labels',
        children: getLabelFilters()
      });
    }
    if (!hideTopicFilter) {
      tabs.push({
        key: 'topics',
        label: 'Topics',
        children: getTopicFilters()
      });
    }
    if (!hideSentimentFilter) {
      tabs.push({
        key: 'descriptors',
        label: 'Descriptors',
        children: getSentimentFilters()
      });
    }
    if (!hideSearchFilter) {
      tabs.push({
        key: 'search',
        label: 'Search',
        children: (
          <div>
            <AutoComplete
              onSelect={(value, option) => handleSearch(value, option)}
              onChange={(value) => changeSearchValue(value)}
              value={activeSearchValue}
              options={autoCompleteOptions}
              defaultActiveFirstOption
              popupClassName={isLargeScreen ? undefined : styles.autocompletePopup}
              dropdownMatchSelectWidth={isLargeScreen}
            >
              <Search
                ref={searchRef}
                className={styles.search}
                placeholder="search"
                autoFocus
                onPressEnter={(e) => e.preventDefault()}
                onSearch={(value) => handleSearch(value)}
              />
            </AutoComplete>
            {getSearchFilters()}
          </div>
        )
      });
    }
    return tabs;
  };

  const getDefaultActiveKey = () => {
    if (!hideLabelFilter) {
      return 'labels';
    }
    if (!hideTopicFilter) {
      return 'topics';
    }
    if (!hideSentimentFilter) {
      return 'descriptors';
    }
    return 'search';
  };

  // check null or undefined, because filter could be empty string
  if (filter == null || filterData == null) {
    return null;
  }

  if (hideAllFilters) {
    return null;
  }

  return (
    <div className={styles.filters}>
      <div className={styles.selectorRow}>
        {(isLargeScreen && !hideSearchFilter) ? (
          <AutoComplete
            onSelect={(value, option) => handleSearch(value, option)}
            onChange={(value) => changeSearchValue(value)}
            value={activeSearchValue}
            options={autoCompleteOptions}
            defaultActiveFirstOption
          >
            <Search
              className={styles.searchBar}
              placeholder="filter comments"
              onPressEnter={(e) => e.preventDefault()}
              onSearch={(value) => handleSearch(value)}
            />
          </AutoComplete>
        ) : null}
        {!hideLabelFilter ? (
          <Button
            className={styles.filterButton}
            type="link"
            onClick={() => openModal('labels')}
          >
            <span className={styles.dropdown}>
              <span className={styles.dropdownLabel}>Labels</span>
              <DownOutlined className={styles.dropdownArrow} />
            </span>
          </Button>
        ) : null}
        {!hideTopicFilter ? (
          <Button
            className={styles.filterButton}
            type="link"
            onClick={() => openModal('topics')}
          >
            <span className={styles.dropdown}>
              <span className={styles.dropdownLabel}>Topics</span>
              <DownOutlined className={styles.dropdownArrow} />
            </span>
          </Button>
        ) : null}
        {!hideSentimentFilter ? (
          <Button
            className={styles.filterButton}
            type="link"
            onClick={() => openModal('descriptors')}
          >
            <span className={styles.dropdown}>
              <span className={styles.dropdownLabel}>Descriptors</span>
              <DownOutlined className={styles.dropdownArrow} />
            </span>
          </Button>
        ) : null}
        {isLargeScreen || hideSearchFilter ? null : (
          <Button
            className={styles.searchButton}
            type="primary"
            shape="circle"
            size="small"
            icon={<SearchOutlined />}
            onClick={() => openModal('search')}
          />
        )}
      </div>
      <div className={styles.tagsRow}>
        {getTags(true, true, true)}
      </div>
      <Modal
        classNames={{ header: styles.modalHeader }}
        title="Filter Comments"
        open={showModal}
        footer={getModalFooter()}
        style={{ top: 20 }}
        onCancel={() => { setShowModal(false); }}
        width={isLargeScreen ? 1100 : undefined}
        // width="auto"
      >
        <div className={styles.tagSearchModalRow}>
          <div className={styles.modalTags}>
            {activeTags.length ? (
              <div className={styles.activeTags}>
                {getTags(!isLargeScreen, false, false)}
              </div>
            ) : null}
          </div>
          {(isLargeScreen && !hideSearchFilter) ? (
            <div className={styles.modalSearch}>
              {activeSearchHistory?.length ? (
                <div className={styles.searchTags}>
                  {activeSearchHistory.map((item) => (
                    <Tag
                      className={item.id === filterData.search_term?.id
                        ? styles.activeTag : styles.tag}
                      key={`${item.type}-${item.id}`}
                      closable={!isLoadingFilters && (item.id === filterData.search_term?.id)}
                      onClose={(e) => { e.preventDefault(); removeFilterItem(item); }}
                      onClick={() => ((item.id !== filterData.search_term?.id)
                        ? handleSearch(item.id) : null)}
                    >
                      {`${item.type === SEARCH_TYPE ? `"${item.name}"` : item.name}${item.count ? ` (${item.count})` : ''}`}
                    </Tag>
                  ))}
                  <Button
                    onClick={() => clearSearchHistory()}
                    type="default"
                    size="small"
                  >
                    Clear History
                  </Button>
                </div>
              ) : null}
              <Search
                ref={searchRef}
                className={styles.search}
                placeholder="search"
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => changeSearchValue(e.target.value)}
                value={activeSearchValue}
                autoFocus
              />
            </div>
          ) : null}
        </div>
        { (isLargeScreen || activeTags?.length)
          ? <Divider className={styles.divider} /> : null}
        {isLargeScreen ? (
          <div className={styles.filterColumns}>
            {!hideLabelFilter ? (
              <div className={styles.labelColumn}>
                <div className={styles.columnTitle}>
                  Labels
                </div>
                {getLabelFilters()}
              </div>
            ) : null}
            {!hideTopicFilter ? (
              <div className={styles.topicColumn}>
                <div className={styles.columnTitle}>
                  Topics
                </div>
                {getTopicFilters(true)}
              </div>
            ) : null}
            {!hideSentimentFilter ? (
              <div className={styles.descriptorColumn}>
                <div className={styles.columnTitle}>
                  Descriptors
                </div>
                {getSentimentFilters()}
              </div>
            ) : null}
          </div>
        ) : (
          <Tabs
            className={styles.tabs}
            activeKey={tabKey}
            defaultActiveKey={getDefaultActiveKey()}
            onChange={(key) => setTabKey(key)}
            items={getFilterModalTabs()}
          />
        )}
      </Modal>
    </div>
  );
}

CompactFilters.displayName = 'Compact Filters';
CompactFilters.defaultProps = {
  setLoadingFilters: undefined
};

export default CompactFilters;
