import React, { useEffect, useState } from 'react';
import {
  Button, App, Modal, Popover, Spin, Switch
} from 'antd';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import useFetch, { CachePolicies } from 'use-http';
import { useUserContext } from '../providers/UserProvider';
import { ApiException } from '../../common/utils';
import { useIsMd } from '../../common/state/responsiveChecks';
import Image from '../../common/view/Image';
import useSharingPermissions from '../state/useSharingPermissions';
import useProfileSettings from '../state/useProfileSettings';
import styles from '../styles/SharingPermissionsModal.module.scss';

function SharingPermissionsModal({
  visible, setVisible, requestingProfileId, requestingProfileName,
  firstTime, giveawayTerms
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const isMediumScreen = useIsMd();
  const [isCreating, setCreating] = useState(false);
  const [permitGiveaway, setPermitGiveaway] = useState(false);
  const [loadingGiveaway, setLoadingGiveaway] = useState(false);
  const [permitFeedback, setPermitFeedback] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [permitMarketing, setPermitMarketing] = useState(false);
  const [loadingMarketing, setLoadingMarketing] = useState(false);
  const [permitExtending, setPermitExtending] = useState(false);
  const [loadingExtending, setLoadingExtending] = useState(false);

  const SA_ICON_URL = 'https://images.socialasking.com/social-asking-icon.png';

  const {
    sharingPermissions,
    mutate: mutatePermissions,
    isLoading: isLoadingSharingPermissions
  } = useSharingPermissions(
    // Need to be logged in, but API call requires the qutee owner's id.
    (requestingProfileId && userState.isLoggedIn) ? requestingProfileId : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const {
    profileSettings,
    mutate: mutateProfileSettings,
    isLoading: isLoadingProfileSettings
  } = useProfileSettings(
    (requestingProfileId && userState.isLoggedIn) ? requestingProfileId : null
  );

  useEffect(() => {
    if (sharingPermissions?.permit_giveaway != null) {
      setPermitGiveaway(sharingPermissions.permit_giveaway);
    }
  }, [sharingPermissions?.permit_giveaway]);

  useEffect(() => {
    if (sharingPermissions?.permit_feedback != null) {
      setPermitFeedback(sharingPermissions.permit_feedback);
    }
  }, [sharingPermissions?.permit_feedback]);

  useEffect(() => {
    if (sharingPermissions?.permit_marketing != null) {
      setPermitMarketing(sharingPermissions.permit_marketing);
    }
  }, [sharingPermissions?.permit_marketing]);

  useEffect(() => {
    if (sharingPermissions?.permit_extending != null) {
      setPermitExtending(sharingPermissions.permit_extending);
    }
  }, [sharingPermissions?.permit_extending]);

  const { post: postPermissionRequest, response: postPermissionResponse } = useFetch('profiles', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const postPermission = async (data) => {
    const response = await postPermissionRequest(`${requestingProfileId}/sharing-permissions`, data);
    if (postPermissionResponse.ok) {
      return response;
    }
    throw new ApiException(response);
  };

  const createPermission = async () => {
    const errorKey = 'createPermissionError';
    message.destroy(errorKey);

    const data = {
      permit_giveaway: permitGiveaway,
      permit_feedback: permitFeedback,
      permit_marketing: permitMarketing,
      permit_extending: permitExtending
    };
    setCreating(true);
    try {
      await postPermission(data);
      mutatePermissions();
      setVisible(false);
    } catch (err) {
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setCreating(false);
    }
  };

  const acceptAllPermissions = async () => {
    const errorKey = 'acceptAllPermissionsError';
    message.destroy(errorKey);

    if (sharingPermissions?.permit_giveaway && sharingPermissions?.permit_feedback
      && sharingPermissions?.permit_marketing && sharingPermissions?.permit_extending) {
      setVisible(false);
    } else {
      const originalGiveaway = permitGiveaway;
      const originalFeedback = permitFeedback;
      const originalMarketing = permitMarketing;
      const originalExtending = permitExtending;
      const data = {
        permit_giveaway: true,
        permit_feedback: true,
        permit_marketing: true,
        permit_extending: true
      };
      setCreating(true);
      try {
        setPermitGiveaway(true);
        setPermitFeedback(true);
        setPermitMarketing(true);
        setPermitExtending(true);
        await postPermission(data);
        mutatePermissions();
        setVisible(false);
      } catch (err) {
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
        setPermitGiveaway(originalGiveaway);
        setPermitFeedback(originalFeedback);
        setPermitMarketing(originalMarketing);
        setPermitExtending(originalExtending);
      } finally {
        setCreating(false);
      }
    }
  };

  const updateGiveawayPermission = async (checked) => {
    const errorKey = 'updateGiveawayPermissionError';
    message.destroy(errorKey);

    const data = {
      permit_giveaway: checked
    };
    setLoadingGiveaway(true);
    try {
      setPermitGiveaway(checked);
      await postPermission(data);
      mutatePermissions(async (permissions) => ({
        ...permissions,
        permit_giveaway: checked
      }), { revalidate: false });
    } catch (err) {
      setPermitGiveaway(!checked);
      message.error({ content: `Error updating giveaway permission: ${err.message}`, key: errorKey, duration: 5 });
    } finally {
      setLoadingGiveaway(false);
    }
  };

  const updateFeedbackPermission = async (checked) => {
    const errorKey = 'updateFeedbackPermissionError';
    message.destroy(errorKey);

    const data = {
      permit_feedback: checked
    };
    setLoadingFeedback(true);
    try {
      setPermitFeedback(checked);
      await postPermission(data);
      mutatePermissions(async (permissions) => ({
        ...permissions,
        permit_feedback: checked
      }), { revalidate: false });
    } catch (err) {
      setPermitFeedback(!checked);
      message.error({ content: `Error updating feedback permission: ${err.message}`, key: errorKey, duration: 5 });
    } finally {
      setLoadingFeedback(false);
    }
  };

  const updateMarketingPermission = async (checked) => {
    const errorKey = 'updateMarketingPermissionError';
    message.destroy(errorKey);

    const data = {
      permit_marketing: checked
    };
    setLoadingMarketing(true);
    try {
      setPermitMarketing(checked);
      await postPermission(data);
      mutatePermissions(async (permissions) => ({
        ...permissions,
        permit_marketing: checked
      }), { revalidate: false });
    } catch (err) {
      setPermitMarketing(!checked);
      message.error({ content: `Error updating marketing permission: ${err.message}`, key: errorKey, duration: 5 });
    } finally {
      setLoadingMarketing(false);
    }
  };

  const updateExtendingPermission = async (checked) => {
    const errorKey = 'updateExtendingPermissionError';
    message.destroy(errorKey);

    const data = {
      permit_extending: checked
    };
    setLoadingExtending(true);
    try {
      setPermitExtending(checked);
      await postPermission(data);
      mutatePermissions(async (permissions) => ({
        ...permissions,
        permit_extending: checked
      }), { revalidate: false });
    } catch (err) {
      setPermitExtending(!checked);
      message.error({ content: `Error updating extending permission: ${err.message}`, key: errorKey, duration: 5 });
    } finally {
      setLoadingExtending(false);
    }
  };

  const openPrivacyPolicy = () => {
    const newWindow = window.open(profileSettings?.privacy_url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const openTermsOfService = () => {
    const newWindow = window.open(profileSettings?.tos_url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <Modal
      className={styles.modal}
      title={(
        <>
          <Image
            className={styles.saIcon}
            src={SA_ICON_URL}
            alt="Social Asking Icon"
            title="Social Asking Icon"
          />
          <span className={styles.modalTitle}>Social Asking Privacy Settings</span>
        </>
      )}
      width={isMediumScreen ? 520 : undefined}
      open={visible}
      footer={null}
      maskClosable={!firstTime}
      closeIcon={firstTime ? false : undefined}
      closable={firstTime ? false : undefined}
      onCancel={() => setVisible(false)}
      style={!isMediumScreen ? { top: 20 } : undefined}
      destroyOnClose
    >
      <div className={styles.requestIntro}>
        <span className={styles.requestingName}>{requestingProfileName}</span>
        {' is requesting permission to do the following:'}
      </div>
      <Spin spinning={isLoadingSharingPermissions}>
        <div className={styles.permissionRow}>
          <Switch
            className={styles.switch}
            checked={permitGiveaway}
            loading={isCreating || loadingGiveaway}
            onChange={firstTime
              ? ((checked) => setPermitGiveaway(checked))
              : ((checked) => updateGiveawayPermission(checked))}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
          <span className={styles.permissionText}>
            Enroll me in any current and future giveaways and notify me if I win.
          </span>
          <Popover
            overlayClassName={styles.info}
            content={(
              <div>
                This means that you are allowing the company to include your details in a
                giveaway or contest. If your name is picked as a winner, they can get in
                touch with you to let you know and give you the prize.
                {giveawayTerms ? (
                  <span>
                    {' The full terms of this giveaway can be '}
                    <a href={giveawayTerms} target="_blank" rel="noreferrer">found here</a>
                    .
                  </span>
                ) : null}
              </div>
            )}
            trigger={['hover', 'click']}
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Popover>
        </div>
        <div className={styles.permissionRow}>
          <Switch
            className={styles.switch}
            checked={permitFeedback}
            loading={isCreating || loadingFeedback}
            onChange={firstTime
              ? ((checked) => setPermitFeedback(checked))
              : ((checked) => updateFeedbackPermission(checked))}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
          <span className={styles.permissionText}>
            Contact me with additional opportunities to give feedback.
          </span>
          <Popover
            overlayClassName={styles.info}
            content={(
              <div>
                Don&apos;t miss out on future opportunities to give feedback.
                By agreeing, you&apos;re saying it&apos;s okay to receive
                email invitations for future engagements on Social Asking.
              </div>
            )}
            trigger={['hover', 'click']}
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Popover>
        </div>
        <div className={styles.permissionRow}>
          <Switch
            className={styles.switch}
            checked={permitMarketing}
            loading={isCreating || loadingMarketing}
            onChange={firstTime
              ? ((checked) => setPermitMarketing(checked))
              : ((checked) => updateMarketingPermission(checked))}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
          <span className={styles.permissionText}>
            Contact me for general marketing purposes.
          </span>
          <Popover
            overlayClassName={styles.info}
            content={(
              <div>
                This means that the company can send you advertisements,
                promotions, or information about their products and services via email.
              </div>
            )}
            trigger={['hover', 'click']}
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Popover>
        </div>
        <div className={styles.permissionRow}>
          <Switch
            className={styles.switch}
            checked={permitExtending}
            loading={isCreating || loadingExtending}
            onChange={firstTime
              ? ((checked) => setPermitExtending(checked))
              : ((checked) => updateExtendingPermission(checked))}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
          <span className={styles.permissionText}>
            Extend these permissions to their partners and advertisers
            that have sponsored this engagement.
          </span>
          <Popover
            overlayClassName={styles.info}
            content={(
              <div>
                By agreeing to this, you&apos;re not only giving the main company permission
                but also allowing them to share your agreement with other companies they
                work with.  These other companies can then do the same things you&apos;ve
                agreed to, like contacting you for marketing or feedback opportunities.
              </div>
            )}
            trigger={['hover', 'click']}
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Popover>
        </div>
        <div className={styles.continueButtons}>
          <div>
            <Button
              className={styles.acceptAllButton}
              loading={isCreating || loadingGiveaway
                || loadingFeedback || loadingMarketing || loadingExtending}
              size="large"
              type="primary"
              onClick={acceptAllPermissions}
            >
              Accept All and Continue
            </Button>
          </div>
          <div className={styles.buttonSpacer}>
            <Button
              className={styles.selectedButton}
              loading={isCreating || loadingGiveaway
                || loadingFeedback || loadingMarketing || loadingExtending}
              size="middle"
              type="default"
              onClick={firstTime ? createPermission : () => setVisible(false)}
            >
              Continue With Selected Settings
            </Button>
          </div>
        </div>
      </Spin>
      <div className={styles.privacyInfo}>
        <div className={styles.privacyHeader}>
          {`${requestingProfileName} Privacy Information`}
        </div>
        <div className={styles.privacyText}>
          {`By continuing, ${requestingProfileName} will receive access to all information you provide.\
          Additionally, they will be informed of your above choices.`}
        </div>
        {(profileSettings?.privacy_url || profileSettings?.tos_url) && (
          <div className={styles.privacyButtons}>
            {profileSettings?.privacy_url ? (
              <Button
                className={styles.privacyButton}
                type="text"
                onClick={openPrivacyPolicy}
              >
                Privacy Policy
              </Button>
            ) : null}
            {profileSettings?.tos_url ? (
              <Button
                className={styles.privacyButton}
                type="text"
                onClick={openTermsOfService}
              >
                Terms of Service
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </Modal>
  );
}

SharingPermissionsModal.defaultProps = {

};

export default SharingPermissionsModal;
