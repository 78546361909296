import React, { useState } from 'react';
import {
  Button, Form, Input, App, Typography
} from 'antd';
import styles from '../styles/CreateGauge.module.scss';

const { Title } = Typography;

function CreateGauge({
  quteeId, onCreateGauge
}) {
  const { message } = App.useApp();
  const [title, setTitle] = useState('');
  const [lowLabel, setLowLabel] = useState('');
  const [highLabel, setHighLabel] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [form] = Form.useForm();

  const forceValidation = async () => {
    form.validateFields()
      .then(() => {
        setValid(true);
      })
      .catch(() => {
        setValid(false);
      });
  };

  const changeTitle = (value) => {
    setTitle(value);
    forceValidation();
  };

  const changeLowLabel = (value) => {
    setLowLabel(value);
    forceValidation();
  };

  const changeHighLabel = (value) => {
    setHighLabel(value);
    forceValidation();
  };

  const resetForm = () => {
    form.resetFields();
    setTitle('');
    setLowLabel('');
    setHighLabel('');
  };

  const submitGauge = async () => {
    const loadingKey = 'createGaugeRequest';
    const successKey = 'createGaugeSuccess';
    const errorKey = 'createGaugeError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Submitting new gauge...', key: loadingKey });
    try {
      await onCreateGauge(quteeId, title, lowLabel, highLabel);
      resetForm();
      message.destroy(loadingKey);
      message.success({ content: 'Gauge successfully created', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Form
        className={styles.form}
        name="create-gauge-form"
        form={form}
        onFinish={submitGauge}
        layout="vertical"
      >
        <Title className={styles.instruction} level={4}>
          Add a new gauge
        </Title>
        <Form.Item
          label="Gauge prompt"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter a prompt for the gauge'
            }
          ]}
        >
          <Input
            onChange={(event) => changeTitle(event.target.value)}
            value={title}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item
          label="Low Label"
          name="low_label"
        >
          <Input
            onChange={(event) => changeLowLabel(event.target.value)}
            value={lowLabel}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item
          label="High Label"
          name="high_label"
        >
          <Input
            onChange={(event) => changeHighLabel(event.target.value)}
            value={highLabel}
            disabled={isLoading}
          />
        </Form.Item>
        <div className={styles.submitContainer}>
          <Button
            className={isLoading || !isValid ? styles.submit : styles.submitValid}
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading
              || !isValid}
          >
            Create New Gauge
          </Button>
        </div>
      </Form>
    </div>
  );
}

CreateGauge.displayName = 'CreateGauge';
CreateGauge.defaultProps = {
  isEditing: false,
  lockEdits: false,
  isDisabled: false,
  onUpdate: () => true
};

export default CreateGauge;
