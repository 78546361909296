/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useFetch, { CachePolicies } from 'use-http';
import { App, Typography } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { ApiException, deepCopy } from '../../common/utils';
import { useUserContext } from '../../user/providers/UserProvider';
import useQuteePolls from '../../qutee/state/useQuteePolls';
import styles from '../styles/ReorderPolls.module.scss';

const { Text } = Typography;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function ReorderPolls({ quteeId }) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [isLoading, setLoading] = useState(false);
  const [polls, setPolls] = useState([]);
  const pollsRef = useRef(polls);

  const titlePlaceholder = '(No Title)';

  const {
    polls: pollsData,
    isLoading: isLoadingPolls,
    mutate: mutatePolls,
    isError: isErrorPolls
  } = useQuteePolls(quteeId, userState.isLoggedIn ? userState.profile : null);

  const updatePolls = (newPolls) => {
    pollsRef.current = newPolls;
    setPolls(newPolls);
  };

  useEffect(() => {
    const p = deepCopy(pollsData);
    updatePolls(p);
  }, [pollsData]);

  const { patch: repositionPoll, response: repositionPollResponse } = useFetch('polls', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const onRepositionPoll = async (pollId, position) => {
    const data = {
      // The component internally uses 0-based positions
      // We use 1-based positions in the API
      position: position + 1
    };
    const result = await repositionPoll(`${pollId}`, data);
    if (repositionPollResponse.ok) {
      mutatePolls();
    } else {
      throw new ApiException(result);
    }
  };

  const dragDisabled = () => isLoadingPolls || isLoading;

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      pollsRef.current,
      result.source.index,
      result.destination.index
    );
    updatePolls(items);

    const execApi = async (pollId, index) => {
      const loadingKey = 'repositionPollRequest';
      const successKey = 'repositionPollSuccess';
      const errorKey = 'repositionPollError';
      message.destroy(loadingKey);
      message.destroy(successKey);
      message.destroy(errorKey);

      setLoading(true);
      message.loading({ content: 'Repositioning poll...', key: loadingKey });
      try {
        await onRepositionPoll(pollId, index);
        message.destroy(loadingKey);
        message.success({ content: 'Poll successfully repositioned', key: successKey });
      } catch (err) {
        message.destroy(loadingKey);
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      } finally {
        setLoading(false);
      }
    };
    // draggableId is a string version of pollId
    execApi(parseInt(draggableId, 10), destination.index);
  };

  const renderPoll = (poll) => (
    <div>
      <Text className={styles.title}>
        {poll.title || titlePlaceholder}
      </Text>
    </div>
  );

  if (isErrorPolls) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`${quteeId}-polls`}>
        {(provided) => (
          <div
            className={styles.container}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {pollsRef.current.map((poll, index) => (
              <Draggable
                key={poll.poll_id}
                draggableId={`${poll.poll_id}`}
                index={index}
                isDragDisabled={dragDisabled()}
              >
                {(provided) => (
                  <div
                    className={styles.dragContainer}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div className={styles.dragHandle} {...provided.dragHandleProps}>
                      {!dragDisabled() && Array.from(Array(2), (_, i) => (
                        <MoreOutlined key={i} className={styles.dragIcon} />
                      ))}
                    </div>
                    {renderPoll(poll)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

ReorderPolls.displayName = 'Reorder Polls';

ReorderPolls.defaultProps = {};

export default ReorderPolls;
