import React from 'react';
import styles from '../styles/PollPercent.module.scss';

function PollPercent({ percent, selected }) {
  const radius = 26;
  const stroke = 3;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percent / 100) * circumference;

  const setTextStyle = () => {
    if (selected) {
      return styles.lightText;
    }
    return styles.darkText;
  };

  return (
    <svg className={styles.percent} height={radius * 2} width={radius * 2}>
      <circle
        className={styles.unfilledCircle}
        fill="transparent"
        strokeWidth={2}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className={selected ? styles.selectedCircle : styles.filledCircle}
        fill="transparent"
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text x="26" y="30" className={setTextStyle()} textAnchor="middle" fontSize="15">
        {percent}
        %
      </text>
    </svg>
  );
}

PollPercent.displayName = 'PollPercent';
PollPercent.defaultProps = {
  percent: 0,
  selected: false
};

export default PollPercent;
