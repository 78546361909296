import React, { useState } from 'react';
import { Alert, Button, Modal } from 'antd';
import AuthForm from './AuthForm';
import styles from '../styles/UpgradeBanner.module.scss';

function UpgradeBanner({ upgradeMessage }) {
  const [upgradeVisible, setUpgradeVisible] = useState(false);

  const successCallback = () => {
    setUpgradeVisible(false);
  };

  const showUpgradeModal = () => {
    setUpgradeVisible(true);
  };

  let text = 'You are responding anonymously. You can finish setting up your account for later use by completing the signup.';
  if (upgradeMessage) {
    text = upgradeMessage;
  }

  return (
    <>
      <Alert
        className={styles.banner}
        type="warning"
        showIcon={false}
        icon={null}
        message={(
          <span className={styles.text}>
            {text}
          </span>
        )}
        action={(
          <Button
            type="primary"
            onClick={() => showUpgradeModal()}
          >
            Continue signup
          </Button>
        )}
      />
      <Modal
        width={520}
        open={upgradeVisible}
        footer={null}
        onCancel={() => setUpgradeVisible(false)}
        closable
        style={{ top: 20 }}
      >
        <AuthForm
          signInSuccessCallback={successCallback}
          signUpSuccessCallback={successCallback}
          startingTab="signup"
        />
      </Modal>
    </>
  );
}

UpgradeBanner.defaultProps = {
  showLogo: true,
  signInSuccessCallback: () => null,
  signUpSuccessCallback: () => null
};

export default UpgradeBanner;
