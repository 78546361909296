import React, { useState } from 'react';
import {
  Alert, Button, Dropdown, App
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import AuthHandler from './AuthHandler';
import { signOut } from '../state/api';
import { useIsSm } from '../../common/state/responsiveChecks';
import Image from '../../common/view/Image';
import config, { isProduction } from '../../common/infra/env.config';
import styles from '../styles/LogInBanner.module.scss';
import bannerLogo from '../../assets/sa_banner_logo.png';

function LogInBanner({
  style, useLink, showLogout, noAlertLoggedOut, quteeOwnerRole,
  isAnonymous, tourHandler, canUseGuide, setShowPermissionsModal
}) {
  const { message } = App.useApp();
  const { state, dispatch } = useUserContext();
  const [isSigningOut, setSigningOut] = useState(false);

  const isSmallScreen = !useIsSm();

  const handleSignOut = async () => {
    const loadingKey = 'logoutRequest';
    const successKey = 'logoutSuccess';
    const errorKey = 'logoutError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({
      content: isAnonymous ? 'Ending session...' : 'Logging out...',
      key: loadingKey
    });
    setSigningOut(true);
    try {
      await signOut();
      message.destroy(loadingKey);
      message.success({
        content: isAnonymous ? 'Successfully ended session' : 'Successfully logged out',
        key: successKey
      });
      setSigningOut(false);
      dispatch({ type: 'LOGOUT_SUCCESS' });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      setSigningOut(false);
    }
  };

  const openTermsOfService = () => {
    const newWindow = window.open(config.site.TERMS_OF_SERVICE_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const openPrivacyPolicy = () => {
    const newWindow = window.open(config.site.PRIVACY_POLICY_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const openHelpDesk = () => {
    const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const startTour = () => {
    if (typeof tourHandler === 'function') {
      tourHandler();
    }
  };

  const handleMenuOption = ({ key }) => {
    switch (key) {
      case 'support':
        openHelpDesk();
        break;
      case 'privacy':
        openPrivacyPolicy();
        break;
      case 'tos':
        openTermsOfService();
        break;
      case 'sharing':
        if (typeof setShowPermissionsModal === 'function') {
          setShowPermissionsModal(true);
        }
        break;
      case 'end':
        if (!isSigningOut) {
          handleSignOut();
        }
        break;
      case 'startTour':
        startTour();
        break;
      default:
    }
  };

  const getButtons = () => {
    const text = !showLogout ? 'Sign Up/Log In' : 'Log Out';
    const type = useLink ? 'link' : 'primary';
    const menuItems = [];

    if (canUseGuide) {
      menuItems.push({ label: 'Restart Guide', key: 'startTour' });
    }
    menuItems.push({ label: 'Help Desk', key: 'support' });

    menuItems.push({ label: 'Privacy Policy', key: 'privacy' });
    menuItems.push({ label: 'Terms of Service', key: 'tos' });
    if (!isAnonymous && (quteeOwnerRole === 'essential-team'
      || quteeOwnerRole === 'complete-team' || quteeOwnerRole === 'admin')) {
      menuItems.push({ label: 'Privacy Settings', key: 'sharing' });
    }
    if (!isAnonymous || !isProduction()) {
      menuItems.push({ label: 'Log Out', key: 'end' });
    }

    if (showLogout) {
      return (
        <Dropdown
          trigger={['click']}
          menu={{ items: menuItems, onClick: handleMenuOption }}
        >
          <MenuOutlined className={styles.menu} />
        </Dropdown>
      );
    }

    return (
      <AuthHandler>
        <Button
          className={styles.button}
          type={type}
        >
          {text}
        </Button>
      </AuthHandler>
    );
  };

  const getMessage = () => {
    let content;
    if (useLink) {
      content = (
        <span className={styles.link}>
          {getButtons()}
        </span>
      );
    } else if (isAnonymous) {
      content = 'Responding anonymously';
    } else if (showLogout) {
      content = state.profile ? `Welcome, ${state.profile.display_name}` : null;
    } else {
      content = null;
    }

    if (isSmallScreen) {
      return (
        <>
          <div>
            <Image className={styles.logo} src={bannerLogo} alt="Social Asking Logo" />
          </div>
          <div className={styles.smallContent}>
            {content}
            {!useLink && getButtons()}
          </div>
        </>
      );
    }

    return content;
  };

  const getAction = () => {
    if (isSmallScreen) {
      return null;
    }

    return useLink ? null : getButtons();
  };

  if (noAlertLoggedOut && !showLogout) {
    return (
      <div className={styles.noAlert}>
        {getButtons()}
      </div>
    );
  }

  return (
    <Alert
      className={`sa-auth-bar ${styles.alert}`}
      message={getMessage()}
      type="info"
      style={style}
      action={getAction()}
      showIcon={!isSmallScreen}
      icon={isSmallScreen ? null : <Image className={styles.logo} src={bannerLogo} alt="Social Asking Logo" />}
    />
  );
}

LogInBanner.defaultProps = {
  style: {},
  useLink: false,
  showLogout: false,
  noAlertLoggedOut: false,
  isAnonymous: false,
  tourHandler: null,
  canUseGuide: false,
  setShowPermissionsModal: undefined,
  quteeOwnerRole: null
};

export default LogInBanner;
