import { Ability, AbilityBuilder } from '@casl/ability';

const defaultAbility = new Ability([
  {
    action: 'read',
    subject: 'all'
  }
]);

export const initialUserState = {
  isPreFirstLoad: true,
  isLoggedIn: false,
  isVerified: false,
  isPending: false,
  isAnonymous: false,
  profile: null,
  role: 'unauthenticated',
  rules: [],
  ability: defaultAbility,
  error: ''
};

const setUserAbility = (userState) => {
  const { can, rules } = new AbilityBuilder(Ability);
  if (userState.isLoggedIn) {
    if (userState.role === 'admin') {
      can('manage', 'all');
      userState.ability = new Ability(rules);
    } else {
      userState.ability = new Ability(userState.rules);
    }
  } else {
    userState.ability = defaultAbility;
  }
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      const newState = {
        ...state,
        isPreFirstLoad: false,
        isLoggedIn: true,
        isVerified: action.payload?.profile.account_status === 'verified',
        isPending: action.payload?.profile.account_status === 'pending',
        isAnonymous: action.payload?.profile.account_type === 'anonymous',
        profile: action.payload.profile,
        role: action.payload.role,
        rules: action.payload.rules,
        error: ''
      };
      setUserAbility(newState);
      return newState;
    }
    case 'LOGIN_ERROR': {
      const newState = {
        ...state,
        isPreFirstLoad: false,
        isLoggedIn: false,
        isVerified: false,
        isPending: false,
        isAnonymous: false,
        profile: null,
        role: 'unauthenticated',
        rules: [],
        error: action.payload
      };
      setUserAbility(newState);
      return newState;
    }
    case 'LOGOUT_SUCCESS':
    case 'UNAUTHENTICATED': {
      const newState = {
        ...state,
        isPreFirstLoad: false,
        isLoggedIn: false,
        isVerified: false,
        isPending: false,
        isAnonymous: false,
        profile: null,
        role: 'unauthenticated',
        rules: [],
        error: ''
      };
      setUserAbility(newState);
      return newState;
    }
    default: {
      const newState = {
        ...state,
        isPreFirstLoad: false
      };
      return newState;
    }
  }
};
