import React, { useEffect, useRef, useState } from 'react';
import {
  Input, App, Switch
} from 'antd';
import { EnterOutlined } from '@ant-design/icons';
import Label from './Label';
import { escFunction } from '../../common/utils';
import styles from '../styles/LabelSelector.module.scss';

function LabelSelector({
  commentBinId, labels, selectedLabelId, onLabelSelect,
  allowEditing, createLabel, updateLabel, deleteLabel, disabled,
  triggerRepositionJoyride
}) {
  const { message } = App.useApp();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const inputRef = useRef(null);

  const changeInput = (e) => {
    setInputValue(e.target.value);
  };

  const cancelInput = () => {
    setInputValue('');
  };

  useEffect(() => {
    if (typeof triggerRepositionJoyride === 'function') {
      setTimeout(() => {
        triggerRepositionJoyride();
      }, 200);
    }
  }, [labels.length]);

  const saveLabel = async () => {
    if (!inputValue.trim()) {
      return;
    }

    const loadingKey = 'newLabelRequest';
    const successKey = 'newLabelSuccess';
    const errorKey = 'newLabelError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Adding new label...', key: loadingKey });
    try {
      await createLabel(commentBinId, inputValue);
      setInputValue('');
      message.destroy(loadingKey);
      message.success({ content: 'Label successfully added', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
      if (inputRef?.current) {
        // @ts-ignore
        inputRef?.current?.focus();
      }
    }
  };

  const handleEditSwitch = (checked) => {
    if (typeof triggerRepositionJoyride === 'function') {
      triggerRepositionJoyride();
    }
    setEditMode(checked);
  };

  return (
    <div className={styles.selector}>
      {labels.map((label) => (
        <Label
          key={label.label_id}
          commentBinId={commentBinId}
          labelId={label.label_id}
          name={label.name}
          selectedLabelId={selectedLabelId}
          isEditMode={isEditMode}
          onLabelSelect={onLabelSelect}
          updateLabel={updateLabel}
          deleteLabel={deleteLabel}
          editingDisabled={label.comment_count > 0}
          deleteDisabled={label.comment_count > 0}
          disabled={disabled}
        />
      ))}
      {isEditMode && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          className={styles.input}
          autoFocus
          disabled={isLoading}
          value={inputValue}
          onChange={changeInput}
          onPressEnter={saveLabel}
          onKeyDown={(e) => escFunction(e, cancelInput, true)}
          suffix={<EnterOutlined />}
          allowClear
        />
      )}
      {allowEditing && (
        <Switch
          className={styles.edit}
          checked={isEditMode}
          checkedChildren="edit labels"
          unCheckedChildren="edit labels"
          onChange={handleEditSwitch}
        />
      )}
    </div>
  );
}

LabelSelector.displayName = 'Label Selector';
LabelSelector.defaultProps = {
  labels: [],
  selectedLabelId: null,
  onLabelSelect: () => {},
  allowEditing: false,
  createLabel: () => {},
  updateLabel: () => {},
  deleteLabel: () => {},
  triggerRepositionJoyride: undefined
};

export default LabelSelector;
