import useSWR from 'swr';

function useQuteeProgress(quteeId, profileId) {
  const {
    data, error, mutate, isLoading, isValidating
  } = useSWR(
    quteeId ? [`qutees/me?filter[qutee_id]=in:${quteeId}`, profileId] : null,
    { revalidateIfStale: false }
  );

  return {
    progress: (data && data.length && data[0]) || null,
    mutate,
    isLoading,
    isValidating,
    isError: error
  };
}

export default useQuteeProgress;
