import React, { useState, useEffect } from 'react';
import {
  Button, App, Modal, Skeleton, Spin, Typography
} from 'antd';
import {
  BarChartOutlined, FilePdfOutlined, SyncOutlined, WarningOutlined
} from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import { getReportStepDescription } from '../../common/utils';
import Image from '../../common/view/Image';
import styles from '../styles/InsightsReportBlurb.module.scss';

const { Text } = Typography;

const REPORT_IMAGE_URL = 'https://images.socialasking.com/report_download.png';

function InsightsReportBlurb({
  commentBinId, getReport, getLatestReport, getReportProgress,
  generateReport, totalCommentCount, reportOwnerRole, hasChatbot
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [latestReport, setLatestReport] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [mustGenerateReport, setMustGenerateReport] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const [progressStep, setProgressStep] = useState(0);
  const [finishedGenerating, setFinishedGenerating] = useState(false);
  const [syncingInProgress, setSyncingInProgress] = useState(false);
  const [finishedSyncingChatbot, setFinishedSyncingChatbot] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowGenerateModal(false);
  };

  const loadingKey = 'downloadReportRequest';
  const successKey = 'downloadReportSuccess';
  const errorKey = 'downloadReportError';

  const initialPollingTimeInMs = 3000;
  const includesSummary = (reportOwnerRole === 'admin') || (reportOwnerRole === 'complete-team')
    || (reportOwnerRole === 'essential-team') || (userState?.role === 'admin');
  const includesChatbot = includesSummary && hasChatbot;
  const pollingTimeInMs = includesSummary ? 10000 : initialPollingTimeInMs;

  const checkSyncProgress = async (progressId) => {
    const progress = await getReportProgress(commentBinId, progressId);
    if (!progress.sync_finished && !progress.sync_error) {
      setTimeout(async () => {
        await checkSyncProgress(progress.report_progress_id);
      }, pollingTimeInMs);
    } else if (progress.sync_finished) {
      setSyncingInProgress(false);
      setFinishedSyncingChatbot(true);
    } else {
      setSyncingInProgress(false);
      message.destroy(loadingKey);
      if (progress.sync_error_message) {
        message.error({ content: progress.sync_error_message, key: errorKey, duration: 8 });
      } else {
        message.error({ content: 'Chatbot syncing failed. Contact admins for support.', key: errorKey, duration: 5 });
      }
    }
  };

  const checkProgress = async (progressId) => {
    const progress = await getReportProgress(commentBinId, progressId);
    if (!progress.finished && !progress.error) {
      setProgressStep(progress.progress);
      setTimeout(async () => {
        await checkProgress(progress.report_progress_id);
      }, pollingTimeInMs);
    } else if (progress.finished) {
      const report = await getReport(commentBinId, progress.report_id);
      message.destroy(loadingKey);
      message.success({
        content: 'The report has finished generating.',
        key: successKey
      });
      // If report download link expires, require getting a new link
      setTimeout(async () => {
        setFinishedGenerating(false);
      }, 1000 * 60 * 2);
      setLatestReport(report);
      setMustGenerateReport(false);
      setGenerationInProgress(false);
      setFinishedGenerating(true);
      setLoading(false);
      setProgressStep(0);
      if (includesChatbot) {
        setSyncingInProgress(true);
        setTimeout(async () => {
          checkSyncProgress(progressId);
        }, pollingTimeInMs);
      }
    } else {
      setGenerationInProgress(false);
      setLoading(false);
      setProgressStep(0);
      message.destroy(loadingKey);
      if (progress.error_message) {
        message.error({ content: progress.error_message, key: errorKey, duration: 8 });
      } else {
        message.error({ content: 'Report generation failed. Contact admins for support.', key: errorKey, duration: 5 });
      }
    }
  };

  useEffect(() => {
    if (!commentBinId && !latestReport) {
      return;
    }
    const getInitialReport = async () => {
      try {
        setLoading(true);
        const report = await getLatestReport(commentBinId);
        // handle generation already in progress
        if (report.generation_in_progress) {
          setTimeout(async () => {
            await checkProgress(report.report_progress_id);
          }, initialPollingTimeInMs);
        }
        setLatestReport(report);
        setMustGenerateReport(false);
      } catch (err) {
        if (err.name === 'Resource_Not_Found_Error') {
          setMustGenerateReport(true);
        }
      } finally {
        setInitialLoad(false);
        setLoading(false);
      }
    };
    getInitialReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentBinId]);

  const downloadOrPromptGenerate = async () => {
    try {
      setLoading(true);
      const report = await getLatestReport(commentBinId);
      setLatestReport(report);
      // handle generation in progress
      if (!report.generation_in_progress && !report.has_new_data) {
        const newWindow = window.open(report.report_url, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
        setLoading(false);
      } else if (report.has_new_data) {
        setShowGenerateModal(true);
        setLoading(false);
      } else {
        setLoading(false);
        setTimeout(async () => {
          await checkProgress(report.report_progress_id);
        }, pollingTimeInMs);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const downloadLatest = async () => {
    setShowGenerateModal(false);
    const newWindow = window.open(latestReport.report_url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
    setLoading(false);
  };

  const generateNewReport = async () => {
    try {
      setShowGenerateModal(false);
      setLoading(true);
      setFinishedSyncingChatbot(false);
      setSyncingInProgress(false);
      setGenerationInProgress(true);
      const response = await generateReport(commentBinId);
      setLoading(false);
      setProgressStep(0);
      message.destroy(loadingKey);
      message.destroy(successKey);
      message.destroy(errorKey);
      message.info({ content: 'Report is being generated. You will be notified upon completion.', key: loadingKey });
      setTimeout(async () => {
        await checkProgress(response.report_progress_id);
      }, initialPollingTimeInMs);
    } catch (err) {
      setGenerationInProgress(false);
      setLoading(false);
      // TODO: Not enough comments (50 needed)
      // TODO: Report already up to date
    }
  };

  const downloadGeneratedReport = async () => {
    setLoading(true);
    await downloadLatest();
    setFinishedGenerating(false);
    setLoading(false);
  };

  const getReportState = () => {
    if (initialLoad || loading) {
      return (
        <div>
          <Spin className={styles.spin} />
          <Text>{' Loading...'}</Text>
        </div>
      );
    }
    // if (totalCommentCount < 0) {
    //   return (
    //     <div>
    //       <WarningOutlined />
    //       <Text className={styles.notEnoughComments}>Not Enough Comments</Text>
    //       <br />
    //       <Text>A minimum of 5 comments are required to generate a report</Text>
    //     </div>
    //   );
    // }
    if (generationInProgress) {
      return (
        <div>
          <Text>Generating report...</Text>
          <br />
          <div className={styles.info}>
            <Spin className={styles.spin} />
            {` ${getReportStepDescription(progressStep, includesSummary)}`}
          </div>
          <br />
          <Text>You will be alerted when the report is ready</Text>
        </div>
      );
    }
    if (mustGenerateReport) {
      return (
        <div>
          <Text>Generate a free insights report</Text>
          <br />
          { /* eslint-disable-next-line max-len */ }
          <Text>The insights report offers a qualitative AI-driven analysis of your engagement.</Text>
          <Button
            className={styles.generate}
            type="primary"
            onClick={() => generateNewReport()}
            disabled={loading}
          >
            Generate report
          </Button>
        </div>
      );
    }

    let extraInfo = null;
    if (includesChatbot) {
      if (finishedSyncingChatbot) {
        extraInfo = (
          <div className={styles.info}>
            <SyncOutlined className={styles.syncIcon} />
            {' Chatbot Synced'}
          </div>
        );
      } else if (syncingInProgress) {
        extraInfo = (
          <div className={styles.info}>
            <Spin className={styles.spin} />
            {' Syncing Chatbot'}
          </div>
        );
      }
    }

    return (
      <div>
        <div>
          <Text>Your report is ready to download</Text>
        </div>
        <Button
          className={styles.download}
          type="primary"
          onClick={() => (finishedGenerating
            ? downloadGeneratedReport()
            : downloadOrPromptGenerate())}
          disabled={loading}
        >
          <FilePdfOutlined />
          {finishedGenerating ? 'Download generated report' : 'Download latest report'}
        </Button>
        {extraInfo}
      </div>
    );
  };

  if (!commentBinId) {
    return <Skeleton />;
  }

  return (
    <>
      <div className={styles.report}>
        <div className={styles.sponsored}>
          <BarChartOutlined />
          <Text>Insights Report</Text>
        </div>
        {getReportState()}
        <Modal
          width={520}
          open={showGenerateModal}
          onCancel={() => closeModal()}
          closable
          footer={[
            <Button key="download" onClick={() => downloadLatest()} className={styles.download}>
              Download existing report
            </Button>,
            <Button key="generate" type="primary" onClick={() => generateNewReport()} className={styles.generate}>
              Generate new report
            </Button>
          ]}
        >
          There is new data available. Would you like to generate a new report to include
          the new data, or download the most recent existing report?
        </Modal>
      </div>
      {!initialLoad && !loading && !generationInProgress && !mustGenerateReport ? (
        <Image
          className={styles.downloadReport}
          src={REPORT_IMAGE_URL}
          alt="PDF Report Available"
          title="PDF Report Available"
        />
      ) : null}
    </>
  );
}

InsightsReportBlurb.displayName = 'Insights Report Blurb';
InsightsReportBlurb.defaultProps = {
  totalCommentCount: 0,
  reportOwnerRole: undefined,
  hasChatbot: false
};

export default InsightsReportBlurb;
