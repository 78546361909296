/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, useRef } from 'react';
import {
  Typography, Input, Button, Dropdown, App, Popconfirm
} from 'antd';
import {
  PushpinFilled, StarFilled,
  MoreOutlined, TagTwoTone,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { IconMessages, IconMessageDots } from '@tabler/icons-react';
import 'easymde/dist/easymde.min.css';
import pretty from 'pretty-time';
import { useUserContext } from '../../user/providers/UserProvider';
import LikeDislikePair from './LikeDislikePair';
import CommentEntry from '../../commentBin/components/CommentEntry';
import { useIsTouch } from '../../common/state/responsiveChecks';
import styles from '../styles/QuteeComment.module.scss';
// @ts-ignore
import cssVariables from '../../common/view/styles/Global.scss';
import Image from '../../common/view/Image';
import verifiedIcon from '../../assets/verified-icon.png';
import incognitoIcon from '../../assets/incognito-icon.png';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const { orangeColorVarName, darkGrayColorVarName } = cssVariables;

/**
 * @typedef {Object} comment A comment for display; may be within a thread
 * @param {*} param0
 */

function QuteeComment({
  commentBinId, comment, labelPrompt, labels, filterData,
  isClosed, isPinned, setPinned, isStarred, setStarred, createReply,
  updateComment, likeComment, deleteComment, removeCommentLike, dislikeComment,
  removeCommentDislike, userCanInteract, cantInteractReason, userCanEditQutee,
  checkUserCanEditComment, mobileOpenHandler, allowAnonymous, continueAnonymously,
  isPreview, refreshProgress, reportComment, removeCommentReport, deleted
}) {
  const {
    comment_id: commentId, comment_text: commentText, author_name: authorName,
    replies_count: repliesCount, liked, disliked, likes_count: likesCount,
    dislikes_count: dislikesCount, created_date: createdDate, is_anonymous: isAnonymous,
    reported, profile_id: profileId
  } = comment;

  // States
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [isLoading, setLoading] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [isReplying, setReplying] = useState(false);
  const [localReported, setLocalReported] = useState(reported);
  const [text, setText] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const isTouchscreen = useIsTouch();
  const editRef = useRef(null);

  useEffect(() => {
    setText(commentText);
  }, [commentText]);

  useEffect(() => {
    if (editingComment && editRef.current) {
      editRef.current.focus({ cursor: 'end' });
    }
  }, [editingComment]);

  useEffect(() => {
    setLocalReported(reported);
  }, [reported]);

  const getLabel = () => {
    if (!deleted && (comment.label && comment.label.label_name)) {
      return comment.label.label_name;
    }
    return 'Uncategorized';
  };

  const getLabelId = () => {
    if (comment.label && comment.label.label_id) {
      return comment.label.label_id;
    }
    return null;
  };

  const filterIncludesLabel = (labelId) => {
    if (!labelId || !filterData?.labels?.length) {
      return false;
    }
    const labelIds = filterData.labels.reduce((acc, cur) => {
      acc.push(cur.id);
      return acc;
    }, []);
    if (labelIds.includes(labelId)) {
      return true;
    }
    return false;
  };

  // State Handlers

  const pinComment = async (pin) => {
    const data = {
      pinned: pin
    };
    setLoading(true);

    const loadingKey = 'updatePinCommentRequest';
    const successKey = 'updatePinCommentSuccess';
    const errorKey = 'updatePinCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${pin ? 'Pinning' : 'Unpinning'} comment...`;
    const successMsg = `Comment successfully ${pin ? '' : 'un'}pinned`;
    message.loading({ content: loadingMsg, key: loadingKey });
    try {
      const updatedComment = await updateComment(commentId, data);
      setPinned(!!updatedComment.pinned);
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const starComment = async (star) => {
    const data = {
      starred: star
    };
    setLoading(true);

    const loadingKey = 'updateStarCommentRequest';
    const successKey = 'updateStarCommentSuccess';
    const errorKey = 'updateStarCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = `${star ? 'Starring' : 'Unstarring'} comment...`;
    const successMsg = `Comment successfully ${star ? '' : 'un'}starred`;

    message.loading({ content: loadingMsg, key: loadingKey });
    try {
      const updatedComment = await updateComment(commentId, data);
      setStarred(!!updatedComment.starred);
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const handleCommentReport = async (report) => {
    setLoading(true);

    const loadingKey = 'reportCommentRequest';
    const successKey = 'reportCommentSuccess';
    const errorKey = 'reportCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const loadingMsg = report ? 'Reporting comment...' : 'Removing comment report...';
    const successMsg = report ? 'Comment successfully reported'
      : 'Successfully removed comment report';

    message.loading({ content: loadingMsg, key: loadingKey });
    try {
      if (report) {
        await reportComment(commentId);
      } else {
        await removeCommentReport(commentId);
      }
      setLocalReported(report);
      message.destroy(loadingKey);
      message.success({ content: successMsg, key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const editCommentText = async () => {
    const data = {
      comment_text: text
    };

    const loadingKey = 'updateCommentRequest';
    const successKey = 'updateCommentSuccess';
    const errorKey = 'updateCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating comment...', key: loadingKey });
    try {
      const updatedComment = await updateComment(commentId, data);
      setText(updatedComment.comment_text);
      setEditingComment(false);
      message.destroy(loadingKey);
      message.success({ content: 'Comment successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const removeComment = async (id) => {
    setShowDeleteConfirmation(false);
    setLoading(true);
    try {
      await deleteComment(id);
    } catch (err) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleCommentOption = ({ key }) => {
    switch (key) {
      case 'delete':
        setShowDeleteConfirmation(true);
        break;
      case 'pin':
        pinComment(!isPinned);
        break;
      case 'star':
        starComment(!isStarred);
        break;
      case 'edit':
        setEditingComment(true);
        break;
      case 'report':
        handleCommentReport(!localReported);
        break;
      default:
    }
  };

  const getCommentOptionsDropdown = () => {
    const menuItems = [];
    if (userCanEditQutee) {
      menuItems.push({ label: `${isPinned ? 'Unpin' : 'Pin'} Comment`, key: 'pin' });
      menuItems.push({ label: `${isStarred ? 'Unstar' : 'Star'} Comment`, key: 'star' });
    }
    if (checkUserCanEditComment(comment)) {
      menuItems.push({ label: 'Edit Comment', key: 'edit' });
      menuItems.push({ label: 'Delete Comment', key: 'delete' });
    }
    if (userCanInteract) {
      menuItems.push({ label: localReported ? 'Undo report comment' : 'Report comment', key: 'report' });
    }

    if (!menuItems.length) {
      return null;
    }
    return (
      <Popconfirm
        open={showDeleteConfirmation}
        title="Delete this comment?"
        onConfirm={() => removeComment(commentId)}
        onCancel={() => setShowDeleteConfirmation(false)}
        okText="Delete"
        okButtonProps={{ className: styles.confirmDelete }}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      >
        <Dropdown
          className={styles.moreOptions}
          menu={{ items: menuItems, onClick: handleCommentOption }}
          trigger={['click']}
        >
          <MoreOutlined />
        </Dropdown>
      </Popconfirm>
    );
  };

  const getTimeSinceCreation = (timestamp) => {
    const currentTime = new Date().getTime();
    const commentTime = new Date(timestamp).getTime();
    if ((currentTime - commentTime) < 1000) {
      return '1 second ago';
    }
    const nanoSecondsSinceCreation = (currentTime - commentTime) * 1000000;
    return `${pretty([0, nanoSecondsSinceCreation])} ago`;
  };

  const updateCommentText = (e) => {
    setText(e.target.value);
  };

  const cancelEditing = () => {
    setText(commentText);
    setEditingComment(false);
  };

  const getTwoToneColor = (name) => {
    const value = getComputedStyle(document.documentElement)
      .getPropertyValue(name);
    return value;
  };

  return (
    <div className={isClosed ? styles.closedContainer : styles.openContainer}>
      { /* eslint-disable-next-line max-len */ }
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.authorContainer} onClick={(isTouchscreen) ? mobileOpenHandler : null}>
        <div className={styles.statusContainer}>
          <div className={styles.infoContainer}>
            <div className={styles.nameContainer}>
              <span className={styles.name}>{deleted ? '<deleted>' : authorName}</span>
              {isAnonymous && !deleted
                && <Image className={styles.incognitoIcon} src={incognitoIcon} alt="Anonymous user" title="Anonymous" />}
              {!isAnonymous && !deleted
                && <Image className={styles.verifiedIcon} src={verifiedIcon} alt="Verified user" title="Verified" />}
              {!isClosed ? (
                <span>
                  <span className={styles.timestamp}>{getTimeSinceCreation(createdDate)}</span>
                </span>
              ) : null}
            </div>
            <div className={styles.labelContainer}>
              {filterIncludesLabel(getLabelId())
                ? (
                  <TagTwoTone
                    twoToneColor={getTwoToneColor(orangeColorVarName)}
                    className={styles.labelIcon}
                  />
                ) : (
                  <TagTwoTone
                    twoToneColor={getTwoToneColor(darkGrayColorVarName)}
                    className={styles.labelIcon}
                  />
                )}
              <Text
                className={filterIncludesLabel(getLabelId())
                  ? styles.labelFiltered
                  : styles.label}
              >
                {`"${getLabel()}"`}
              </Text>
            </div>
          </div>
          <div className={styles.pinStarContainer}>
            {isPinned
              && (
              <PushpinFilled
                className={styles.pinnedIcon}
              />
              )}
            {isStarred
              && (
              <StarFilled
                className={styles.starredIcon}
              />
              )}
          </div>
        </div>
        {isClosed && (repliesCount != null)
          ? (
            <div className={styles.closedInfoContainer}>
              <IconMessageDots className={styles.closedIcon} />
              <Text className={styles.closedCount}>
                {`+${repliesCount}`}
              </Text>
            </div>
          )
          : null}
      </div>
      {!isClosed
        && (
        <>
          {editingComment
            ? (
              <div className={styles.editorContainer}>
                <TextArea
                  ref={editRef}
                  value={text}
                  onChange={updateCommentText}
                  showCount
                />
                <Button
                  className={styles.editorSubmit}
                  type="primary"
                  onClick={editCommentText}
                  size="large"
                  disabled={isLoading}
                >
                  Save
                </Button>
                <Button
                  className={styles.editorCancel}
                  type="text"
                  onClick={cancelEditing}
                  size="large"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
            )
            : (
              <Paragraph
                className={styles.text}
                ellipsis={isPinned ? false : { rows: 5, expandable: true, symbol: 'more' }}
              >
                {deleted ? '<deleted>' : text}
              </Paragraph>
            )}
          {!isPreview && !deleted && (
            <div className={styles.optionsContainer}>
              <div
                className={styles.reply}
                onClick={() => setReplying(!isReplying)}
                onKeyPress={() => setReplying(!isReplying)}
                role="button"
                tabIndex={0}
              >
                <IconMessages
                  className={styles.replyIcon}
                />
                <span className={styles.replyButton}>Reply</span>
              </div>
              <LikeDislikePair
                commentId={commentId}
                liked={liked}
                likesCount={likesCount}
                disliked={disliked}
                dislikesCount={dislikesCount}
                likeComment={likeComment}
                removeCommentLike={removeCommentLike}
                dislikeComment={dislikeComment}
                removeCommentDislike={removeCommentDislike}
                userCanInteract={userCanInteract && (userState?.profile?.profile_id !== profileId)}
                continueAnonymously={continueAnonymously}
              />
              {getCommentOptionsDropdown()}
            </div>
          )}
        </>
        )}
      {(isReplying) && (
      <div className={styles.replyContainer}>
        <CommentEntry
          commentBinId={commentBinId}
          labelPrompt={labelPrompt}
          labels={labels}
          parentId={commentId}
          afterSubmit={() => { setReplying(false); }}
          afterCancel={() => { setReplying(false); }}
          createReply={createReply}
          userCanInteract={userCanInteract}
          cantInteractReason={cantInteractReason}
          allowAnonymous={allowAnonymous}
          continueAnonymously={continueAnonymously}
          stayOpen
          refreshProgress={refreshProgress}
        />
      </div>
      )}
    </div>
  );
}

QuteeComment.displayName = 'Qutee Comment';
QuteeComment.defaultProps = {
  isPreview: false
};

export default QuteeComment;
