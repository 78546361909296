import React, { useContext, useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import clone from 'just-clone';
import {
  Button, App, Result, Skeleton
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import EmbedContext from '../../embed/providers/EmbedProvider';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException, isEmptyObject } from '../../common/utils';
import config from '../../common/infra/env.config';
import { useIsXl } from '../../common/state/responsiveChecks';
import useCommentBin from '../state/useCommentBin';
import useCommentBinSettings from '../state/useCommentBinSettings';
import useComments from '../state/useComments';
import useMyComments from '../state/useMyComments';
import useNewCommentsCount from '../state/useNewCommentsCount';
import useCommentBinVisit from '../state/useCommentBinVisit';
import useReplies from '../state/useReplies';
import CommentList from './CommentList';
import ThreadModal from './ThreadModal';
import CommentBinPrompt from './CommentBinPrompt';
import CommentBinTitle from './CommentBinTitle';
import CommentBinSubtitle from './CommentBinSubtitle';
import CommentEntry from './CommentEntry';
import styles from '../styles/CommentBin.module.scss';

function CommentBin({
  commentBinId, filter, setFilter, filterData, setFilterData,
  isLoadingFilters, setLoadingFilters, totalCommentCount,
  userCanInteract, cantInteractReason, userCanEditBin,
  labelEditingDisabled, checkUserCanEditComment,
  continueAnonymously, allowAnonymous, showTitle,
  showSubtitle, highlightNextCommentBinButton,
  refreshBinData, setRefreshBinData, updateExternalBinData,
  hasNextBin, selectNextActivity, mutateProgress,
  topNavOffset, allowNextPoll, setMutateCommentsGlobal,
  setJoyrideIndex, commentDialogStateHandler, commentDialogState,
  triggerRepositionJoyride, previewDialogState
}) {
  const { notification } = App.useApp();
  const embedConfig = useContext(EmbedContext);
  const { liveFrequency } = embedConfig;
  const { state: userState } = useUserContext();
  const isLargeScreen = useIsXl();
  const [newComment, setNewComment] = useState(null);
  const [newCommentVisible, setNewCommentVisible] = useState(false);
  const [sort, setSort] = useState(liveFrequency ? 'recent' : 'popular');
  const [newCommentCountDate, setNewCommentCountDate] = useState((new Date()).toISOString());
  const [createdCommentsCount, setCountCreatedComments] = useState(0);
  const [createdCommentsCount2, setCreatedCommentsCount2] = useState(0);
  const [newCommentsCount, setNewCommentsCount] = useState(0);
  const [foundNewComments, setFoundNewComments] = useState(false);
  const [newReplyThreads, setNewReplyThreads] = useState([]);
  const [prevIsLoadingReplies, setPrevIsLoadingReplies] = useState(false);
  const [checkNewReplies, setCheckNewReplies] = useState(false);
  const [showNewReplyThreadsModal, setShowNewReplyThreadsModal] = useState(false);

  const {
    commentBin,
    mutate: commentBinMutate,
    isLoading: isLoadingCommentBin,
    isError: isErrorCommentBin
  } = useCommentBin(commentBinId);
  const {
    settings: commentBinSettings
  } = useCommentBinSettings(commentBinId);
  const {
    comments,
    numComments: filterCommentCount,
    mutate: commentsMutate,
    isLoadingMore: isLoadingComments,
    isReachingEnd: isEndComments,
    isRefreshing: isRefreshingComments,
    size: sizeComments,
    setSize: setSizeComments,
    reload: reloadComments
  } = useComments(
    commentBin && !userState.isPreFirstLoad ? commentBin.comment_bin_id : null,
    sort,
    filter,
    userState.profile
  );
  const {
    count: numNewComments,
    foundComments: hasNewComments
  } = useNewCommentsCount(
    commentBin ? commentBin.comment_bin_id : null,
    newCommentCountDate,
    liveFrequency
  );
  const {
    numComments: numUserComments
  } = useMyComments(
    (commentBin && userState.isLoggedIn) ? commentBin.comment_bin_id : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );
  const {
    commentBinVisit,
    isLoading: isLoadingCommentBinVisit,
    isError: isErrorCommentBinVisit
  } = useCommentBinVisit(
    (commentBin && userState.isLoggedIn) ? commentBin.comment_bin_id : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );
  const {
    replyThreads,
    isLoading: isLoadingReplies,
    isValidating: isValidatingReplies,
    isError: isErrorReplies
  } = useReplies(
    (commentBin && userState.isLoggedIn) ? commentBin.comment_bin_id : null,
    userState.isLoggedIn ? userState.profile.profile_id : null,
    commentBinVisit?.latest_visit_date
  );

  const hideCommentBinResults = commentBinSettings
    && commentBinSettings.results_hidden;
  const hideComments = commentBinSettings
    && commentBinSettings.comments_hidden;

  useEffect(() => {
    if (refreshBinData) {
      if (typeof commentBinMutate === 'function') {
        commentBinMutate();
      }
      if (setRefreshBinData) {
        setRefreshBinData(false);
      }
    }
  }, [refreshBinData, setRefreshBinData, commentBinMutate]);

  useEffect(() => {
    setMutateCommentsGlobal(() => commentsMutate);
  }, [commentsMutate, setMutateCommentsGlobal]);

  useEffect(() => {
    if (prevIsLoadingReplies && !isLoadingReplies && !isValidatingReplies && !isErrorReplies) {
      setCheckNewReplies(true);
    }
    setPrevIsLoadingReplies(isLoadingReplies || isValidatingReplies);
  }, [isLoadingReplies, isValidatingReplies, isErrorReplies, prevIsLoadingReplies]);

  const { patch: patchCommentBinTitleRequest, response: patchCommentBinTitleResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchCommentBinSubtitleRequest, response: patchCommentBinSubtitleResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchCommentBinPromptRequest, response: patchCommentBinPromptResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchLabelSetRequest, response: patchLabelSetResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { post: postLabelRequest, response: postLabelResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchLabelRequest, response: patchLabelResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { delete: deleteLabelRequest, response: deleteLabelResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { post: postCommentRequest, response: postCommentResponse } = useFetch('comments', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchCommentRequest, response: patchCommentResponse } = useFetch('comments', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { delete: deleteCommentRequest, response: deleteCommentResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });

  const { get: getRepliesRequest, response: getRepliesResponse } = useFetch('comment-bins', (globalOptions) => {
    if (!userState.profile) {
      // @ts-ignore
      globalOptions.headers.Authorization = 'Bearer unauthenticated';
    }
    return globalOptions;
  });
  const { get: getDeeperThreadRequest, response: getDeeperThreadResponse } = useFetch('comment-bins', (globalOptions) => {
    if (!userState.profile) {
      // @ts-ignore
      globalOptions.headers.Authorization = 'Bearer unauthenticated';
    }
    return globalOptions;
  });

  const { post: postCommentLikeRequest, response: postCommentLikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  const { delete: deleteCommentLikeRequest, response: deleteCommentLikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  const { post: postCommentDislikeRequest, response: postCommentDislikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  const { delete: deleteCommentDislikeRequest, response: deleteCommentDislikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  const { post: postCommentReportRequest, response: postCommentReportResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  const { delete: deleteCommentReportRequest, response: deleteCommentReportResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });

  const { post: postCommentBinVisitRequest } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  // If there's no existing visit data, create an initial one
  useEffect(
    () => {
      if (!isLoadingCommentBinVisit && !isErrorCommentBinVisit
        && commentBinVisit && isEmptyObject(commentBinVisit)) {
        postCommentBinVisitRequest(`${commentBinId}/visit/me`);
      }
    },
    [commentBinVisit, isLoadingCommentBinVisit,
      isErrorCommentBinVisit, commentBinId, postCommentBinVisitRequest]
  );

  // After getting replies since last visit, update last visit date,
  // process the threads and notify user.
  // Had trouble figuring out the right logic to get this to only run at the correct times,
  // so the activation conditions are a little complex. Could probably be simplified
  // by better handling rerender stuff.
  useEffect(() => {
    if (commentBinId && replyThreads != null && checkNewReplies) {
      setCheckNewReplies(false);
      postCommentBinVisitRequest(`${commentBinId}/visit/me`);
      if (!replyThreads.length) {
        return;
      }
      const myComments = replyThreads.reduce((acc, cur) => {
        let existing = acc.filter((c) => c.comment_id === cur.parent_comment_id);
        if (existing.length) {
          // array deconstruction
          [existing] = existing;
          const { parent_comment: removing, ...remaining } = cur;
          existing.replies.push(remaining);
          return acc;
        }

        // Make use of object and array spread to get copies of the objects/arrays,
        // instead of using them by reference
        const { parent_comment: curParentComment, ...remaining } = cur;
        const newParentComment = { ...curParentComment };
        newParentComment.replies = [...curParentComment.replies];
        newParentComment.replies.push(remaining);
        acc.push(newParentComment);
        return acc;
      }, []);
      setNewReplyThreads(myComments);

      notification.open({
        className: styles.notification,
        message: 'New Replies To You',
        key: 'newReplies',
        description:
          'There are new replies to your comments since your last visit. Click this notification to view them.',
        duration: 0,
        onClick: () => { notification.destroy('newReplies'); setShowNewReplyThreadsModal(true); }
      });
    }
  }, [commentBinId, replyThreads, checkNewReplies, notification, postCommentBinVisitRequest]);

  useEffect(() => {
    if (previewDialogState === 'close') {
      setNewCommentVisible(false);
    }
  }, [previewDialogState]);

  useEffect(() => {
    if (hasNewComments) {
      if (numNewComments && (numNewComments === createdCommentsCount)) {
        setFoundNewComments(false);
      } else {
        setFoundNewComments(true);
      }
      if (numNewComments) {
        setNewCommentsCount(numNewComments - createdCommentsCount);
      }
    } else {
      setFoundNewComments(false);
    }
  }, [numNewComments, createdCommentsCount, hasNewComments]);

  const updateCommentBinPrompt = async (commentBinId, prompt) => {
    const data = {
      prompt
    };
    const original = { ...commentBin };
    commentBinMutate({ ...commentBin, ...data }, false);
    const updatedCommentBin = await patchCommentBinPromptRequest(`${commentBinId}`, data);
    if (patchCommentBinPromptResponse.ok) {
      commentBinMutate(updatedCommentBin);
    } else {
      commentBinMutate(original);
      throw new ApiException(updatedCommentBin);
    }
  };

  const updateCommentBinTitle = async (commentBinId, title) => {
    const data = {
      title
    };
    const original = { ...commentBin };
    commentBinMutate({ ...commentBin, ...data }, false);
    const updatedCommentBin = await patchCommentBinTitleRequest(`${commentBinId}`, data);
    if (patchCommentBinTitleResponse.ok) {
      commentBinMutate(updatedCommentBin);
      if (typeof updateExternalBinData === 'function') {
        updateExternalBinData();
      }
    } else {
      commentBinMutate(original);
      throw new ApiException(updatedCommentBin);
    }
  };

  const updateCommentBinSubtitle = async (commentBinId, subtitle) => {
    const data = {
      subtitle
    };
    const original = { ...commentBin };
    commentBinMutate({ ...commentBin, ...data }, false);
    const updatedCommentBin = await patchCommentBinSubtitleRequest(`${commentBinId}`, data);
    if (patchCommentBinSubtitleResponse.ok) {
      commentBinMutate(updatedCommentBin);
      if (typeof updateExternalBinData === 'function') {
        updateExternalBinData();
      }
    } else {
      commentBinMutate(original);
      throw new ApiException(updatedCommentBin);
    }
  };

  const updateLabelSet = async (commentBinId, prompt) => {
    const data = {
      prompt
    };
    const original = { ...commentBin };
    commentBinMutate({ ...commentBin, label_set: { ...commentBin.label_set, ...data } }, false);
    const updatedLabelSet = await patchLabelSetRequest(`${commentBinId}/labelset`, data);
    if (patchLabelSetResponse.ok) {
      commentBinMutate();
    } else {
      commentBinMutate(original);
      throw new ApiException(updatedLabelSet);
    }
  };

  const createLabel = async (commentBinId, name) => {
    const labelData = {
      name
    };

    const newLabel = await postLabelRequest(`${commentBinId}/labels`, labelData);
    if (postLabelResponse.ok) {
      commentBinMutate();
      return newLabel;
    }
    throw new ApiException(newLabel);
  };

  const updateLabel = async (commentBinId, labelId, name) => {
    const data = {
      name
    };
    const original = clone(commentBin);
    const mutation = clone(commentBin);
    const mutationLabel = commentBin.label_set.labels.filter((label) => label.label_id === labelId);
    mutationLabel.name = name;
    commentBinMutate(mutation, false);
    const updatedLabel = await patchLabelRequest(`${commentBinId}/labels/${labelId}`, data);
    if (patchLabelResponse.ok) {
      commentBinMutate();
    } else {
      commentBinMutate(original);
      throw new ApiException(updatedLabel);
    }
  };

  const deleteLabel = async (commentBinId, labelId) => {
    const response = await deleteLabelRequest(`${commentBinId}/labels/${labelId}`);
    if (deleteLabelResponse.ok) {
      commentBinMutate();
    } else {
      throw new ApiException(response);
    }
  };

  // eslint-disable-next-line function-paren-newline
  const createComment = async (
    commentBinId, entryText, labelId, parentId, authorName, confirmAppropriate
  // eslint-disable-next-line function-paren-newline
  ) => {
    const commentData = {
      comment_bin_id: commentBinId,
      comment_text: entryText,
      label_id: labelId
    };
    if (parentId) {
      commentData.parent_comment_id = parentId;
    }

    if (authorName) {
      commentData.author_name = authorName;
    }

    if (confirmAppropriate) {
      commentData.confirm_appropriate = confirmAppropriate;
    }

    const newComment = await postCommentRequest('', commentData);
    if (postCommentResponse.ok) {
      return newComment;
    }
    throw new ApiException(newComment);
  };

  const updateComment = async (commentId, data) => {
    const updatedComment = await patchCommentRequest(`${commentId}`, data);
    if (patchCommentResponse.ok) {
      return updatedComment;
    }
    throw new ApiException(updatedComment);
  };

  const deleteComment = async (commentId) => {
    const response = await deleteCommentRequest(`${commentId}`);
    if (!deleteCommentResponse.ok) {
      throw new ApiException(response);
    }
  };

  const likeComment = async (commentId) => {
    const response = await postCommentLikeRequest(`${commentId}/likes`);
    if (!postCommentLikeResponse.ok) {
      throw new ApiException(response);
    }
  };

  const removeCommentLike = async (commentId) => {
    const response = await deleteCommentLikeRequest(`${commentId}/likes`);
    if (!deleteCommentLikeResponse.ok) {
      throw new ApiException(response);
    }
  };

  const dislikeComment = async (commentId) => {
    const response = await postCommentDislikeRequest(`${commentId}/dislikes`);
    if (!postCommentDislikeResponse.ok) {
      throw new ApiException(response);
    }
  };

  const removeCommentDislike = async (commentId) => {
    const response = await deleteCommentDislikeRequest(`${commentId}/dislikes`);
    if (!deleteCommentDislikeResponse.ok) {
      throw new ApiException(response);
    }
  };

  const reportComment = async (commentId) => {
    const response = await postCommentReportRequest(`${commentId}/reports`);
    if (!postCommentReportResponse.ok) {
      throw new ApiException(response);
    }
  };

  const removeCommentReport = async (commentId) => {
    const response = await deleteCommentReportRequest(`${commentId}/reports`);
    if (!deleteCommentReportResponse.ok) {
      throw new ApiException(response);
    }
  };

  const loadReplies = async (commentBinId, parentId, page, limit) => {
    const filterQuery = `filter[parent_comment_id]=${parentId}`;
    const sortQuery = `sort=${sort}`;
    const paginationQuery = `page=${page}&limit=${limit}`;
    const result = await getRepliesRequest(`${commentBinId}/threads?${filterQuery}&${sortQuery}&${paginationQuery}`);
    if (getRepliesResponse.ok) {
      return [result.results, result.pageInfo.has_next];
    }
    throw new ApiException(result);
  };

  const loadDeeperThread = async (commentBinId, parentId) => {
    const filterQuery = `filter[parent_comment_id]=${parentId}`;
    const sortQuery = `sort=${sort}`;
    const result = await getDeeperThreadRequest(`${commentBinId}/threads?${filterQuery}&${sortQuery}`);
    if (getDeeperThreadResponse.ok) {
      const replies = result.results;
      return replies;
    }
    throw new ApiException(result);
  };

  const refreshProgress = async () => {
    if (typeof mutateProgress === 'function' && !numUserComments && !createdCommentsCount2) {
      mutateProgress();
    }
  };

  const reloadNewComments = async (resetFilter = true, goToRecent = true) => {
    if (resetFilter) {
      setFilterData({
        connections: [], labels: [], topics: [], sentiments: [], search_term: {}
      });
      setFilter('');
    }
    if (goToRecent && sort === 'popular') {
      setSort('recent');
    }
    await reloadComments();
    setNewCommentCountDate((new Date()).toISOString());
  };

  const incrementCreatedComments = () => {
    setCountCreatedComments((count) => count + 1);
  };

  const incrementCreatedComments2 = () => {
    setCreatedCommentsCount2((count) => count + 1);
  };

  const getCommentDisplay = (
    comments,
    { continueThread = null, createReply = null, isPreview = false }
  ) => {
    const list = (
      <CommentList
        commentBinId={commentBinId}
        threadList={comments}
        labelPrompt={commentBin?.label_set?.prompt}
        labels={commentBin?.label_set?.labels}
        filters={filter}
        setFilters={setFilter}
        filterData={filterData}
        setFilterData={setFilterData}
        isLoadingFilters={isLoadingFilters}
        setLoadingFilters={setLoadingFilters}
        sizeComments={sizeComments}
        setSizeComments={setSizeComments}
        isLoadingComments={isLoadingComments}
        isEndComments
        isRefreshingComments={isRefreshingComments}
        totalCommentCount={totalCommentCount}
        filterCommentCount={filterCommentCount}
        sort={sort}
        setSort={setSort}
        loadReplies={loadReplies}
        continueThread={continueThread || null}
        createComment={createReply || createComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        likeComment={likeComment}
        removeCommentLike={removeCommentLike}
        dislikeComment={dislikeComment}
        removeCommentDislike={removeCommentDislike}
        reportComment={reportComment}
        removeCommentReport={removeCommentReport}
        userCanInteract={userCanInteract}
        cantInteractReason={cantInteractReason}
        userCanEditQutee={userCanEditBin}
        checkUserCanEditComment={checkUserCanEditComment}
        showHeading={false}
        allowAnonymous={allowAnonymous}
        foundNewComments={false}
        hideCommentBinResults={false}
        continueAnonymously={continueAnonymously}
        isPreview={isPreview}
        refreshProgress={refreshProgress}
      />
    );
    return list;
  };

  // TODO: Further flesh this out to allow loading additional comments scoped to
  // the current parentId, if there are more than pageSize of comments
  const continueThread = async (parentId) => {
    const comments = await loadDeeperThread(commentBinId, parentId);
    const list = getCommentDisplay(comments, { continueThread });
    return list;
  };

  // eslint-disable-next-line function-paren-newline
  const createTopLevelComment = async (
    commentBinId, entryText, labelId, authorName, confirmAppropriate
  // eslint-disable-next-line function-paren-newline
  ) => {
    // eslint-disable-next-line function-paren-newline
    const comment = await createComment(
      commentBinId, entryText, labelId, null, authorName, confirmAppropriate
    // eslint-disable-next-line function-paren-newline
    );
    commentsMutate();
    incrementCreatedComments();
    incrementCreatedComments2();
    const list = getCommentDisplay([comment], { continueThread, isPreview: true });

    setNewComment(list);
    setNewCommentVisible(true);
    setTimeout(() => {
      setJoyrideIndex(20);
    }, 200);

    return comment;
  };

  // eslint-disable-next-line function-paren-newline
  const createReply = async (
    commentBinId, entryText, labelId, parentId, authorName, confirmAppropriate
  // eslint-disable-next-line function-paren-newline
  ) => {
    // eslint-disable-next-line function-paren-newline
    const comment = await createComment(
      commentBinId, entryText, labelId, parentId, authorName, confirmAppropriate
    // eslint-disable-next-line function-paren-newline
    );
    incrementCreatedComments2();
    const list = getCommentDisplay([comment], { continueThread, createReply, isPreview: true });

    setNewComment(list);
    setNewCommentVisible(true);

    return comment;
  };

  const openHelpDesk = () => {
    const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const hasError = () => isErrorCommentBin;

  const err = hasError();
  if (err) {
    console.log(err);
    const errTitle = err.message;
    const errSubtitle = err?.details?.issue;

    return (
      <Result
        className="qutee--error"
        status="error"
        icon={<WarningOutlined />}
        title={userState.role === 'admin'
          ? errTitle
          : 'There was an error loading comments for this engagement.'}
        subTitle={userState.role === 'admin'
          ? errSubtitle
          : 'Please refresh the page, or contact support if the issue persists.'}
        extra={[
          <Button type="primary" key="support" onClick={() => openHelpDesk()}>
            Get Support
          </Button>
        ]}
      />
    );
  }

  const afterSubmitComment = () => {
    if (typeof highlightNextCommentBinButton === 'function') {
      highlightNextCommentBinButton();
    }
  };

  if (isLoadingCommentBin) {
    return <Skeleton />;
  }

  return (
    <div className={styles.comments}>
      <div className={styles.header}>
        {(isLargeScreen) && (
          <div className={styles.titleSubtitle}>
            {showTitle && (
              <div className={styles.title}>
                <CommentBinTitle
                  commentBinId={commentBinId}
                  title={commentBin?.title}
                  allowEditing={userCanEditBin}
                  updateCommentBin={updateCommentBinTitle}
                />
              </div>
            )}
            {showSubtitle && (
              <div className={styles.subtitle}>
                <CommentBinSubtitle
                  commentBinId={commentBinId}
                  subtitle={commentBin?.subtitle}
                  allowEditing={userCanEditBin}
                  updateCommentBin={updateCommentBinSubtitle}
                />
              </div>
            )}
          </div>
        )}
        <div className={styles.entry}>
          <div className={styles.prompt}>
            <CommentBinPrompt
              commentBinId={commentBinId}
              commentPrompt={commentBin?.prompt}
              allowEditing={userCanEditBin}
              updateCommentBin={updateCommentBinPrompt}
            />
          </div>
          <CommentEntry
            commentBinId={commentBinId}
            commentPrompt={(
              <div className={styles.prompt}>
                <CommentBinPrompt
                  commentBinId={commentBinId}
                  commentPrompt={commentBin?.prompt}
                  allowEditing={userCanEditBin}
                  updateCommentBin={updateCommentBinPrompt}
                />
              </div>
            )}
            labelPrompt={commentBin?.label_set?.prompt}
            labels={commentBin?.label_set?.labels}
            createComment={createTopLevelComment}
            allowEditing={userCanEditBin}
            updateLabelSet={updateLabelSet}
            createLabel={createLabel}
            updateLabel={updateLabel}
            deleteLabel={deleteLabel}
            userCanInteract={userCanInteract}
            cantInteractReason={cantInteractReason}
            labelEditingDisabled={labelEditingDisabled}
            allowAnonymous={allowAnonymous}
            continueAnonymously={continueAnonymously}
            afterSubmit={afterSubmitComment}
            stayOpen
            refreshProgress={refreshProgress}
            setJoyrideIndex={setJoyrideIndex}
            commentDialogStateHandler={commentDialogStateHandler}
            commentDialogState={commentDialogState}
            triggerRepositionJoyride={triggerRepositionJoyride}
          />
        </div>
      </div>
      <CommentList
        commentBinId={commentBinId}
        threadList={comments}
        labelPrompt={commentBin?.label_set?.prompt}
        labels={commentBin?.label_set?.labels}
        filters={filter}
        setFilters={setFilter}
        filterData={filterData}
        setFilterData={setFilterData}
        isLoadingFilters={isLoadingFilters}
        setLoadingFilters={setLoadingFilters}
        sizeComments={sizeComments}
        setSizeComments={setSizeComments}
        hideCommentBinResults={hideCommentBinResults}
        hideComments={hideComments}
        foundNewComments={foundNewComments}
        newCommentsCount={newCommentsCount}
        reloadComments={reloadNewComments}
        isLoadingComments={isLoadingComments}
        isEndComments={isEndComments}
        isRefreshingComments={isRefreshingComments}
        totalCommentCount={totalCommentCount}
        filterCommentCount={filterCommentCount}
        sort={sort}
        setSort={setSort}
        loadReplies={loadReplies}
        continueThread={continueThread}
        createComment={createReply}
        updateComment={updateComment}
        deleteComment={deleteComment}
        likeComment={likeComment}
        removeCommentLike={removeCommentLike}
        dislikeComment={dislikeComment}
        removeCommentDislike={removeCommentDislike}
        reportComment={reportComment}
        removeCommentReport={removeCommentReport}
        userCanInteract={userCanInteract}
        cantInteractReason={cantInteractReason}
        userCanEditQutee={userCanEditBin}
        checkUserCanEditComment={checkUserCanEditComment}
        showHeading
        allowAnonymous={allowAnonymous}
        continueAnonymously={continueAnonymously}
        isPreview={false}
        refreshProgress={refreshProgress}
        topNavOffset={topNavOffset}
      />
      <ThreadModal
        visible={newCommentVisible}
        setVisible={setNewCommentVisible}
        title="New Comment"
        useDefaultFooter={hasNextBin || allowNextPoll}
        handleOk={() => { setNewCommentVisible(false); selectNextActivity(); }}
        okText={hasNextBin ? 'Next Question' : 'Go to polls'}
        okButtonProps={{ className: styles.okButton }}
        cancelText="Close"
        className={styles.newCommentModal}
      >
        {newComment}
      </ThreadModal>
      <ThreadModal
        visible={showNewReplyThreadsModal}
        setVisible={setShowNewReplyThreadsModal}
        title="New Replies To You"
        useDefaultFooter={false}
        footer={(
          <Button
            className={styles.closeRepliesModal}
            type="primary"
            onClick={() => setShowNewReplyThreadsModal(false)}
          >
            Close
          </Button>
        )}
        className={styles.newCommentModal}
      >
        <CommentList
          commentBinId={commentBinId}
          threadList={newReplyThreads}
          labelPrompt={commentBin?.label_set?.prompt}
          labels={commentBin?.label_set?.labels}
          filters={filter}
          setFilters={setFilter}
          filterData={filterData}
          setFilterData={setFilterData}
          isLoadingFilters={isLoadingFilters}
          setLoadingFilters={setLoadingFilters}
          sizeComments={sizeComments}
          setSizeComments={setSizeComments}
          isLoadingComments={isLoadingComments}
          isEndComments
          isRefreshingComments={isRefreshingComments}
          totalCommentCount={totalCommentCount}
          filterCommentCount={filterCommentCount}
          sort={sort}
          setSort={setSort}
          loadReplies={loadReplies}
          continueThread={continueThread || null}
          createComment={createReply || createComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          likeComment={likeComment}
          removeCommentLike={removeCommentLike}
          dislikeComment={dislikeComment}
          removeCommentDislike={removeCommentDislike}
          reportComment={reportComment}
          removeCommentReport={removeCommentReport}
          userCanInteract={userCanInteract}
          cantInteractReason={cantInteractReason}
          userCanEditQutee={userCanEditBin}
          checkUserCanEditComment={checkUserCanEditComment}
          showHeading={false}
          allowAnonymous={allowAnonymous}
          foundNewComments={false}
          hideCommentBinResults={false}
          continueAnonymously={continueAnonymously}
          isPreview={false}
          refreshProgress={refreshProgress}
          defaultCollapsedReplies={false}
        />
      </ThreadModal>
    </div>
  );
}

CommentBin.displayName = 'Comment Bin';

CommentBin.defaultProps = {
  nextCommentBinButton: null,
  highlightNextCommentBinButton: null,
  refreshBinData: false,
  setRefreshBinData: false,
  updateExternalBinData: null,
  showTitle: true,
  showSubtitle: true,
  topNavOffset: undefined,
  triggerRepositionJoyride: undefined
};

export default CommentBin;
