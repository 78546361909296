import useSWR from 'swr';

function useQuteePolls(quteeId, profile) {
  const { data, error, mutate } = useSWR(quteeId
    ? [`qutees/${quteeId}/polls?limit=25&expand=options,ballot`, profile ? profile.profile_id : null]
    : null);

  return {
    polls: data ? data.results : null,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useQuteePolls;
