import React, { useEffect, useState } from 'react';
import {
  Button, App, Popconfirm, Switch, Checkbox, Tooltip
} from 'antd';
import {
  RightOutlined, LeftOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import styles from '../styles/PollControls.module.scss';

function PollControls({
  pollId, onlyResults, noResults, userCanEdit, userCanDelete,
  userCanPublish, showEditToggle, isEditing, setEditing,
  isPublished, setPublished, showResults,
  setShowResults, isDisabled, isLoading, setLoading, onDeletePoll,
  onPublishPoll, isMultipleChoice, totalBallots, onUpdateBallotChoice
}) {
  const { message } = App.useApp();
  const [localIsMultipleChoice, setLocalIsMultipleChoice] = useState(isMultipleChoice);

  const showPublish = () => !isDisabled && isEditing && userCanPublish && !isPublished;
  const showDelete = () => !isDisabled && userCanDelete && isEditing;
  const showEdit = () => !isDisabled && userCanEdit && showEditToggle && !onlyResults;
  const showSeeResults = () => !isEditing && !onlyResults && !noResults;
  const showBallotChoice = () => !isDisabled && isEditing && userCanEdit;

  useEffect(() => {
    setLocalIsMultipleChoice(isMultipleChoice);
  }, [isMultipleChoice]);

  const deletePoll = async () => {
    const loadingKey = 'deletePollRequest';
    const successKey = 'deletePollSuccess';
    const errorKey = 'deletePollError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Deleting poll...', key: loadingKey });
    try {
      await onDeletePoll(pollId);
      message.destroy(loadingKey);
      message.success({ content: 'Poll successfully deleted', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };
  const publishPoll = async () => {
    const loadingKey = 'publishPollRequest';
    const successKey = 'publishPollSuccess';
    const errorKey = 'publishPollError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Publishing poll...', key: loadingKey });
    try {
      await onPublishPoll(pollId);
      setPublished(true);
      message.destroy(loadingKey);
      message.success({ content: 'Poll successfully published', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const updateBallotChoice = async (isMultiple) => {
    const loadingKey = 'updateBallotChoiceRequest';
    const successKey = 'updateBallotChoiceSuccess';
    const errorKey = 'updateBallotChoiceError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLocalIsMultipleChoice(isMultiple);
    setLoading(true);
    message.loading({
      content: `Making poll ${isMultiple ? 'multiple' : 'single'} choice...`,
      key: loadingKey
    });
    try {
      await onUpdateBallotChoice(pollId, isMultiple);
      message.destroy(loadingKey);
      message.success({ content: `Poll is now ${isMultiple ? 'multiple' : 'single'} choice`, key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const toggleResults = () => {
    if (showResults) {
      setShowResults(false);
    } else {
      setShowResults(true);
    }
  };

  return (
    <div className={`${styles.container} ${isDisabled ? styles.disabledContainer : ''}`}>
      {showBallotChoice() && (
      <Tooltip
        mouseEnterDelay={0.3}
        title={totalBallots
          ? 'Cannot toggle multi-selection after the poll has received a ballot'
          : null}
      >
        <Checkbox
          className={styles.ballotChoice}
          checked={localIsMultipleChoice}
          disabled={isDisabled || isLoading || totalBallots}
          onChange={(e) => updateBallotChoice(e.target.checked)}
        >
          Enable Multi-Selection
        </Checkbox>
      </Tooltip>
      )}
      {showPublish() && (
        <Button className={styles.publish} type="primary" onClick={publishPoll} disabled={isLoading}>
          Publish
        </Button>
      )}
      {showDelete() && (
        <Popconfirm
          title="Delete this poll?"
          onConfirm={deletePoll}
          okText="Delete"
          okButtonProps={{ className: styles.confirmDelete }}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button
            className={styles.delete}
            type="primary"
            danger
            disabled={isLoading}
          >
            Delete Poll
          </Button>
        </Popconfirm>
      )}
      {showSeeResults() && (
      <Button className={styles.switch} type="text" onClick={toggleResults} disabled={isLoading}>
        {showResults && <LeftOutlined />}
        {showResults ? 'Back To Ballot' : 'See Results'}
        {!showResults && <RightOutlined />}
      </Button>
      )}
      {showEdit()
        && (
        <Switch
          checkedChildren="Edit"
          unCheckedChildren="Edit"
          checked={isEditing}
          onClick={(checked) => setEditing(checked)}
          disabled={isDisabled || isLoading}
        />
        )}
    </div>
  );
}

PollControls.displayName = 'PollControls';
PollControls.defaultProps = {
  onlyResults: false,
  noResults: false,
  userCanEdit: false,
  userCanDelete: false,
  userCanPublish: false,
  showEditToggle: false,
  isDisabled: false,
  onDeletePoll: () => true,
  onPublishPoll: () => true
};

export default PollControls;
