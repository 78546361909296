import { useMediaQuery } from 'react-responsive';

const useIsXs = () => {
  const isSize = useMediaQuery({ query: '(min-width: 480px)' });

  return isSize;
};

const useIsSm = () => {
  const isSize = useMediaQuery({ query: '(min-width: 576px)' });

  return isSize;
};

const useIsMd = () => {
  const isSize = useMediaQuery({ query: '(min-width: 768px)' });

  return isSize;
};

const useIsLg = () => {
  const isSize = useMediaQuery({ query: '(min-width: 992px)' });

  return isSize;
};

const useIsXl = () => {
  const isSize = useMediaQuery({ query: '(min-width: 1200px)' });

  return isSize;
};

const useIsXxl = () => {
  const isSize = useMediaQuery({ query: '(min-width: 1600px)' });

  return isSize;
};

const useIsTouch = () => {
  const isTouch = useMediaQuery({ query: '(hover: none)' });
  return isTouch;
};

export {
  useIsXl, useIsXxl, useIsLg, useIsMd, useIsSm, useIsXs, useIsTouch
};
