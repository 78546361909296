import { initializeApp } from 'firebase/app';
import {
  getAuth, GoogleAuthProvider, FacebookAuthProvider
} from 'firebase/auth';
import config, { setApiTenant, isLocal } from './env.config';

const firebaseConfig = {
  apiKey: config.firebase.API_KEY,
  authDomain: config.firebase.AUTH_DOMAIN
};

if (config.environment === 'localdev' || config.environment === 'local') {
  firebaseConfig.databaseURL = config.firebase.DATABASE_URL;
  firebaseConfig.projectId = config.firebase.PROJECT_ID;
  firebaseConfig.storageBucket = config.firebase.STORAGE_BUCKET;
  firebaseConfig.messagingSenderId = config.firebase.MESSAGING_SENDER_ID;
  firebaseConfig.appId = config.firebase.APP_ID;
}

const firebaseApp = initializeApp(firebaseConfig);

export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export const auth = getAuth(firebaseApp);

export const setTenant = (tenantId) => {
  if (!isLocal() && tenantId) {
    auth.tenantId = tenantId;
  } else if (!isLocal()) {
    auth.tenantId = config.api.DEFAULT_TENANT_ID;
  }
  setApiTenant(tenantId);
};
export const getTenant = () => auth.tenantId;
