import React from 'react';
import { useUserContext } from '../../user/providers/UserProvider';
import useQuteeProgress from '../state/useQuteeProgress';
import styles from '../styles/SaysoProgressText.module.scss';

function SaysoProgressText({ saysoId }) {
  const { state: userState } = useUserContext();

  const {
    progress,
    isLoading: isLoadingProgress
  } = useQuteeProgress(
    userState.isLoggedIn ? saysoId : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const getProgressPercent = () => {
    if (!progress) {
      return 0;
    }

    const totalActivities = (progress.comment_bin_count * 5)
      + progress.poll_count + progress.gauge_count;
    const userProgress = (progress.responded_comment_bin_count * 5)
      + progress.responded_poll_count + progress.responded_gauge_count;

    if (totalActivities === 0 || userProgress === 0) {
      return 0;
    }

    const percent = Math.round((userProgress / totalActivities) * 100);
    return percent;
  };

  if (!userState.isLoggedIn || isLoadingProgress) {
    return null;
  }

  const percent = getProgressPercent();
  let className = styles.inProgress;
  let text = `${percent}% Completed`;
  if (percent === 0) {
    className = styles.noProgress;
    text = 'Get Started';
  }
  if (percent === 100) {
    className = styles.completeProgress;
  }
  return <span className={className}>{text}</span>;
}

SaysoProgressText.displayName = 'Sayso Progress Text';

export default SaysoProgressText;
