import React, { useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  Button, Modal, Spin
} from 'antd';
import queryString from 'query-string';
import { auth } from '../../common/infra/firebase';
import config from '../../common/infra/env.config';
import { signOut } from '../state/api';
import { useUserContext } from '../../user/providers/UserProvider';
import AuthModal from './AuthModal';
import styles from '../styles/AccessCodeHandler.module.scss';

function AccessCodeHandler({
  mainEngagementId, accessCode, email
}) {
  const { state: userState, dispatch: userDispatch } = useUserContext();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isLoadingHashCheck, setLoadingHashCheck] = useState(true);
  const [isValidHash, setValidHash] = useState(null);
  const [loggedInEmail, setLoggedInEmail] = useState(null);
  const [isLoadingAuth, setLoadingAuth] = useState(false);

  const { post: verifyHash, response: verifyHashResponse } = useFetch('qutees', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setLoggedInEmail(user.email);
        if (user.email === email) {
          setShowInfoModal(false);
          const newUrl = queryString.exclude(window.location.href, ['email', 'access_code']);
          window.history.replaceState({}, document.title, newUrl);
        } else {
          setShowInfoModal(true);
        }
      } else {
        setLoggedInEmail(null);
        setShowInfoModal(true);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleInviteAccess = async () => {
      if (!accessCode || !email || !mainEngagementId) {
        return;
      }
      const data = {
        hash: accessCode,
        email
      };
      await verifyHash(`${mainEngagementId}/verify-hash`, data);
      if (verifyHashResponse.ok) {
        setValidHash(true);
        setLoadingHashCheck(false);
      } else {
        setValidHash(false);
        setLoadingHashCheck(false);
      }
    };
    handleInviteAccess();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openHelpDesk = () => {
    const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const closeAuthModal = () => {
    setShowAuthModal(false);
    setShowInfoModal(true);
  };

  const openAuthModal = () => {
    setLoadingAuth(true);
    setShowInfoModal(false);
    setShowAuthModal(true);
  };

  const successCallback = () => {
    closeAuthModal();
    setLoadingAuth(false);
  };

  const handleSignOut = async () => {
    setLoadingAuth(true);
    await signOut();
    setLoadingAuth(false);
    userDispatch({ type: 'LOGOUT_SUCCESS' });
  };

  const getInfoContent = () => {
    if (isLoadingHashCheck) {
      return (
        <div>
          <Spin className={styles.spin} />
          Verifying invite...
        </div>
      );
    }
    if (isLoadingAuth) {
      return (
        <div>
          <Spin className={styles.spin} />
          Loading...
        </div>
      );
    }
    if (!isValidHash) {
      return (
        <div>
          <div>
            The sayso invite is not valid. Please ensure you followed the correct link from your
            email, or contact support for assistance.
          </div>
          <div>
            <Button
              className={styles.authButton}
              type="primary"
              key="support"
              onClick={() => openHelpDesk()}
            >
              Get Support
            </Button>
          </div>
        </div>
      );
    }
    if (userState.isLoggedIn && email !== loggedInEmail) {
      return (
        <div>
          <div>
            Your email address does not match the email address from the invite.
            To accept the invite, please sign out first.
          </div>
          <div>
            <Button
              className={styles.authButton}
              onClick={handleSignOut}
              disabled={isLoadingAuth}
              type="primary"
            >
              Sign Out
            </Button>
          </div>
        </div>
      );
    }
    if (!userState.isLoggedIn) {
      return (
        <div>
          <div>
            You have been invited to participate in a sayso. Please sign up or sign in with the
            email address where you received the invite to accept.
          </div>
          <div>
            <Button
              className={styles.authButton}
              onClick={openAuthModal}
              type="primary"
            >
              Sign Up/Sign In
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  if (!accessCode || !email || !mainEngagementId) {
    return null;
  }

  return (
    <>
      <Modal
        title="Sayso Invite"
        open={showInfoModal}
        footer={null}
        closable={false}
        destroyOnClose
      >
        {getInfoContent()}
      </Modal>
      <AuthModal
        isVisible={showAuthModal}
        setVisible={setShowAuthModal}
        signInSuccessCallback={() => successCallback()}
        signUpSuccessCallback={() => successCallback()}
        cancelCallback={() => closeAuthModal()}
        startingTab="signup"
        closable
        showContinueNoAccount={false}
        allowAnonymous={false}
        forcedEmail={email}
        hash={accessCode}
        quteeId={mainEngagementId}
      />
    </>
  );
}

AccessCodeHandler.displayName = 'Access Code Handler';

export default AccessCodeHandler;
