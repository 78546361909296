import React from 'react';

const defaultProviderValue = {
  embedType: null,
  id: null,
  embedMode: null,
  headerType: null,
  hideTitle: null,
  hideGauges: null,
  hidePolls: null,
  simpleGauge: null,
  continueUrl: null,
  authUrl: null,
  actionUrl: null,
  liveFrequency: null,
  hideBanners: null,
  contentOnly: null,
  hideBannerImage: null,
  afterCommentCallback: null,
  anonBannerText: null,
  hideAnonBannerTOS: null,
  useProgressAffix: null,
  affixTargetContainerId: null,
  hideGaugesDesktop: null,
  extraGaugesContent: null,
  defaultPageMobile: null,
  hideGaugeTitle: null,
  gaugeType: null,
  hidePreheader: null,
  firstSwipeGauge: null
};

const EmbedContext = React.createContext(defaultProviderValue);

export default EmbedContext;
