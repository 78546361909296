import React, { useState, useContext } from 'react';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import AuthModal from './AuthModal';
import VerifyModal from './VerifyModal';

/**
 * Adds a click handler to its children, which opens the auth modal.
 * @param {*} children The child element to which the auth click handler will be attached.
 */
function AuthHandler({ children, signInSuccessCallback, signUpSuccessCallback }) {
  const { state: userState } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const [authVisible, setAuthVisible] = useState(false);
  const [verifyVisible, setVerifyVisible] = useState(false);

  const { authUrl } = embedConfig;

  const signInSuccess = () => {
    setAuthVisible(false);
    if (typeof signInSuccessCallback === 'function') {
      signInSuccessCallback();
    }
  };

  const signUpSuccess = () => {
    setAuthVisible(false);
    setVerifyVisible(true);
    if (typeof signUpSuccessCallback === 'function') {
      signUpSuccessCallback();
    }
  };

  const handleAuth = () => {
    const url = new URL(authUrl);
    const curUrl = new URL(window.location.toString());
    const checkBase = `${curUrl.origin}${curUrl.pathname}`;
    const authBase = `${url.origin}${url.pathname}`;
    if (checkBase === authBase) {
      setAuthVisible(true);
    } else {
      // Redirect to authUrl
      // TODO: Do we need to append continueUrl here? How can we do that safely?
      const newWindow = window.open(authUrl, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };

  return (
    <>
      {userState.profile
        ? children
        : React.cloneElement(children, { onClick: handleAuth })}
      <AuthModal
        isVisible={authVisible}
        setVisible={setAuthVisible}
        signUpSuccessCallback={signUpSuccess}
        signInSuccessCallback={signInSuccess}
        closable
      />
      <VerifyModal isVisible={verifyVisible} setVisible={setVerifyVisible} />
    </>
  );
}

AuthHandler.defaultProps = {
  signInSuccessCallback: () => null,
  signUpSuccessCallback: () => null
};

export default AuthHandler;
