import useSWR from 'swr';

function useNewCommentsCount(commentBinId, date, frequencyInSeconds) {
  const opts = {};
  if (frequencyInSeconds) {
    opts.refreshInterval = frequencyInSeconds * 1000;
  } else if (frequencyInSeconds === 0) {
    opts.refreshInterval = 0;
  } else {
    opts.refreshInterval = 30000;
  }

  const { data, error, mutate } = useSWR(
    (commentBinId && date && (frequencyInSeconds !== 0))
      ? [`comment-bins/${commentBinId}/count-comments?filter[created_date]=gt:${date}&filter[parent_comment_id]=`]
      : null,
    opts
  );

  return {
    count: (data && data.count) ? data.count : 0,
    foundComments: (data && data.found_comments),
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useNewCommentsCount;
