import React from 'react';
import styles from '../styles/GaugeRatingDisplay.module.scss';

function GaugeRatingDisplay({ rating }) {
  return (
    <div className={styles.container}>
      <span className={styles.rating}>
        Your Rating:
        {' '}
        {(rating >= 0) ? `${rating}%` : '?'}
      </span>
    </div>
  );
}

export default GaugeRatingDisplay;
