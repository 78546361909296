import React, { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { App as AntdApp, Modal } from 'antd';
import { useUserContext } from './user/providers/UserProvider';
import AuthModal from './auth/components/AuthModal';
import ActionsContainer from './embed/components/ActionsContainer';
import EmbedContext from './embed/providers/EmbedProvider';
import EmbedPage from './embed/components/EmbedPage';
import AccessCodeHandler from './auth/components/AccessCodeHandler';

function App({
  options, isMainEngagement, mainEngagementId, setMainEngagementId,
  resetTourHandler, setResetTourHandler, allowAnonymous, setAllowAnonymous
}) {
  const { message } = AntdApp.useApp();
  const { state: userState } = useUserContext();
  const queryValues = queryString.parse(window.location.search);
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [initialShowAuth, setInitialShowAuth] = useState(true);
  const actionMode = queryValues.mode;
  let oobCode = queryValues.oob_code;
  if (!oobCode) {
    oobCode = queryValues.oobCode;
  }
  const accessCode = queryValues.access_code;
  let email;
  if (queryValues.email) {
    email = decodeURIComponent(`${queryValues.email}`);
  }

  let shouldShowAuth = false;
  if (typeof queryValues.show_auth !== 'undefined') {
    shouldShowAuth = queryValues.show_auth === 'true';
  }
  // @ts-ignore
  if (typeof window.showAuth !== 'undefined') {
    // @ts-ignore
    shouldShowAuth = window.showAuth;
  }
  if (options && typeof options.showAuth !== 'undefined') {
    shouldShowAuth = options.showAuth === 'true';
  }
  if (actionMode) {
    shouldShowAuth = false;
  }

  const getActionsModalTitle = () => {
    if (!actionMode) {
      return null;
    }
    switch (actionMode) {
      case 'resetPassword':
        return 'Reset Password';
      case 'verifyEmail':
        return 'Verify Email';
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isMainEngagement && actionMode && oobCode) {
      setShowActionsModal(true);
    }
  }, [actionMode, oobCode, isMainEngagement, userState.isLoggedIn]);

  useEffect(() => {
    if (!userState.isPreFirstLoad) {
      if (userState.isLoggedIn) {
        setInitialShowAuth(false);
      } else if (shouldShowAuth && initialShowAuth) {
        setShowAuthModal(true);
        setInitialShowAuth(false);
      }
    }
  }, [shouldShowAuth, userState.isLoggedIn, userState.isPreFirstLoad,
    initialShowAuth]);

  const closeAuthModal = () => {
    setShowAuthModal(false);
  };

  // TODO: Think about making a generalized creation function for these,
  // or else putting them in a separate file
  const getEmbedType = () => {
    if (options && typeof options.embedType !== 'undefined') {
      return options.embedType;
    }
    // @ts-ignore
    if (typeof window.socialAskingEmbedType !== 'undefined') {
      // @ts-ignore
      return window.socialAskingEmbedType;
    }
    // @ts-ignore
    if (typeof window.quteeEmbedType !== 'undefined') {
      // @ts-ignore
      return window.quteeEmbedType;
    }
    if (typeof queryValues.embed_type !== 'undefined') {
      return queryValues.embed_type;
    }
    return 'social-asking-sidebars';
  };

  const getTargetId = () => {
    if (options && typeof options.id !== 'undefined') {
      return options.id;
    }
    // @ts-ignore
    if (typeof window.socialAskingTargetId !== 'undefined') {
      // @ts-ignore
      return window.socialAskingTargetId;
    }
    // @ts-ignore
    if (typeof window.quteeTargetId !== 'undefined') {
      // @ts-ignore
      return window.quteeTargetId;
    }
    if (typeof queryValues.id !== 'undefined') {
      return queryValues.id;
    }
    return null;
  };

  const isContentOnly = () => {
    if (options && typeof options.contentOnly !== 'undefined') {
      return options.contentOnly === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingContentOnly !== 'undefined') {
      // @ts-ignore
      return window.socialAskingContentOnly;
    }
    if (typeof queryValues.content_only !== 'undefined') {
      return queryValues.content_only === 'true';
    }
    return false;
  };

  // Unused
  const getEmbedMode = () => {
    if (options && typeof options.embedMode !== 'undefined') {
      return options.embedMode;
    }
    // @ts-ignore
    if (typeof window.socialAskingEmbedMode !== 'undefined') {
      // @ts-ignore
      return window.socialAskingEmbedMode;
    }
    if (typeof queryValues.embed_mode !== 'undefined') {
      return queryValues.embed_mode;
    }
    return null;
  };

  // Unused
  const getHeaderType = () => {
    if (options && typeof options.headerType !== 'undefined') {
      return options.headerType;
    }
    // @ts-ignore
    if (typeof window.socialAskingHeaderType !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHeaderType;
    }
    if (typeof queryValues.header_type !== 'undefined') {
      return queryValues.header_type;
    }
    return null;
  };

  // Unused
  const isTitleHidden = () => {
    if (options && typeof options.hideTitle !== 'undefined') {
      return options.hideTitle === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideTitle !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideTitle;
    }
    if (typeof queryValues.hide_title !== 'undefined') {
      return queryValues.hide_title === 'true';
    }
    return false;
  };

  const shouldHideGauges = () => {
    if (options && typeof options.hideGauges !== 'undefined') {
      return options.hideGauges === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideGauges !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideGauges;
    }
    if (typeof queryValues.hide_gauges !== 'undefined') {
      return queryValues.hide_gauges === 'true';
    }
    return false;
  };

  const shouldHidePolls = () => {
    if (options && typeof options.hidePolls !== 'undefined') {
      return options.hidePolls === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHidePolls !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHidePolls;
    }
    if (typeof queryValues.hide_polls !== 'undefined') {
      return queryValues.hide_polls === 'true';
    }
    return false;
  };

  // Deprecated
  const isSimpleGauge = () => {
    if (options && typeof options.simpleGauge !== 'undefined') {
      return options.simpleGauge === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingSimpleGauge !== 'undefined') {
      // @ts-ignore
      return window.socialAskingSimpleGauge;
    }
    if (typeof queryValues.simple_gauge !== 'undefined') {
      return queryValues.simple_gauge === 'true';
    }
    return false;
  };

  const getContinueUrl = () => {
    if (options && typeof options.continueUrl !== 'undefined') {
      return options.continueUrl;
    }
    // @ts-ignore
    if (typeof window.socialAskingContinueUrl !== 'undefined') {
      // @ts-ignore
      return window.socialAskingContinueUrl;
    }
    // @ts-ignore
    if (typeof window.continueUrl !== 'undefined') {
      // @ts-ignore
      return window.continueUrl;
    }
    if (typeof queryValues.continue_url !== 'undefined') {
      return queryValues.continue_url;
    }
    if (typeof queryValues.continueUrl !== 'undefined') {
      return queryValues.continueUrl;
    }
    return null;
  };

  const getAuthUrl = () => {
    if (options && typeof options.authUrl !== 'undefined') {
      return options.authUrl;
    }
    // @ts-ignore
    if (typeof window.socialAskingAuthUrl !== 'undefined') {
      // @ts-ignore
      return window.socialAskingAuthUrl;
    }
    // @ts-ignore
    if (typeof window.authUrl !== 'undefined') {
      // @ts-ignore
      return window.authUrl;
    }
    if (typeof queryValues.auth_url !== 'undefined') {
      return queryValues.auth_url;
    }
    const url = new URL(window.location.toString());
    url.hash = '';
    return url.toString();
  };

  const getActionUrl = () => {
    if (options && typeof options.actionUrl !== 'undefined') {
      return options.actionUrl;
    }
    // @ts-ignore
    if (typeof window.socialAskingActionUrl !== 'undefined') {
      // @ts-ignore
      return window.socialAskingActionUrl;
    }
    // @ts-ignore
    if (typeof window.actionUrl !== 'undefined') {
      // @ts-ignore
      return window.actionUrl;
    }
    if (typeof queryValues.action_url !== 'undefined') {
      return queryValues.action_url;
    }
    const url = new URL(window.location.toString());
    url.hash = '';
    return url.toString();
  };

  const getLiveFrequency = () => {
    const parse = (frequency) => {
      try {
        let liveFrequency = parseInt(frequency, 10);
        // Enforce a minimum polling frequency of 10 seconds
        if (liveFrequency > 0 && liveFrequency < 10) {
          liveFrequency = 10;
        }
        return liveFrequency;
      } catch (e) {
        message.error('Live frequency parameter should be a number.', 0);
        return null;
      }
    };
    if (options && typeof options.liveFrequency !== 'undefined') {
      return parse(options.liveFrequency);
    }
    // @ts-ignore
    if (typeof window.socialAskingLiveFrequency !== 'undefined') {
      // @ts-ignore
      return parse(window.socialAskingLiveFrequency);
    }
    // @ts-ignore
    if (typeof window.liveFrequency !== 'undefined') {
      // @ts-ignore
      return parse(window.liveFrequency);
    }
    if (typeof queryValues.live_frequency !== 'undefined') {
      return parse(queryValues.live_frequency);
    }
    return null;
  };

  const shouldHideBanners = () => {
    if (options && typeof options.hideBanners !== 'undefined') {
      return options.hideBanners === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideBanners !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideBanners;
    }
    if (typeof queryValues.hide_banners !== 'undefined') {
      return queryValues.hide_banners === 'true';
    }
    return false;
  };

  const shouldHideBannerImage = () => {
    if (options && typeof options.hideBannerImage !== 'undefined') {
      return options.hideBannerImage === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideBannerImage !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideBannerImage;
    }
    if (typeof queryValues.hide_banner_image !== 'undefined') {
      return queryValues.hide_banner_image === 'true';
    }
    return false;
  };

  const getAfterCommentCallback = () => {
    // @ts-ignore
    if (typeof window.socialAskingAfterCommentCallback === 'function') {
      // @ts-ignore
      return window.socialAskingAfterCommentCallback;
    }
    // @ts-ignore
    if (typeof window.afterCommentCallback === 'function') {
      // @ts-ignore
      return window.afterCommentCallback;
    }
    return null;
  };

  const getAnonBannerText = () => {
    if (options && typeof options.anonBannerText !== 'undefined') {
      return options.anonBannerText;
    }
    // @ts-ignore
    if (typeof window.anonBannerText !== 'undefined') {
      // @ts-ignore
      return window.anonBannerText;
    }
    if (typeof queryValues.anon_banner_text !== 'undefined') {
      return queryValues.anon_banner_text;
    }
    return null;
  };

  const hideAnonBannerTOS = () => {
    if (options && typeof options.hideAnonBannerTos !== 'undefined') {
      return options.hideAnonBannerTos === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideAnonBannerTos !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideAnonBannerTos;
    }
    // @ts-ignore
    if (typeof window.hideAnonBannerTos !== 'undefined') {
      // @ts-ignore
      return window.hideAnonBannerTos;
    }
    if (typeof queryValues.hide_anon_banner_tos !== 'undefined') {
      return queryValues.hide_anon_banner_tos === 'true';
    }
    return false;
  };

  const haveProgressAffix = () => {
    if (options && typeof options.useProgressAffix !== 'undefined') {
      return options.useProgressAffix === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingUseProgressAffix !== 'undefined') {
      // @ts-ignore
      return window.socialAskingUseProgressAffix;
    }
    // @ts-ignore
    if (typeof window.useProgressAffix !== 'undefined') {
      // @ts-ignore
      return window.useProgressAffix;
    }
    if (typeof queryValues.use_progress_affix !== 'undefined') {
      return queryValues.use_progress_affix === 'true';
    }
    return true;
  };

  const getAffixTargetContainerId = () => {
    if (options && typeof options.affixTargetContainerId !== 'undefined') {
      return options.affixTargetContainerId;
    }
    // @ts-ignore
    if (typeof window.socialAskingAffixTargetContainerId !== 'undefined') {
      // @ts-ignore
      return window.socialAskingAffixTargetContainerId;
    }
    // @ts-ignore
    if (typeof window.affixTargetContainerId !== 'undefined') {
      // @ts-ignore
      return window.affixTargetContainerId;
    }
    if (typeof queryValues.affix_target_container_id !== 'undefined') {
      return queryValues.affix_target_container_id;
    }
    return null;
  };

  const shouldHideGaugesDesktop = () => {
    if (options && typeof options.hideGaugesDesktop !== 'undefined') {
      return options.hideGaugesDesktop === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideGaugesDesktop !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideGaugesDesktop;
    }
    // @ts-ignore
    if (typeof window.hideGaugesDesktop !== 'undefined') {
      // @ts-ignore
      return window.hideGaugesDesktop;
    }
    if (typeof queryValues.hide_gauges_desktop !== 'undefined') {
      return queryValues.hide_gauges_desktop === 'true';
    }
    return false;
  };

  const getExtraGaugesContent = () => {
    if (options && typeof options.extraGaugesContent !== 'undefined') {
      return options.extraGaugesContent;
    }
    // @ts-ignore
    if (typeof window.socialAskingExtraGaugesContent !== 'undefined') {
      // @ts-ignore
      return window.socialAskingExtraGaugesContent;
    }
    // @ts-ignore
    if (typeof window.extraGaugesContent !== 'undefined') {
      // @ts-ignore
      return window.extraGaugesContent;
    }
    return null;
  };

  const getDefaultPageMobile = () => {
    if (options && typeof options.defaultPageMobile !== 'undefined') {
      return options.defaultPageMobile;
    }
    // @ts-ignore
    if (typeof window.socialAskingDefaultPageMobile !== 'undefined') {
      // @ts-ignore
      return window.socialAskingDefaultPageMobile;
    }
    // @ts-ignore
    if (typeof window.defaultPageMobile !== 'undefined') {
      // @ts-ignore
      return window.defaultPageMobile;
    }
    if (typeof queryValues.default_page_mobile !== 'undefined') {
      return queryValues.default_page_mobile;
    }
    return null;
  };

  const isGaugeTitleHidden = () => {
    if (options && typeof options.hideGaugeTitle !== 'undefined') {
      return options.hideGaugeTitle === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHideGaugeTitle !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHideGaugeTitle;
    }
    if (typeof queryValues.hide_gauge_title !== 'undefined') {
      return queryValues.hide_gauge_title === 'true';
    }
    return false;
  };

  const getGaugeType = () => {
    if (options && typeof options.gaugeType !== 'undefined') {
      return options.gaugeType;
    }
    // @ts-ignore
    if (typeof window.socialAskingGaugeType !== 'undefined') {
      // @ts-ignore
      return window.socialAskingGaugeType;
    }
    // @ts-ignore
    if (typeof window.gaugeType !== 'undefined') {
      // @ts-ignore
      return window.gaugeType;
    }
    if (typeof queryValues.gauge_type !== 'undefined') {
      return queryValues.gauge_type;
    }
    return 'slider';
  };

  const shouldHidePreheader = () => {
    if (options && typeof options.hidePreheader !== 'undefined') {
      return options.hidePreheader === 'true';
    }
    // @ts-ignore
    if (typeof window.socialAskingHidePreheader !== 'undefined') {
      // @ts-ignore
      return window.socialAskingHidePreheader;
    }
    if (typeof queryValues.hide_preheader !== 'undefined') {
      return queryValues.hide_preheader === 'true';
    }
    return false;
  };

  const getFirstSwipeGauge = () => {
    try {
      if (options && typeof options.firstSwipeGauge !== 'undefined') {
        return parseInt(options.firstSwipeGauge, 10);
      }
      // @ts-ignore
      if (typeof window.socialAskingFirstSwipeGauge !== 'undefined') {
        // @ts-ignore
        return parseInt(window.socialAskingFirstSwipeGauge, 10);
      }
      if (typeof queryValues.first_swipe_gauge !== 'undefined') {
        // @ts-ignore
        return parseInt(queryValues.first_swipe_gauge, 10);
      }
    } catch (e) {
      message.error('First Swipe Gauge parameter should be an ID (number).', 0);
      return null;
    }
    return null;
  };

  const providerValues = useMemo(() => ({
    embedType: getEmbedType(),
    embedMode: getEmbedMode(),
    id: getTargetId(),
    headerType: getHeaderType(),
    hideTitle: isTitleHidden(),
    hideGauges: shouldHideGauges(),
    hidePolls: shouldHidePolls(),
    simpleGauge: isSimpleGauge(),
    continueUrl: getContinueUrl(),
    authUrl: getAuthUrl(),
    actionUrl: getActionUrl(),
    liveFrequency: getLiveFrequency(),
    hideBanners: shouldHideBanners(),
    contentOnly: isContentOnly(),
    hideBannerImage: shouldHideBannerImage(),
    afterCommentCallback: getAfterCommentCallback(),
    anonBannerText: getAnonBannerText(),
    hideAnonBannerTOS: hideAnonBannerTOS(),
    useProgressAffix: haveProgressAffix(),
    affixTargetContainerId: getAffixTargetContainerId(),
    hideGaugesDesktop: shouldHideGaugesDesktop(),
    extraGaugesContent: getExtraGaugesContent(),
    defaultPageMobile: getDefaultPageMobile(),
    hideGaugeTitle: isGaugeTitleHidden(),
    gaugeType: getGaugeType(),
    hidePreheader: shouldHidePreheader(),
    firstSwipeGauge: getFirstSwipeGauge()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <EmbedContext.Provider value={providerValues}>
      <EmbedPage
        isMainEngagement={isMainEngagement}
        mainEngagementId={mainEngagementId}
        setMainEngagementId={setMainEngagementId}
        resetTourHandler={resetTourHandler}
        setResetTourHandler={setResetTourHandler}
        allowAnonymous={allowAnonymous}
        setAllowAnonymous={setAllowAnonymous}
        hasInviteCode={accessCode && email}
      />
      <AuthModal
        isVisible={showAuthModal}
        setVisible={setShowAuthModal}
        signInSuccessCallback={() => closeAuthModal()}
        signUpSuccessCallback={() => closeAuthModal()}
        cancelCallback={() => closeAuthModal()}
        startingTab="signin"
        closable
        showContinueNoAccount
        allowAnonymous={allowAnonymous}
      />
      {isMainEngagement && accessCode && email && (
      <AccessCodeHandler
        mainEngagementId={getTargetId()}
        accessCode={accessCode}
        email={email}
      />
      )}
      {isMainEngagement && (
      <Modal
        title={getActionsModalTitle()}
        open={showActionsModal}
        footer={null}
        destroyOnClose
        closable
        onCancel={() => setShowActionsModal(false)}
      >
        <ActionsContainer
          afterActionHook={() => setShowActionsModal(false)}
          notLoggedInHook={() => setShowAuthModal(true)}
        />
      </Modal>
      )}
    </EmbedContext.Provider>
  );
}

App.defaultProps = {
  options: {},
  mainEngagementId: null,
  setMainEngagementId: () => {},
  setAllowAnonymous: () => {},
  resetTourHandler: () => {},
  setResetTourHandler: () => () => {}
};

export default App;
