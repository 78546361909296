import React from 'react';
import { Alert, Button } from 'antd';
import { LockOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styles from './styles/EngagementStatusBanner.module.scss';

function EngagementStatusBanner({
  status, canEditQutee, setShowSettingsModal
}) {
  if (!status) {
    return null;
  }

  let message = null;
  let icon = null;
  let type = null;
  if (status === 'unpublished' && canEditQutee) {
    message = 'This engagement is hidden. Nobody can see it but you.';
    icon = <EyeInvisibleOutlined style={{ fontSize: 24 }} />;
    type = 'error';
  } else if (status === 'inactive') {
    icon = <LockOutlined style={{ fontSize: 24 }} />;
    type = 'warning';
    if (canEditQutee) {
      message = 'This engagement is locked. The public can view it but they cannot respond.';
    } else {
      message = 'This engagement is locked and not accepting responses.';
    }
  }

  if (!message) {
    return null;
  }

  if (canEditQutee && (typeof setShowSettingsModal === 'function')) {
    message = (
      <div>
        <span>{message}</span>
        <Button
          className={styles.button}
          type="link"
          onClick={() => setShowSettingsModal(true)}
        >
          Change Settings
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Alert
        // @ts-ignore
        type={type}
        icon={icon}
        showIcon
        message={message}
      />
    </div>
  );
}

EngagementStatusBanner.defaultProps = {
  userLoggedIn: false,
  userCanEdit: false,
  setShowSettingsModal: undefined
};

export default EngagementStatusBanner;
