import React, { useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  App, Popconfirm, Skeleton, Steps, Typography
} from 'antd';
import {
  PlusOutlined, DeleteOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import { ApiException } from '../../common/utils';
import styles from '../styles/CommentBinSelector.module.scss';

const { Text } = Typography;

function CommentBinSelector({
  commentBins, selectedCommentBinId, setSelectedCommentBinId,
  loadingInitial, mutateCommentBins, userCanEditBin,
  extraSelectedDescription, questionsProgress, afterSelectCallback,
  triggerCommentBinPinpoint, setTriggerCommentBinPinpoint, pinpointSection,
  progress, progressCommentBinRef
}) {
  const { message } = App.useApp();
  const [selectedStep, setSelectedStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const titlePlaceholder = '(No Title)';
  const titleAddPlaceholder = 'Add Title';
  const subtitleAddPlaceholder = 'Add Subtitle';

  useEffect(() => {
    if (commentBins && selectedCommentBinId) {
      const index = commentBins.map((bin) => bin.comment_bin_id).indexOf(selectedCommentBinId);
      setSelectedStep(index);
    }
  }, [commentBins, selectedCommentBinId]);

  const { patch: patchCommentBinTitleRequest, response: patchCommentBinTitleResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchCommentBinSubtitleRequest, response: patchCommentBinSubtitleResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { delete: deleteCommentBinRequest, response: deleteCommentBinResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const getFirstUnfinished = () => {
    const unfinishedIds = new Set(progress?.comment_bins
      ?.filter((bin) => bin.responded === false)
      ?.map((bin) => bin.comment_bin_id));
    const first = commentBins?.find((bin) => unfinishedIds.has(bin.comment_bin_id))?.comment_bin_id;
    return first;
  };
  const firstUnfinished = getFirstUnfinished();

  useEffect(() => {
    if (triggerCommentBinPinpoint) {
      if ((typeof pinpointSection === 'function')
      && progressCommentBinRef.current) {
        const rect = progressCommentBinRef.current.getBoundingClientRect();
        pinpointSection(progressCommentBinRef, rect.width + 10, 0);
      }
      if (typeof setTriggerCommentBinPinpoint === 'function') {
        setTriggerCommentBinPinpoint(false);
      }
    }
  }, [triggerCommentBinPinpoint, setTriggerCommentBinPinpoint,
    pinpointSection, progressCommentBinRef]);

  const updateCommentBinTitle = async (commentBinId, title) => {
    const data = {
      title
    };

    const loadingKey = 'updateQuestionTitleRequest';
    const successKey = 'updateQuestionTitleSuccess';
    const errorKey = 'updateQuestionTitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating question title...', key: loadingKey });
    // const original = [...commentBins];
    // if (typeof mutateCommentBins === 'function') {
    //   const modified = original.map((b) => {
    //     const bin = { ...b };
    //     if (bin.comment_bin_id === commentBinId) {
    //       bin.title = title;
    //     }
    //     return bin;
    //   });
    //   mutateCommentBins(modified, { revalidate: false });
    // }
    const updatedCommentBin = await patchCommentBinTitleRequest(`${commentBinId}`, data);
    if (patchCommentBinTitleResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: 'Question title successfully updated', key: successKey });
      setLoading(false);
      if (typeof mutateCommentBins === 'function') {
        mutateCommentBins();
      }
    } else {
      // if (typeof mutateCommentBins === 'function') {
      //   mutateCommentBins(original, false);
      // }
      const err = new ApiException(updatedCommentBin);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      setLoading(false);
    }
  };

  const updateCommentBinSubtitle = async (commentBinId, subtitle) => {
    const data = {
      subtitle
    };

    const loadingKey = 'updateQuestionSubtitleRequest';
    const successKey = 'updateQuestionSubtitleSuccess';
    const errorKey = 'updateQuestionSubtitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating question subtitle...', key: loadingKey });
    // const original = [...commentBins];
    // if (typeof mutateCommentBins === 'function') {
    //   const modified = original.map((b) => {
    //     const bin = { ...b };
    //     if (bin.comment_bin_id === commentBinId) {
    //       bin.subtitle = subtitle;
    //     }
    //     return bin;
    //   });
    //   mutateCommentBins(modified, false);
    // }
    const updatedCommentBin = await patchCommentBinSubtitleRequest(`${commentBinId}`, data);
    if (patchCommentBinSubtitleResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: 'Question subtitle successfully updated', key: successKey });
      setLoading(false);
      if (typeof mutateCommentBins === 'function') {
        mutateCommentBins();
      }
    } else {
      // if (typeof mutateCommentBins === 'function') {
      //   mutateCommentBins(original, false);
      // }
      const err = new ApiException(updatedCommentBin);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      setLoading(false);
    }
  };

  const deleteQuestion = async (commentBinId) => {
    setLoading(true);

    const loadingKey = 'deleteQuestionRequest';
    const successKey = 'deleteQuestionSuccess';
    const errorKey = 'deleteQuestionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Deleting question...', key: loadingKey });
    const deletedBin = await deleteCommentBinRequest(`${commentBinId}`);
    if (deleteCommentBinResponse.ok) {
      message.destroy(loadingKey);
      message.success({ content: 'Question successfully deleted', key: successKey });
      setLoading(false);
      setSelectedCommentBinId(null);
      if (typeof mutateCommentBins === 'function') {
        mutateCommentBins();
      }
    } else {
      const err = new ApiException(deletedBin);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      setLoading(false);
    }
  };

  const selectBin = (current) => {
    const binId = commentBins[current].comment_bin_id;
    setSelectedCommentBinId(binId);
    setSelectedStep(current);
    if (typeof afterSelectCallback === 'function') {
      afterSelectCallback();
    }
  };

  const binItems = commentBins
    ? commentBins.map((bin) => {
      const titleDisplay = (
        <span ref={firstUnfinished === bin.comment_bin_id ? progressCommentBinRef : undefined}>
          <Text
            className={(userCanEditBin && !bin.title) ? styles.addTitle : styles.title}
            editable={(userCanEditBin && !loading && (bin.comment_bin_id === selectedCommentBinId))
              ? {
                onChange: (value) => updateCommentBinTitle(selectedCommentBinId, value),
                text: bin.title || '',
                icon: bin.title ? null : <PlusOutlined className={styles.titlePlus} />,
                tooltip: bin.title ? 'Edit Title' : 'Add Title'
              }
              : false}
          >
            {bin.title || ((userCanEditBin && (bin.comment_bin_id === selectedCommentBinId))
              ? titleAddPlaceholder : titlePlaceholder)}
          </Text>
          {userCanEditBin && (bin.comment_bin_id === selectedCommentBinId) && (
            <Popconfirm
              title="Delete this question?"
              onConfirm={() => deleteQuestion(selectedCommentBinId)}
              okText="Delete"
              okButtonProps={{ className: styles.confirmDelete }}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <DeleteOutlined className={styles.delete} />
            </Popconfirm>
          )}
        </span>
      );
      const subtitleDisplay = (
        <>
          <Text
            className={(userCanEditBin && !bin.subtitle) ? styles.addSubtitle : styles.subtitle}
            editable={(userCanEditBin && !loading && (bin.comment_bin_id === selectedCommentBinId))
              ? {
                onChange: (value) => updateCommentBinSubtitle(selectedCommentBinId, value),
                text: bin.subtitle || '',
                icon: bin.subtitle ? null : <PlusOutlined className={styles.subtitlePlus} />,
                tooltip: bin.subtitle ? 'Edit Subtitle' : 'Add Subtitle'
              }
              : false}
          >
            {bin.subtitle || (userCanEditBin && (bin.comment_bin_id === selectedCommentBinId)
              && subtitleAddPlaceholder)}
          </Text>
          {(bin.comment_bin_id === selectedCommentBinId) && extraSelectedDescription}
        </>
      );

      const responded = questionsProgress ? questionsProgress[bin.comment_bin_id] : false;
      let status = 'wait';
      if ((bin.comment_bin_id === selectedCommentBinId) && !responded) {
        status = 'process';
      } else if (responded) {
        status = 'finish';
      }

      const item = {
        title: titleDisplay,
        description: subtitleDisplay,
        status
      };

      return item;
    })
    : [];

  if (loadingInitial) {
    return <Skeleton />;
  }
  return (
    <Steps
      className={styles.steps}
      direction="vertical"
      current={selectedStep}
      items={binItems}
      onChange={selectBin}
    />
  );
}

CommentBinSelector.displayName = 'Comment Bin Selector';
CommentBinSelector.defaultProps = {
  mutateCommentBins: null,
  userCanEditBin: false,
  extraSelectedDescription: undefined,
  questionsProgress: null,
  afterSelectCallback: null,
  triggerCommentBinPinpoint: undefined,
  setTriggerCommentBinPinpoint: undefined,
  pinpointSection: undefined,
  progress: undefined,
  progressCommentBinRef: undefined
};

export default CommentBinSelector;
