import React, { useState } from 'react';
import {
  Modal, Button, App, Spin
} from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import { anonymousLogin } from '../state/api';
import AuthForm from './AuthForm';
import styles from '../styles/AuthModal.module.scss';

function AuthModal({
  isVisible, setVisible, signInSuccessCallback, signUpSuccessCallback,
  cancelCallback, startingTab, closable, showContinueNoAccount, allowAnonymous,
  forcedEmail, hash, quteeId
}) {
  const { message } = App.useApp();
  const { dispatch: userDispatch } = useUserContext();
  const [loading, setLoading] = useState(false);

  const handleDefaultCancel = () => setVisible(false);

  const handleCancel = () => {
    if (typeof cancelCallback === 'function') {
      cancelCallback();
    } else {
      handleDefaultCancel();
    }
  };

  const continueAnonymously = async () => {
    const loadingKey = 'anonymousRequest';
    const errorKey = 'anonymousError';
    message.destroy(loadingKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Continuing anonymously...', key: loadingKey });
    try {
      const userInfo = await anonymousLogin();
      message.destroy(loadingKey);
      setLoading(false);
      if (typeof signInSuccessCallback === 'function') {
        signInSuccessCallback();
      }
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
    } catch (err) {
      message.destroy('anonymousRequest');
      message.error({ key: errorKey, content: err?.details?.issue || err.message, duration: 5 });
      setLoading(false);
    }
  };

  return (
    <Modal
      className={showContinueNoAccount ? styles.continueModal : styles.modal}
      width={520}
      open={isVisible}
      footer={null}
      onCancel={handleCancel}
      closable={closable}
    >
      <Spin spinning={loading}>
        {showContinueNoAccount && (
          <div className={styles.continueContainer}>
            <Button
              className={styles.continueButton}
              type="link"
              onClick={handleCancel}
            >
              <span className={styles.continue}>
                {'Skip and continue >'}
              </span>
            </Button>
          </div>
        )}
        <AuthForm
          signInSuccessCallback={signInSuccessCallback}
          signUpSuccessCallback={signUpSuccessCallback}
          startingTab={startingTab}
          forcedEmail={forcedEmail}
          hash={hash}
          quteeId={quteeId}
        />
      </Spin>
    </Modal>
  );
}

AuthModal.defaultProps = {
  setVisible: null,
  signInSuccessCallback: null,
  signUpSuccessCallback: null,
  cancelCallback: null,
  startingTab: null,
  closable: true,
  showContinueNoAccount: false,
  allowAnonymous: false,
  forcedEmail: null,
  hash: null,
  quteeId: null
};

export default AuthModal;
