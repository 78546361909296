import React, { useState } from 'react';
import {
  Button, Modal, Steps, Typography
} from 'antd';
import config from '../../common/infra/env.config';
import { useUserContext } from '../../user/providers/UserProvider';
import CreatePoll from '../../poll/components/CreatePoll';
import styles from '../styles/SurveySteps.module.scss';

const { Step } = Steps;
const { Text } = Typography;

function SurveySteps({
  quteeId, commentPrompt, pollTitles, currentStep,
  setCurrentStep, currentPoll, setCurrentPoll, createPoll,
  userCanEditQutee, allowAnonymous
}) {
  const { state: userState } = useUserContext();
  const [showPollModal, setShowPollModal] = useState(false);

  const changeStep = (current) => {
    setCurrentStep(current);
  };

  const changePoll = (current) => {
    setCurrentPoll(current);
  };

  const openTermsOfService = () => {
    const newWindow = window.open(config.site.TERMS_OF_SERVICE_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const openPrivacyPolicy = () => {
    const newWindow = window.open(config.site.PRIVACY_POLICY_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const openAddPoll = () => {
    setShowPollModal(true);
  };

  const getDescription1 = () => {
    let description1 = 'Register';
    if (userState.isLoggedIn) {
      description1 = userState.profile.display_name;
    } else if (allowAnonymous) {
      description1 = 'Agree';
    }
    return description1;
  };

  return (
    <>
      <Steps
        className={styles.steps}
        direction="vertical"
        current={currentStep}
        onChange={(current) => changeStep(current)}
      >
        <Step
          title={getDescription1()}
        />
        <Step
          title="Rate"
          disabled={!userState.isLoggedIn}
        />
        <Step
          title="Respond"
          description={commentPrompt}
          disabled={!userState.isLoggedIn}
        />
        <Step
          className={styles.pollStep}
          title={(
            <div className={styles.voteTitleContainer}>
              <Text>
                Vote
              </Text>
              {userCanEditQutee && (
                <Button
                  onClick={() => openAddPoll()}
                >
                  Add Poll
                </Button>
              )}
            </div>
          )}
          disabled={!userState.isLoggedIn}
          description={(
            <Steps
              className={styles.pollList}
              direction="vertical"
              current={(currentStep !== 3) ? -1 : currentPoll}
              onChange={(current) => changePoll(current)}
            >
              {/* eslint-disable-next-line react/no-array-index-key, max-len */}
              {pollTitles.map((title, index) => <Step key={index} title={title} disabled={!userState.isLoggedIn} />)}
            </Steps>
          )}
        />
        <Step
          title="Finished"
          disabled={!userState.isLoggedIn}
        />
      </Steps>
      <div className={styles.legal}>
        <Button
          className={styles.legalLink}
          type="link"
          onClick={() => openPrivacyPolicy()}
        >
          Privacy Policy
        </Button>
        <Button
          className={styles.legalLink}
          type="link"
          onClick={() => openTermsOfService()}
        >
          Terms of Service
        </Button>
      </div>
      {showPollModal && (
      <Modal
        title="Add Poll"
        open={showPollModal}
        footer={null}
        onCancel={() => setShowPollModal(false)}
        destroyOnClose
      >
        <CreatePoll quteeId={quteeId} onCreatePoll={createPoll} />
      </Modal>
      )}
    </>
  );
}

SurveySteps.displayName = 'Survey Steps';
SurveySteps.defaultProps = {

};

export default SurveySteps;
