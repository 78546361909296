import React, { useContext } from 'react';
import { App, Modal } from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import VerifyForm from './VerifyForm';
import { apiLogin } from '../state/api';
import styles from '../styles/VerifyModal.module.scss';

function VerifyModal({ isVisible, setVisible }) {
  const { message } = App.useApp();
  const { dispatch: userDispatch } = useUserContext();
  const embedConfig = useContext(EmbedContext);

  const { continueUrl } = embedConfig;

  const closeModal = () => setVisible(false);

  const handleCancel = async () => {
    const loadingKey = 'checkVerifiedRequest';
    const errorKey = 'checkVerifiedError';
    message.destroy(loadingKey);
    message.destroy(errorKey);

    message.loading({ content: 'Checking account status...', key: loadingKey });
    try {
      const userInfo = await apiLogin();
      message.destroy(loadingKey);
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
      if (userInfo?.profile?.username && continueUrl) {
        const newWindow = window.open(continueUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      userDispatch({ type: 'LOGIN_ERROR', payload: err });
    } finally {
      closeModal();
    }
  };

  return (
    <Modal
      className={styles.modal}
      width={600}
      open={isVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <VerifyForm closeModal={closeModal} />
    </Modal>
  );
}

VerifyModal.defaultProps = {
  isVisible: false,
  setVisible: () => {}
};

export default VerifyModal;
