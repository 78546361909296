import React, { useEffect, useState, useRef } from 'react';
import { Button, Typography } from 'antd';
import {
  CloseOutlined, DoubleLeftOutlined, FormOutlined
} from '@ant-design/icons';
import SurveyStartButton from './SurveyStartButton';
import { useIsLg } from '../../common/state/responsiveChecks';
import styles from '../styles/SurveySlideBanner.module.scss';

const { Text } = Typography;

function SurveySlideBanner({
  quteeId, liveFrequency, useSimpleGauge,
  isMainEngagement, setAllowAnonymous, hideBannerImage
}) {
  const [showBanner, setShowBanner] = useState(false);
  const [visible, setVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const bannerRef = useRef(null);
  const isLargeScreen = useIsLg();

  useEffect(() => {
    if (bannerRef && bannerRef.current) {
      setHeight(bannerRef.current.clientHeight);
    }
  }, [bannerRef]);

  const toggleBanner = () => {
    if (showBanner) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  };

  const closeBanner = () => {
    setShowBanner(false);
  };

  const hideBanner = () => {
    setShowBanner(false);
    setVisible(false);
  };

  const revealBanner = () => {
    setVisible(true);
  };

  return (
    <div
      className={styles.container}
      style={{ bottom: showBanner ? 0 : -1 * height, visibility: visible ? 'visible' : 'hidden' }}
    >
      { /*  eslint-disable-next-line max-len */ }
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
      <div className={styles.controller} onClick={() => toggleBanner()}>
        <DoubleLeftOutlined
          className={styles.upIcon}
          rotate={showBanner ? -90 : 90}
        />
        <Text className={styles.controllerText}>Answer our survey?</Text>
        <DoubleLeftOutlined
          className={styles.upIcon}
          rotate={showBanner ? -90 : 90}
        />
      </div>
      <div className={styles.bannerContainer} ref={bannerRef}>
        <div className={styles.closeContainer}>
          <Button
            type="text"
            onClick={() => closeBanner()}
          >
            Close
            <CloseOutlined />
          </Button>
        </div>
        <div className={styles.banner}>
          <div className={styles.bannerInstructions}>
            {isLargeScreen && <FormOutlined className={styles.instructionsIcon} />}
            <div className={styles.instructionsContent}>
              <div className={styles.instructionsTitle}>
                <Text>You&apos;ve been selected!</Text>
              </div>
              <div className={styles.instructionsText}>
                <Text>
                  Are we doing a good job? Tell us what you would like to see changed.
                  <br />
                  This under 5 minute survey will include open ended feedback
                  and collect some simple multiple choice polls.
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.start}>
            <SurveyStartButton
              quteeId={quteeId}
              buttonText="Start Quick Survey"
              afterStart={hideBanner}
              afterClose={revealBanner}
              liveFrequency={liveFrequency}
              useSimpleGauge={useSimpleGauge}
              isMainEngagement={isMainEngagement}
              setAllowAnonymous={setAllowAnonymous}
              hideBannerImage={hideBannerImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SurveySlideBanner.defaultProps = {
  liveFrequency: 30,
  useSimpleGauge: true
};

export default SurveySlideBanner;
