import React, {
  useState, useEffect, useRef
} from 'react';
import { App } from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import AnonPopconfirm from '../../auth/components/AnonPopconfirm';
import styles from '../styles/GaugeDial.module.scss';

const getRotateDegree = (percent) => ((305 * percent) / 100) + 90;
const getRotateStyle = (degree) => `rotate(${degree}deg)`;

function GaugeDial({
  gaugeId, averageScore, userRating, changeRatingDisplay, switchAfterRate,
  setShowDial, onSubmitRating, hideScore, afterRateHook, disabled, mutateProgress,
  continueAnonymously
}) {
  const angles = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [percent, setPercent] = useState(50);
  const [rotateStyle, setRotateStyle] = useState(getRotateStyle(getRotateDegree(50)));
  const [isLoading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const gaugeRef = useRef();
  const percentRef = useRef(percent);
  const rotateStyleRef = useRef(rotateStyle);

  useEffect(() => {
    if (userRating || (userRating >= 0)) {
      setPercent(userRating);
      changeRatingDisplay(userRating);
      setRotateStyle(getRotateStyle(getRotateDegree(userRating)));
    } else if (userRating === null) {
      setPercent(50);
      changeRatingDisplay('?');
      setRotateStyle(getRotateStyle(getRotateDegree(50)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRating]);

  // side-by-side updates of a state value and a corresponding ref are perhaps
  // not good practice, but I'm not sure of a better way to accomplish getting fresh
  // state in an event listener while also rendering components based on it.
  useEffect(() => {
    percentRef.current = percent;
  }, [percent]);

  useEffect(() => {
    rotateStyleRef.current = rotateStyle;
  }, [rotateStyle]);

  const refreshProgress = async () => {
    if (typeof mutateProgress === 'function' && userRating == null) {
      mutateProgress();
    }
  };

  const saveRating = async (rating) => {
    const loadingKey = 'saveRatingRequest';
    const successKey = 'saveRatingSuccess';
    const errorKey = 'saveRatingError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Saving your gauge rating...', key: loadingKey });
    try {
      await onSubmitRating(gaugeId, rating);
      if (typeof afterRateHook === 'function') {
        afterRateHook();
      }
      setLoading(false);
      refreshProgress();
      if (switchAfterRate) {
        setShowDial(false);
      }
      message.destroy(loadingKey);
      message.success({ content: 'Rating successfully saved', key: successKey });
    } catch (err) {
      setLoading(false);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const resetRating = async () => {
    let newPercent = 0;
    if (userRating) {
      newPercent = userRating;
    }
    setPercent(newPercent);
    changeRatingDisplay(newPercent);
    setRotateStyle(getRotateStyle(getRotateDegree(newPercent)));
  };

  const confirmRating = async () => {
    setShowConfirm(false);
    setLoading(true);
    continueAnonymously(saveRating, percent);
  };

  const cancelRating = async () => {
    setShowConfirm(false);
    resetRating();
  };

  const rotate = (x, y) => {
    if (!gaugeRef.current) {
      return null;
    }
    const {
      left, width, height, top
    // @ts-ignore
    } = gaugeRef.current.getBoundingClientRect();
    const { x: X, y: Y } = { x: left + width / 2, y: top + height / 2 };
    const deltaX = x - X; const deltaY = y - Y;
    let angle = ((Math.atan2(deltaY, deltaX) * 180) / Math.PI) - 90;
    if (angle < 0) angle = 360 + angle;
    return {
      angleForPercent: Math.round(angle),
      angleForRotate: (Math.atan2(deltaY, deltaX) * 180) / Math.PI
    };
  };

  const mouseMove = ({ clientX, clientY }) => {
    const { angleForPercent, angleForRotate } = rotate(clientX, clientY);
    if (angleForPercent < 306) {
      const newPercent = Math.round(angleForPercent * 0.327);
      setPercent(newPercent);
      changeRatingDisplay(newPercent);
      setRotateStyle(getRotateStyle(angleForRotate));
    }
  };

  const mouseUp = () => {
    document.removeEventListener('pointerup', mouseUp);
    document.removeEventListener('pointermove', mouseMove);
    const newRating = percentRef.current;
    if (newRating === 100) {
      setPercent(newRating);
      changeRatingDisplay(newRating);
      setRotateStyle(getRotateStyle(35.45));
    } else if (newRating === 0) {
      setPercent(0);
      changeRatingDisplay(0);
      setRotateStyle(getRotateStyle(91.33));
    }
    if (userState.isLoggedIn) {
      continueAnonymously(saveRating, newRating);
    } else {
      setShowConfirm(true);
    }
  };

  const mouseDown = (event, isTouch) => {
    if (disabled) {
      return;
    }
    if (!isTouch) {
      event.preventDefault();
    }
    mouseMove(event);
    document.addEventListener('pointermove', mouseMove);
    document.addEventListener('pointerup', mouseUp);
  };

  const getRotateStyling = (angle, number) => {
    const angleComputation = (angle * 3.05 - 90);
    return { transform: `rotate(${number ? -angleComputation : angleComputation}deg)` };
  };

  const getContainerClass = () => {
    if (disabled) {
      return styles.containerLoggedOut;
    }
    if (percent === 100) {
      return styles.containerFull;
    }
    return styles.container;
  };
  const getLeftGaugeRotate = () => (percent < 60 ? { transform: `rotate(${((305 * percent) / 100) + 45}deg)` } : { transform: 'rotate(222deg)' });
  const getRightGaugeRotate = () => (percent > 59 ? { transform: `rotate(${((305 * percent) / 100) - 135}deg)` } : { borderColor: 'transparent' });

  const getStartDotClass = () => {
    if (disabled) {
      return styles.disabledDot;
    }

    return percent > 0 ? styles.dot : styles.emptyDot;
  };

  const getPickerElementClass = () => {
    if (disabled) {
      return styles.disabledPickerElement;
    }
    return styles.pickerElement;
  };

  return (
    <AnonPopconfirm
      overlayClassName={styles.popconfirm}
      disabled={disabled || userState.isLoggedIn}
      onConfirm={() => confirmRating()}
      onCancel={() => cancelRating()}
      open={showConfirm}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={getContainerClass()}
        ref={gaugeRef}
        onPointerDown={isLoading ? null : (event) => mouseDown(event)}
      >
        {!hideScore && (
        <div className={styles.percentage}>
          <div className={styles.percentageText} style={{ fontSize: 37 }}>
            {(averageScore >= 0) ? `${Math.round(averageScore)}%` : '?'}
          </div>
          <div>Average</div>
        </div>
        )}
        <div className={styles.left}>
          <div className={styles.leftInner} style={getLeftGaugeRotate()} />
        </div>
        <div className={styles.right}>
          <div className={styles.rightInner} style={getRightGaugeRotate()} />
        </div>
        <div className={styles.in} />
        <div className={getStartDotClass()} />
        <div className={styles.out} />
        <div className={styles.picker} style={{ transform: rotateStyle }}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={getPickerElementClass()}
            onPointerDown={isLoading ? null : (event) => mouseDown(event)}
          />
        </div>
        <ul className={styles.sections}>
          {angles.map((angle) => (
            <li
              key={angle}
              className={styles.section}
              style={getRotateStyling(angle)}
            >
              <div className={styles.pointer}>
                <span
                  className={angle === 100 ? styles.angle100 : styles.angle}
                  style={getRotateStyling(angle, 'number')}
                >
                  {angle}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </AnonPopconfirm>
  );
}

GaugeDial.defaultProps = {
  afterRateHook: null,
  switchAfterRate: false,
  hideScore: false,
  disabled: false
};

export default GaugeDial;
