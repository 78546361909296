import React, { useState, useEffect } from 'react';
import {
  Button, App, Modal, Result, Skeleton, Spin
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import Image from '../../common/view/Image';
import { getReportStepDescription } from '../../common/utils';
import reportProblem from '../../assets/report-problem.png';
import reportWaiting from '../../assets/report-waiting.png';
import reportReady from '../../assets/report-ready.png';
import styles from '../styles/InsightsReportView.module.scss';

function InsightsReportView({
  commentBinId, getReport, getLatestReport, getReportProgress,
  generateReport, totalCommentCount, navigateToReport, reportOwnerRole, hasChatbot
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [latestReport, setLatestReport] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [mustGenerateReport, setMustGenerateReport] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const [progressStep, setProgressStep] = useState(0);
  const [finishedGenerating, setFinishedGenerating] = useState(false);
  const [syncingInProgress, setSyncingInProgress] = useState(false);
  const [finishedSyncingChatbot, setFinishedSyncingChatbot] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadingKey = 'downloadReportRequest';
  const successKey = 'downloadReportSuccess';
  const errorKey = 'downloadReportError';

  const initialPollingTimeInMs = 3000;
  const includesSummary = (reportOwnerRole === 'admin') || (reportOwnerRole === 'complete-team')
    || (reportOwnerRole === 'essential-team') || (userState?.role === 'admin');
  const includesChatbot = includesSummary && hasChatbot;
  const pollingTimeInMs = includesSummary ? 10000 : initialPollingTimeInMs;

  const checkSyncProgress = async (progressId) => {
    const progress = await getReportProgress(commentBinId, progressId);
    if (!progress.sync_finished && !progress.sync_error) {
      setTimeout(async () => {
        await checkSyncProgress(progress.report_progress_id);
      }, pollingTimeInMs);
    } else if (progress.sync_finished) {
      setSyncingInProgress(false);
      setFinishedSyncingChatbot(true);
    } else {
      setSyncingInProgress(false);
      message.destroy(loadingKey);
      if (progress.sync_error_message) {
        message.error({ content: progress.sync_error_message, key: errorKey, duration: 8 });
      } else {
        message.error({ content: 'Chatbot syncing failed. Contact admins for support.', key: errorKey, duration: 5 });
      }
    }
  };

  const checkProgress = async (progressId) => {
    const progress = await getReportProgress(commentBinId, progressId);
    if (!progress.finished && !progress.error) {
      setProgressStep(progress.progress);
      setTimeout(async () => {
        await checkProgress(progress.report_progress_id);
      }, pollingTimeInMs);
    } else if (progress.finished) {
      const report = await getReport(commentBinId, progress.report_id);
      message.destroy(loadingKey);
      message.success({
        content: 'The report has finished generating. Click here to go to the download area.',
        onClick: () => navigateToReport(),
        key: successKey
      });
      // If report download link expires, require getting a new link
      setTimeout(async () => {
        setFinishedGenerating(false);
      }, 1000 * 60 * 2);
      setLatestReport(report);
      setMustGenerateReport(false);
      setGenerationInProgress(false);
      setFinishedGenerating(true);
      setLoading(false);
      setProgressStep(0);
      if (includesChatbot) {
        setSyncingInProgress(true);
        setTimeout(async () => {
          checkSyncProgress(progressId);
        }, pollingTimeInMs);
      }
    } else {
      setGenerationInProgress(false);
      setLoading(false);
      setProgressStep(0);
      message.destroy(loadingKey);
      if (progress.error_message) {
        message.error({ content: progress.error_message, key: errorKey, duration: 8 });
      } else {
        message.error({ content: 'Report generation failed. Contact admins for support.', key: errorKey, duration: 5 });
      }
    }
  };

  useEffect(() => {
    if (!commentBinId) {
      return;
    }
    const getInitialReport = async () => {
      try {
        const report = await getLatestReport(commentBinId);
        // handle generation already in progress
        if (report.generation_in_progress) {
          setTimeout(async () => {
            await checkProgress(report.report_progress_id);
          }, initialPollingTimeInMs);
        }
        setLatestReport(report);
      } catch (err) {
        if (err.name === 'Resource_Not_Found_Error') {
          setMustGenerateReport(true);
        }
      } finally {
        setInitialLoad(false);
      }
    };
    getInitialReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentBinId]);

  const downloadOrPromptGenerate = async () => {
    try {
      setLoading(true);
      const report = await getLatestReport(commentBinId);
      setLatestReport(report);
      // handle generation in progress
      if (!report.generation_in_progress && !report.has_new_data) {
        const newWindow = window.open(report.report_url, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
        setLoading(false);
      } else if (report.has_new_data) {
        setShowGenerateModal(true);
      } else {
        setLoading(false);
        setTimeout(async () => {
          await checkProgress(report.report_progress_id);
        }, pollingTimeInMs);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const downloadLatest = async () => {
    setShowGenerateModal(false);
    const newWindow = window.open(latestReport.report_url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
    setLoading(false);
  };

  const generateNewReport = async () => {
    try {
      setShowGenerateModal(false);
      setLoading(true);
      setFinishedSyncingChatbot(false);
      setSyncingInProgress(false);
      setGenerationInProgress(true);
      const response = await generateReport(commentBinId);
      setLoading(false);
      setProgressStep(0);
      message.destroy(loadingKey);
      message.destroy(successKey);
      message.destroy(errorKey);
      message.info({ content: 'Report is being generated. You will be notified upon completion.', key: loadingKey });
      setTimeout(async () => {
        await checkProgress(response.report_progress_id);
      }, initialPollingTimeInMs);
    } catch (err) {
      setGenerationInProgress(false);
      setLoading(false);
      // TODO: Not enough comments (50 needed)
      // TODO: Report already up to date
    }
  };

  const downloadGeneratedReport = async () => {
    setLoading(true);
    await downloadLatest();
    setFinishedGenerating(false);
    setLoading(false);
  };

  if (!commentBinId) {
    return <Skeleton />;
  }

  if (initialLoad) {
    return (
      <Result
        status="info"
        title="Loading..."
        subTitle="Retrieving insights report status"
        icon={
          <Spin />
        }
      />
    );
  }
  // if (totalCommentCount < 50) {
  //   return (
  //     <Result
  //       status="warning"
  //       title="Not Enough Comments"
  //       subTitle="A minimum of 50 comments are required to generate a report"
  //       icon={
  //         <Image className={styles.problem} src={reportProblem} alt="Warning: Not Enough Comments" />
  //       }
  //     />
  //   );
  // }
  if (generationInProgress) {
    return (
      <Result
        status="info"
        title="Generating report..."
        subTitle={(
          <>
            <div className={styles.info}>
              <Spin className={styles.spin} />
              {` ${getReportStepDescription(progressStep, includesSummary)}`}
            </div>
            <br />
            <span>You will be alerted when the report is ready</span>
          </>
        )}
        icon={
          <Image className={styles.waiting} src={reportWaiting} alt="Info: Generating Report" />
        }
      />
    );
  }
  if (mustGenerateReport) {
    return (
      <Result
        status="info"
        title="Generate a free insights report"
        subTitle="The insights report offers a qualitative AI-driven analysis of your engagement."
        icon={
          <Image className={styles.ready} src={reportReady} alt="Info: Generate a report" />
        }
        extra={(
          <Button
            type="primary"
            onClick={() => generateNewReport()}
            disabled={loading}
          >
            Generate report
          </Button>
        )}
      />
    );
  }

  let extraInfo = null;
  if (includesChatbot) {
    if (finishedSyncingChatbot) {
      extraInfo = (
        <div className={styles.info}>
          <SyncOutlined className={styles.syncIcon} />
          {' Chatbot Synced'}
        </div>
      );
    } else if (syncingInProgress) {
      extraInfo = (
        <div className={styles.info}>
          <Spin />
          {' Syncing Chatbot'}
        </div>
      );
    }
  }

  return (
    <>
      <Result
        className={styles.result}
        status="info"
        title={latestReport.report_name}
        subTitle="Your report is ready to download"
        icon={
          <Image className={styles.ready} src={reportReady} alt="Info: Report ready" />
        }
        extra={(
          <>
            <Button
              type="primary"
              className={styles.downloadButton}
              onClick={() => (finishedGenerating
                ? downloadGeneratedReport()
                : downloadOrPromptGenerate())}
              disabled={loading}
            >
              {finishedGenerating ? 'Download generated report' : 'Download latest report'}
            </Button>
            {extraInfo}
          </>
        )}
      />
      <Modal
        width={520}
        open={showGenerateModal}
        cancelText="Download existing report"
        onCancel={() => downloadLatest()}
        okText="Generate new report"
        onOk={() => generateNewReport()}
        closable
      >
        There is new data available. Would you like to generate a new report to include
        the new data, or download the most recent existing report?
      </Modal>
    </>
  );
}

InsightsReportView.displayName = 'Insights Report View';
InsightsReportView.defaultProps = {
  totalCommentCount: 0,
  reportOwnerRole: undefined,
  hasChatbot: false
};

export default InsightsReportView;
