import useSWR from 'swr';

function useLabels(commentBinId, filter, profileId) {
  const {
    data, error, mutate, isLoading, isValidating
  } = useSWR(
    commentBinId
      ? [`comment-bins/${commentBinId}/labels${filter ? `?${filter}` : ''}`, profileId]
      : null
  );

  return {
    labels: data ? data.results : null,
    mutate,
    isLoading,
    isValidating,
    isError: error
  };
}

export default useLabels;
