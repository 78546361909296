import React, { useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  Button, Collapse, Form, Input, App, Typography
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ApiException } from '../../common/utils';
import styles from '../styles/CreateCommentBin.module.scss';

const { Title } = Typography;
const { Panel } = Collapse;

function CreateCommentBin({
  quteeId, mutateCommentBins, setShowCreateModal
}) {
  const { message } = App.useApp();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [commentPrompt, setCommentPrompt] = useState('');
  const [labelPrompt, setLabelPrompt] = useState('');
  const [labels, setLabels] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [form] = Form.useForm();

  const { post: postCommentBinRequest, response: postCommentBinResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const createCommentBin = async (data) => {
    const newBin = await postCommentBinRequest('', data);
    if (postCommentBinResponse.ok) {
      if (typeof mutateCommentBins === 'function') {
        mutateCommentBins();
      }
      return newBin;
    }
    throw new ApiException(newBin);
  };

  const forceValidation = async () => {
    form.validateFields()
      .then(() => {
        setValid(true);
      })
      .catch(() => {
        setValid(false);
      });
  };

  const changeTitle = (value) => {
    setTitle(value);
    forceValidation();
  };

  const changeSubtitle = (value) => {
    setSubtitle(value);
  };

  const changeCommentPrompt = (value) => {
    setCommentPrompt(value);
  };

  const changeLabelPrompt = (value) => {
    setLabelPrompt(value);
  };

  const changeLabel = (value, index) => {
    const values = [...labels];
    values[index] = value.trim();
    setLabels([...values]);
    forceValidation();
  };

  const removeLabel = (index) => {
    const arr = [...labels];
    arr.splice(index, 1);
    setLabels([...arr]);
    forceValidation();
  };

  const addLabel = () => {
    const arr = [...labels];
    arr.push('');
    setLabels([...arr]);
    forceValidation();
  };

  const resetForm = () => {
    form.resetFields();
    setTitle('');
  };

  const getDataToSubmit = () => {
    const data = {
      qutee_id: quteeId,
      title
    };
    if (subtitle) {
      data.subtitle = subtitle;
    }
    if (commentPrompt) {
      data.prompt = commentPrompt;
    }
    if (labelPrompt || labels.length) {
      const labelSet = {
        required: true
      };
      if (labelPrompt) {
        labelSet.prompt = labelPrompt;
      }
      if (labels.length) {
        labelSet.labels = [...labels];
      }
      data.label_sets = [labelSet];
    }

    return data;
  };

  const submitCommentBin = async () => {
    const loadingKey = 'createQuestionRequest';
    const successKey = 'createQuestionSuccess';
    const errorKey = 'createQuestionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Submitting new question...', key: loadingKey });
    try {
      const data = getDataToSubmit();
      await createCommentBin(data);
      resetForm();
      message.destroy(loadingKey);
      message.success({ content: 'Question successfully created', key: successKey });
      setShowCreateModal(false);
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Form
        className={styles.form}
        name="create-question-form"
        form={form}
        onFinish={submitCommentBin}
        layout="vertical"
      >
        <Title className={styles.instruction} level={4}>
          Add a new question
        </Title>
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter a title'
            }
          ]}
        >
          <Input
            onChange={(event) => changeTitle(event.target.value)}
            value={title}
            disabled={isLoading}
          />
        </Form.Item>
        <Collapse
          ghost
        >
          <Panel header="Advanced Options" key="advanced">
            <Form.Item
              label="Subtitle"
              name="subtitle"
            >
              <Input
                onChange={(event) => changeSubtitle(event.target.value)}
                value={subtitle}
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              label="Comment Prompt"
              name="comment_prompt"
            >
              <Input
                onChange={(event) => changeCommentPrompt(event.target.value)}
                value={commentPrompt}
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              label="Label Prompt"
              name="label_prompt"
            >
              <Input
                onChange={(event) => changeLabelPrompt(event.target.value)}
                value={labelPrompt}
                disabled={isLoading}
              />
            </Form.Item>
            <div className={styles.labels}>
              {labels.map((name, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.labelContainer} key={index}>
                  <div className={styles.label}>
                    <Form.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      label={`Label ${index + 1}`}
                      name={`label-${index + 1}`}
                      preserve={false}
                      rules={[
                        {
                          validator: async () => {
                            if (!name) {
                              return Promise.reject(new Error('Option cannot have an empty value'));
                            }
                            if (name && (labels.indexOf(name) !== index
                            || labels.lastIndexOf(name) !== index)
                            ) {
                              return Promise.reject(new Error('Options should have unique values'));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onChange={(event) => changeLabel(event.target.value, index)}
                        value={labels[index]}
                        disabled={isLoading}
                        maxLength={50}
                      />
                    </Form.Item>
                    <CloseOutlined
                      className={isLoading ? styles.removeDisabled : styles.remove}
                      onClick={() => (isLoading ? null : removeLabel(index))}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>
              <Button
                className={styles.add}
                type="text"
                size="large"
                onClick={() => addLabel()}
                disabled={isLoading}
              >
                + Add Label
              </Button>
            </div>
          </Panel>
        </Collapse>
        <div className={styles.submitContainer}>
          <Button
            className={(isLoading || !isValid) ? styles.submit : styles.submitValid}
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading
              || !isValid}
          >
            Create New Question
          </Button>
        </div>
      </Form>
    </div>
  );
}

CreateCommentBin.displayName = 'Create Question';
CreateCommentBin.defaultProps = {
  mutateCommentBins: null
};

export default CreateCommentBin;
