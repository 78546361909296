import config from './env.config';
import { auth } from './firebase';
import 'unfetch/polyfill';

const handleResponse = (response) => {
  if (response.status === 204) return '';
  return response.text()
    .then((text) => {
      const json = text ? JSON.parse(text) : {};
      if (response.ok) {
        return json;
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        ...json
      });
    });
};

const fetcher = async (relativeUrl, options, data) => {
  const headers = (options && options.headers) ? options.headers : {};
  const optionsWithoutHeaders = { ...options };
  if (optionsWithoutHeaders.headers) {
    delete optionsWithoutHeaders.headers;
  }

  // Always send token when logged in and using a tenant
  const user = auth.currentUser;
  if (user && !headers.Authorization) {
    const token = await user.getIdToken();
    headers.Authorization = token;
  }

  const fetchOpts = {
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    credentials: headers.Authorization ? 'same-origin' : 'include',
    ...optionsWithoutHeaders
  };

  if (data) {
    fetchOpts.body = data;
  }

  // Stringify body if it's an object
  if (fetchOpts.body && typeof fetchOpts.body === 'object') {
    fetchOpts.body = JSON.stringify(fetchOpts.body);
  }

  return fetch(`${config.api.BASE_URL}/${config.api.TENANT_ID}/${relativeUrl}`, fetchOpts)
    .then(handleResponse);
  // .then((data) => {
  //   console.log(data);
  //   return data;
  // });
};

// Passes in an array as the key; the relative url should always be the first item in the array
const swrFetcher = async (key, opts) => {
  let url = key;
  if (Array.isArray(key)) {
    [url] = key;
  }
  const response = await fetcher(url, opts, null);
  return response;
};

export default fetcher;
export {
  swrFetcher
};
