import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import {
  App, Button, Checkbox, Form, Input, Modal, Typography
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
// import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import config from '../../common/infra/env.config';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import LogInBanner from '../../auth/components/LogInBanner';
import AnonPopconfirm from '../../auth/components/AnonPopconfirm';
import LabelSelector from '../../qutee/components/LabelSelector';
import styles from '../styles/CommentEntry.module.scss';

const { Text, Title } = Typography;
const { TextArea } = Input;

function CommentEntry({
  commentBinId, commentPrompt, labelPrompt, labels, parentId, createComment,
  createReply, afterSubmit, afterCancel, allowEditing, updateLabelSet,
  createLabel, updateLabel, deleteLabel, userCanInteract, cantInteractReason,
  allowAnonymous, stayOpen, continueAnonymously, refreshProgress,
  setJoyrideIndex, commentDialogStateHandler, commentDialogState,
  triggerRepositionJoyride
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const [entryText, setEntryText] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTOSConfirmation, setShowTOSConfirmation] = useState(false);
  const [confirmFollowsTOS, setConfirmFollowsTOS] = useState(false);
  const [caretPosition, setCaretPosition] = useState(0);
  const [form] = Form.useForm();
  const placeholderRef = useRef();
  const inputRef = useRef();

  const { afterCommentCallback } = embedConfig;

  useEffect(() => {
    if (showResponseModal) {
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          // @ts-ignore
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [showResponseModal]);

  useEffect(() => {
    if (typeof setJoyrideIndex !== 'function') {
      return;
    }
    if (commentDialogState === 'open') {
      setShowResponseModal(true);
      setTimeout(() => {
        setJoyrideIndex(2);
      }, 500);
    } else if (commentDialogState === 'close') {
      setShowResponseModal(false);
      setTimeout(() => {
        setJoyrideIndex(6);
      }, 500);
    } else if (commentDialogState === 'open-again') {
      setShowResponseModal(true);
      setTimeout(() => {
        setJoyrideIndex(16);
      }, 500);
    } else if (commentDialogState === 'close-again') {
      setShowResponseModal(false);
      setTimeout(() => {
        setJoyrideIndex(19);
      }, 200);
    }
  }, [commentDialogState, setJoyrideIndex]);

  const resetForm = (doCancel) => {
    form.resetFields();
    setSelectedLabelId(null);
    setEntryText('');
    setAuthorName('');
    if (doCancel && typeof afterCancel === 'function') {
      afterCancel();
    }
  };

  const submitComment = async () => {
    const loadingKey = 'postCommentRequest';
    const successKey = 'postCommentSuccess';
    const errorKey = 'postCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    let doExit = false;
    if (!selectedLabelId) {
      message.error({ content: 'Please select a label', key: errorKey, duration: 3 });
      doExit = true;
    }

    if (showTOSConfirmation && !confirmFollowsTOS) {
      message.error('Please confirm that your comment follows our Terms of Service', 5);
      doExit = true;
    }

    if (doExit) {
      return;
    }

    let name;
    if (!authorName) {
      name = 'anonymous';
    } else {
      name = authorName;
    }
    setLoading(true);
    message.loading({ content: 'Posting comment...', key: loadingKey });
    try {
      if (parentId) {
        // eslint-disable-next-line max-len
        await createReply(commentBinId, entryText, selectedLabelId, parentId, name, confirmFollowsTOS);
      } else {
        await createComment(commentBinId, entryText, selectedLabelId, name, confirmFollowsTOS);
      }
      refreshProgress();
      if (typeof afterSubmit === 'function') {
        afterSubmit();
      }
      setShowTOSConfirmation(false);
      setConfirmFollowsTOS(false);
      resetForm();
      message.destroy(loadingKey);
      message.success({ content: 'Comment successfully posted', key: successKey });
      setLoading(false);
      setShowResponseModal(false);
      if (afterCommentCallback
        && (typeof afterCommentCallback === 'function')) {
        afterCommentCallback();
      }
    } catch (err) {
      message.destroy(loadingKey);
      // TODO: handle errors for moderation
      if (err.name === 'Moderation_Error') {
        switch (err.details.issue_id) {
          // The first two cases are from the more naive premoderation check.
          // For these, give a warning that there MIGHT be inappropriate language,
          // and have them confirm that they're following TOS.
          case 'SLURS':
          case 'EXTREME_SEXUAL': {
            message.error({
              content: `We may have detected inappropriate language in your comment ("${err.details.value}" at position ${err.details.location}). Please confirm your comment adheres to our standards.`,
              key: errorKey,
              duration: 10
            });
            setShowTOSConfirmation(true);
            // Select detected word in the text and scroll it into view
            // @ts-ignore
            const area = inputRef?.current?.resizableTextArea?.textArea;
            if (area && (err.details.location != null)) {
              const start = err.details.location;
              const end = start + err.details.length || start;
              area.focus();
              const fullText = area.value;
              area.value = fullText.substring(0, end);
              const { scrollHeight } = area;
              area.value = fullText;
              let scrollTop = scrollHeight;
              const areaHeight = area.clientHeight;
              if (scrollTop > areaHeight) {
                scrollTop -= areaHeight / 2;
              } else {
                scrollTop = 0;
              }
              area.scrollTop = scrollTop;

              area.setSelectionRange(start, end);
              setCaretPosition(err.details.location);
            }
            break;
          }
          case 'QUALITY_SPAM':
            message.error({
              content: 'Your comment was flagged as spam. Please try again later.', key: errorKey, duration: 8
            });
            break;
          case 'TOXICITY_PROFANITY':
            message.error({
              content: 'Remove profanity from your comment and try again.', key: errorKey, duration: 8
            });
            break;
          case 'TOXICITY_SEVERE_TOXICITY':
            message.error({
              content: 'Your comment is too toxic. Try again while being nicer.', key: errorKey, duration: 8
            });
            break;
          case 'TOXICIITY_THREAT':
            message.error({
              content: 'Your comment contains a threat. Remove all threats before resubmitting.', key: errorKey, duration: 8
            });
            break;
          case 'TOXICITY_DISCRIMINATION':
            message.error({
              content: 'Your comment contains discrimination. Remove all discriminatory language before resubmitting.', key: errorKey, duration: 8
            });
            break;
          case 'PROPRIETY_SEXUALLY_EXPLICIT':
            message.error({
              content: 'Your comment contains sexually explicit language. Remove all sexually explicit language before resubmitting.', key: errorKey, duration: 8
            });
            break;
          case 'NSFW_UNSAFE':
            message.error({
              content: 'Your comment has been rejected as unsafe for work. Remove all NSFW language before resubmitting.', key: errorKey, duration: 8
            });
            break;
          default:
            message.error({
              content: 'Your comment was rejected for moderation reasons. Amend it and try again.', key: errorKey, duration: 8
            });
            break;
        }
        setLoading(false);
      } else if (err.type === 'Rate_Limit_Error' && err.issue_id === 'Comment_Throttle') {
        message.error({ content: 'You are commenting too fast. Please try again in a moment.', key: errorKey, duration: 8 });
        setLoading(false);
      } else {
        message.error({ content: `${JSON.stringify(err)}-${err.message}`, key: errorKey, duration: 8 });
        setLoading(false);
      }
    }
  };

  const changeLabelPrompt = async (newPrompt) => {
    const loadingKey = 'updateLabelPromptRequest';
    const successKey = 'updateLabelPromptSuccess';
    const errorKey = 'updateLabelPromptError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating label prompt...', key: loadingKey });
    try {
      await updateLabelSet(commentBinId, newPrompt);
      message.destroy(loadingKey);
      message.success({ content: 'Label prompt successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const handleLabelSelection = (checked, labelId) => {
    if (checked) {
      setSelectedLabelId(labelId);
    } else {
      setSelectedLabelId(null);
    }
  };

  const updateCommentEntry = (event) => {
    setEntryText(event.target.value);
    setCaretPosition(event.target.selectionStart);
  };

  const updateAuthorName = (event) => {
    setAuthorName(event.target.value);
  };

  const disabled = !entryText.trim() || !selectedLabelId
    || (showTOSConfirmation && !confirmFollowsTOS);

  const openModal = () => {
    setShowResponseModal(true);
    if (placeholderRef && placeholderRef.current) {
      // @ts-ignore
      placeholderRef.current.blur();
    }
  };

  const modalContent = (
    <div>
      {commentPrompt}
      <Form
        form={form}
        onFinish={() => continueAnonymously(submitComment)}
      >
        <Form.Item
          name="text"
        >
          <div className={styles.inputContainer}>
            <TextArea
              ref={inputRef}
              defaultValue=""
              className={styles.commentEntry}
              value={entryText}
              onChange={updateCommentEntry}
              placeholder="Write your comment here..."
              autoSize={{ minRows: 3, maxRows: 8 }}
              autoFocus
              // Show caret position for help locating premoderation hits
              // @ts-ignore
              onKeyDown={(e) => setCaretPosition(e.target.selectionStart)}
              // @ts-ignore
              onKeyUp={(e) => setCaretPosition(e.target.selectionStart)}
              // @ts-ignore
              onClick={((e) => setCaretPosition(e.target.selectionStart))}
              showCount={{
                // @ts-ignore
                formatter: ({ count }) => `${caretPosition}:${count}`
              }}
            />
          </div>
        </Form.Item>
        {(stayOpen || entryText !== '')
          && (
          <div className={styles.labelContainer}>
            {showTOSConfirmation ? (
              <div>
                <WarningOutlined className={styles.warningIcon} />
                <Checkbox
                  checked={confirmFollowsTOS}
                  onChange={(e) => setConfirmFollowsTOS(e.target.checked)}
                >
                  {'I confirm that my post adheres to the '}
                  <a href={config.site.TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                </Checkbox>
              </div>
            ) : null}
            {allowAnonymous && (!userState.profile || userState.isAnonymous) ? (
              <>
                <Text
                  className={styles.labelSubtitle}
                >
                  <span className={styles.signature}>Signature:</span>
                  <sup className={styles.labelRequired}>Optional</sup>
                </Text>
                <Form.Item
                  name="name"
                >
                  <div className={styles.inputContainer}>
                    <Input
                      defaultValue=""
                      value={authorName}
                      onChange={updateAuthorName}
                      placeholder="Anonymous"
                    />
                  </div>
                </Form.Item>
              </>
            ) : null}
            <Title
              className={styles.labelInstruction}
              level={4}
            >
              Label your comment:
              <sup className={styles.labelRequired}>Required</sup>
            </Title>
            <Text
              className={styles.labelSubtitle}
              editable={allowEditing && !loading ? { onChange: changeLabelPrompt } : false}
            >
              {labelPrompt}
            </Text>
            <Form.Item
              className={styles.labelSelector}
              name="password"
              rules={[
                {
                  validator: () => {
                    if (!selectedLabelId) {
                      return Promise.reject(new Error('Please select a label above for your comment'));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <LabelSelector
                commentBinId={commentBinId}
                labels={labels}
                selectedLabelId={selectedLabelId}
                onLabelSelect={handleLabelSelection}
                allowEditing={allowEditing}
                createLabel={createLabel}
                updateLabel={updateLabel}
                deleteLabel={deleteLabel}
                disabled={!userCanInteract}
                triggerRepositionJoyride={triggerRepositionJoyride}
              />
            </Form.Item>
            <div>
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item style={{ display: 'inline-block', marginBottom: 0 }}>
                  <AnonPopconfirm
                    disabled={disabled || userState.isLoggedIn}
                    onConfirm={() => form.submit()}
                  >
                    <Button
                     // Do NOT add htmlType="submit" here; it will cause double submission
                      className={disabled ? styles.submit : styles.submitValid}
                      type="primary"
                      loading={loading}
                      disabled={disabled}
                      size="large"
                      onClick={userState.isLoggedIn ? () => form.submit() : null}
                    >
                      Post Comment
                    </Button>
                  </AnonPopconfirm>
                </Form.Item>
              </Form.Item>
            </div>
          </div>
          )}
      </Form>
    </div>
  );

  return (
    <>
      <div>
        {!userCanInteract
          ? (
            <div className={parentId ? styles.replyInputContainer : styles.inputContainer}>
              {parentId != null && !userState.isLoggedIn && (
                <div className={styles.replyLogin}>
                  <LogInBanner />
                </div>
              )}
              <TextArea
                className={styles.inputLoggedOut}
                placeholder={cantInteractReason}
                autoSize={{ minRows: 3, maxRows: 3 }}
                disabled
              />
            </div>
          ) : (
            <div className={parentId ? styles.replyInputContainer : styles.inputContainer}>
              <TextArea
                ref={placeholderRef}
                className={styles.commentEntry}
                placeholder="Write your comment here..."
                autoSize={{ minRows: 3, maxRows: 6 }}
                value=""
                defaultValue=""
                onClick={openModal}
                onInput={openModal}
              />
            </div>
          )}
      </div>
      <Modal
        className={styles.modal}
        classNames={{ header: styles.modalHeader }}
        title="Post a response"
        open={showResponseModal}
        footer={null}
        onCancel={() => setShowResponseModal(false)}
        // @ts-ignore
        autoFocus={false}
        width={600}
        top={50}
      >
        {modalContent}
      </Modal>
    </>
  );
}

CommentEntry.displayName = 'Comment Entry';
CommentEntry.defaultProps = {
  parentId: null,
  commentPrompt: null,
  createComment: null,
  createReply: null,
  afterSubmit: null,
  afterCancel: null,
  allowEditing: false,
  updateLabelSet: () => {},
  createLabel: () => {},
  updateLabel: () => {},
  deleteLabel: () => {},
  userCanInteract: false,
  userCanEditQutee: false,
  labelEditingDisabled: false,
  allowAnonymous: false,
  stayOpen: true,
  startOpen: false,
  triggerRepositionJoyride: undefined,
  setJoyrideIndex: undefined,
  commentDialogState: undefined,
  commentDialogStateHandler: undefined
};

export default CommentEntry;
