import React, { useContext, useState } from 'react';
import {
  Button, Input, Form, App
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import PasswordStrengthBar from 'react-password-strength-bar';
import EmbedContext from '../../embed/providers/EmbedProvider';
import { confirmPasswordReset } from '../state/api';
import AuthLoader from './AuthLoader';
import styles from '../styles/ConfirmPassword.module.scss';

function ConfirmPassword({ actionCode, afterActionHook }) {
  const { message } = App.useApp();
  const embedConfig = useContext(EmbedContext);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);
  const [succeeded, setSucceeded] = useState(false);
  const [form] = Form.useForm();

  const { continueUrl } = embedConfig;

  const resetForm = () => {
    form.resetFields();
    setPassword('');
    setPasswordScore(0);
  };

  const handleSubmit = async (values) => {
    const { password } = values;

    const loadingKey = 'newPasswordRequest';
    const successKey = 'newPasswordSuccess';
    const errorKey = 'newPasswordError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Resetting password...', key: loadingKey });
    try {
      await confirmPasswordReset({ code: actionCode, newPassword: password });
      message.destroy(loadingKey);
      message.success({ content: 'Password successfully reset', key: successKey });
      resetForm();
      setSucceeded(true);
      if (typeof afterActionHook === 'function') {
        afterActionHook();
      }
      if (continueUrl) {
        const curUrl = new URL(window.location.toString());
        const contUrl = new URL(continueUrl);
        const contPath = `${contUrl.pathname}${contUrl.search}${contUrl.hash}`;
        const checkBase = `${curUrl.origin}${curUrl.pathname}`;
        const contBase = `${contUrl.origin}${contUrl.pathname}`;
        if (contBase === checkBase) {
          window.history.replaceState({}, document.title, `${contPath}`);
        } else {
          const newWindow = window.open(continueUrl, '_blank', 'noopener,noreferrer');
          if (newWindow) {
            newWindow.opener = null;
          }
        }
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const changePasswordScore = (score) => {
    setPasswordScore(score);
  };

  if (succeeded) {
    return (
      <div className={styles.forgot}>
        <h1>Confirm Password</h1>
        <MailOutlined className={styles.icon} />
        <p className={styles.description}>Your password has been successfully reset.</p>
      </div>
    );
  }
  return (
    <div className={styles.forgot}>
      <h1>Confirm Password</h1>
      <MailOutlined className={styles.icon} />
      <p className={styles.description}>Please set and confirm your new password.</p>
      <Form name="confirm" form={form} onFinish={handleSubmit}>
        <Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                validator: () => {
                  if (!password) {
                    return Promise.reject(new Error('Please enter your password'));
                  }
                  if (passwordScore >= 2) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Your password is too weak'));
                }
              }
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="Password"
              onChange={changePassword}
            />
          </Form.Item>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            onChangeScore={changePasswordScore}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error('Please confirm your new password'));
                }
                if (value === password) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The passwords do not match'));
              }
            }
          ]}
        >
          <Input.Password
            autoComplete="off"
            placeholder="Confirm New Password"
          />
        </Form.Item>
        {loading && <AuthLoader />}
        <div className={styles.buttons}>
          <Form.Item>
            <Button
              type="primary"
              className={styles.reset}
              htmlType="submit"
              disabled={loading}
            >
              Reset Password
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

ConfirmPassword.defaultProps = {
  afterActionHook: () => {}
};

export default ConfirmPassword;
