import React, { useEffect, useState } from 'react';
import {
  App, Modal, Popconfirm, Typography
} from 'antd';
import {
  DeleteOutlined, InfoCircleOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import styles from '../styles/GaugeInfo.module.scss';

const { Title } = Typography;

function GaugeInfo({
  gaugeId, title, hideTitle, userCanEdit,
  onUpdateTitle, hideHelpIcon, onDeleteGauge
}) {
  const DEFAULT_TITLE = 'How likely are you to recommend this to a friend or colleague?';
  const { message } = App.useApp();
  const [text, setText] = useState((title && title.length ? title : DEFAULT_TITLE));
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (title && title.length) {
      setText(title);
    } else {
      setText(DEFAULT_TITLE);
    }
  }, [title]);

  const changeTitle = async (value) => {
    const loadingKey = 'updateTitleRequest';
    const successKey = 'updateTitleSuccess';
    const errorKey = 'updateTitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating gauge title...', key: loadingKey });
    try {
      await onUpdateTitle(gaugeId, value);
      setText(value);
      message.destroy(loadingKey);
      message.success({ content: 'Title successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const deleteGauge = async () => {
    const loadingKey = 'deleteGaugeRequest';
    const successKey = 'deleteGaugeSuccess';
    const errorKey = 'deleteGaugeError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Deleting gauge...', key: loadingKey });
    try {
      await onDeleteGauge(gaugeId);
      message.destroy(loadingKey);
      message.success({ content: 'Gauge successfully deleted', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const showNotification = () => setVisible(true);
  const makeCancel = () => setVisible(false);

  return (
    <>
      {!hideTitle && (
      <div className={styles.container}>
        {!hideHelpIcon
          && <InfoCircleOutlined className={styles.infoIcon} onClick={showNotification} />}
        <Title
          className={styles.title}
          level={4}
          editable={!isLoading && userCanEdit ? { onChange: changeTitle } : false}
        >
          {text}
        </Title>
        {userCanEdit && (
          <Popconfirm
            title="Delete this gauge?"
            onConfirm={deleteGauge}
            okText="Delete"
            okButtonProps={{ className: styles.confirmDelete }}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <DeleteOutlined className={styles.delete} />
          </Popconfirm>
        )}
      </div>
      )}
      <Modal
        className={styles.modal}
        title="The Gauge"
        open={visible}
        onCancel={makeCancel}
        footer={null}
      >
        <p>{'Voice your opinion on the Gauge! Drag the ring left or right to rate the engagement\'s topic.'}</p>
        <p>
          Vote to the
          {' '}
          <span className={styles.high}>Right (100%)</span>
          {' '}
          if you
          {' '}
          <span className={styles.high}>Would Recommend </span>
          a topic, or to the
          {' '}
          <span className={styles.low}>Left (0%)</span>
          {' '}
          if you
          {' '}
          <span className={styles.low}>Would Not Recommend </span>
          {' '}
          it.

        </p>
        <p>You can also vote by directly clicking on the gauge or a tick marker value.</p>
      </Modal>
    </>
  );
}

GaugeInfo.defaultProps = {
  hideTitle: false,
  hideHelpIcon: false
};

export default GaugeInfo;
