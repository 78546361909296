import React, { useState } from 'react';
import {
  App, Typography
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from '../styles/CommentBinSubtitle.module.scss';

const { Text } = Typography;

function CommentBinSubtitle({
  commentBinId, subtitle, allowEditing, updateCommentBin
}) {
  const { message } = App.useApp();
  const [isLoading, setLoading] = useState(false);

  const placeholder = 'Add Subtitle';

  const changeCommentSubtitle = async (subtitle) => {
    const loadingKey = 'updateCommentBinSubtitleRequest';
    const successKey = 'updateCommentBinSubtitleSuccess';
    const errorKey = 'updateCommentBinSubtitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating question subtitle...', key: loadingKey });
    try {
      await updateCommentBin(commentBinId, subtitle);
      message.destroy(loadingKey);
      message.success({ content: 'Question subtitle successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Text
        className={(allowEditing && !subtitle) ? styles.addSubtitle : styles.subtitle}
        editable={allowEditing && !isLoading ? {
          text: subtitle || '',
          onChange: changeCommentSubtitle,
          icon: subtitle ? null : <PlusOutlined className={styles.plus} />,
          tooltip: subtitle ? 'Edit Subtitle' : 'Add Subtitle'
        } : false}
      >
        {subtitle || (allowEditing && placeholder)}
      </Text>
    </div>
  );
}

CommentBinSubtitle.displayName = 'Comment Bin Subtitle';
CommentBinSubtitle.defaultProps = {
  allowEditing: false,
  isEditMode: false
};

export default CommentBinSubtitle;
