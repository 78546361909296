import useSWR from 'swr';

function usePoll(pollId, initial) {
  const { data, error, mutate } = useSWR(pollId ? `polls/${pollId}` : null, {
    fallbackData: initial,
    revalidateOnMount: false
  });

  return {
    poll: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default usePoll;
