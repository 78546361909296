// @ts-nocheck
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { Tabs } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import useQutee from '../state/useQutee';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException } from '../../common/utils';
import QuteeImageUpload from './QuteeImageUpload';
import styles from '../styles/QuteeImageSettings.module.scss';

function QuteeImageSettings({ quteeId }) {
  const { state: userState } = useUserContext();
  // const [loading, setLoading] = useState(false);

  const {
    qutee,
    mutate: quteeMutate
  } = useQutee(quteeId, userState.isLoggedIn);

  const hasImage = (imageType) => {
    const fieldName = `${imageType}_url`;
    if (qutee && qutee.settings) {
      return !!qutee.settings[fieldName];
    }
    return false;
  };

  const tabList = [
    {
      label: 'Banner Image',
      key: 'banner_image'
    },
    {
      label: 'Mobile Banner Image',
      key: 'mobile_banner_image'
    },
    {
      label: 'Square Logo',
      key: 'square_logo'
    },
    {
      label: 'Wide Logo',
      key: 'wide_logo'
    },
    {
      label: 'Background Image',
      key: 'background_image'
    },
    {
      label: 'Favicon',
      key: 'favicon'
    },
    {
      label: 'Twitter Social Image',
      key: 'twitter_social_image'
    },
    {
      label: 'Facebook Social Image',
      key: 'facebook_social_image'
    }
  ];

  return (
    <Tabs
      className={styles.tabs}
      tabPosition="left"
      items={tabList.map((item) => ({
        label: item.label,
        key: item.key,
        children: (
          <QuteeImageUpload
            quteeId={quteeId}
            imageLabel={item.label}
            imageTitle={<div className={styles.title}>{item.label}</div>}
            imageType={item.key}
          />
        )
      }))}
    />
  );
  // return (
  //   <div>
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Banner Image</div>}
  //       imageType="banner_image"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Mobile Banner Image</div>}
  //       imageType="mobile_banner_image"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Square Logo</div>}
  //       imageType="square_logo"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Wide Logo</div>}
  //       imageType="wide_logo"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Background Image</div>}
  //       imageType="background_image"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Favicon</div>}
  //       imageType="favicon"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Twitter Social Image</div>}
  //       imageType="twitter_social_image"
  //     />
  //     <QuteeImageUploader
  //       quteeId={quteeId}
  //       imageTitle={<div className={styles.title}>Facebook Social Image</div>}
  //       imageType="facebook_social_image"
  //     />
  //   </div>
  // );
}

export default QuteeImageSettings;
