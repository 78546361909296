import React, { useContext } from 'react';
import { Alert, Button } from 'antd';
import AuthHandler from '../../auth/components/AuthHandler';
import { useIsLg, useIsMd } from '../state/responsiveChecks';
import config from '../infra/env.config';
import EmbedContext from '../../embed/providers/EmbedProvider';
import Image from './Image';
import bannerLogo from '../../assets/sa_banner_logo.png';
import styles from './styles/AnonymousBanner.module.scss';

function AnonymousBanner({
  showLogo, signInSuccessCallback, signUpSuccessCallback
}) {
  const isLargeScreen = useIsLg();
  const isMediumScreen = useIsMd();
  const embedConfig = useContext(EmbedContext);

  const { anonBannerText, hideAnonBannerTOS } = embedConfig;

  const openTermsOfService = () => {
    const newWindow = window.open(config.site.TERMS_OF_SERVICE_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const getTosButton = () => {
    const tosButton = (
      <Button
        type="link"
        size="small"
        onClick={() => openTermsOfService()}
      >
        <span className={styles.link}>Terms of Service</span>
      </Button>
    );

    if (isMediumScreen) {
      return tosButton;
    }

    return (
      <div>
        {tosButton}
      </div>
    );
  };

  const getAuthButton = () => {
    const authButton = (
      <AuthHandler
        signInSuccessCallback={signInSuccessCallback}
        signUpSuccessCallback={signUpSuccessCallback}
      >
        <Button
          className={styles.authButton}
          type="primary"
          key="auth"
        >
          Sign Up/Log In
        </Button>
      </AuthHandler>
    );

    if (isMediumScreen) {
      return (
        <span className={styles.authText}>
          {authButton}
        </span>
      );
    }

    return (
      <div className={styles.authText}>
        {authButton}
      </div>
    );
  };

  return (
    <div>
      <Alert
        className={`sa-auth-bar ${styles.banner}`}
        type="info"
        showIcon={isLargeScreen && showLogo}
        icon={isLargeScreen && showLogo
          ? <Image className={styles.logo} src={bannerLogo} alt="Social Asking Logo" />
          : null}
        message={(
          <div>
            {!isLargeScreen && showLogo && (
              <div className={styles.logoContainer}>
                <Image className={styles.logo} src={bannerLogo} alt="Social Asking Logo" />
              </div>
            )}
            <span className={styles.anonText}>
              {(anonBannerText != null)
                ? anonBannerText
                : 'By responding anonymously, you agree to our'}
              {!hideAnonBannerTOS ? getTosButton() : null}
            </span>
            {getAuthButton()}
          </div>
        )}
      />
    </div>
  );
}

AnonymousBanner.defaultProps = {
  showLogo: true,
  signInSuccessCallback: () => null,
  signUpSuccessCallback: () => null
};

export default AnonymousBanner;
