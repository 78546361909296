import useSWR from 'swr';

function useCommentBin(commentBinId) {
  const { data, error, mutate } = useSWR(commentBinId
    ? `comment-bins/${commentBinId}?expand=label_set.labels` : null);

  return {
    commentBin: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useCommentBin;
