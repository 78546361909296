import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import GaugeInfo from './GaugeInfo';
import GaugeDial from './GaugeDial';
import GaugeControls from './GaugeControls';
import GaugeGraph from './GaugeGraph';
import GaugeRatingDisplay from './GaugeRatingDisplay';
import styles from '../styles/Gauge.module.scss';

function Gauge({
  gaugeId, title, highLabel, lowLabel, segments, averageScore, ratingCount,
  userRating, hideTitle, hideGraph, hideUserRating, hideScore, resultsOnly,
  rateBeforeResults, userCanEdit, userCanInteract, disabled, disabledTooltipText,
  onUpdateTitle, onUpdateLabel, onSubmitRating, afterRateHook, mutateProgress,
  onDeleteGauge, hideResultsPublic, continueAnonymously, externalRef
}) {
  const [showDial, setShowDial] = useState(true);
  const [displayPercent, setDisplayPercent] = useState('?');

  // TODO: Some of the logic here repeats what is done in GaugeContainer,
  // especially related to rateBeforeResults. It should be cleaned up
  // to remove any of those redundancies.
  const showUserRating = () => !hideUserRating && !resultsOnly && showDial;
  const showScore = () => !hideScore && (averageScore != null);
  const showInputDial = () => showDial || !segments;
  const showGraph = () => !hideGraph && segments && !showInputDial();
  const showTotalRatings = () => !hideGraph && (ratingCount != null);
  const showSwitchButton = () => {
    if (hideGraph || resultsOnly) {
      return false;
    }
    if (rateBeforeResults) {
      return userRating || userRating === 0;
    }
    return true;
  };
  const switchAfterRate = () => !hideGraph && !resultsOnly && !hideResultsPublic;

  const changeRatingDisplay = (percent) => {
    setDisplayPercent(`${percent}`);
  };

  const getDial = () => {
    if (showGraph() || !showInputDial()) {
      return null;
    }

    let tooltipTitle = null;
    const defaultDisabledText = 'Gauge interactions are disabled.';
    if (disabled) {
      tooltipTitle = disabledTooltipText || defaultDisabledText;
    }
    return (
      <Tooltip
        mouseEnterDelay={0.3}
        title={tooltipTitle}
      >
        <div>
          <GaugeDial
            gaugeId={gaugeId}
            averageScore={averageScore}
            userRating={userRating}
            changeRatingDisplay={changeRatingDisplay}
            onSubmitRating={onSubmitRating}
            hideScore={!showScore()}
            switchAfterRate={switchAfterRate()}
            setShowDial={setShowDial}
            afterRateHook={afterRateHook}
            disabled={!userCanInteract || disabled || resultsOnly}
            mutateProgress={mutateProgress}
            continueAnonymously={continueAnonymously}
          />
        </div>
      </Tooltip>
    );
  };

  const getToggleButtonText = () => {
    if (showDial && hideResultsPublic) {
      return 'See Results  *Not Public';
    }
    if (showDial) {
      return 'See Results';
    }
    return 'Back To Your Rating';
  };

  if (!gaugeId) {
    return null;
  }

  // TODO: Change this into separate returns for dial vs graph, with
  // separate consts for the parts that should be reused between both.
  // This should simplify the logic and make it more readable.
  return (
    <div ref={externalRef} className={styles.gauge}>
      <GaugeInfo
        gaugeId={gaugeId}
        title={title}
        hideTitle={hideTitle}
        userCanEdit={userCanEdit}
        onUpdateTitle={onUpdateTitle}
        hideHelpIcon
        onDeleteGauge={onDeleteGauge}
      />
      {showTotalRatings() && (
        <div>
          <span className={styles.totalLabel}>
            Total Ratings:
            <span className={styles.numeral}>
              {' '}
              {ratingCount}
            </span>
          </span>
        </div>
      )}
      {!showInputDial() && showScore() && (
        <div>
          <span className={styles.bigAverage}>
            Average Rating:
            <span className={styles.numeral}>
              {' '}
              {(averageScore >= 0) ? `${Math.round(averageScore)}%` : '?'}
            </span>
          </span>
        </div>
      )}
      {showUserRating() && <GaugeRatingDisplay rating={displayPercent} />}
      {getDial()}
      {showGraph() && <GaugeGraph segments={segments} />}
      <GaugeControls
        gaugeId={gaugeId}
        highLabel={highLabel}
        lowLabel={lowLabel}
        userCanEdit={userCanEdit}
        onUpdateLabel={onUpdateLabel}
      />
      {showSwitchButton() && (
        <div className={styles.switchContainer}>
          <Button
            className={styles.switch}
            type="text"
            onClick={() => setShowDial(!showDial)}
          >
            {!showDial && <LeftOutlined />}
            {getToggleButtonText()}
            {showDial && <RightOutlined />}
          </Button>
        </div>
      )}
    </div>
  );
}

Gauge.defaultProps = {
  afterRateHook: null
};

Gauge.defaultProps = {
  afterRateHook: null,
  hideTitle: false,
  hideGraph: false,
  hideUserRating: false,
  hideScore: false,
  resultsOnly: false,
  rateBeforeResults: false,
  userCanInteract: false,
  userCanEdit: false,
  onUpdateTitle: () => {},
  onUpdateLabel: () => {},
  onSubmitRating: () => {},
  externalRef: undefined
};

export default Gauge;
