import React, { useState } from 'react';
import {
  Checkbox, App, Popconfirm, Typography, Tag, Tooltip
} from 'antd';
import {
  CloseOutlined, EditTwoTone, QuestionCircleOutlined
} from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import useAnonymousHandler from '../../auth/state/useAnonymousHandler';
import AnonPopconfirm from '../../auth/components/AnonPopconfirm';
import PollPercent from './PollPercent';
import DigitStatistic from './DigitStatistic';
import styles from '../styles/PollOption.module.scss';

const { Text } = Typography;
const { CheckableTag } = Tag;

function PollOption({
  pollId, index, option, totalBallots, onlyResults, disableVoteMessage,
  isSelected, selectOption, deselectOption, isEditMode, lockEdits, showResults,
  isDisabled, disabledOptionTooltipText, isLoading, setLoading,
  removeOption, onUpdateOption, onSelectOption, onDeselectOption,
  afterVoteHook, isLoadingBallot, isMultipleChoice
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [name, changeName] = useState(option.text);
  const [isEditingOption, setEditingOption] = useState(false);
  const continueAnonymously = useAnonymousHandler();

  const checkEditing = () => !isDisabled && isEditMode;
  const checkEditingAllowed = () => !isDisabled && isEditMode && !lockEdits && !isLoading;
  const checkEditingLocked = () => !isDisabled && isEditMode && lockEdits;
  const checkSelected = () => !onlyResults && !isDisabled && !isEditMode && isSelected;

  const removePollOption = () => {
    removeOption(pollId, option.id);
  };

  const changeOptionName = async (value) => {
    const loadingKey = 'updateOptionRequest';
    const successKey = 'updateOptionSuccess';
    const errorKey = 'updateOptionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating poll option...', key: loadingKey });
    try {
      await onUpdateOption(pollId, option.id, value);
      changeName(value);
      message.destroy(loadingKey);
      message.success({ content: 'Option successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const calcBallotPercent = () => {
    const count = isMultipleChoice ? option?.user_count : option?.count;
    if (!count || !totalBallots) {
      return 0;
    }
    return Math.round(((count * 1.0) / totalBallots) * 100);
  };

  const showTooltip = (inner) => {
    let tooltipTitle = 'This cannot be changed.';
    const defaultDisabledText = 'Poll interactions are disabled.';
    if (isDisabled) {
      tooltipTitle = disabledOptionTooltipText || defaultDisabledText;
    } else if (isLoadingBallot) {
      tooltipTitle = 'Your selected poll option is loading';
    } else {
      tooltipTitle = 'This cannot be changed after the poll has been published and received any votes';
    }
    return (
      <Tooltip
        mouseEnterDelay={0.3}
        title={tooltipTitle}
      >
        {inner}
      </Tooltip>
    );
  };

  const selectPollOption = async () => {
    setLoading(true);
    if (!disableVoteMessage) {
      message.loading(
        { content: `${isSelected ? 'Deselecting' : 'Selecting'} poll option...`, duration: 3 }
      );
    }
    try {
      if (isSelected) {
        await onDeselectOption(pollId, option.id);
        deselectOption(option.id);
      } else {
        await onSelectOption(pollId, option.id);
        selectOption(option.id);
        if (typeof afterVoteHook === 'function') {
          afterVoteHook();
        }
      }
      if (!disableVoteMessage) {
        message.success(
          { content: `Option successfully ${isSelected ? 'deselected' : 'selected'}`, duration: 5 }
        );
      }
    } catch (err) {
      message.error({ content: err?.details?.issue || err.message, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const handleContinueAnonymously = (func) => {
    setLoading(true);
    continueAnonymously(func);
  };

  const renderRemoveOption = () => (
    <Popconfirm
      title="Delete this option?"
      onConfirm={() => (isLoading ? null : removePollOption())}
      okText="Delete"
      okButtonProps={{ className: styles.confirmDelete }}
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    >
      <CloseOutlined
        className={(lockEdits || isLoading) ? styles.removeDisabled : styles.remove}
      />
    </Popconfirm>
  );

  const renderTextEditIcon = () => (
    <EditTwoTone className={(lockEdits || isLoading) ? styles.editDisabled : styles.edit} />
  );

  const renderOptionChildren = () => (
    <>
      {!showResults && !checkEditing() && isMultipleChoice && (
        <Checkbox
          className={styles.checkbox}
          checked={isSelected}
          disabled={isDisabled || isLoading || isLoadingBallot}
        />
      )}
      {showResults && !checkEditing() && (option.count != null) && (
        <DigitStatistic votes={option.count} selected={checkSelected()} disabled={isDisabled} />
      )}
      <Text
        className={`${styles.name} ${isEditingOption ? styles.nameEditing : ''} ${checkSelected() ? styles.nameChecked : ''}`}
        editable={checkEditingAllowed()
          ? {
            onChange: changeOptionName,
            maxLength: 50,
            onStart: () => setEditingOption(true),
            onCancel: () => setEditingOption(false),
            onEnd: () => setEditingOption(false)
          }
          : false}
        disabled={isDisabled || isLoading || isLoadingBallot}
      >
        {name}
      </Text>
      {checkEditingLocked() && showTooltip(renderTextEditIcon())}
      {showResults && !checkEditing() && (option.count !== null) && (
        <PollPercent percent={calcBallotPercent()} selected={checkSelected()} />
      )}
    </>
  );

  const getOptionClassName = () => {
    let classname = isMultipleChoice ? styles.multiOption : styles.option;
    if (isLoading) {
      classname = `${classname} ${styles.optionLoading}`;
    }
    if (isDisabled) {
      classname = `${classname} ${styles.optionDisabled}`;
    } else if (checkSelected()) {
      classname = `${classname} ${styles.optionChecked}`;
    }

    if (showResults) {
      classname = `${classname} ${styles.noSelect}`;
    }

    if (onlyResults) {
      classname = `${classname} ${styles.notAllowed}`;
    }
    return classname;
  };

  const renderOption = () => {
    const children = renderOptionChildren();
    // CheckableTag shouldn't be used if disabled, while editing, or if showing results
    if (isDisabled || isEditMode || showResults || isLoading || isLoadingBallot) {
      const renderedOption = (
        <div
          className={getOptionClassName()}
        >
          {children}
        </div>
      );
      return (
        onlyResults || isDisabled ? showTooltip(renderedOption) : renderedOption
      );
    }
    return (
      <AnonPopconfirm
        overlayClassName={styles.popconfirm}
        disabled={isDisabled || userState.isLoggedIn}
        onConfirm={() => handleContinueAnonymously(selectPollOption)}
      >
        <CheckableTag
          className={getOptionClassName()}
          onChange={userState.isLoggedIn ? () => handleContinueAnonymously(selectPollOption) : null}
          checked={checkSelected()}
        >
          {children}
        </CheckableTag>
      </AnonPopconfirm>
    );
  };

  return (
    <>
      <div className={styles.container}>
        {showResults && !checkEditing() && (option.count != null) && (
        <Text className={styles.order}>
          {index + 1}
          .
        </Text>
        )}
        {renderOption()}
      </div>
      {checkEditing() && (lockEdits ? showTooltip(renderRemoveOption()) : renderRemoveOption())}
    </>
  );
}

PollOption.displayName = 'PollOption';
PollOption.defaultProps = {
  onlyResults: false,
  isEditing: false,
  lockEdits: false,
  showResults: false,
  isDisabled: false,
  afterVoteHook: null,
  disableVoteMessage: false,
  isLoadingBallot: false
};

export default PollOption;
