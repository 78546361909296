import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import SurveyContainer from './SurveyContainer';
import styles from '../styles/SurveyStartButton.module.scss';

function SurveyStartButton({
  quteeId, buttonText, afterStart, afterClose, hideBannerImage,
  liveFrequency, useSimpleGauge, isMainEngagement, setAllowAnonymous
}) {
  const [showModal, setShowModal] = useState(false);

  const openSurvey = () => {
    setShowModal(true);
    if (typeof afterStart === 'function') {
      afterStart();
    }
  };

  const closeSurvey = () => {
    setShowModal(false);
    if (typeof afterStart === 'function') {
      afterClose();
    }
  };

  return (
    <>
      <div>
        <Button
          type="primary"
          size="large"
          onClick={() => openSurvey()}
        >
          {buttonText}
        </Button>
      </div>
      <Modal
        open={showModal}
        footer={null}
        width="95vw"
        style={{ top: '40px' }}
        bodyStyle={{ paddingTop: 0 }}
        onCancel={() => closeSurvey()}
        closable={false}
      >
        <div className={styles.close}>
          <Button
            type="text"
            size="large"
            onClick={() => closeSurvey()}
          >
            Close Survey
            <CloseOutlined />
          </Button>
        </div>
        <SurveyContainer
          quteeId={quteeId}
          liveFrequency={liveFrequency}
          useSimpleGauge={useSimpleGauge}
          isMainEngagement={isMainEngagement}
          setAllowAnonymous={setAllowAnonymous}
          hideBannerImage={hideBannerImage}
        />
      </Modal>
    </>
  );
}

SurveyStartButton.defaultProps = {
  buttonText: 'Start Survey',
  afterStart: () => {},
  afterClose: () => {},
  liveFrequency: 30,
  useSimpleGauge: true
};

export default SurveyStartButton;
