// @ts-nocheck
import React, {
  forwardRef, useCallback, useEffect, useRef, useState
} from 'react';
import {
  Button, App, Modal, Spin
} from 'antd';
import useFetch, { CachePolicies } from 'use-http';
import ReactCrop from 'react-image-crop';
import Uploady, {
  withRequestPreSendUpdate,
  useItemFinalizeListener,
  useItemStartListener
} from '@rpldy/uploady';
import { asUploadButton } from '@rpldy/upload-button';
import UploadPreview, { PREVIEW_TYPES } from '@rpldy/upload-preview';
import cropImage from '../cropImage';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './styles/ImageUploadButton.module.scss';

function PreviewButtons({
  loading,
  finished,
  crop,
  updateRequest,
  onUploadCancel,
  onUploadCrop,
  onUploadNormal
}) {
  return (
    <div>
      <Button
        className={!finished && updateRequest && crop ? styles.previewButton : styles.hide}
        onClick={onUploadCrop}
        disabled={loading}
        type="primary"
      >
        Upload Cropped
      </Button>
      <Button
        className={!finished && updateRequest ? styles.previewButton : styles.hide}
        onClick={onUploadNormal}
        disabled={loading}
        type={crop ? 'default' : 'primary'}
      >
        Upload without Crop
      </Button>
      <Button
        className={!finished && updateRequest ? styles.previewButton : styles.hide}
        onClick={onUploadCancel}
        disabled={loading}
      >
        Cancel
      </Button>
    </div>
  );
}

const UPLOAD_STATES = {
  NONE: 0,
  UPLOADING: 1,
  FINISHED: 2
};

function CropBeforeUpload(props) {
  const {
    id, url, isFallback, type, updateRequest,
    requestData, previewMethods, resourceType, resourceId,
    imageType, imageTitle, successCallback
  } = props;
  const { message } = App.useApp();
  const cropRef = useRef(null);
  const [uploadState, setUploadState] = useState(UPLOAD_STATES.NONE);
  const [crop, setCrop] = useState(null);
  const [croppedUrl, setCroppedUrl] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [modalWidth, setModalWidth] = useState(1200);
  const isFinished = uploadState === UPLOAD_STATES.FINISHED;
  const MIN_MODAL_WIDTH = 200;

  const { post: initiateUpload, response: initiateUploadResponse } = useFetch('uploads', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const loadingKey = 'imageUploadRequest';
  const successKey = 'imageUploadSuccess';

  const showStartLoading = () => {
    message.destroy(loadingKey);
    message.destroy(successKey);
    setLoading(true);
    message.loading({ content: 'Uploading image...', key: loadingKey });
  };

  const showDoneLoading = () => {
    message.destroy(loadingKey);
    message.success({ content: 'Image successfully uploaded.', key: successKey });
    setLoading(false);
  };

  useItemStartListener(() => setUploadState(UPLOAD_STATES.UPLOADING), id);
  useItemFinalizeListener(
    () => {
      if (isLoading) {
        showDoneLoading();
      }
      setUploadState(UPLOAD_STATES.FINISHED);
    },
    id
  );

  useEffect(() => {
    if (requestData) {
      const { file } = requestData.items[0];
      const img = new Image();
      img.onload = () => {
        const width = Math.min(Math.max(MIN_MODAL_WIDTH, img.width), window.innerWidth - 40);
        setModalWidth(width);
      };
      img.src = URL.createObjectURL(file);
    }
  }, [requestData]);

  const updateDestination = useCallback(async (items) => {
    const files = items.length > 0 ? items[0] : {};
    const { file } = files;
    const { type } = file;

    const data = {
      image_field: imageType,
      content_type: type
    };
    if (resourceType === 'qutee') {
      data.qutee_id = resourceId;
    } else if (resourceType === 'gauge') {
      data.gauge_id = resourceId;
    }
    const response = await initiateUpload('', data);
    if (initiateUploadResponse.ok) {
      const uploadUrl = response.upload_url;
      if (typeof successCallback === 'function') {
        successCallback(URL.createObjectURL(file));
      }
      return {
        sendWithFormData: false,
        destination: {
          url: uploadUrl,
          method: 'PUT',
          headers: {
            'Content-Type': type
          }
        }
      };
    }

    return null;
  }, [initiateUpload, initiateUploadResponse, resourceType, resourceId, imageType, requestData, successCallback]);

  const onUploadCrop = useCallback(async () => {
    if (updateRequest && (crop?.height || crop?.width)) {
      showStartLoading();
      const { blob: croppedBlob, blobUrl, revokeUrl } = await cropImage(
        cropRef.current,
        requestData.items[0].file,
        crop,
        true
      );

      requestData.items[0].file = croppedBlob;

      const options = await updateDestination(requestData.items);
      const toUpdate = { items: requestData.items };
      if (options) {
        toUpdate.options = options;
      }

      updateRequest(toUpdate);
      setCroppedUrl({ blobUrl, revokeUrl });
    }
  }, [requestData, updateRequest, crop, updateDestination]);

  const onUploadNormal = useCallback(async () => {
    if (updateRequest) {
      showStartLoading();
      const options = await updateDestination(requestData.items);
      const toUpdate = { };
      if (options) {
        toUpdate.options = options;
      }
      updateRequest(toUpdate);
    }
  }, [requestData, updateRequest, updateDestination]);

  const onUploadCancel = useCallback(() => {
    updateRequest(false);
    if (previewMethods.current?.clear) {
      previewMethods.current.clear();
    }
  }, [updateRequest, previewMethods]);

  useEffect(() => () => croppedUrl?.revokeUrl(), [croppedUrl]);

  const useFallback = isFallback || (type !== PREVIEW_TYPES.IMAGE);

  if (useFallback) {
    return <img src={url} alt="fallback img" />;
  }

  if (requestData) {
    return (
      <div>
        <Modal
          width={modalWidth}
          title="Preview/Crop"
          open={uploadState !== UPLOAD_STATES.FINISHED}
          onCancel={() => (!isLoading ? onUploadCancel() : null)}
          footer={null}
          mask={false}
          maskClosable={false}
          style={{ top: 20 }}
          keyboard={false}
        >
          <Spin spinning={isLoading} tip="Loading...">
            {imageTitle}
            <div>Click and drag on the image to crop</div>
            {!useFallback && requestData ? (
              <ReactCrop
                className={styles.crop}
                ruleOfThirds
                crop={crop}
                onChange={setCrop}
                onComplete={setCrop}
              >
                <img ref={cropRef} className={styles.image} src={url} alt="Crop" />
              </ReactCrop>
            ) : (
              <img className={styles.image} src={croppedUrl?.blobUrl || url} alt="img to upload" />
            )}
            <PreviewButtons
              loading={isLoading}
              finished={isFinished}
              crop={crop}
              updateRequest={updateRequest}
              onUploadCancel={onUploadCancel}
              onUploadCrop={onUploadCrop}
              onUploadNormal={onUploadNormal}
            />
            <p>{isFinished ? 'FINISHED' : ''}</p>
          </Spin>
        </Modal>
      </div>
    );
  }

  return null;
}

function ImageUploadButton({
  resourceType, resourceId, imageTitle, imageType, buttonRef, successCallback
}) {
  const previewMethodsRef = useRef();

  let button = buttonRef;
  if (!buttonRef) {
    // eslint-disable-next-line react/display-name
    button = forwardRef(
      (props, ref) => (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          ref={ref}
        >
          Click To Upload
        </Button>
      )
    );
    button.displayName = 'Upload Button';
  }

  const UploadButton = asUploadButton(button);

  return (
    <div>
      <Uploady
        multiple={false}
        clearPendingOnAdd
      >
        <UploadButton />
        <br />
        <UploadPreview
          PreviewComponent={withRequestPreSendUpdate(CropBeforeUpload)}
          previewComponentProps={{
            previewMethods: previewMethodsRef,
            resourceType,
            resourceId,
            imageType,
            imageTitle,
            successCallback
          }}
          previewMethodsRef={previewMethodsRef}
        />
      </Uploady>
    </div>
  );
}

ImageUploadButton.defaultProps = {
  imageTitle: null,
  successCallback: null
};

export default ImageUploadButton;
