import React, { useState, useEffect } from 'react';
import {
  Input, App, Popconfirm, Tag, Tooltip
} from 'antd';
import {
  CloseOutlined, EditOutlined, SaveOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import { escFunction } from '../../common/utils';
import styles from '../styles/Label.module.scss';

const { CheckableTag } = Tag;

function Label({
  commentBinId, labelId, name, selectedLabelId, isEditMode,
  onLabelSelect, updateLabel, deleteLabel, disabled, editingDisabled, deleteDisabled

}) {
  const { message } = App.useApp();
  const [text, setText] = useState('');
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  useEffect(() => {
    setText(name);
  }, [name]);

  const startEditing = async () => {
    setEditing(true);
  };

  const cancelEdit = async () => {
    setText(name);
    setEditing(false);
  };

  const changeTagText = async (e) => {
    setText(e.target.value);
  };

  const confirmDelete = async () => {
    setShowDeleteConfirmation(true);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const saveLabel = async () => {
    const loadingKey = 'updateLabelRequest';
    const successKey = 'updateLabelSuccess';
    const errorKey = 'updateLabelError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating label...', key: loadingKey });
    try {
      await updateLabel(commentBinId, labelId, text);
      setEditing(false);
      message.destroy(loadingKey);
      message.success({ content: 'Label successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const removeLabel = async () => {
    const loadingKey = 'deleteLabelRequest';
    const successKey = 'deleteLabelSuccess';
    const errorKey = 'deleteLabelError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Deleting label...', key: loadingKey });
    try {
      setShowDeleteConfirmation(false);
      await deleteLabel(commentBinId, labelId);
      setDeleted(true);
      message.destroy(loadingKey);
      message.success({ content: 'Label successfully deleted', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const getCloseIcon = () => {
    if (deleteDisabled) {
      return (
        <Tooltip title="This label cannot be deleted while it has associated comments">
          <CloseOutlined className={styles.closeDisabled} />
        </Tooltip>
      );
    }
    return <CloseOutlined className={styles.close} />;
  };

  const getEditIcon = () => {
    if (editingDisabled) {
      return (
        <Tooltip title="This label cannot be edited while it has associated comments">
          <EditOutlined className={styles.editDisabled} />
        </Tooltip>
      );
    }
    return <EditOutlined className={styles.edit} onClick={startEditing} />;
  };

  const getClassname = () => {
    if (disabled) {
      return styles.disabled;
    }
    if (selectedLabelId === labelId) {
      return styles.checked;
    }
    return styles.unchecked;
  };

  if (!isEditMode) {
    return (
      <CheckableTag
        key={labelId}
        className={getClassname()}
        checked={selectedLabelId === labelId}
        onChange={(checked) => onLabelSelect(checked, labelId)}
      >
        {text}
      </CheckableTag>
    );
  }

  return (
    <Popconfirm
      open={showDeleteConfirmation}
      title="Are you sure you want to delete this label?"
      onConfirm={removeLabel}
      onCancel={cancelDelete}
      okText="Delete"
      okButtonProps={{ className: styles.confirmDelete }}
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    >
      <Tag
        key={labelId}
        className={styles.editable}
        closable={!isLoading && !isEditing}
        visible={!isDeleted}
        onClose={deleteDisabled ? null : confirmDelete}
        closeIcon={getCloseIcon()}
      >
        {isEditing
          ? (
            <div>
              <Input
                className={styles.input}
                size="small"
                value={text}
                disabled={isLoading}
                autoFocus
                onChange={changeTagText}
                onPressEnter={saveLabel}
                onKeyDown={(e) => escFunction(e, cancelEdit, true)}
              />
              <SaveOutlined className={styles.save} onClick={saveLabel} />
            </div>
          )
          : (
            <span>
              {getEditIcon()}
              <span>{text}</span>
            </span>
          )}
      </Tag>
    </Popconfirm>
  );
}

Label.displayName = 'Label';
Label.defaultProps = {

};

export default Label;
