import React from 'react';
import { Spin } from 'antd';

const wrapper = { margin: '-10px 0 20px 0' }; const
  spinnerPosition = { marginRight: 10 };

function AuthLoader() {
  return (
    <div style={wrapper}>
      <Spin size="small" style={spinnerPosition} />
      Sending info, please wait...
    </div>
  );
}

export default AuthLoader;
