import React, { useEffect, useRef, useState } from 'react';
import useFetch from 'use-http';
import {
  Button, Collapse, Checkbox, Input, Spin, Typography
} from 'antd';
import { ApiException } from '../../common/utils';
import useCommentBinSettings from '../state/useCommentBinSettings';
import useLabels from '../state/useLabels';
import useTopics from '../state/useTopics';
import useSentiments from '../state/useSentiments';
import styles from '../styles/CommentFilters.module.scss';

const { Panel } = Collapse;
const { Search } = Input;
const { Text } = Typography;

/**
 * @typedef {Object} FilterList
 * @property {number} id An identifier for the filter item
 * @property {string} name The text for the filter item
 * @property {number=} count The number of items found for the filter item
 * @property {string} type The type of filter item
 */

/**
 * A panel for filtering comments by various categories of items.
 * @param {Object} props Props for the component
 * @param {number} props.commentBinId The id of the comment bin to which the filters apply
 * @param {string} props.filter The filter data in string form
 * @param {Function} props.setFilter A function to set the filter string
 * @param {Object} props.filterData The filter data in object form
 * @param {Function} props.setFilterData A function to set the filter data in object form
 * @param {Function} props.setLoadingFilters A function to set whether filters are loading
 */
// TODO: Redesign to accept arbitrary filter data
function CommentFilters({
  commentBinId, filter, setFilter, filterData, setFilterData, setLoadingFilters
}) {
  const SEARCHES_COLLAPSE_KEY = 'Searches';
  const LABELS_COLLAPSE_KEY = 'Labels';
  const TOPICS_COLLAPSE_KEY = 'Topics';
  const SENTIMENTS_COLLAPSE_KEY = 'Descriptors';
  const DEFAULT_COLLAPSE_KEYS = [
    SEARCHES_COLLAPSE_KEY, LABELS_COLLAPSE_KEY, TOPICS_COLLAPSE_KEY, SENTIMENTS_COLLAPSE_KEY
  ];
  const isFirstRun = useRef(true);
  const [searchValue, setSearchValue] = useState({});
  const [searchHistory, setSearchHistory] = useState({});
  const [topicSelections, setTopicSelections] = useState({});
  const [sentimentSelections, setSentimentSelections] = useState({});
  const [collapseKeys, setCollapseKeys] = useState(DEFAULT_COLLAPSE_KEYS);

  useEffect(() => {
    if (!commentBinId) {
      return;
    }
    if (!Object.prototype.hasOwnProperty.call(searchValue, commentBinId)) {
      setSearchValue((old) => ({
        ...old,
        [commentBinId]: ''
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(searchHistory, commentBinId)) {
      setSearchHistory((old) => ({
        ...old,
        [commentBinId]: []
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(topicSelections, commentBinId)) {
      setTopicSelections((old) => ({
        ...old,
        [commentBinId]: []
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(sentimentSelections, commentBinId)) {
      setSentimentSelections((old) => ({
        ...old,
        [commentBinId]: []
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentBinId]);

  const activeSearchValue = commentBinId ? searchValue[commentBinId] : '';
  const activeSearchHistory = commentBinId ? searchHistory[commentBinId] : [];
  const activeTopicSelections = commentBinId ? topicSelections[commentBinId] : [];
  const activeSentimentSelections = commentBinId ? sentimentSelections[commentBinId] : [];

  const changeSearchValue = (value) => {
    setSearchValue((old) => ({
      ...old,
      [commentBinId]: value
    }));
  };
  const changeSearchHistory = (list) => {
    setSearchHistory((old) => ({
      ...old,
      [commentBinId]: list
    }));
  };
  const changeTopicSelections = (list) => {
    setTopicSelections((old) => ({
      ...old,
      [commentBinId]: list
    }));
  };
  const changeSentimentSelections = (list) => {
    setSentimentSelections((old) => ({
      ...old,
      [commentBinId]: list
    }));
  };

  const {
    settings: commentBinSettings
  } = useCommentBinSettings(commentBinId);
  const {
    labels,
    isLoading: isLoadingLabels
  } = useLabels(commentBinId, filter);
  const {
    topics,
    isLoadingMore: isLoadingTopics,
    isReachingEnd: isEndTopics,
    size: sizeTopics,
    setSize: setSizeTopics
  } = useTopics(commentBinId, filter);
  const {
    sentiments,
    isLoadingMore: isLoadingSentiments,
    isReachingEnd: isEndSentiments,
    size: sizeSentiments,
    setSize: setSizeSentiments
  } = useSentiments(commentBinId, filter);

  const hideSearchFilter = commentBinSettings
    && commentBinSettings.comments_prevent_search;
  const hideLabelFilter = commentBinSettings
    && commentBinSettings.labels_hidden;
  const hideTopicFilter = commentBinSettings
    && commentBinSettings.topics_hidden;
  const hideSentimentFilter = commentBinSettings
    && commentBinSettings.sentiments_hidden;
  const hideAllFilters = hideSearchFilter && hideLabelFilter
    && hideTopicFilter && hideSentimentFilter;

  // do these update properly, or do I need a useEffect on them?
  const labelFilters = labels ? labels.map((item) => (
    {
      name: item.name,
      count: item.comment_count,
      id: item.label_id,
      type: 'label'
    }
  )) : null;

  const topicFilters = topics ? topics.map((item) => (
    {
      name: item.topic_name,
      count: item.comment_count,
      id: item.topic_number,
      type: 'topic'
    }
  )) : null;

  const sentimentFilters = sentiments ? sentiments.map((item) => (
    {
      name: item.sentiment_name,
      count: item.comment_count,
      id: item.sentiment_number,
      type: 'sentiment'
    }
  )) : null;

  useEffect(() => {
    if (typeof setLoadingFilters !== 'function') {
      return;
    }
    if (isLoadingLabels || isLoadingTopics || isLoadingSentiments) {
      setLoadingFilters(true);
    } else {
      setLoadingFilters(false);
    }
  }, [isLoadingLabels, isLoadingTopics, isLoadingSentiments, setLoadingFilters]);

  const { get: getCommentCount, response: getCommentCountResponse } = useFetch('comment-bins');

  const countComments = async (commentBinId, term) => {
    const response = await getCommentCount(`${commentBinId}/count-comments?filter[search_term]=${term}`);
    if (getCommentCountResponse.ok) {
      return response.count || null;
    }
    throw new ApiException(response);
  };

  const updateFilters = async () => {
    if (!filterData) {
      return;
    }
    let queryString = '';
    // TODO: Create a more robust way to filter by search terms (e.g. handling special characters)
    if (filterData.search_term) {
      queryString += `&filter[search_term]=${filterData.search_term}`;
    }

    if (filterData.connections.length) {
      queryString += `&filter[connection]=in:${filterData.connections.join(',')}`;
    }

    if (filterData.labels.length) {
      const labelIds = filterData.labels.reduce((acc, cur) => {
        acc.push(cur.id);
        return acc;
      }, []);
      queryString += `&filter[label_id]=in:${labelIds.join(',')}`;
    }

    if (filterData.topics) {
      changeTopicSelections(filterData.topics);
      if (filterData.topics.length) {
        const topicIds = filterData.topics.reduce((acc, cur) => {
          acc.push(cur.id);
          return acc;
        }, []);
        queryString += `&filter[topic_number]=in:${topicIds.join(',')}`;
      }
    }

    if (filterData.sentiments) {
      changeSentimentSelections(filterData.sentiments);
      if (filterData.sentiments.length) {
        const sentimentIds = filterData.sentiments.reduce((acc, cur) => {
          acc.push(cur.id);
          return acc;
        }, []);
        queryString += `&filter[sentiment_number]=in:${sentimentIds.join(',')}`;
      }
    }

    if (queryString.length && queryString.indexOf('&') === 0) {
      queryString = queryString.substring(1);
    }

    setFilter(queryString);
  };

  useEffect(() => {
    if (filterData) {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }

      const updateData = async () => {
        await updateFilters();
      };
      updateData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const handleChecked = (checked, item, list, allowMultiple = true) => {
    if (checked) {
      let newList = [];
      if (allowMultiple) {
        newList = [...list];
      }

      newList.unshift(item);
      return newList;
    }

    let newList = [];
    if (allowMultiple) {
      newList = list.filter((other) => (other.id !== item.id));
    }
    return newList;
  };

  const modifyCollapseKeys = ((keys) => {
    setCollapseKeys(keys);
  });

  const setSearchFilters = async (term) => {
    if (!collapseKeys.includes(SEARCHES_COLLAPSE_KEY)) {
      collapseKeys.push(SEARCHES_COLLAPSE_KEY);
    }

    const updateSearchList = async () => {
      const newHistory = [...activeSearchHistory];
      if (!newHistory.find((item) => item.id === term)) {
        const newItem = {
          id: term,
          name: term
        };
        try {
          newItem.count = await countComments(commentBinId, term);
        } finally {
          newHistory.unshift(newItem);
          changeSearchHistory(newHistory);
        }
      }
    };
    updateSearchList();

    setFilterData(
      {
        connections: filterData.connections,
        labels: filterData.labels,
        topics: filterData.topics,
        sentiments: filterData.sentiments,
        search_term: term
      }
    );
  };

  const handleSearch = async (value) => {
    changeSearchValue('');
    await setSearchFilters(value);
  };

  const clearSearchTerm = () => {
    setFilterData(
      {
        connections: filterData.connections,
        labels: filterData.labels,
        topics: filterData.topics,
        sentiments: filterData.sentiments,
        search_term: ''
      }
    );
  };

  const getFilterPanel = (group, groupName, selected, onChange, isChecked, isLoading, loadMore) => {
    const displayFilterList = (item) => (
      <li className={styles.item} key={item.name}>
        <Checkbox
          className={groupName === LABELS_COLLAPSE_KEY ? styles.round : undefined}
          onChange={onChange ? (e) => { onChange(e.target.checked, item); } : null}
          checked={isChecked(item.id)}
          disabled={!isChecked(item.id) && item.count === 0}
        >
          <Text
            ellipsis={!isChecked(item.id)}
            className={`${styles.text} ${isChecked(item.id) ? styles.selected : ''}`}
          >
            {`${item.name}${(item.count || item.count === 0) ? ` (${item.count})` : ''}`}
          </Text>
        </Checkbox>
      </li>
    );

    let unselected = group;
    if (unselected && unselected.length && selected && selected.length) {
      unselected = group.filter((item) => !selected.find((item2) => item.id === item2.id));
    }
    return (
      <Panel className={styles.header} header={groupName} key={groupName}>
        {groupName === SEARCHES_COLLAPSE_KEY && (
          <Search
            className={styles.search}
            placeholder="search comments"
            onSearch={(value) => handleSearch(value)}
            onChange={(e) => changeSearchValue(e.target.value)}
            value={activeSearchValue}
          />
        )}
        {isLoading ? <Spin /> : (
          <ul className={styles.list}>
            {selected && selected.length ? selected.map(displayFilterList) : null}
            {unselected && unselected.length ? unselected.map(displayFilterList) : null}
          </ul>
        )}
        {loadMore}
      </Panel>
    );
  };

  const getSearchFilters = () => {
    const onChange = (checked, item) => {
      if (checked) {
        setSearchFilters(item.name);
      } else {
        clearSearchTerm();
      }
    };
    const isChecked = (id) => (filterData.search_term === id);
    return getFilterPanel(
      activeSearchHistory,
      SEARCHES_COLLAPSE_KEY,
      null,
      onChange,
      isChecked,
      false,
      null
    );
  };

  const getLabelFilters = () => {
    const onChange = (checked, label) => {
      const newLabels = handleChecked(checked, label, filterData.labels, false);
      changeTopicSelections([]);
      changeSentimentSelections([]);
      setFilterData(
        {
          connections: filterData.connections,
          labels: newLabels,
          topics: [],
          sentiments: [],
          search_term: filterData.search_term
        }
      );
    };
    const labelIds = filterData?.labels?.reduce((acc, l) => {
      acc.push(l.id);
      return acc;
    }, []);
    const isChecked = (id) => (labelIds?.indexOf(id) > -1);
    return getFilterPanel(
      labelFilters,
      LABELS_COLLAPSE_KEY,
      null,
      onChange,
      isChecked,
      isLoadingLabels
    );
  };

  const getTopicFilters = () => {
    const onChange = (checked, topic) => {
      const newTopics = handleChecked(checked, topic, activeTopicSelections);
      changeTopicSelections(newTopics);
      changeSentimentSelections([]);
      setFilterData(
        {
          connections: filterData.connections,
          labels: filterData.labels,
          topics: newTopics,
          sentiments: [],
          search_term: filterData.search_term
        }
      );
    };

    const loadMore = !isEndTopics ? (
      <Button
        className={styles.loadMore}
        type="text"
        disabled={isLoadingTopics || isEndTopics}
        onClick={() => setSizeTopics(sizeTopics + 1)}
      >
        <u>+ More Topics</u>
      </Button>
    ) : null;

    const topicIds = filterData?.topics?.reduce((acc, t) => {
      acc.push(t.id);
      return acc;
    }, []);
    const isChecked = (id) => (topicIds?.indexOf(id) > -1);
    return getFilterPanel(
      topicFilters,
      TOPICS_COLLAPSE_KEY,
      activeTopicSelections,
      onChange,
      isChecked,
      isLoadingTopics,
      loadMore
    );
  };

  const getSentimentFilters = () => {
    const onChange = (checked, sentiment) => {
      const newSentiments = handleChecked(checked, sentiment, activeSentimentSelections);
      changeSentimentSelections(newSentiments);
      setFilterData(
        {
          connections: filterData.connections,
          labels: filterData.labels,
          topics: filterData.topics,
          sentiments: newSentiments,
          search_term: filterData.search_term
        }
      );
    };

    const loadMore = !isEndSentiments ? (
      <Button
        className={styles.loadMore}
        type="text"
        disabled={isLoadingSentiments || isEndSentiments}
        onClick={() => setSizeSentiments(sizeSentiments + 1)}
      >
        <u>+ More Descriptors</u>
      </Button>
    ) : null;

    const sentimentIds = filterData?.sentiments?.reduce((acc, s) => {
      acc.push(s.id);
      return acc;
    }, []);
    const isChecked = (id) => (sentimentIds?.indexOf(id) > -1);
    return getFilterPanel(
      sentimentFilters,
      SENTIMENTS_COLLAPSE_KEY,
      activeSentimentSelections,
      onChange,
      isChecked,
      isLoadingSentiments,
      loadMore
    );
  };

  // check null or undefined, because filter could be empty string
  if (filter == null || filterData == null) {
    return null;
  }

  if (hideAllFilters) {
    return null;
  }

  return (
    <div className={styles.sidebar}>
      <Collapse
        className={styles.filters}
        activeKey={collapseKeys}
        ghost
        onChange={modifyCollapseKeys}
      >
        {!hideSearchFilter && getSearchFilters()}
        {!hideLabelFilter && getLabelFilters()}
        {!hideTopicFilter && getTopicFilters()}
        {!hideSentimentFilter && getSentimentFilters()}
      </Collapse>
    </div>
  );
}

CommentFilters.displayName = 'Comment Filters';
CommentFilters.defaultProps = {
  setLoadingFilters: undefined
};

export default CommentFilters;
