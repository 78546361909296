import useSWR from 'swr';

function useQuteeSummary(qutee) {
  const { data, error, mutate } = useSWR((qutee && qutee.qutee_id)
    ? `qutees/summary?filter[qutee_id]=in:${qutee.qutee_id}` : null);

  return {
    summary: (data && data.length && data[0]) || null,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useQuteeSummary;
