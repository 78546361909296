import React, { useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { Result, Button } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import clone from 'just-clone';
import { subject } from '@casl/ability';
import config from '../../common/infra/env.config';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException } from '../../common/utils';
import useAnonymousHandler from '../../auth/state/useAnonymousHandler';
import useQutee from '../../qutee/state/useQutee';
import useQuteeSummary from '../../qutee/state/useQuteeSummary';
import useCommentBin from '../../commentBin/state/useCommentBin';
import useComments from '../../commentBin/state/useComments';
import useNewCommentsCount from '../../commentBin/state/useNewCommentsCount';
import useLabels from '../../commentBin/state/useLabels';
import useTopics from '../../commentBin/state/useTopics';
import useSentiments from '../../commentBin/state/useSentiments';
import Qutee from '../../qutee/components/Qutee';
import LogInBanner from '../../auth/components/LogInBanner';
import AuthForm from '../../auth/components/AuthForm';
import BannerImage from '../../common/view/BannerImage';

// This component is for connecting the qutee component with the App's business logic
function QuteeContainer({
  quteeId, liveFrequency, hideGauges, hidePolls, hideBanners,
  useSimpleGauge, isMainEngagement, setAllowAnonymous, hideBannerImage
}) {
  const { state: userState } = useUserContext();
  // const [filter, setFilter] = useState('');
  // const [filterData, setFilterData] = useState({
  //   connections: [], labels: [], topics: [], sentiments: [], search_term: ''
  // });
  // const [sort, setSort] = useState(liveFrequency ? 'recent' : 'popular');
  // const [checkForNewDate, setCheckForNewDate] = useState((new Date()).toISOString());
  // const [createdCommentsCount, setCountCreatedComments] = useState(0);
  // const [newCommentsCount, setNewCommentsCount] = useState(0);
  // const [foundNewComments, setFoundNewComments] = useState(false);
  // const continueAnonymously = useAnonymousHandler();

  // const {
  //   qutee,
  //   mutate: quteeMutate,
  //   isLoading: isLoadingQutee,
  //   isError: isErrorQutee
  // } = useQutee(quteeId, userState.isLoggedIn);

  // const canView = userState.isLoggedIn && userState.isVerified
  //   && userState.ability.can('view', subject('Qutee', qutee));
  // const canEdit = userState.isLoggedIn && userState.isVerified
  //   && userState.ability.can('update', subject('Qutee', qutee));

  // const allowAnonymous = qutee
  //   ? (qutee.settings && !qutee.settings.prevent_anonymous_response)
  //   : true;
  // const isInactive = qutee && qutee.status === 'inactive';
  // const hideCommentBinResults = !canView && qutee && qutee.settings
  //   && qutee.settings.comment_bin_results_hidden;
  // const hideSearchFilter = !canView && qutee
  //   && qutee.settings && qutee.settings.comments_prevent_search;
  // const hideLabelFilter = !canView && qutee
  //   && qutee.settings && qutee.settings.labels_hidden;
  // const hideTopicFilter = !canView && qutee
  //   && qutee.settings && qutee.settings.topics_hidden;
  // const hideSentimentFilter = !canView && qutee
  //   && qutee.settings && qutee.settings.sentiments_hidden;
  // const hideAllFilters = !canView && hideSearchFilter && hideLabelFilter
  //   && hideTopicFilter && hideSentimentFilter;

  // useEffect(() => {
  //   if (isMainEngagement) {
  //     setAllowAnonymous(allowAnonymous);
  //   }
  // }, [allowAnonymous, isMainEngagement, setAllowAnonymous]);

  // const {
  //   summary
  // } = useQuteeSummary(qutee);
  // const {
  //   commentBin,
  //   mutate: commentBinMutate,
  //   isLoading: isLoadingCommentBin,
  //   isError: isErrorCommentBin
  // } = useCommentBin(qutee);
  // const {
  //   comments,
  //   numComments: filterCommentCount,
  //   mutate: commentsMutate,
  //   isLoadingMore: isLoadingComments,
  //   isReachingEnd: isEndComments,
  //   isRefreshing: isRefreshingComments,
  //   size: sizeComments,
  //   setSize: setSizeComments,
  //   reload: reloadComments
  // } = useComments(
  //   commentBin ? commentBin.comment_bin_id : null,
  //   sort,
  //   filter,
  //   userState.profile
  // );
  // const {
  //   count: numNewComments,
  //   foundComments: hasNewComments
  // } = useNewCommentsCount(
  //   commentBin ? commentBin.comment_bin_id : null,
  //   checkForNewDate,
  //   liveFrequency
  // );
  // const {
  //   labels,
  //   mutate: labelsMutate,
  //   isLoading: isLoadingLabels
  // } = useLabels(commentBin, filter);
  // const {
  //   topics,
  //   mutate: topicsMutate,
  //   isLoadingMore: isLoadingTopics,
  //   isReachingEnd: isEndTopics,
  //   size: sizeTopics,
  //   setSize: setSizeTopics
  // } = useTopics(commentBin, filter);
  // const {
  //   sentiments,
  //   mutate: sentimentsMutate,
  //   isLoadingMore: isLoadingSentiments,
  //   isReachingEnd: isEndSentiments,
  //   size: sizeSentiments,
  //   setSize: setSizeSentiments
  // } = useSentiments(commentBin, filter);

  // const { patch: patchCommentBin, response: patchCommentBinResponse } = useFetch('comment-bins', {
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });
  // const { patch: patchLabelSet, response: patchLabelSetResponse } = useFetch('comment-bins', {
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });
  // const { post: postLabel, response: postLabelResponse } = useFetch('comment-bins', {
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });
  // const { patch: patchLabel, response: patchLabelResponse } = useFetch('comment-bins', {
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });
  // const { delete: deleteLabel, response: deleteLabelResponse } = useFetch('comment-bins', {
  //   cachePolicy: CachePolicies.NO_CACHE
  // });

  // const { post: postPoll, response: postPollResponse } = useFetch('polls', {
  //   // @ts-ignore
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });

  // const { post: postComment, response: postCommentResponse } = useFetch('comments', {
  //   // @ts-ignore
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });
  // const { patch: patchComment, response: patchCommentResponse } = useFetch('comments', {
  //   cachePolicy: CachePolicies.NO_CACHE,
  //   headers: {
  //     Prefer: 'return=representation'
  //   }
  // });
  // const { delete: deleteComment, response: deleteCommentResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });

  // const { get: getReplies, response: getRepliesResponse } = useFetch('comment-bins', (globalOptions) => {
  //   if (!userState.profile) {
  //     // @ts-ignore
  //     globalOptions.headers.Authorization = 'Bearer unauthenticated';
  //   }
  //   return globalOptions;
  // });
  // const { get: getDeeperThread, response: getDeeperThreadResponse } = useFetch('comment-bins', (globalOptions) => {
  //   if (!userState.profile) {
  //     // @ts-ignore
  //     globalOptions.headers.Authorization = 'Bearer unauthenticated';
  //   }
  //   return globalOptions;
  // });
  // const { get: getCommentCount, response: getCommentCountResponse } = useFetch('comment-bins');

  // const { post: postCommentLike, response: postCommentLikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  // const { delete: deleteCommentLike, response: deleteCommentLikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  // const { post: postCommentDislike, response: postCommentDislikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });
  // const { delete: deleteCommentDislike, response: deleteCommentDislikeResponse } = useFetch('comments', { cachePolicy: CachePolicies.NO_CACHE });

  // const { get: loadReport, response: loadReportResponse } = useFetch('qutees', { cachePolicy: CachePolicies.NO_CACHE });
  // const { get: loadLatestReport, response: loadLatestReportResponse } = useFetch('qutees', { cachePolicy: CachePolicies.NO_CACHE });
  // const { get: loadReportProgress, response: loadReportProgressResponse } = useFetch('qutees', { cachePolicy: CachePolicies.NO_CACHE });
  // const { post: postGenerateReport, response: postGenerateReportResponse } = useFetch('qutees', { cachePolicy: CachePolicies.NO_CACHE });

  // useEffect(() => {
  //   if (hasNewComments) {
  //     if (numNewComments && (numNewComments === createdCommentsCount)) {
  //       setFoundNewComments(false);
  //     } else {
  //       setFoundNewComments(true);
  //     }
  //     if (numNewComments) {
  //       setNewCommentsCount(numNewComments - createdCommentsCount);
  //     }
  //   } else {
  //     setFoundNewComments(false);
  //   }
  // }, [numNewComments, createdCommentsCount, hasNewComments]);

  // const getGaugeIds = () => {
  //   if (!qutee) return null;
  //   const gaugeIds = qutee.gauges.reduce((acc, val) => {
  //     acc.push(val.gauge_id);
  //     return acc;
  //   }, []);

  //   return gaugeIds;
  // };

  // const getPollIds = () => {
  //   if (!qutee) return null;
  //   const pollIds = qutee.polls.reduce((acc, val) => {
  //     acc.push(val.poll_id);
  //     return acc;
  //   }, []);

  //   return pollIds;
  // };

  // const updateCommentBin = async (commentBinId, prompt) => {
  //   const data = {
  //     prompt
  //   };
  //   const original = { ...commentBin };
  //   commentBinMutate({ ...commentBin, ...data }, false);
  //   const updatedCommentBin = await patchCommentBin(`${commentBinId}`, data);
  //   if (patchCommentBinResponse.ok) {
  //     commentBinMutate(updatedCommentBin);
  //   } else {
  //     commentBinMutate(original);
  //     throw new ApiException(updatedCommentBin);
  //   }
  // };

  // const updateLabelSet = async (commentBinId, prompt) => {
  //   const data = {
  //     prompt
  //   };
  //   const original = { ...commentBin };
  //   commentBinMutate({ ...commentBin, label_set: { ...commentBin.label_set, ...data } }, false);
  //   const updatedLabelSet = await patchLabelSet(`${commentBinId}/labelset`, data);
  //   if (patchLabelSetResponse.ok) {
  //     commentBinMutate();
  //   } else {
  //     commentBinMutate(original);
  //     throw new ApiException(updatedLabelSet);
  //   }
  // };

  // const createLabel = async (commentBinId, name) => {
  //   const labelData = {
  //     name
  //   };

  //   const newLabel = await postLabel(`${commentBinId}/labels`, labelData);
  //   if (postLabelResponse.ok) {
  //     commentBinMutate();
  //     return newLabel;
  //   }
  //   throw new ApiException(newLabel);
  // };

  // const updateLabel = async (commentBinId, labelId, name) => {
  //   const data = {
  //     name
  //   };
  //   const original = clone(commentBin);
  //   const mutation = clone(commentBin);
  //   const mutationLabel = commentBin.label_set.labels.filter((label) => label.label_id === labelId);
  //   mutationLabel.name = name;
  //   commentBinMutate(mutation, false);
  //   const updatedLabel = await patchLabel(`${commentBinId}/labels/${labelId}`, data);
  //   if (patchLabelResponse.ok) {
  //     commentBinMutate();
  //   } else {
  //     commentBinMutate(original);
  //     throw new ApiException(updatedLabel);
  //   }
  // };

  // const removeLabel = async (commentBinId, labelId) => {
  //   const response = await deleteLabel(`${commentBinId}/labels/${labelId}`);
  //   if (deleteLabelResponse.ok) {
  //     commentBinMutate();
  //   } else {
  //     throw new ApiException(response);
  //   }
  // };

  // const createPoll = async (quteeId, title, options) => {
  //   const pollData = {
  //     qutee_id: quteeId,
  //     title,
  //     options,
  //     published: true
  //   };

  //   const newPoll = await postPoll('', pollData);
  //   if (postPollResponse.ok) {
  //     quteeMutate();
  //     return newPoll;
  //   }
  //   throw new ApiException(newPoll);
  // };

  // const createComment = async (quteeId, entryText, labelId, parentId, authorName) => {
  //   const commentData = {
  //     qutee_id: quteeId,
  //     comment_text: entryText,
  //     label_id: labelId
  //   };
  //   if (parentId) {
  //     commentData.parent_comment_id = parentId;
  //   }

  //   if (authorName) {
  //     commentData.author_name = authorName;
  //   }

  //   const newComment = await postComment('', commentData);
  //   if (postCommentResponse.ok) {
  //     return newComment;
  //   }
  //   throw new ApiException(newComment);
  // };

  // const updateComment = async (commentId, data) => {
  //   const updatedComment = await patchComment(`${commentId}`, data);
  //   if (patchCommentResponse.ok) {
  //     return updatedComment;
  //   }
  //   throw new ApiException(updatedComment);
  // };

  // const removeComment = async (commentId) => {
  //   const response = await deleteComment(`${commentId}`);
  //   if (!deleteCommentResponse.ok) {
  //     throw new ApiException(response);
  //   }
  // };

  // const likeComment = async (commentId) => {
  //   const response = await postCommentLike(`${commentId}/likes`);
  //   if (!postCommentLikeResponse.ok) {
  //     throw new ApiException(response);
  //   }
  // };

  // const removeCommentLike = async (commentId) => {
  //   const response = await deleteCommentLike(`${commentId}/likes`);
  //   if (!deleteCommentLikeResponse.ok) {
  //     throw new ApiException(response);
  //   }
  // };

  // const dislikeComment = async (commentId) => {
  //   const response = await postCommentDislike(`${commentId}/dislikes`);
  //   if (!postCommentDislikeResponse.ok) {
  //     throw new ApiException(response);
  //   }
  // };

  // const removeCommentDislike = async (commentId) => {
  //   const response = await deleteCommentDislike(`${commentId}/dislikes`);
  //   if (!deleteCommentDislikeResponse.ok) {
  //     throw new ApiException(response);
  //   }
  // };

  // const loadReplies = async (commentBinId, parentId, page, limit) => {
  //   const filterQuery = `filter[parent_comment_id]=${parentId}`;
  //   const sortQuery = `sort=${sort}`;
  //   const paginationQuery = `page=${page}&limit=${limit}`;
  //   const result = await getReplies(`${commentBinId}/threads?${filterQuery}&${sortQuery}&${paginationQuery}`);
  //   if (getRepliesResponse.ok) {
  //     const replies = result.results;
  //     return replies;
  //   }
  //   throw new ApiException(result);
  // };

  // const loadDeeperThread = async (commentBinId, parentId) => {
  //   const filterQuery = `filter[parent_comment_id]=${parentId}`;
  //   const sortQuery = `sort=${sort}`;
  //   const result = await getDeeperThread(`${commentBinId}/threads?${filterQuery}&${sortQuery}`);
  //   if (getDeeperThreadResponse.ok) {
  //     const replies = result.results;
  //     return replies;
  //   }
  //   throw new ApiException(result);
  // };

  // const countComments = async (commentBinId, term) => {
  //   const response = await getCommentCount(`${commentBinId}/count-comments?filter[search_term]=${term}`);
  //   if (getCommentCountResponse.ok) {
  //     return response.count || null;
  //   }
  //   throw new ApiException(response);
  // };

  // const getLatestReport = async (quteeId) => {
  //   const response = await loadLatestReport(`${quteeId}/reports/latest`);
  //   if (loadLatestReportResponse.ok) {
  //     return response;
  //   }
  //   throw new ApiException(response);
  // };

  // const getReport = async (quteeId, reportId) => {
  //   const response = await loadReport(`${quteeId}/reports/${reportId}`);
  //   if (loadReportResponse.ok) {
  //     return response;
  //   }
  //   throw new ApiException(response);
  // };

  // const getReportProgress = async (quteeId, reportProgressId) => {
  //   const response = await loadReportProgress(`${quteeId}/report-progress/${reportProgressId}`);
  //   if (loadReportProgressResponse.ok) {
  //     return response;
  //   }
  //   throw new ApiException(response);
  // };

  // const generateReport = async (quteeId) => {
  //   const response = await postGenerateReport(`${quteeId}/reports`);
  //   if (postGenerateReportResponse.ok) {
  //     return response;
  //   }
  //   throw new ApiException(response);
  // };

  // const reloadNewComments = async (resetFilter = true, goToRecent = true) => {
  //   if (resetFilter) {
  //     setFilterData({
  //       connections: [], labels: [], topics: [], sentiments: [], search_term: ''
  //     });
  //     setFilter('');
  //   }
  //   if (goToRecent && sort === 'popular') {
  //     setSort('recent');
  //   }
  //   await reloadComments();
  //   setCheckForNewDate((new Date()).toISOString());
  // };

  // const incrementCreatedComments = () => {
  //   setCountCreatedComments((count) => count + 1);
  // };

  // const openHelpDesk = () => {
  //   const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
  //   if (newWindow) {
  //     newWindow.opener = null;
  //   }
  // };

  // const canUserInteract = () => {
  //   if (userState.isPending) {
  //     return false;
  //   }
  //   if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
  //     return false;
  //   }
  //   if (!canView && isInactive) {
  //     return false;
  //   }
  //   return true;
  // };

  // const getCantInteractReason = () => {
  //   if (userState.isPending) {
  //     return 'You must verify your email before responding.';
  //   }
  //   if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
  //     return 'Anonymous users are not allowed to respond to this engagement.';
  //   }
  //   if (!canView && isInactive) {
  //     return 'This engagement is no longer accepting responses.';
  //   }
  //   return '';
  // };

  // const hasError = () => isErrorQutee || isErrorCommentBin;

  // const err = hasError();
  // if (err) {
  //   console.log(err);
  //   let errTitle = err.message;
  //   let errSubtitle = err?.details?.issue;
  //   if (err.status === 404) {
  //     errTitle = 'An engagement with the configured ID does not exist.';
  //     errSubtitle = 'If you are the engagement owner, please ensure the engagement ID is correct, otherwise contact support.';
  //   }
  //   if (err.status === 404 && !userState.isLoggedIn) {
  //     return (
  //       <div style={{ margin: 'auto', maxWidth: 600 }}>
  //         <AuthForm startingTab="signin" />
  //       </div>
  //     );
  //   }
  //   return (
  //     <>
  //       {!hideBanners && (
  //         <LogInBanner
  //           showLogout={!!userState.profile}
  //         />
  //       )}
  //       <Result
  //         className="qutee--error"
  //         status="error"
  //         icon={<WarningOutlined />}
  //         title={errTitle}
  //         subTitle={errSubtitle}
  //         extra={[
  //           <Button type="primary" key="support" onClick={() => openHelpDesk()}>
  //             Get Support
  //           </Button>
  //         ]}
  //       />
  //     </>
  //   );
  // }

  // return (
  //   <>
  //     {!hideBannerImage && <BannerImage quteeId={quteeId} />}
  //     <Qutee
  //       quteeId={qutee?.qutee_id}
  //       commentBinId={commentBin?.comment_bin_id}
  //       gaugeIds={getGaugeIds()}
  //       pollIds={getPollIds()}
  //       ratingCount={summary?.rating_count}
  //       totalCommentCount={summary?.comment_count}
  //       filterCommentCount={filterCommentCount}
  //       ballotCount={summary?.ballot_count}
  //       commentPrompt={commentBin?.prompt}
  //       labelPrompt={commentBin?.label_set?.prompt}
  //       labels={commentBin?.label_set?.labels}
  //       threads={comments}
  //       mutateThreads={commentsMutate}
  //       filters={filter}
  //       labelFilters={labels ? labels.map((item) => (
  //         {
  //           name: item.name,
  //           count: item.comment_count,
  //           id: item.label_id
  //         }
  //       )) : null}
  //       mutateLabels={labelsMutate}
  //       isLoadingLabels={isLoadingLabels}
  //       topicFilters={topics ? topics.map((item) => (
  //         {
  //           name: item.topic_name,
  //           count: item.comment_count,
  //           id: item.topic_number
  //         }
  //       )) : topics}
  //       mutateTopics={topicsMutate}
  //       sizeTopics={sizeTopics}
  //       setSizeTopics={setSizeTopics}
  //       isLoadingTopics={isLoadingTopics}
  //       isEndTopics={isEndTopics}
  //       sentimentFilters={sentiments ? sentiments.map((item) => (
  //         {
  //           name: item.sentiment_name,
  //           count: item.comment_count,
  //           id: item.sentiment_number
  //         }
  //       )) : null}
  //       mutateSentiments={sentimentsMutate}
  //       sizeSentiments={sizeSentiments}
  //       setSizeSentiments={setSizeSentiments}
  //       isLoadingSentiments={isLoadingSentiments}
  //       isEndSentiments={isEndSentiments}
  //       setFilter={setFilter}
  //       filterData={filterData}
  //       setFilterData={setFilterData}
  //       countComments={countComments}
  //       hideCommentBinResults={hideCommentBinResults}
  //       foundNewComments={foundNewComments}
  //       newCommentsCount={newCommentsCount}
  //       incrementCreatedComments={incrementCreatedComments}
  //       sizeComments={sizeComments}
  //       setSizeComments={setSizeComments}
  //       reloadComments={reloadNewComments}
  //       isLoadingComments={isLoadingComments}
  //       isEndComments={isEndComments}
  //       isRefreshingComments={isRefreshingComments}
  //       loadReplies={loadReplies}
  //       loadDeeperThread={loadDeeperThread}
  //       createComment={createComment}
  //       sort={sort}
  //       setSort={setSort}
  //       updateComment={updateComment}
  //       deleteComment={removeComment}
  //       likeComment={likeComment}
  //       removeCommentLike={removeCommentLike}
  //       dislikeComment={dislikeComment}
  //       removeCommentDislike={removeCommentDislike}
  //       updateCommentBin={updateCommentBin}
  //       updateLabelSet={updateLabelSet}
  //       createLabel={createLabel}
  //       updateLabel={updateLabel}
  //       deleteLabel={removeLabel}
  //       createPoll={createPoll}
  //       getReport={getReport}
  //       getLatestReport={getLatestReport}
  //       getReportProgress={getReportProgress}
  //       generateReport={generateReport}
  //       userCanInteract={canUserInteract()}
  //       cantInteractReason={getCantInteractReason()}
  //       userCanEditQutee={canEdit}
  //       labelEditingDisabled={userState.role !== 'admin' && summary?.comment_count > 0}
  //       checkUserCanEditComment={(comment) => userState.ability.can('update', subject('Comment', comment))}
  //       allowAnonymous={allowAnonymous}
  //       hideGauges={hideGauges}
  //       hidePolls={hidePolls}
  //       hideBanners={hideBanners}
  //       useSimpleGauge={useSimpleGauge}
  //       hideSearchFilter={hideSearchFilter}
  //       hideLabelFilter={hideLabelFilter}
  //       hideTopicFilter={hideTopicFilter}
  //       hideSentimentFilter={hideSentimentFilter}
  //       hideAllFilters={hideAllFilters}
  //       continueAnonymously={continueAnonymously}
  //     />
  //   </>
  // );
  return null;
}

QuteeContainer.defaultProps = {
  liveFrequency: 30,
  hideBanners: false,
  hideGauges: false,
  hidePolls: false,
  useSimpleGauge: false
};

export default QuteeContainer;
