import React, { useEffect, useState } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { Skeleton } from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import PollContainer from '../../embed/components/PollContainer';
import useQuteePolls from '../state/useQuteePolls';
import styles from '../styles/QuteePolls.module.scss';

function QuteePolls({
  quteeId, mode, showNoPollText, userCanEdit,
  shouldRefreshPolls, setShouldRefreshPolls,
  mutateProgress, triggerPollPinpoint,
  setTriggerPollPinpoint, pinpointSection,
  progress, progressPollRef
}) {
  const { state: userState } = useUserContext();
  const [polls, setPolls] = useState([]);
  // ballots is derived from polls and must maintain the same content order
  const [ballots, setBallots] = useState([]);
  // options is derived from polls and must maintain the same content order
  const [options, setOptions] = useState([]);

  const {
    polls: pollData,
    mutate: pollsMutate,
    isLoading
  } = useQuteePolls(quteeId, userState.profile);

  useEffect(() => {
    if (!pollData) {
      return;
    }
    const pollList = [];
    const ballotList = [];
    const optionsList = [];
    pollData.forEach((p) => {
      const { ballot = null, options, ...poll } = p;
      pollList.push(poll);
      ballotList.push(ballot);
      optionsList.push(options);
    });
    setPolls((prev) => {
      if (equal(prev, pollList)) {
        return prev;
      }
      return pollList;
    });
    setBallots((prev) => {
      if (equal(prev, ballotList)) {
        return prev;
      }
      return ballotList;
    });
    setOptions((prev) => {
      if (equal(prev, optionsList)) {
        return prev;
      }
      return optionsList;
    });
  }, [pollData]);

  useEffect(() => {
    if (shouldRefreshPolls) {
      setShouldRefreshPolls(false);
      pollsMutate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshPolls]);

  const getFirstUnfinished = () => {
    const unfinishedIds = new Set(progress?.polls
      ?.filter((poll) => poll.responded === false)
      ?.map((poll) => poll.poll_id));
    const first = polls?.find((poll) => unfinishedIds.has(poll.poll_id))?.poll_id;
    return first;
  };
  const firstUnfinished = getFirstUnfinished();

  useEffect(() => {
    if (triggerPollPinpoint) {
      if ((typeof pinpointSection === 'function')
      && progressPollRef.current) {
        pinpointSection(progressPollRef, -20, 0);
      }
      if (typeof setTriggerPollPinpoint === 'function') {
        setTriggerPollPinpoint(false);
      }
    }
  }, [triggerPollPinpoint, setTriggerPollPinpoint,
    pinpointSection, progressPollRef]);

  const getPollComponents = () => {
    const components = [];
    polls.forEach((poll, index) => {
      const optionList = options[index];
      const ballot = ballots[index];
      components.push((
        <PollContainer
          externalRef={firstUnfinished === poll.poll_id ? progressPollRef : undefined}
          key={poll.poll_id}
          pollId={poll.poll_id}
          mode={mode}
          initialPoll={poll}
          initialOptions={optionList}
          initialUserBallots={ballot}
          setShouldRefreshPolls={setShouldRefreshPolls}
          isLoadingInitial={isLoading}
          mutateProgress={mutateProgress}
        />
      ));
    });
    return components;
  };

  if ((!polls || !polls.length) && isLoading) {
    return <Skeleton active />;
  }

  if (polls) {
    return (
      <div className={styles.container}>
        {getPollComponents()}
      </div>
    );
  }

  if (showNoPollText && userCanEdit) {
    return (
      <div className={styles.noPolls}>
        {'You don\'t have any polls yet. Create one now.'}
      </div>
    );
  }

  return null;
}

QuteePolls.displayName = 'Qutee Polls';
QuteePolls.defaultProps = {
  mode: null,
  showNoPollText: false,
  userCanEdit: false,
  shouldRefreshPolls: false,
  setShouldRefreshPolls: () => {},
  triggerPollPinpoint: undefined,
  setTriggerPollPinpoint: undefined,
  pinpointSection: undefined,
  progress: undefined,
  progressPollRef: undefined
};

export default QuteePolls;
