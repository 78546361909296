import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../user/providers/UserProvider';
import useQutee from '../../qutee/state/useQutee';
import Image from './Image';
import styles from './styles/BannerImage.module.scss';

function BannerImage({ quteeId }) {
  const { state: userState } = useUserContext();
  const [imageUrl, setImageUrl] = useState(null);

  const {
    qutee
  } = useQutee(quteeId, userState.isLoggedIn);

  useEffect(() => {
    if (qutee && qutee.settings && qutee.settings.banner_image_url) {
      setImageUrl(qutee.settings.banner_image_url);
    }
  }, [qutee, setImageUrl]);

  if (imageUrl) {
    return (
      <Image className={styles.image} src={imageUrl} title="Banner" alt="Banner" isInternal={false} />
    );
  }
  return null;
}

export default BannerImage;
