const getBlobFromCanvas = (canvas, file, withUrl) => new Promise((resolve, reject) => {
  canvas.toBlob((blob) => {
    if (blob) {
      blob.name = file.name;
      blob.lastModified = file.lastModified;

      let blobUrl; let
        revokeUrl;

      if (withUrl) {
        blobUrl = URL.createObjectURL(blob);
        revokeUrl = () => URL.revokeObjectURL(blobUrl);
      }

      resolve({ blob, blobUrl, revokeUrl });
    } else {
      reject(new Error('Canvas is empty'));
    }
  }, file.type);
});

const cropImage = async (imageElm, file, crop, withUrl = false) => {
  const canvas = document.createElement('canvas');
  const scaleX = imageElm.naturalWidth / imageElm.width;
  const scaleY = imageElm.naturalHeight / imageElm.height;
  const pixelRatio = window.devicePixelRatio;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    imageElm,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  const cropped = await getBlobFromCanvas(canvas, file, withUrl);
  return cropped;
};

export default cropImage;
