import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import {
  BugOutlined, InfoCircleOutlined, CheckCircleOutlined, PoweroffOutlined
} from '@ant-design/icons';
import ReactTimeAgo from 'react-time-ago';
import { formatDistanceToNow } from 'date-fns';
import styles from '../styles/ErrorPage.module.scss';

function ErrorPage(
  { status }
) {
  const [title, setTitle] = useState('We had to turn this off for a moment.');
  const [statusTitle, setStatusTitle] = useState('Unavailable.');
  const [message, setMessage] = useState('We are currently undergoing maintenance. Please check back later.');
  const [issues, setIssues] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  useEffect(() => {
    setTitle(status.title);
    setStatusTitle(status.status);
    setMessage(status.message);
    const timezoneOffset = new Date().getTimezoneOffset();
    setLastUpdated(new Date(Date.parse(status.last_updated) + -1 * (timezoneOffset * 60 * 1000)));
    setIssues(status.issues);
  }, [status]);

  const buildUpdates = (updates) => {
    const descendants = [];
    updates.forEach((update) => {
      const component = (
        <Paragraph>
          <CheckCircleOutlined className={styles.icon} />
          { update.id }
          :&nbsp;
          { update.status }
          <br />

          <p className={styles.message}>{ update.message }</p>
          <p className={styles.timestamp}>
            { formatDistanceToNow(Date.parse(update.created)) }
          </p>
        </Paragraph>
      );
      descendants.push(component);
    });
    return descendants;
  };

  const buildIssues = () => {
    const descendants = [];
    if (!issues) {
      return (
        <Paragraph>
          <InfoCircleOutlined className={styles.icon} />
          No issues identified.
        </Paragraph>
      );
    }
    issues.forEach((issue) => {
      const component = (
        <Paragraph>
          <div className={styles.headline}>
            <BugOutlined className={styles.icon} />
            { issue.name }
            :&nbsp;
            { issue.status }
          </div>

          <p className={styles.message}>{ issue.message }</p>
          <p className={styles.timestamp}>
            { formatDistanceToNow(Date.parse(issue.created)) }
          </p>
          <div className={styles.updates}>
            <h3 className={styles.updatesHeading}>Updates: </h3>
            {buildUpdates(issue.updates)}
          </div>
        </Paragraph>

      );
      descendants.push(component);
    });

    return descendants;
  };

  return (
    <>
      <Title level={2} className={styles.mainTitle}>
        {title}
      </Title>

      <Result
        status="error"
        title={statusTitle}
        subTitle={message}
        icon={<PoweroffOutlined className={styles.mainIcon} />}
        className={styles.resultComponent}
      >
        <div className="desc">
          <h1>Known Issues: </h1>
          {buildIssues()}
        </div>
        <div className={styles.updatedContainer}>
          Hello from the engineering deck, we&apos;re fixing things. See details below.
        </div>
      </Result>
    </>
  );
}

export default ErrorPage;
