import React, { useEffect, useRef, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { mutate } from 'swr';
import {
  Button, Collapse, Divider, Dropdown,
  Input, App, Modal, Switch, Typography, Tooltip
} from 'antd';
import {
  LockOutlined, EyeOutlined, EyeInvisibleOutlined,
  CheckOutlined, CloseOutlined, DownOutlined, UnlockOutlined
} from '@ant-design/icons';
import { ApiException, isEmptyObject } from '../../common/utils';
import { useUserContext } from '../../user/providers/UserProvider';
import styles from '../styles/QuteeGeneralSettings.module.scss';

const { Text } = Typography;
const { Panel } = Collapse;

function QuteeGeneralSettings({
  quteeId, mutateComments, quteeOwnerRole, qutee, quteeMutate
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [showClearModal, setShowClearModal] = useState(false);
  const [confirmClearText, setConfirmClearText] = useState('');
  const inputRef = useRef();

  const titleAddPlaceholder = 'Add Title';

  const canUseAccessType = (quteeOwnerRole === 'essential-team' || quteeOwnerRole === 'complete-team'
    || quteeOwnerRole === 'admin' || userState.role === 'admin');

  const { patch: patchTitle, response: patchTitleResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { patch: patchSettings, response: patchSettingsResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { patch: patchBrand, response: patchBrandResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { post: postClearData, response: postClearDataResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { patch: patchPreheaderMainText, response: patchPreheaderMainTextResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { patch: patchPreheaderSubText, response: patchPreheaderSubTextResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { patch: patchGiveawayUrl, response: patchGiveawayUrlResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  useEffect(() => {
    if (qutee && qutee.settings && !isEmptyObject(qutee.settings)) {
      const apiSettings = qutee.settings;

      const viewSettings = {
        status: qutee.status,
        email_invite: qutee?.access_type === 'email_invite',
        allow_anonymous: !apiSettings.prevent_anonymous_response,
        respond_before_results: (
          apiSettings.gauge_rate_before_results || apiSettings.poll_vote_before_results
        ),
        comment_bin_results_hidden: apiSettings.comment_bin_results_hidden,
        gauge_results_hidden: apiSettings.gauge_results_hidden,
        poll_results_hidden: apiSettings.poll_results_hidden,
        withhold_all_data: apiSettings.comments_hidden
      };
      setSettings(viewSettings);
    }
  }, [qutee]);

  useEffect(() => {
    if (showClearModal) {
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          // @ts-ignore
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [showClearModal]);

  const transformSettingsViewToApi = (viewSettings) => {
    const apiSettings = {};

    const setAllResultsHidden = (hidden) => {
      apiSettings.comment_bin_results_hidden = hidden;
      apiSettings.comments_hidden = hidden;
      apiSettings.gauge_results_hidden = hidden;
      apiSettings.poll_results_hidden = hidden;
      apiSettings.topics_hidden = hidden;
      apiSettings.sentiments_hidden = hidden;
      apiSettings.labels_hidden = hidden;
      apiSettings.comments_prevent_search = hidden;
    };

    if ('status' in viewSettings) {
      apiSettings.qutee_status = viewSettings.status;
      apiSettings.comment_bin_status = viewSettings.status;
      apiSettings.gauge_status = viewSettings.status;
      apiSettings.poll_status = viewSettings.status;
    }
    if ('email_invite' in viewSettings) {
      apiSettings.access_type = viewSettings.email_invite ? 'email_invite' : 'public';
    }
    if ('allow_anonymous' in viewSettings) {
      apiSettings.prevent_anonymous_response = !viewSettings.allow_anonymous;
    }
    if ('respond_before_results' in viewSettings) {
      apiSettings.gauge_rate_before_results = viewSettings.respond_before_results;
      apiSettings.poll_vote_before_results = viewSettings.respond_before_results;
    }
    if ('withhold_all_data' in viewSettings) {
      setAllResultsHidden(viewSettings.withhold_all_data);
    } else {
      if ('comment_bin_results_hidden' in viewSettings) {
        apiSettings.comment_bin_results_hidden = viewSettings.comment_bin_results_hidden;
        if (!viewSettings.comment_bin_results_hidden) {
          setAllResultsHidden(false);
        }
      }
      if ('gauge_results_hidden' in viewSettings) {
        apiSettings.gauge_results_hidden = viewSettings.gauge_results_hidden;
        if (!viewSettings.gauge_results_hidden) {
          setAllResultsHidden(false);
        }
      }
      if ('poll_results_hidden' in viewSettings) {
        apiSettings.poll_results_hidden = viewSettings.poll_results_hidden;
        if (!viewSettings.poll_results_hidden) {
          setAllResultsHidden(false);
        }
      }
    }
    if ('chat_bot_id' in viewSettings) {
      apiSettings.chat_bot_id = viewSettings.chat_bot_id;
    }
    return apiSettings;
  };

  const updateSettings = async (setting) => {
    const loadingKey = 'updateSettingsRequest';
    const successKey = 'updateSettingsSuccess';
    const errorKey = 'updateSettingsError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Updating settings...', key: loadingKey });
    setLoading(true);
    try {
      const data = transformSettingsViewToApi(setting);
      const original = { ...qutee };
      const mutation = { ...qutee };
      mutation.settings = { ...mutation.settings, ...data };
      if ('qutee_status' in mutation.settings) {
        mutation.status = mutation.settings.qutee_status;
        delete mutation.settings.qutee_status;
      }
      quteeMutate(mutation, { revalidate: false });
      const response = await patchSettings(`${quteeId}/settings`, data);
      if (patchSettingsResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: 'Settings successfully updated', key: successKey });
      } else {
        quteeMutate(original);
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      if (err.details?.issue) {
        message.error({ content: err.details.issue, key: errorKey, duration: 5 });
      } else {
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      }
    } finally {
      setLoading(false);
    }
  };

  const updateTitle = async (title) => {
    const loadingKey = 'updateTitleRequest';
    const successKey = 'updateTitleSuccess';
    const errorKey = 'updateTitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Updating sayso title...', key: loadingKey });
    setLoading(true);
    try {
      const data = {
        title
      };
      const mutation = { ...qutee, ...data };
      quteeMutate(mutation, { revalidate: false });
      const response = await patchTitle(`${quteeId}`, data);
      if (patchTitleResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: 'Title successfully updated', key: successKey });
      } else {
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const updateBrand = async (brand) => {
    const loadingKey = 'updateBrandRequest';
    const successKey = 'updateBrandSuccess';
    const errorKey = 'updateBrandError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Updating brand...', key: loadingKey });
    setLoading(true);
    try {
      const data = {
        brand
      };
      const mutation = { ...qutee };
      mutation.settings = { ...mutation.settings, ...data };
      quteeMutate(mutation, { revalidate: false });
      const response = await patchBrand(`${quteeId}/settings`, data);
      if (patchBrandResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: 'Brand successfully updated', key: successKey });
      } else {
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const updatePreheaderMainText = async (text) => {
    const loadingKey = 'updatePreheaderMainTextRequest';
    const successKey = 'updatePreheaderMainTextSuccess';
    const errorKey = 'updatePreheaderMainTextError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Updating preheader main text...', key: loadingKey });
    setLoading(true);
    try {
      const data = {
        preheader_main_text: text
      };
      const mutation = { ...qutee };
      mutation.settings = { ...mutation.settings, ...data };
      quteeMutate(mutation, { revalidate: false });
      const response = await patchPreheaderMainText(`${quteeId}/settings`, data);
      if (patchPreheaderMainTextResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: 'Preheader main text successfully updated', key: successKey });
      } else {
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const updatePreheaderSubText = async (text) => {
    const loadingKey = 'updatePreheaderSubTextRequest';
    const successKey = 'updatePreheaderSubTextSuccess';
    const errorKey = 'updatePreheaderSubTextError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Updating preheader sub text...', key: loadingKey });
    setLoading(true);
    try {
      const data = {
        preheader_sub_text: text
      };
      const mutation = { ...qutee };
      mutation.settings = { ...mutation.settings, ...data };
      quteeMutate(mutation, { revalidate: false });
      const response = await patchPreheaderSubText(`${quteeId}/settings`, data);
      if (patchPreheaderSubTextResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: 'Preheader sub text successfully updated', key: successKey });
      } else {
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const updateGiveawayUrl = async (url) => {
    const loadingKey = 'updateGiveawayUrlRequest';
    const successKey = 'updateGiveawayUrlSuccess';
    const errorKey = 'updateGiveawayUrlError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Updating giveaway URL...', key: loadingKey });
    setLoading(true);
    try {
      const data = {
        giveaway_terms_url: url
      };
      const mutation = { ...qutee };
      mutation.settings = { ...mutation.settings, ...data };
      quteeMutate(mutation, { revalidate: false });
      const response = await patchGiveawayUrl(`${quteeId}/settings`, data);
      if (patchGiveawayUrlResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: 'Giveaway URL successfully updated', key: successKey });
      } else {
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const clearUserData = async () => {
    const loadingKey = 'clearUserDataRequest';
    const successKey = 'clearUserDataSuccess';
    const errorKey = 'clearUserDataError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Clearing data...', key: loadingKey });
    setLoading(true);
    try {
      const response = await postClearData(`${quteeId}/clear-user-data`);
      if (postClearDataResponse.ok) {
        message.destroy(loadingKey);
        message.success({ content: 'User-submitted data has been cleared', key: successKey });
        setLoading(false);
        setShowClearModal(false);
        // This line clears the cache on all swr calls using a global mutate,
        // except SWRInfinite ones because of a bug in swr
        mutate(() => true);
        // To workaround the bug, we access a "bound mutate" for the comments
        // which use SWRInfinite
        mutateComments();
      } else {
        setLoading(false);
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const openClearModal = () => {
    setShowClearModal(true);
  };

  const closeClearModal = () => {
    setConfirmClearText('');
    setShowClearModal(false);
  };

  const submitClear = () => {
    setConfirmClearText('');
    clearUserData();
  };

  const attemptSubmitClear = () => {
    if (confirmClearText === 'clear') {
      submitClear();
    }
  };

  const handleMenuOption = ({ key }) => {
    switch (key) {
      case 'activate':
        updateSettings({ status: 'active' });
        break;
      case 'lock':
        updateSettings({ status: 'inactive' });
        break;
      case 'hide':
        updateSettings({ status: 'unpublished' });
        break;
      default:
    }
  };

  const getStatusSection = () => {
    let icon = null;
    let text = null;
    let backgroundStyle = null;
    let description = null;
    let defaultItem = null;

    const activate = { label: 'Activate', key: 'activate' };
    const lock = { label: 'Lock', key: 'lock' };
    const hide = { label: 'Hide', key: 'hide' };
    let menuItems;

    // @ts-ignore
    switch (settings.status) {
      case 'active':
      // eslint-disable-next-line default-case-last, no-fallthrough
      default:
        icon = <span className={`${styles.liveIcon} ${styles.statusIcon}`} />;
        text = 'Live';
        backgroundStyle = styles.live;
        description = 'Currently active and accepting responses.';
        menuItems = [hide];
        defaultItem = lock;
        break;
      case 'inactive':
        icon = <LockOutlined className={styles.statusIcon} />;
        text = 'Locked';
        backgroundStyle = styles.locked;
        description = 'While locked, the public can view your engagement but they cannot respond, rate gauges, vote on polls, or like/dislike comments.';
        menuItems = [hide];
        defaultItem = activate;
        break;
      case 'unpublished':
        icon = <EyeInvisibleOutlined className={styles.statusIcon} />;
        text = 'Hidden';
        backgroundStyle = styles.hidden;
        description = 'While hidden, nobody can access this engagement but you or your team.';
        menuItems = [lock];
        defaultItem = activate;
        break;
    }

    return (
      <div>
        <div className={styles.heading}>Status</div>
        <div className={`${styles.statusBlock} ${backgroundStyle}`}>
          {icon}
          {text}
        </div>
        <div className={styles.statusAction}>
          <div className={styles.statusText}>
            {description}
          </div>
          <div>
            <Dropdown.Button
              onClick={() => handleMenuOption(defaultItem)}
              menu={{ items: menuItems, onClick: handleMenuOption }}
              trigger={['click']}
              icon={<DownOutlined />}
              disabled={loading}
            >
              {defaultItem.label}
            </Dropdown.Button>
          </div>
        </div>
      </div>
    );
  };

  if (!settings || isEmptyObject(settings)) {
    return null;
  }
  return (
    <div>
      {getStatusSection()}
      <Divider className={styles.divider} />
      <div className={styles.settingSection}>
        <Switch
          className={styles.switch}
          // @ts-ignore
          checked={settings.email_invite}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) => updateSettings({ email_invite: checked })}
          disabled={loading || !canUseAccessType}
        />
        <div>
          <div className={styles.heading}>
            Email Invites Only
            {canUseAccessType
              ? (
                <Tooltip
                  title="This feature is available with the sayso owner's current team plan."
                >
                  <UnlockOutlined className={styles.settingAccessIcon} />
                </Tooltip>
              )
              : (
                <Tooltip
                  title="The sayso owner must be on an Essential Team plan or higher to use this feature."
                >
                  <LockOutlined className={styles.settingAccessIcon} />
                </Tooltip>
              )}
          </div>
          { /* eslint-disable-next-line max-len */ }
          <div>Users must receive an email invitation to view or participate in the sayso. Contact an admin for creating the invitations.</div>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.settingSection}>
        <Switch
          className={styles.switch}
          // @ts-ignore
          checked={settings.allow_anonymous}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) => updateSettings({ allow_anonymous: checked })}
          disabled={loading}
        />
        <div>
          <div className={styles.heading}>Allow Anonymous Responses</div>
          { /* eslint-disable-next-line max-len */ }
          <div>Let users respond without an account but while still being able to leave an unverified signature.</div>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.settingSection}>
        <Switch
          className={styles.switch}
          // @ts-ignore
          checked={settings.respond_before_results}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) => updateSettings({ respond_before_results: checked })}
          disabled={loading}
        />
        <div>
          <div className={styles.heading}>Discourage Bandwagoning</div>
          { /* eslint-disable-next-line max-len */ }
          <div>{'Don\'t allow users to see how everyone else has voted until after they have submitted their choice. This setting only affects polls and gauges.'}</div>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.heading}>Data Safeguarding Configuration</div>
      <div className={styles.settingItem}>
        <Switch
          className={styles.switch}
          // @ts-ignore
          checked={settings.comment_bin_results_hidden || settings.withhold_all_data}
          checkedChildren={<EyeInvisibleOutlined />}
          unCheckedChildren={<EyeOutlined />}
          onChange={(checked) => updateSettings({ comment_bin_results_hidden: checked })}
          disabled={loading}
        />
        <div>Hide Comment/Filter Counts</div>
      </div>
      <div className={styles.settingItem}>
        <Switch
          className={styles.switch}
          // @ts-ignore
          checked={settings.gauge_results_hidden || settings.withhold_all_data}
          checkedChildren={<EyeInvisibleOutlined />}
          unCheckedChildren={<EyeOutlined />}
          onChange={(checked) => updateSettings({ gauge_results_hidden: checked })}
          disabled={loading}
        />
        <div>Hide Gauge Results</div>
      </div>
      <div className={styles.settingItem}>
        <Switch
          className={styles.switch}
          // @ts-ignore
          checked={settings.poll_results_hidden || settings.withhold_all_data}
          checkedChildren={<EyeInvisibleOutlined />}
          unCheckedChildren={<EyeOutlined />}
          onChange={(checked) => updateSettings({ poll_results_hidden: checked })}
          disabled={loading}
        />
        <div>Hide Poll Results</div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.dangerHeading}>Master Data Safeguard</div>
      <div className={styles.settingSection}>
        <Switch
          className={`${styles.switch} ${styles.dangerSwitch}`}
          // @ts-ignore
          checked={settings.withhold_all_data}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) => updateSettings({ withhold_all_data: checked })}
          disabled={loading}
        />
        <div>Withhold all data including comments</div>
      </div>
      <div>
        <Divider className={styles.divider} />
        <Collapse
          className={styles.collapse}
          ghost
        >
          <Panel header="Preheader/Giveaway" key="preheader">
            <div>
              <div className={styles.heading}>Preheader Main Text</div>
              <div className={styles.preheader}>
                <Text
                  editable={{ onChange: updatePreheaderMainText }}
                >
                  {qutee?.settings?.preheader_main_text}
                </Text>
              </div>
              <div className={styles.heading}>Preheader Sub Text/URL</div>
              <div className={styles.preheader}>
                <Text
                  editable={{ onChange: updatePreheaderSubText }}
                >
                  {qutee?.settings?.preheader_sub_text}
                </Text>
              </div>
              <div className={styles.heading}>Giveaway Terms URL</div>
              <div className={styles.preheader}>
                <Text
                  editable={{ onChange: updateGiveawayUrl }}
                >
                  {qutee?.settings?.giveaway_terms_url}
                </Text>
              </div>
            </div>
          </Panel>
        </Collapse>
        <Divider className={styles.divider} />
        <Collapse
          className={styles.collapse}
          ghost
        >
          <Panel header="Advanced" key="advanced">
            <div>
              <div className={styles.heading}>Sayso Title</div>
              <div className={styles.title}>
                <Text
                  editable={{ onChange: updateTitle }}
                  className={qutee?.title ? undefined : styles.addTitle}
                >
                  {qutee?.title || titleAddPlaceholder}
                </Text>
              </div>
              <div className={styles.heading}>Chat Bot ID</div>
              <div className={styles.bot}>
                <Text
                  editable={{
                    onChange: (value) => updateSettings({ chat_bot_id: value })
                  }}
                  className={qutee?.settings?.chat_bot_id ? undefined : styles.addBotId}
                >
                  {qutee?.settings?.chat_bot_id}
                </Text>
              </div>
              {userState.role === 'admin' && (
                <>
                  <div className={styles.heading}>Brand Identifier</div>
                  <div className={styles.brand}>
                    <Text
                      editable={{ onChange: updateBrand }}
                    >
                      {qutee?.settings?.brand || 'social-asking'}
                    </Text>
                  </div>
                </>
              )}
              {userState.role === 'admin' && (
                <Button
                  className={styles.clear}
                  type="primary"
                  danger
                  disabled={loading}
                  onClick={() => openClearModal()}
                >
                  Clear User Data
                </Button>
              )}
            </div>
          </Panel>
        </Collapse>
        <Modal
          open={showClearModal}
          onCancel={() => closeClearModal()}
          footer={null}
        >
          <div>
            <span className={styles.warning}>WARNING: </span>
            <span>
              This will remove all poll ballots and gauge ratings from the sayso,
              as well as all comments from every question, except pinned comments.
            </span>
          </div>
          <div>
            To confirm this is what you want, type &quot;clear&quot; in the input below
            and then press confirm.
          </div>
          <Input
            ref={inputRef}
            className={styles.clearInput}
            value={confirmClearText}
            onChange={(e) => setConfirmClearText(e.target.value)}
            onPressEnter={() => attemptSubmitClear()}
            autoFocus
          />
          <Button
            className={styles.clearButton}
            type="primary"
            disabled={loading || (confirmClearText !== 'clear')}
            onClick={() => submitClear()}
          >
            Confirm
          </Button>
        </Modal>
      </div>
    </div>
  );
}

export default QuteeGeneralSettings;
