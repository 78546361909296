import React, { useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  Result, Button
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { subject } from '@casl/ability';
import config from '../../common/infra/env.config';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException } from '../../common/utils';
import useAnonymousHandler from '../../auth/state/useAnonymousHandler';
import useQutee from '../../qutee/state/useQutee';
import useQuteeSummary from '../../qutee/state/useQuteeSummary';
import useQuteeCommentBins from '../../qutee/state/useQuteeCommentBins';
import Quteev1 from '../../qutee/components/Quteev1';
import SAJoyride from '../../qutee/components/SAJoyride';
import LogInBanner from '../../auth/components/LogInBanner';
import AuthForm from '../../auth/components/AuthForm';
import BannerImage from '../../common/view/BannerImage';

// This component is for connecting the qutee component with the App's business logic
function Quteev1Container({
  quteeId, hideGauges, hidePolls, hideBanners, useSimpleGauge, gaugeType, resetTourHandler,
  setResetTourHandler, isMainEngagement, setAllowAnonymous, hideBannerImage,
  hasInviteCode
}) {
  const { state: userState } = useUserContext();
  const continueAnonymously = useAnonymousHandler();
  const [commentDialogState, commentDialogStateHandler] = useState('none');
  const [previewDialogState, previewDialogStateHandler] = useState('start');
  const [joyrideIndex, setJoyrideIndex] = useState(0);
  const [repositionJoyride, setRepositionJoyride] = useState(false);

  const {
    qutee,
    mutate: quteeMutate,
    isLoading: isLoadingQutee,
    isError: isErrorQutee
  } = useQutee(quteeId, userState.isLoggedIn);

  const canView = userState.isLoggedIn && userState.isVerified
    && userState.ability.can('view', subject('Qutee', qutee));
  const canEdit = userState.isLoggedIn && userState.isVerified
    && userState.ability.can('update', subject('Qutee', qutee));

  const allowAnonymous = qutee
    ? (qutee.settings && !qutee.settings.prevent_anonymous_response)
    : true;
  const isInactive = qutee && qutee.status === 'inactive';

  useEffect(() => {
    if (isMainEngagement) {
      setAllowAnonymous(allowAnonymous);
    }
  }, [allowAnonymous, isMainEngagement, setAllowAnonymous]);

  const {
    summary
  } = useQuteeSummary(qutee);
  const {
    commentBins,
    isLoading: isLoadingCommentBins,
    mutate: mutateCommentBins,
    isError: isErrorCommentBins
  } = useQuteeCommentBins(quteeId, userState.isLoggedIn ? userState.profile.profile_id : null);

  const { post: postGauge, response: postGaugeResponse } = useFetch('gauges', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const { post: postPoll, response: postPollResponse } = useFetch('polls', {
    // @ts-ignore
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const { get: loadReport, response: loadReportResponse } = useFetch('comment-bins', { cachePolicy: CachePolicies.NO_CACHE });
  const { get: loadLatestReport, response: loadLatestReportResponse } = useFetch('comment-bins', { cachePolicy: CachePolicies.NO_CACHE });
  const { get: loadReportProgress, response: loadReportProgressResponse } = useFetch('comment-bins', { cachePolicy: CachePolicies.NO_CACHE });
  const { post: postGenerateReport, response: postGenerateReportResponse } = useFetch('comment-bins', { cachePolicy: CachePolicies.NO_CACHE });

  const getLatestReport = async (commentBinId) => {
    const response = await loadLatestReport(`${commentBinId}/reports/latest`);
    if (loadLatestReportResponse.ok) {
      return response;
    }
    throw new ApiException(response);
  };

  const getReport = async (commentBinId, reportId) => {
    const response = await loadReport(`${commentBinId}/reports/${reportId}`);
    if (loadReportResponse.ok) {
      return response;
    }
    throw new ApiException(response);
  };

  const getReportProgress = async (commentBinId, reportProgressId) => {
    const response = await loadReportProgress(`${commentBinId}/report-progress/${reportProgressId}`);
    if (loadReportProgressResponse.ok) {
      return response;
    }
    throw new ApiException(response);
  };

  const generateReport = async (commentBinId) => {
    const response = await postGenerateReport(`${commentBinId}/reports`);
    if (postGenerateReportResponse.ok) {
      return response;
    }
    throw new ApiException(response);
  };

  const createGauge = async (quteeId, title, lowLabel, highLabel) => {
    const gaugeData = {
      qutee_id: quteeId,
      title,
      low_label: lowLabel,
      high_label: highLabel
    };

    const newGauge = await postGauge('', gaugeData);
    if (postGaugeResponse.ok) {
      quteeMutate();
      return newGauge;
    }
    throw new ApiException(newGauge);
  };

  const createPoll = async (quteeId, { title, options, multiselect }) => {
    const pollData = {
      qutee_id: quteeId,
      title,
      options,
      ballot_choice: multiselect ? 'multiple' : 'single'
    };

    const newPoll = await postPoll('', pollData);
    if (postPollResponse.ok) {
      quteeMutate();
      return newPoll;
    }
    throw new ApiException(newPoll);
  };

  const triggerRepositionJoyride = () => {
    setRepositionJoyride((value) => !value);
  };

  const hasError = () => isErrorQutee || isErrorCommentBins;

  const openHelpDesk = () => {
    const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const canUserInteract = () => {
    if (userState.isPending) {
      return false;
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return false;
    }
    if (!canView && isInactive) {
      return false;
    }
    return true;
  };

  const getCantInteractReason = () => {
    if (userState.isPending) {
      return 'You must verify your email before responding.';
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return 'Anonymous users are not allowed to respond to this engagement.';
    }
    if (!canView && isInactive) {
      return 'This engagement is locked and not accepting responses.';
    }
    return '';
  };

  const err = hasError();
  if (err) {
    console.log(err);
    let errTitle = err.message;
    let errSubtitle = err?.details?.issue;
    if (err.status === 404) {
      errTitle = 'An engagement with the configured ID does not exist.';
      errSubtitle = 'If you are the engagement owner, please configure the embed with a valid engagement ID, or contact support.';
    }
    if (err.status === 429) {
      errTitle = 'You have exceeded your rate limit.';
      errSubtitle = 'Please try again later.';
    }
    if (err.status === 404 && !userState.isLoggedIn) {
      if (hasInviteCode) {
        return null;
      }
      return (
        <div style={{ margin: 'auto', maxWidth: 600 }}>
          <AuthForm startingTab="signin" />
        </div>
      );
    }
    return (
      <>
        {!hideBanners && (
          <LogInBanner
            showLogout={!!userState.profile}
            quteeOwnerRole={qutee?.profile?.account_type}
          />
        )}
        <Result
          className="qutee--error"
          status="error"
          icon={<WarningOutlined />}
          title={errTitle}
          subTitle={errSubtitle}
          extra={[
            <Button type="primary" key="support" onClick={() => openHelpDesk()}>
              Get Support
            </Button>
          ]}
        />
      </>
    );
  }

  return (
    <>
      <SAJoyride
        qutee={qutee}
        userState={userState}
        setResetTourHandler={setResetTourHandler}
        setJoyrideIndex={setJoyrideIndex}
        joyrideIndex={joyrideIndex}
        commentDialogStateHandler={commentDialogStateHandler}
        repositionJoyride={repositionJoyride}
        previewDialogStateHandler={previewDialogStateHandler}
      />
      {!hideBannerImage && <BannerImage quteeId={quteeId} />}
      <Quteev1
        quteeId={qutee?.qutee_id}
        quteeTitle={qutee?.title}
        commentBins={commentBins}
        isLoadingCommentBins={isLoadingCommentBins}
        mutateCommentBins={mutateCommentBins}
        getReport={getReport}
        getLatestReport={getLatestReport}
        getReportProgress={getReportProgress}
        generateReport={generateReport}
        createGauge={createGauge}
        createPoll={createPoll}
        userCanInteract={canUserInteract()}
        cantInteractReason={getCantInteractReason()}
        userCanEditQutee={canEdit}
        labelEditingDisabled={userState.role !== 'admin' && summary?.comment_count > 0}
        checkUserCanEditComment={(comment) => userState.ability.can('update', subject('Comment', comment))}
        allowAnonymous={allowAnonymous}
        tourHandler={resetTourHandler}
        hideBanners={hideBanners}
        hideGauges={hideGauges}
        hidePolls={hidePolls}
        useSimpleGauge={useSimpleGauge}
        gaugeType={gaugeType}
        continueAnonymously={continueAnonymously}
        pollCount={qutee?.polls?.length}
        gaugeCount={qutee?.gauges?.length}
        quteeOwnerId={qutee?.profile?.profile_id}
        quteeOwnerName={qutee?.profile?.display_name}
        quteeOwnerRole={qutee?.profile?.account_type}
        giveawayUrl={qutee?.settings?.giveaway_terms_url}
        setJoyrideIndex={setJoyrideIndex}
        commentDialogStateHandler={commentDialogStateHandler}
        commentDialogState={commentDialogState}
        previewDialogState={previewDialogState}
        triggerRepositionJoyride={triggerRepositionJoyride}
      />
    </>
  );
}

Quteev1Container.defaultProps = {
  liveFrequency: 30,
  hideBanners: false,
  hideGauges: false,
  hidePolls: false,
  useSimpleGauge: false,
  isMainEngagement: false,
  setAllowAnonymous: null,
  hasInviteCode: false
};

export default Quteev1Container;
