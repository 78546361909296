import React, { useState, useEffect } from 'react';
import { App, Typography, Tooltip } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import styles from '../styles/PollTitle.module.scss';

const { Title } = Typography;

function PollTitle({
  pollId, title, isEditing, lockEdits, isDisabled,
  isLoading, setLoading, onUpdateTitle, isMultipleChoice
}) {
  const { message } = App.useApp();
  const [text, changeText] = useState(title);

  useEffect(() => {
    changeText(title);
  }, [title]);

  const savePollTitle = async (newText) => {
    const loadingKey = 'updateTitleRequest';
    const successKey = 'updateTitleSuccess';
    const errorKey = 'updateTitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating poll title...', key: loadingKey });
    try {
      await onUpdateTitle(pollId, newText);
      changeText(newText);
      message.destroy(loadingKey);
      message.success({ content: 'Title successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const showTooltip = (inner) => ((
    <Tooltip
      mouseEnterDelay={0.3}
      title="This cannot be changed after the poll has been published and received any votes"
    >
      {inner}
    </Tooltip>
  ));

  const renderTitleEditIcon = () => (
    <EditTwoTone className={`${styles.edit} ${styles.disabled}`} />
  );

  return (
    <div className={styles.container}>
      <Title
        level={4}
        className={`${styles.title} ${isDisabled ? styles.disabled : ''}`}
        editable={!isDisabled && !isLoading && isEditing && !lockEdits
          ? { onChange: savePollTitle }
          : false}
      >
        {text}
      </Title>
      {isEditing && lockEdits && showTooltip(renderTitleEditIcon())}
      <div>
        <span className={styles.choice}>
          {isMultipleChoice ? 'Select all that apply' : 'Choose one'}
        </span>
      </div>
    </div>
  );
}

PollTitle.displayName = 'PollTitle';
PollTitle.defaultProps = {
  isEditing: false,
  lockEdits: false,
  isDisabled: false,
  onUpdate: () => true
};

export default PollTitle;
