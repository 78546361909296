import React, { useContext, useEffect, useState } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { Skeleton } from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import useAnonymousHandler from '../../auth/state/useAnonymousHandler';
import useQuteeGauges from '../state/useQuteeGauges';
import GaugeContainer from '../../embed/components/GaugeContainer';
import SwipeGaugeList from '../../gauge/components/SwipeGaugeList';
import styles from '../styles/QuteeGauges.module.scss';

function QuteeGauges({
  quteeId, useSimple, gaugeType, hideUserRating, afterRateHook,
  shouldRefreshGauges, setShouldRefreshGauges,
  getExtraContent, hideGaugeTitle, setShowContinueOnRatings,
  triggerGaugePinpoint, setTriggerGaugePinpoint, pinpointSection,
  progress, progressGaugeRef
}) {
  const { state: userState } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const [gauges, setGauges] = useState([]);
  // ratings is derived from gauges and must maintain the same content order
  const [ratings, setRatings] = useState([]);
  // scores is derived from gauges and must maintain the same content order
  const [scores, setScores] = useState([]);
  const continueAnonymously = useAnonymousHandler();

  const {
    gauges: gaugeData,
    mutate: gaugesMutate,
    isLoading,
    isLoadingInitial,
    isValidating
  } = useQuteeGauges(quteeId, userState.profile);

  useEffect(() => {
    if (!gaugeData || !gaugeData.length) {
      return;
    }
    const gaugeList = [];
    const ratingList = [];
    const scoreList = [];
    gaugeData.forEach((g) => {
      const { score = null, rating = null, ...gauge } = g;
      gaugeList.push(gauge);
      ratingList.push(rating);
      scoreList.push(score);
    });
    if (gaugeType === 'swipe' && embedConfig.firstSwipeGauge) {
      const index = gaugeList.findIndex(
        (gauge) => gauge.gauge_id === embedConfig.firstSwipeGauge
      );
      if (index > -1) {
        gaugeList.unshift(gaugeList.splice(index, 1)[0]);
        ratingList.unshift(ratingList.splice(index, 1)[0]);
        scoreList.unshift(scoreList.splice(index, 1)[0]);
      }
    }
    setGauges((prev) => {
      if (equal(prev, gaugeList)) {
        return prev;
      }
      return gaugeList;
    });
    setRatings((prev) => {
      if (equal(prev, ratingList)) {
        return prev;
      }
      return ratingList;
    });
    setScores((prev) => {
      if (equal(prev, scoreList)) {
        return prev;
      }
      return scoreList;
    });
  }, [gaugeData]);

  useEffect(() => {
    if (shouldRefreshGauges) {
      setShouldRefreshGauges(false);
      gaugesMutate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGauges]);

  const getFirstUnfinished = () => {
    const unfinishedIds = new Set(progress?.gauges
      ?.filter((gauge) => gauge.responded === false)
      ?.map((gauge) => gauge.gauge_id));
    const first = gauges?.find((gauge) => unfinishedIds.has(gauge.gauge_id))?.gauge_id;
    return first;
  };
  const firstUnfinished = getFirstUnfinished();

  useEffect(() => {
    if (triggerGaugePinpoint) {
      if ((typeof pinpointSection === 'function')
      && progressGaugeRef.current) {
        pinpointSection(progressGaugeRef, -20, 0);
      }
      if (typeof setTriggerGaugePinpoint === 'function') {
        setTriggerGaugePinpoint(false);
      }
    }
  }, [triggerGaugePinpoint, setTriggerGaugePinpoint,
    pinpointSection, progressGaugeRef]);

  const getGaugeComponents = () => {
    const components = [];
    gauges.forEach((gauge, index) => {
      const rating = ratings[index];
      const score = scores[index];
      components.push((
        <div key={gauge.gauge_id} className={styles.spacer}>
          {(typeof getExtraContent === 'function')
            ? (
              <div ref={(ref) => {
                ref?.replaceChildren(); ref?.appendChild(getExtraContent(gauge.gauge_id));
              }}
              />
            )
            : null}
          <GaugeContainer
            externalRef={firstUnfinished === gauge.gauge_id ? progressGaugeRef : undefined}
            key={gauge.gauge_id}
            gaugeId={gauge.gauge_id}
            useSimple={useSimple}
            hideUserRating={hideUserRating}
            hideTitle={hideGaugeTitle}
            afterRateHook={afterRateHook}
            initialGauge={gauge}
            initialRating={rating}
            initialScore={score}
            isLoadingInitial={isLoading}
            setShouldRefreshGauges={setShouldRefreshGauges}
          />
        </div>
      ));
    });
    return components;
  };

  if ((!gauges || !gauges.length) && isLoading) {
    return <Skeleton active />;
  }

  if (gaugeType === 'swipe') {
    return (
      <div>
        <SwipeGaugeList
          quteeId={quteeId}
          gauges={gauges}
          ratings={ratings}
          scores={scores}
          mutateGauges={gaugesMutate}
          hideUserRating={hideUserRating}
          hideTitle={hideGaugeTitle}
          afterRateHook={afterRateHook}
          isLoadingInitial={isLoadingInitial}
          isValidating={isValidating}
          setShouldRefreshGauges={setShouldRefreshGauges}
          continueAnonymously={continueAnonymously}
          setShowContinueOnRatings={setShowContinueOnRatings}
          nextUnfinishedId={firstUnfinished}
          progressGaugeRef={progressGaugeRef}
        />
      </div>
    );
  }

  if (gauges) {
    return (
      <div className={styles.container}>
        {getGaugeComponents()}
      </div>
    );
  }

  return null;
}

QuteeGauges.displayName = 'Qutee Gauges';
QuteeGauges.defaultProps = {
  useSimple: true,
  hideUserRating: false,
  afterRateHook: null,
  shouldRefreshGauges: false,
  setShouldRefreshGauges: () => {},
  getExtraContent: null,
  hideGaugeTitle: false,
  setShowContinueOnRatings: undefined,
  triggerGaugePinpoint: undefined,
  setTriggerGaugePinpoint: undefined,
  pinpointSection: undefined,
  progress: undefined,
  progressGaugeRef: undefined
};

export default QuteeGauges;
