/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { App } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import PollOption from './PollOption';
import PollAddOption from './PollAddOption';
import styles from '../styles/PollOptionList.module.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function PollOptionList({
  pollId, options, selectedOptionIds, totalBallots, onlyResults,
  isEditing, lockEdits, showResults, disableVoteMessage,
  isDisabled, disabledOptionTooltipText, isLoading, setLoading,
  onAddOption, onRepositionOption, onRemoveOption,
  onUpdateOption, onSelectOption, onDeselectOption,
  afterVoteHook, isLoadingBallot, mutateProgress, isMultipleChoice
}) {
  const { message } = App.useApp();
  const [pollOptions, setPollOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(
    selectedOptionIds ? [...selectedOptionIds] : []
  );
  const optionsRef = useRef(pollOptions);

  const updateOptions = (newOptions) => {
    optionsRef.current = newOptions;
    setPollOptions(newOptions);
  };

  useEffect(() => {
    updateOptions(options);
  }, [options]);

  useEffect(() => {
    setSelectedOptions(selectedOptionIds ? [...selectedOptionIds] : []);
  }, [selectedOptionIds, setSelectedOptions]);

  const selectOption = (optionId) => {
    const selected = [...selectedOptions];
    selected.push(optionId);
    setSelectedOptions(selected);
    if ((selected.length === 1) && (typeof mutateProgress === 'function')) {
      mutateProgress();
    }
  };

  const deselectOption = (optionId) => {
    const selected = [...selectedOptions];
    const index = selected.indexOf(optionId);
    if (index > -1) {
      selected.splice(index, 1);
    }
    setSelectedOptions(selected);
    if (!selected.length && (typeof mutateProgress === 'function')) {
      mutateProgress();
    }
  };

  const dragDisabled = () => isDisabled || isLoading || !isEditing;

  // Must be synchronous for the reordering logic
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      optionsRef.current,
      result.source.index,
      result.destination.index
    );
    updateOptions(items);

    // API update here can be asynchronous
    const execApi = async (optionId, index) => {
      const loadingKey = 'repositionOptionRequest';
      const successKey = 'repositionOptionSuccess';
      const errorKey = 'repositionOptionError';
      message.destroy(loadingKey);
      message.destroy(successKey);
      message.destroy(errorKey);

      setLoading(true);
      message.loading({ content: 'Repositioning poll option...', key: loadingKey });
      try {
        await onRepositionOption(pollId, optionId, index);
        message.destroy(loadingKey);
        message.success({ content: 'Option successfully repositioned', key: successKey });
      } catch (err) {
        message.destroy(loadingKey);
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      } finally {
        setLoading(false);
      }
    };
    // draggableId is a string version of optionId
    execApi(parseInt(draggableId, 10), destination.index);
  };

  const removeOption = async (pollId, optionId) => {
    const items = optionsRef.current.filter((option) => option.id !== optionId);
    updateOptions(items);

    const loadingKey = 'removeOptionRequest';
    const successKey = 'removeOptionSuccess';
    const errorKey = 'removeOptionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Removing poll option...', key: loadingKey });
    try {
      await onRemoveOption(pollId, optionId);
      message.destroy(loadingKey);
      message.success({ content: 'Option successfully removed', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const addOption = async (pollId, text) => {
    const loadingKey = 'addOptionRequest';
    const successKey = 'addOptionSuccess';
    const errorKey = 'addOptionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    const exists = optionsRef.current.filter((option) => option.text === text);
    if (exists.length) {
      message.error({ content: 'You cannot have duplicate poll options.', key: errorKey, duration: 5 });
      return;
    }
    // The id should get overwritten by the API.
    const newOption = {
      id: text,
      text,
      count: 0
    };

    const items = Array.from(optionsRef.current);
    items.push(newOption);
    updateOptions(items);

    setLoading(true);
    message.loading({ content: 'Adding poll option...', key: loadingKey });
    try {
      await onAddOption(pollId, text);
      message.destroy(loadingKey);
      message.success({ content: 'Option successfully added', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const checkSelected = (optionId) => selectedOptions.includes(optionId);

  const sortOptions = () => {
    // Sort by count descending when showing results
    if (showResults) {
      return [...optionsRef.current].sort((a, b) => ((a.count > b.count) ? -1 : 1));
    }
    return [...optionsRef.current];
  };

  const renderPollOption = (option, index) => (
    <PollOption
      pollId={pollId}
      index={index}
      option={option}
      totalBallots={totalBallots}
      onlyResults={onlyResults}
      disableVoteMessage={disableVoteMessage}
      isSelected={checkSelected(option.id)}
      selectOption={selectOption}
      deselectOption={deselectOption}
      isEditMode={isEditing}
      lockEdits={lockEdits}
      showResults={showResults}
      isDisabled={isDisabled}
      disabledOptionTooltipText={disabledOptionTooltipText}
      isLoading={isLoading}
      isLoadingBallot={isLoadingBallot}
      setLoading={setLoading}
      removeOption={removeOption}
      onUpdateOption={onUpdateOption}
      onSelectOption={onSelectOption}
      onDeselectOption={onDeselectOption}
      afterVoteHook={afterVoteHook}
      isMultipleChoice={isMultipleChoice}
    />
  );

  const renderPollList = () => {
    if (isEditing) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${pollId}-poll-options`}>
            {(provided) => (
              <div
                className={styles.container}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {optionsRef.current.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={`${option.id}`}
                    index={index}
                    isDragDisabled={dragDisabled()}
                  >
                    {(provided) => (
                      <div
                        className={styles.dragContainer}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className={styles.dragHandle} {...provided.dragHandleProps}>
                          {!dragDisabled() && Array.from(Array(2), (_, i) => (
                            <MoreOutlined key={i} className={styles.dragIcon} />
                          ))}
                        </div>
                        {renderPollOption(option, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }

    return (
      <Flipper
        flipKey={showResults}
        className={styles.container}
      >
        {sortOptions().map((option, index) => (
          <Flipped key={option.id} flipId={option.id}>
            <div>
              {renderPollOption(option, index)}
            </div>
          </Flipped>
        ))}
      </Flipper>
    );
  };

  return (
    <>
      {renderPollList()}
      {isEditing
        && (
        <PollAddOption
          pollId={pollId}
          isEditing={isEditing}
          lockEdits={lockEdits}
          isDisabled={isDisabled}
          isLoading={isLoading}
          addOption={addOption}
        />
        )}
    </>
  );
}

PollOptionList.displayName = 'PollOptionList';
PollOptionList.defaultProps = {
  onlyResults: false,
  isEditing: false,
  lockEdits: false,
  showResults: false,
  isDisabled: false,
  afterVoteHook: null,
  disableVoteMessage: false,
  isLoadingBallot: false
};

export default PollOptionList;
