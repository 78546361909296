import React, { useState } from 'react';
import { subject } from '@casl/ability';
import useQutee from '../../qutee/state/useQutee';
import { useUserContext } from '../../user/providers/UserProvider';
import LogInBanner from '../../auth/components/LogInBanner';
import AnonymousBanner from './AnonymousBanner';
import VerificationBanner from './VerificationBanner';
import UpgradeBanner from '../../auth/components/UpgradeBanner';
import EngagementStatusBanner from './EngagementStatusBanner';
import SharingPermissionsModal from '../../user/components/SharingPermissionsModal';
import styles from './styles/BannerBundle.module.scss';

function BannerBundle({
  allowAnonymous, resetTourHandler, quteeId, setShowSettingsModal
}) {
  const { state: userState } = useUserContext();
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  const {
    qutee
  } = useQutee(quteeId, userState.isLoggedIn);

  const canEditQutee = !!qutee && userState.isLoggedIn && userState.isVerified
    && userState.ability.can('update', subject('Qutee', qutee));

  // Auth banner and anonymous banner are mutually exclusive; one will be visible
  const getAuthBanner = () => {
    if (!allowAnonymous || (allowAnonymous && userState.isLoggedIn)) {
      return (
        <LogInBanner
          showLogout={!!userState.profile}
          isAnonymous={userState.isAnonymous}
          canUseGuide={canEditQutee && (resetTourHandler !== null)}
          tourHandler={resetTourHandler}
          setShowPermissionsModal={setShowPermissionsModal}
          quteeOwnerRole={qutee?.profile?.account_type}
        />
      );
    }
    return null;
  };

  const getAnonymousBanner = () => {
    if (userState.isPreFirstLoad) {
      return null;
    }
    if (allowAnonymous && !userState.isLoggedIn) {
      return (
        <div>
          <AnonymousBanner />
        </div>
      );
    }
    return null;
  };

  // Any of the other banners could potentially be visible
  const getVerificationBanner = () => {
    if (userState.isPreFirstLoad) {
      return null;
    }
    if (userState.isLoggedIn && userState.isPending) {
      return (
        <div className={styles.sometimesVisible}>
          <VerificationBanner />
        </div>
      );
    }
    return null;
  };

  const getUpgradeBanner = () => {
    if (userState.isPreFirstLoad) {
      return null;
    }
    if (userState.isLoggedIn && userState.isAnonymous) {
      let text = null;
      if (!allowAnonymous) {
        text = 'This engagement is not accepting responses from anonymous users. Complete the signup to respond.';
      }
      return (
        <div className={styles.sometimesVisible}>
          <UpgradeBanner upgradeMessage={text} />
        </div>
      );
    }
    return null;
  };

  const getStatusBanner = () => {
    if (userState.isPreFirstLoad || !qutee) {
      return null;
    }

    return (
      <div className={styles.sometimesVisible}>
        <EngagementStatusBanner
          status={qutee.status}
          canEditQutee={canEditQutee}
          setShowSettingsModal={setShowSettingsModal}
        />
      </div>
    );
  };

  return (
    <div>
      {getAnonymousBanner()}
      {getAuthBanner()}
      {getVerificationBanner()}
      {getUpgradeBanner()}
      {getStatusBanner()}
      <SharingPermissionsModal
        visible={showPermissionsModal}
        setVisible={setShowPermissionsModal}
        requestingProfileId={qutee?.profile?.profile_id}
        requestingProfileName={qutee?.profile?.display_name}
        firstTime={false}
        giveawayTerms={qutee?.settings?.giveaway_terms_url}
      />
    </div>
  );
}

BannerBundle.defaultProps = {
  allowAnonymous: false,
  resetTourHandler: null,
  setShowSettingsModal: undefined
};

export default BannerBundle;
