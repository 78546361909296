import useSWRInfinite from 'swr/infinite';
import { swrFetcher } from '../../common/infra/fetcher';

function useTopics(commentBinId, filter, profileId) {
  const pageSize = 40;

  const getKey = (index) => {
    let query = '';
    if (filter) {
      query += `&${filter}`;
    }
    query += `&sort=starred:desc&page=${index + 1}&limit=${pageSize}`;
    if (query.length) {
      query = query.substring(1);
      query = `?${query}`;
    }

    return commentBinId
      ? [`comment-bins/${commentBinId}/topics${query}`, profileId]
      : null;
  };

  const {
    data, error, mutate, size, setSize, isLoading, isValidating
  } = useSWRInfinite(getKey, (url, opts) => swrFetcher(url, opts));

  const flattenResults = () => {
    if (!data) return null;

    // First, map data to get a 2d-array consisting of only the results (and not page info)
    // then concatenate the inner arrays to flatten it
    return [].concat(...(data.map((x) => x.results)));
  };

  const checkLoadingInitial = () => isLoading;
  const checkLoadingMore = () => checkLoadingInitial() || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const checkEmpty = () => data?.[0]?.results?.length === 0;
  const checkReachingEnd = () => checkEmpty()
    || (data && data[data.length - 1]?.results?.length < pageSize);
  const checkRefreshing = () => isValidating && data && data.length === size;

  return {
    topics: flattenResults(),
    mutate,
    isLoadingInitial: checkLoadingInitial(),
    isLoadingMore: checkLoadingMore(),
    isEmpty: checkEmpty(),
    isReachingEnd: checkReachingEnd(),
    isRefreshing: checkRefreshing(),
    isValidating,
    isError: error,
    size,
    setSize
  };
}

export default useTopics;
