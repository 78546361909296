import React, { useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  Button, Divider, Form, Input, App, Popconfirm, Select, Table
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ApiException } from '../../common/utils';
import useQuteePermissions from '../state/useQuteePermissions';
import styles from '../styles/QuteeTeamSettings.module.scss';

function QuteeTeamSettings({ quteeId }) {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [accessLevel, setAccessLevel] = useState('view');
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();

  const {
    permissions,
    mutate: permissionsMutate,
    isLoading: loadingPermissions
  } = useQuteePermissions(quteeId);

  const { post: postPermission, response: postPermissionResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { patch: patchPermissionRequest, response: patchPermissionResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const { delete: deletePermissionRequest, response: deletePermissionResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const resetForm = () => {
    form.resetFields();
    setAccessLevel('view');
    setEmail('');
  };

  const createPermission = async (userAccess, userEmail) => {
    const data = {
      access_level: userAccess,
      email: userEmail
    };

    const newPermission = await postPermission(`${quteeId}/permissions`, data);
    if (postPermissionResponse.ok) {
      permissionsMutate();
      return newPermission;
    }
    throw new ApiException(newPermission);
  };

  const patchPermission = async (profileId, userAccess) => {
    const data = {
      access_level: userAccess
    };
    const updated = await patchPermissionRequest(`${quteeId}/permissions/${profileId}`, data);
    if (patchPermissionResponse.ok) {
      permissionsMutate();
      return;
    }
    throw new ApiException(updated);
  };

  const deletePermission = async (profileId) => {
    const deleted = await deletePermissionRequest(`${quteeId}/permissions/${profileId}`);
    if (deletePermissionResponse.ok) {
      permissionsMutate();
      return;
    }
    throw new ApiException(deleted);
  };

  const addPermission = async () => {
    const loadingKey = 'addPermissionRequest';
    const successKey = 'addPermissionSuccess';
    const errorKey = 'addPermissionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Adding permission for user...', key: loadingKey });
    try {
      await createPermission(accessLevel, email);
      message.destroy(loadingKey);
      message.success({ content: 'Permission successfully added', key: successKey });
      resetForm();
      setLoading(false);
    } catch (err) {
      message.destroy(loadingKey);
      if (err.details?.issue) {
        message.error({ content: err.details.issue, key: errorKey, duration: 5 });
      } else {
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      }
      setLoading(false);
    }
  };

  const updatePermission = async (profileId, userAccess) => {
    const loadingKey = 'updatePermissionRequest';
    const successKey = 'updatePermissionSuccess';
    const errorKey = 'updatePermissionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating permission for user...', key: loadingKey });
    try {
      await patchPermission(profileId, userAccess);
      message.destroy(loadingKey);
      message.success({ content: 'Permission successfully updated', key: successKey });
      setLoading(false);
    } catch (err) {
      message.destroy(loadingKey);
      if (err.details?.issue) {
        message.error({ content: err.details.issue, key: errorKey, duration: 5 });
      } else {
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      }
      setLoading(false);
    }
  };

  const removePermission = async (profileId) => {
    const loadingKey = 'removePermissionRequest';
    const successKey = 'removePermissionSuccess';
    const errorKey = 'removePermissionError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Removing permission for user...', key: loadingKey });
    try {
      await deletePermission(profileId);
      message.destroy(loadingKey);
      message.success({ content: 'Permission successfully removed', key: successKey });
      setLoading(false);
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      setLoading(false);
    }
  };

  const changeAccessLevel = (value) => {
    setAccessLevel(value);
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const dataSource = permissions ? permissions.map((permission) => ({
    key: permission.email,
    ...permission
  })) : null;

  const tableColumns = [
    {
      title: 'access level',
      dataIndex: 'access_level',
      render: (_, record) => (
        <Select
          className={styles.select}
          defaultValue={record.access_level}
          value={record.access_level}
          disabled={loading}
          onChange={(value) => updatePermission(record.profile_id, value)}
          options={[
            {
              value: 'view',
              label: 'View'
            },
            {
              value: 'edit',
              label: 'Edit'
            }
          ]}
        />
      )
    },
    {
      title: 'display name',
      dataIndex: 'display_name'
    },
    {
      title: 'email',
      dataIndex: 'email'
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => (dataSource.length >= 1 ? (
        <Popconfirm
          title="Delete this permission?"
          onConfirm={() => removePermission(record.profile_id)}
          okText="Delete"
          okButtonProps={{ className: styles.confirmDelete }}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button
            type="link"
          >
            Delete
          </Button>
        </Popconfirm>
      ) : null)
    }
  ];

  return (
    <div>
      <Form form={form} onFinish={() => addPermission()}>
        <Form.Item>
          <div>
            <span>Access level:</span>
            <Select
              className={styles.select}
              defaultValue="view"
              value={accessLevel}
              onChange={changeAccessLevel}
              options={[
                {
                  value: 'view',
                  label: 'View'
                },
                {
                  value: 'edit',
                  label: 'Edit'
                }
              ]}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div>
            <span>Email:</span>
            <Input
              defaultValue=""
              value={email}
              onChange={changeEmail}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            Add Permission
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Table
        bordered
        dataSource={dataSource}
        columns={tableColumns}
        loading={loadingPermissions}
      />
    </div>
  );
}

export default QuteeTeamSettings;
