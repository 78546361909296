import React, { useContext, useEffect, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { Skeleton, Result } from 'antd';
import { subject } from '@casl/ability';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException } from '../../common/utils';
import EmbedContext from '../providers/EmbedProvider';
import useAnonymousHandler from '../../auth/state/useAnonymousHandler';
import useQuteeProgress from '../../qutee/state/useQuteeProgress';
import useGauge from '../../gauge/state/useGauge';
import useGaugeLatestScore from '../../gauge/state/useGaugeLatestScore';
import useGaugeTally from '../../gauge/state/useGaugeTally';
import useMyGaugeRating from '../../gauge/state/useMyGaugeRating';
import useFirstLoadGauge from '../../gauge/state/useFirstLoadGauge';
import Gauge from '../../gauge/components/Gauge';
import SimpleGauge from '../../gauge/components/SimpleGauge';

// This component is for connecting the gauge component with the App's business logic
function GaugeContainer({
  gaugeId, hideTitle, useSimple, hideUserRating, afterRateHook,
  isMainEngagement, setAllowAnonymous, initialGauge,
  initialRating, initialScore, isLoadingInitial,
  setShouldRefreshGauges, externalRef
}) {
  const { state: userState } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const continueAnonymously = useAnonymousHandler();
  const [useFirst, setUseFirst] = useState(true);
  const [gaugeType, setGaugeType] = useState('dial');

  const {
    gauge: firstLoadGauge
  } = useFirstLoadGauge(
    gaugeId,
    initialGauge,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const {
    gauge,
    mutate: gaugeMutate,
    isLoading: isLoadingGauge,
    isError: isErrorGauge
  } = useGauge(
    gaugeId,
    initialGauge,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const {
    myRating,
    mutate: ratingMutate,
    isLoading: isLoadingMyRating
  } = useMyGaugeRating(
    gaugeId,
    initialRating,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const canView = userState.isVerified && userState.ability.can('view', subject('Gauge', gauge));
  const canEdit = userState.isVerified && userState.ability.can('update', subject('Gauge', gauge));

  const hideScoreSetting = !canView && gauge && gauge.settings && gauge.settings.score_hidden;
  const hideScorePublic = gauge && gauge.settings && gauge.settings.score_hidden;
  const hideGraphSetting = !canView && gauge && gauge.settings && gauge.settings.tally_hidden;
  const hideResults = !canView && gauge && gauge.settings && gauge.settings.results_hidden;
  const hideResultsPublic = gauge && gauge.settings && gauge.settings.results_hidden;
  const resultsOnly = !canView && gauge && gauge.status === 'inactive';
  const rateBeforeResults = !canView && gauge
    && gauge.settings && gauge.settings.rate_before_results;
  const allowAnonymous = gauge && gauge.settings && !gauge.settings.prevent_anonymous_response;

  const shouldHideScore = () => {
    if (!gauge) {
      return true;
    }

    if (rateBeforeResults) {
      return !myRating;
    }
    return hideResults || hideScoreSetting;
  };

  const shouldHideGraph = () => {
    if (!gauge) {
      return true;
    }

    if (rateBeforeResults) {
      return !myRating;
    }
    return hideResults || hideGraphSetting;
  };

  const {
    score,
    mutate: scoreMutate
  } = useGaugeLatestScore(!shouldHideScore() ? gaugeId : null, initialScore);
  const {
    tally,
    mutate: tallyMutate
  } = useGaugeTally(!shouldHideGraph() ? gaugeId : null);

  const {
    mutate: mutateProgress
  } = useQuteeProgress(
    (userState.isLoggedIn && gauge?.qutee_id) ? gauge.qutee_id : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  useEffect(() => {
    if (useFirst && firstLoadGauge) {
      const { rating: firstRating, score: firstScore, ...remainingGauge } = firstLoadGauge;
      gaugeMutate(remainingGauge, false);
      if (firstScore) {
        scoreMutate({ ...firstScore }, false);
      }
      if (firstRating) {
        ratingMutate({ ...firstRating }, false);
      }
      setUseFirst(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoadGauge, useFirst]);

  useEffect(() => {
    if (isMainEngagement && (typeof setAllowAnonymous === 'function')) {
      setAllowAnonymous(allowAnonymous);
    }
  }, [allowAnonymous, isMainEngagement, setAllowAnonymous]);

  const isValidGaugeType = (type) => {
    if (type === 'dial') {
      return true;
    }
    if (type === 'slider') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (gauge?.gauge_type && isValidGaugeType(gauge?.gauge_type)) {
      setGaugeType(gauge.gauge_type);
    } else if (embedConfig?.gaugeType && isValidGaugeType(embedConfig?.gaugeType)) {
      const { gaugeType: type } = embedConfig;
      setGaugeType(type);
    } else if (useSimple) {
      setGaugeType('slider');
    } else {
      setGaugeType('dial');
    }
  }, [embedConfig, gauge, useSimple]);

  // TODO: How to clean this section up so it's more organized?
  // How to effectively make an external file for holding API calls
  // that utilize hooks?
  const { patch: patchTitle, response: patchTitleResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { delete: deleteGaugeRequest, response: deleteGaugeResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { patch: patchLabel, response: patchLabelResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });
  const { post: postRating, response: postRatingResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const onUpdateTitle = async (gaugeId, newTitle) => {
    const data = {
      title: newTitle
    };
    const original = { ...gauge };
    gaugeMutate({ ...gauge, ...data }, false);
    const updatedGauge = await patchTitle(`${gaugeId}`, data);
    if (patchTitleResponse.ok) {
      gaugeMutate(updatedGauge);
    } else {
      gaugeMutate(original);
      throw new ApiException(updatedGauge);
    }
  };

  const onDeleteGauge = async (gaugeId) => {
    const deletedGauge = await deleteGaugeRequest(`${gaugeId}`);
    if (deleteGaugeResponse.ok) {
      gaugeMutate();
      setShouldRefreshGauges(true);
    } else {
      throw new ApiException(deletedGauge);
    }
  };

  const onUpdateLabel = async (gaugeId, labelType, label) => {
    const data = {
      [labelType]: label
    };
    const original = { ...gauge };
    gaugeMutate({ ...gauge, ...data }, false);
    const updatedGauge = await patchLabel(`${gaugeId}`, data);
    if (patchLabelResponse.ok) {
      gaugeMutate(updatedGauge);
    } else {
      gaugeMutate(original);
      throw new ApiException(updatedGauge);
    }
  };

  const onSubmitRating = async (gaugeId, rating) => {
    const data = {
      rating
    };
    const original = { ...myRating };
    if (myRating) {
      ratingMutate({ ...myRating, rating }, false);
    }
    const newRating = await postRating(`${gaugeId}/ratings`, data);
    if (postRatingResponse.ok) {
      ratingMutate();
      if (!shouldHideScore()) {
        scoreMutate();
      }
      if (!shouldHideGraph()) {
        tallyMutate();
      }
    } else {
      if (myRating) {
        ratingMutate(original);
      }
      throw new ApiException(newRating);
    }
  };

  const canUserInteract = () => {
    if (userState.isPending) {
      return false;
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return false;
    }
    if (resultsOnly) {
      return false;
    }
    if (isLoadingInitial) {
      return false;
    }
    return true;
  };

  const getTally = () => {
    // "== null" check for both null and undefined
    if ((tally == null) || shouldHideGraph()) {
      return null;
    }
    return tally.segments;
  };

  const getInitialScore = () => {
    // "== null" check for both null and undefined
    if ((score == null) || shouldHideScore()) {
      return null;
    }
    return score.average_score;
  };

  const getRatingCount = () => {
    // "== null" check for both null and undefined
    if ((score == null) || shouldHideScore()) {
      return null;
    }
    return score.rating_count;
  };

  const getCantInteractReason = () => {
    if (userState.isPending) {
      return 'You must verify your email before rating.';
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return 'Anonymous users are not allowed to rate this gauge.';
    }
    if (resultsOnly) {
      return 'This gauge is no longer accepting responses.';
    }
    if (isLoadingInitial) {
      return 'Loading data';
    }
    return '';
  };

  const getUserRating = () => {
    if (isLoadingMyRating) {
      return undefined;
    }
    return myRating ? myRating.rating : null;
  };

  const isLoading = () => (
    isLoadingGauge
  );

  const hasError = () => isErrorGauge;

  if (hasError() && !isLoading()) {
    return <Result status="error" title="Rate Limits Exceeded or Internal Error" />;
  }
  if (isLoading()) {
    return <Skeleton active />;
  }
  if (gaugeType === 'slider') {
    return (
      <SimpleGauge
        externalRef={externalRef}
        gaugeId={gauge.gauge_id}
        title={gauge.title}
        highLabel={gauge.high_label}
        lowLabel={gauge.low_label}
        segments={getTally()}
        averageScore={getInitialScore()}
        userRating={getUserRating()}
        hideTitle={hideTitle}
        hideGraph={gauge && (hideResults || hideGraphSetting)}
        hideUserRating={hideUserRating}
        hideScore={gauge && (hideResults || hideScoreSetting)}
        hideResultsPublic={hideResultsPublic || hideScorePublic}
        rateBeforeResults={rateBeforeResults}
        resultsOnly={resultsOnly}
        disabled={!canUserInteract()}
        disabledTooltipText={getCantInteractReason()}
        userCanEdit={canEdit}
        onUpdateTitle={onUpdateTitle}
        onUpdateLabel={onUpdateLabel}
        onSubmitRating={onSubmitRating}
        afterRateHook={afterRateHook}
        mutateProgress={mutateProgress}
        onDeleteGauge={onDeleteGauge}
        continueAnonymously={continueAnonymously}
      />
    );
  }
  return (
    <Gauge
      externalRef={externalRef}
      gaugeId={gauge.gauge_id}
      title={gauge.title}
      highLabel={gauge.high_label}
      lowLabel={gauge.low_label}
      segments={getTally()}
      averageScore={getInitialScore()}
      ratingCount={getRatingCount()}
      userRating={myRating ? myRating.rating : null}
      hideTitle={hideTitle}
      hideGraph={gauge && (hideResults || hideGraphSetting)}
      hideUserRating={hideUserRating}
      hideScore={gauge && (hideResults || hideScoreSetting)}
      hideResultsPublic={hideResultsPublic || hideScorePublic}
      resultsOnly={resultsOnly}
      disabled={!canUserInteract()}
      disabledTooltipText={getCantInteractReason()}
      rateBeforeResults={rateBeforeResults}
      userCanInteract={canUserInteract()}
      userCanEdit={canEdit}
      onUpdateTitle={onUpdateTitle}
      onUpdateLabel={onUpdateLabel}
      onSubmitRating={onSubmitRating}
      afterRateHook={afterRateHook}
      mutateProgress={mutateProgress}
      onDeleteGauge={onDeleteGauge}
      continueAnonymously={continueAnonymously}
    />
  );
}

GaugeContainer.defaultProps = {
  hideTitle: false,
  hideGraph: false,
  useSimple: false,
  hideRatings: false,
  hideUserRating: false,
  resultsOnly: false,
  afterRateHook: undefined,
  isMainEngagement: false,
  setAllowAnonymous: undefined,
  initialGauge: undefined,
  initialRating: undefined,
  initialScore: undefined,
  isLoadingInitial: false,
  setShouldRefreshGauges: undefined,
  externalRef: undefined
};

export default GaugeContainer;
