import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { formatDistanceToNow } from 'date-fns';
import { Spin, App as AntdApp, message } from 'antd';
import { useFetch } from 'use-http/dist/cjs/useFetch';
import { CachePolicies } from 'use-http/dist/cjs/types';
// import queryString from 'query-string';
import config from './common/infra/env.config';
import {
  anonymousLogin, apiLogin, clientSignOut, cookieLogin
} from './auth/state/api';
import { auth } from './common/infra/firebase';
import { useUserContext } from './user/providers/UserProvider';
import App from './App';
import ErrorPage from './embed/components/ErrorPage';

function AppSetup() {
  const { notification } = AntdApp.useApp();
  // const queryValues = queryString.parse(window.location.search);
  const { dispatch: userDispatch } = useUserContext();
  // The main quteeId; used if the app is split into separate html elements
  const [mainEngagementId, setMainEngagementId] = useState(null);
  // Whether anonymous accounts are allowed. Set inside the main engagement
  const [allowAnonymous, setAllowAnonymous] = useState(true);
  // Note: to store a function as state, use a function that returns your desired function
  const [resetTourHandler, setResetTourHandler] = useState(() => () => {});
  const [bonusStaticEmbeds, setBonusStaticEmbeds] = useState([]);
  const [bonusDynamicEmbeds, setBonusDynamicEmbeds] = useState([]);
  const [showMainEngagement, setShowMainEngagement] = useState(false);
  const [statusRequestFinished, setStatusRequestFinished] = useState(false);
  const [statusResult, setStatusResult] = useState(null);

  // const accessCode = queryValues.access_code;
  // let email;
  // if (queryValues.email) {
  //   email = decodeURIComponent(`${queryValues.email}`);
  // }

  const elementsToArray = (elements) => Array.prototype.slice.call(elements);

  const url = config.environment === 'prod' ? 'https://status.socialasking.com/' : `https://status.socialasking.com/${config.environment}/`;

  const { get: getStatusRequest, response: getStatusResponse } = useFetch(url, {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const loadStatus = async () => {
    const result = await getStatusRequest();

    if (getStatusResponse.ok) {
      try {
        if (result.planned_outages.length > 0) {
          for (let i = 0; i < result.planned_outages.length; i += 1) {
            const startTime = Date.parse(result.planned_outages[i].start_time);
            const endTime = Date.parse(result.planned_outages[i].end_time);

            // if startTime is less than one hour from now
            if (startTime - Date.now() < 3600000) {
              if (startTime > Date.now()) { // Don't show if the start time is in the past
                notification.warning({
                  placement: 'bottomRight', message: `Service Interruption in ${formatDistanceToNow(startTime)}`, description: `${result.planned_outages[0].reason}  \n\n| Expected Impact: ${result.planned_outages[0].impact}`, duration: 0
                });
              } else if (endTime > Date.now()) {
                notification.error({
                  placement: 'bottomRight', message: 'Service Interruption IMMINNENT! Shutting down now!', description: `${result.planned_outages[0].reason}  \n\n| Expected Impact: ${result.planned_outages[0].impact}`, duration: 0
                });
              }
            }
          }
        }
        if (result.live === true) {
          setShowMainEngagement(true);
          return true;
        }
        setStatusResult(result);
        setStatusRequestFinished(true);
        return false;
      } catch (e) {
        console.log(e);
        setShowMainEngagement(true);
        return null;
      }
    } else {
      console.log('Status check not ok.');
      setStatusRequestFinished(true);
      return null;
    }
  };

  useEffect(() => {
    loadStatus();
  }, []);

  useEffect(() => {
    const bonus = elementsToArray(document.getElementsByClassName('social-asking-bonus-embed'));
    setBonusStaticEmbeds(bonus);
  }, []);

  // Observe embeds added/removed
  useEffect(() => {
    const embedList = document.getElementById('social-asking-embeds-list');
    const listConfig = { attributes: false, childList: true, subtree: false };
    const modifiedConfig = { attributes: true, childList: false, subtree: false };
    if (embedList) {
      const callback = (mutationList, observer) => {
        mutationList.forEach((mutation) => {
          if (mutation.type === 'childList' && mutation.addedNodes) {
            mutation.addedNodes.forEach((node) => {
              if (node.classList.contains('social-asking-bonus-embed')) {
                observer.observe(node, modifiedConfig);
              }
            });
          }
        });
        const allDescendants = elementsToArray(embedList.querySelectorAll('*'));
        const embedDescendants = allDescendants.reduce((acc, cur) => {
          if (cur.classList.contains('social-asking-bonus-embed')) {
            acc.push(cur);
          }
          return acc;
        }, []);
        setBonusDynamicEmbeds(embedDescendants);
      };
      const observer = new MutationObserver(callback);
      observer.observe(embedList, listConfig);
    }
  }, []);

  message.config({
    top: 50
  });

  useEffect(() => {
    // Disable email access stuff until auth flow with it can be fixed
    // if (!mainEngagementId) {
    //   return;
    // }
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      if (user) {
        // User is authenticated client-side
        try {
          let userInfo;
          if (user.isAnonymous) {
            userInfo = await anonymousLogin();
          } else {
            userInfo = await apiLogin();
            // userInfo = await apiLogin({ hash: accessCode, email, quteeId: mainEngagementId });
          }
          userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
        } catch (err) {
          console.log(err);
          if (err.status === 401 || err.error === 'Unauthorized') {
            await clientSignOut();
            userDispatch({ type: 'LOGIN_ERROR', payload: err });
          }
        }
      } else {
        // Check if there's a valid session cookie
        try {
          let userInfo = await cookieLogin();
          if (userInfo.role === 'anonymous') {
            userInfo = await anonymousLogin();
          } else {
            userInfo = await apiLogin();
            // userInfo = await apiLogin({ hash: accessCode, email, quteeId: mainEngagementId });
          }
          userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
          return;
        } catch (err) {
          userDispatch({ type: 'UNAUTHENTICATED' });
        }

        // User is unauthenticated
        userDispatch({ type: 'UNAUTHENTICATED' });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExtraEmbeds = () => {
    const portals = [];

    const addEmbeds = (embeds) => {
      Array.from(embeds).forEach((element) => {
        // @ts-ignore
        if (element.dataset.embedType) {
          const portal = ReactDOM.createPortal(
            <App
              isMainEngagement={false}
              setMainEngagementId={setMainEngagementId}
              // @ts-ignore
              options={{ ...element.dataset }}
              mainEngagementId={mainEngagementId}
              resetTourHandler={resetTourHandler}
              allowAnonymous={allowAnonymous}
            />,
            element
          );
          portals.push(portal);
        }
      });
    };

    if (bonusStaticEmbeds) {
      addEmbeds(bonusStaticEmbeds);
    }
    if (bonusDynamicEmbeds) {
      addEmbeds(bonusDynamicEmbeds);
    }

    return portals;
  };

  const main = document.getElementById('social-asking-embed');
  const mainOptions = main.dataset;

  if (showMainEngagement) {
    return (
      <>
        <App
          isMainEngagement
          options={{ ...mainOptions }}
          setMainEngagementId={setMainEngagementId}
          setAllowAnonymous={setAllowAnonymous}
          resetTourHandler={resetTourHandler}
          setResetTourHandler={setResetTourHandler}
          allowAnonymous={allowAnonymous}
        />
        {getExtraEmbeds()}
      </>
    );
  }
  if (statusRequestFinished) {
    return (
      <ErrorPage
        status={statusResult}
      />
    );
  }
  return (
    <Spin />
  );
}

export default AppSetup;
