import React, { useEffect, useRef, useState } from 'react';
import {
  Button, App, Slider, Tooltip, Typography
} from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { useUserContext } from '../../user/providers/UserProvider';
import AnonPopconfirm from '../../auth/components/AnonPopconfirm';
import GaugeInfo from './GaugeInfo';
import GaugeGraph from './GaugeGraph';
import GaugeLabel from './GaugeLabel';
// Temporarily swap to thumb icons
// import fullHeartIcon from '../../assets/full-heart.png';
// import emptyHeartIcon from '../../assets/empty-heart.png';
import thumbUpIcon from '../../assets/thumb-up.png';
import thumbDownIcon from '../../assets/thumb-down.png';
import styles from '../styles/SimpleGauge.module.scss';

const { Text } = Typography;

function SimpleGauge({
  gaugeId, title, highLabel, lowLabel, segments, averageScore, userRating,
  hideTitle, hideGraph, hideUserRating, hideScore, resultsOnly, rateBeforeResults,
  disabled, userCanEdit, onUpdateTitle, onUpdateLabel, onSubmitRating, afterRateHook,
  disabledTooltipText, mutateProgress, onDeleteGauge, continueAnonymously, hideResultsPublic,
  externalRef
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [showSlider, setShowSlider] = useState(true);
  const [percent, setPercent] = useState(50);
  const [isLoading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const sliderRef = useRef();
  const graphTransitionRef = useRef();
  const sliderTransitionRef = useRef();

  const refreshProgress = async () => {
    if (typeof mutateProgress === 'function' && userRating == null) {
      mutateProgress();
    }
  };

  const showInputSlider = () => showSlider || !segments;
  const showGraph = () => !hideGraph && segments && !showInputSlider();
  const showSwitchButton = () => {
    if (hideGraph || resultsOnly) {
      return false;
    }
    if (rateBeforeResults) {
      return userRating || userRating === 0;
    }
    return true;
  };
  const switchAfterRate = () => !hideGraph && !resultsOnly && !hideResultsPublic;

  const changeRatingDisplay = (value) => {
    setPercent(value);
  };

  useEffect(() => {
    if (userRating || (userRating === 0)) {
      changeRatingDisplay(userRating);
    } else if (userRating === null) {
      setPercent(50);
    }
  }, [userRating]);

  const saveRating = async (percent) => {
    const loadingKey = 'saveRatingRequest';
    const successKey = 'saveRatingSuccess';
    const errorKey = 'saveRatingError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Saving your gauge rating...', key: loadingKey });
    try {
      await onSubmitRating(gaugeId, percent);
      setLoading(false);
      refreshProgress();
      if (typeof afterRateHook === 'function') {
        afterRateHook();
      }
      if (switchAfterRate()) {
        setShowSlider(false);
      }
      message.destroy(loadingKey);
      message.success({ content: 'Rating successfully saved', key: successKey });
    } catch (err) {
      setLoading(false);
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const resetRating = async () => {
    let newPercent = 50;
    if (userRating) {
      newPercent = userRating;
    }
    setPercent(newPercent);
  };

  const selectRating = async (value) => {
    changeRatingDisplay(value);
    if (userState.isLoggedIn) {
      continueAnonymously(saveRating, value);
    } else {
      setShowConfirm(true);
    }
  };

  const confirmRating = async () => {
    setShowConfirm(false);
    setLoading(true);
    continueAnonymously(saveRating, percent);
  };

  const cancelRating = async () => {
    setShowConfirm(false);
    resetRating();
  };

  const getInputSlider = () => {
    if (showGraph() || !showInputSlider()) {
      return null;
    }

    let tooltipTitle = null;
    const defaultDisabledText = 'Gauge interactions are disabled.';
    if (disabled || resultsOnly) {
      tooltipTitle = disabledTooltipText || defaultDisabledText;
    } else if (isLoading) {
      tooltipTitle = 'Loading data';
    }
    return (
      <Tooltip
        mouseEnterDelay={0.3}
        title={tooltipTitle}
      >
        <div ref={sliderRef} className={styles.sliderWithIcons}>
          <div
            className={`${styles.icon} ${disabled ? styles.disabledIcon : ''}`}
            style={{ backgroundImage: `url(${thumbDownIcon})` }}
            onClick={() => (disabled ? null : selectRating(0))}
            onKeyDown={() => (disabled ? null : selectRating(0))}
            role="button"
            tabIndex={0}
          />
          <div className={styles.sliderContainer}>
            <AnonPopconfirm
              disabled={disabled || userState.isLoggedIn}
              onConfirm={() => confirmRating()}
              onCancel={() => cancelRating()}
              open={showConfirm}
            >
              <Slider
                className={styles.slider}
                defaultValue={userRating || 50}
                value={percent}
                onChange={changeRatingDisplay}
                onAfterChange={selectRating}
                disabled={isLoading || disabled || resultsOnly}
                tooltip={{
                  open: userRating != null,
                  placement: 'top',
                  getPopupContainer: () => sliderRef?.current,
                  autoAdjustOverflow: false,
                  zIndex: 10
                }}
              />
            </AnonPopconfirm>
          </div>
          <div
            className={`${styles.icon} ${disabled ? styles.disabledIcon : ''}`}
            style={{ backgroundImage: `url(${thumbUpIcon})` }}
            onClick={() => (disabled ? null : selectRating(100))}
            onKeyDown={() => (disabled ? null : selectRating(100))}
            role="button"
            tabIndex={0}
          />
        </div>
      </Tooltip>
    );
  };

  const getToggleButtonText = () => {
    if (showSlider && hideResultsPublic) {
      return 'See Results  *Not Public';
    }
    if (showSlider) {
      return 'See Results';
    }
    return 'Back To Your Rating';
  };

  if (!gaugeId) {
    return null;
  }

  if (!gaugeId) {
    return null;
  }
  return (
    <div ref={externalRef} className={styles.container}>
      <GaugeInfo
        gaugeId={gaugeId}
        title={title}
        hideTitle={hideTitle}
        userCanEdit={userCanEdit}
        onUpdateTitle={onUpdateTitle}
        hideHelpIcon
        onDeleteGauge={onDeleteGauge}
      />
      <CSSTransition
        in={!showGraph() && showInputSlider()}
        nodeRef={sliderTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.sliderEnter,
          enterActive: styles.sliderEnterActive
        }}
        unmountOnExit
      >
        <div ref={sliderTransitionRef}>
          {getInputSlider()}
        </div>
      </CSSTransition>
      <CSSTransition
        in={showGraph()}
        nodeRef={graphTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.graphEnter,
          enterActive: styles.graphEnterActive
        }}
        unmountOnExit
      >
        <div ref={graphTransitionRef}>
          <GaugeGraph
            segments={segments}
            score={averageScore != null ? Math.round(averageScore) : null}
            showIcons
            iconSet="thumbs"
          />
        </div>
      </CSSTransition>
      <div className={styles.labels}>
        <div className={styles.leftLabel}>
          <GaugeLabel
            gaugeId={gaugeId}
            label={lowLabel}
            isLowLabel
            onUpdateLabel={onUpdateLabel}
            userCanEdit={userCanEdit}
            showColor={false}
          />
        </div>
        <div className={styles.rightLabel}>
          <GaugeLabel
            gaugeId={gaugeId}
            label={highLabel}
            isLowLabel={false}
            onUpdateLabel={onUpdateLabel}
            userCanEdit={userCanEdit}
            showColor={false}
          />
        </div>
      </div>
      {showInputSlider() && (
        <div className={styles.instructions}>
          <Text>
            Drag or click on the slider to give your rating out of 100.
          </Text>
        </div>
      )}
      {showSwitchButton() && (
        <div className={styles.switchContainer}>
          <Button
            className={styles.switch}
            type="text"
            onClick={() => setShowSlider(!showSlider)}
          >
            {!showSlider && <LeftOutlined />}
            {getToggleButtonText()}
            {showSlider && <RightOutlined />}
          </Button>
        </div>
      )}
    </div>
  );
}

SimpleGauge.defaultProps = {
  afterRateHook: null,
  hideTitle: false,
  hideGraph: false,
  hideUserRating: false,
  hideScore: false,
  resultsOnly: false,
  rateBeforeResults: false,
  disabled: false,
  userCanEdit: false,
  onUpdateTitle: () => {},
  onUpdateLabel: () => {},
  onSubmitRating: () => {},
  externalRef: undefined
};

export default SimpleGauge;
