import React, { useEffect, useState } from 'react';
import {
  Col, Row, Tabs, Modal, Button, Tooltip
} from 'antd';
import { PlusOutlined, ExclamationOutlined } from '@ant-design/icons/lib/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import QuteeCommentsView from './QuteeCommentsView';
import QuteePolls from './QuteePolls';
import QuteeGauges from './QuteeGauges';
import InsightsReportView from './InsightsReportView';
import CreatePoll from '../../poll/components/CreatePoll';
import BannerBundle from '../../common/view/BannerBundle';
import styles from '../styles/Qutee.module.scss';

const { TabPane } = Tabs;

function Qutee({
  quteeId, commentBinId, gaugeIds, pollIds, ratingCount, totalCommentCount,
  filterCommentCount, ballotCount, commentPrompt, labelPrompt, labels, threads,
  mutateThreads, labelFilters, mutateLabels, isLoadingLabels,
  topicFilters, mutateTopics, sizeTopics, setSizeTopics, isLoadingTopics,
  isEndTopics, sentimentFilters, mutateSentiments, sizeSentiments, setSizeSentiments,
  isLoadingSentiments, isEndSentiments, setFilter, filterData, setFilterData, filters,
  countComments, sizeComments, setSizeComments, foundNewComments, newCommentsCount,
  hideCommentBinResults, reloadComments, isLoadingComments,
  isEndComments, isRefreshingComments, loadReplies, loadDeeperThread, createComment,
  sort, setSort, updateComment, deleteComment, likeComment, removeCommentLike, dislikeComment,
  removeCommentDislike, updateCommentBin, updateLabelSet, createLabel, updateLabel, deleteLabel,
  createPoll, getReport, getLatestReport, getReportProgress, generateReport,
  userCanInteract, cantInteractReason, userCanEditQutee, labelEditingDisabled,
  checkUserCanEditComment, allowAnonymous, incrementCreatedComments,
  hideBanners, hideGauges, hidePolls, useSimpleGauge,
  hideSearchFilter, hideLabelFilter, hideTopicFilter, hideSentimentFilter, hideAllFilters,
  continueAnonymously
}) {
  const { state: userState } = useUserContext();
  const [tabKey, setTabKey] = useState('comments');
  const [showCreatePollModal, setShowCreatePollModal] = useState(false);
  const [shouldRefreshPolls, setShouldRefreshPolls] = useState(false);

  useEffect(() => {
    if (!userState.isLoggedIn && tabKey === 'report') {
      setTabKey('comments');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  const navigateToReport = async () => {
    setTabKey('report');
  };

  const createNewPoll = async (quteeId, title, options) => {
    await createPoll(quteeId, title, options);
    setShouldRefreshPolls(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.bannerPadding}>
        {!hideBanners && <BannerBundle quteeId={quteeId} allowAnonymous={allowAnonymous} />}
      </div>
      <Tabs
        className={styles.tabs}
        activeKey={tabKey}
        onTabClick={(key) => setTabKey(key)}
        size="large"
      >
        <TabPane
          tab={(
            <Row>
              <Col md={{ span: 8, order: 2 }} xs={{ span: 24, order: 1 }}>
                <div className={styles.count}>
                  {totalCommentCount}
                </div>
              </Col>
              <Col md={{ span: 16, order: 1 }} xs={{ span: 24, order: 2 }}>Comments</Col>

            </Row>
          )}
          key="comments"
        >
          <QuteeCommentsView
            quteeId={quteeId}
            commentBinId={commentBinId}
            commentPrompt={commentPrompt}
            labelPrompt={labelPrompt}
            labels={labels}
            threadList={threads}
            totalCommentCount={totalCommentCount}
            filterCommentCount={filterCommentCount}
            mutateThreads={mutateThreads}
            labelFilters={labelFilters}
            mutateLabels={mutateLabels}
            isLoadingLabels={isLoadingLabels}
            topicFilters={topicFilters}
            mutateTopics={mutateTopics}
            sizeTopics={sizeTopics}
            setSizeTopics={setSizeTopics}
            isLoadingTopics={isLoadingTopics}
            isEndTopics={isEndTopics}
            sentimentFilters={sentimentFilters}
            mutateSentiments={mutateSentiments}
            sizeSentiments={sizeSentiments}
            setSizeSentiments={setSizeSentiments}
            isLoadingSentiments={isLoadingSentiments}
            isEndSentiments={isEndSentiments}
            setFilter={setFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            filters={filters}
            countComments={countComments}
            hideCommentBinResults={hideCommentBinResults}
            foundNewComments={foundNewComments}
            newCommentsCount={newCommentsCount}
            incrementCreatedComments={incrementCreatedComments}
            sizeComments={sizeComments}
            setSizeComments={setSizeComments}
            reloadComments={reloadComments}
            isLoadingComments={isLoadingComments}
            isEndComments={isEndComments}
            isRefreshingComments={isRefreshingComments}
            loadReplies={loadReplies}
            loadDeeperThread={loadDeeperThread}
            createComment={createComment}
            sort={sort}
            setSort={setSort}
            updateComment={updateComment}
            deleteComment={deleteComment}
            likeComment={likeComment}
            removeCommentLike={removeCommentLike}
            dislikeComment={dislikeComment}
            removeCommentDislike={removeCommentDislike}
            updateCommentBin={updateCommentBin}
            updateLabelSet={updateLabelSet}
            createLabel={createLabel}
            updateLabel={updateLabel}
            deleteLabel={deleteLabel}
            userCanInteract={userCanInteract}
            cantInteractReason={cantInteractReason}
            userCanEditQutee={userCanEditQutee}
            labelEditingDisabled={labelEditingDisabled}
            checkUserCanEditComment={checkUserCanEditComment}
            allowAnonymous={allowAnonymous}
            hideSearchFilter={hideSearchFilter}
            hideLabelFilter={hideLabelFilter}
            hideTopicFilter={hideTopicFilter}
            hideSentimentFilter={hideSentimentFilter}
            hideAllFilters={hideAllFilters}
            continueAnonymously={continueAnonymously}
          />
        </TabPane>
        {!hidePolls && (
          <TabPane
            tab={(
              <Row>
                <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
                  <div className={styles.count}>
                    {ballotCount}
                    {userCanEditQutee && (!pollIds || !pollIds.length) ? (
                      <Tooltip
                        mouseEnterDelay={0.3}
                        title="Add a poll to finish configuration"
                      >
                        <ExclamationOutlined className={styles.noPolls} />
                      </Tooltip>
                    ) : null}
                  </div>
                </Col>
                <Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
                  Votes
                </Col>
              </Row>
            )}
            key="votes"
          >
            {userCanEditQutee && (
              <div className={styles.createPollContainer}>
                <Button
                  className={styles.createPoll}
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined />}
                  onClick={() => setShowCreatePollModal(true)}
                >
                  Add Poll
                </Button>
              </div>
            )}
            <QuteePolls
              quteeId={quteeId}
              userCanEdit={userCanEditQutee}
              showNoPollText
              shouldRefreshPolls={shouldRefreshPolls}
              setShouldRefreshPolls={setShouldRefreshPolls}
            />
            <Modal
              title="Add Poll"
              open={showCreatePollModal}
              footer={null}
              onCancel={() => setShowCreatePollModal(false)}
              destroyOnClose
            >
              <CreatePoll quteeId={quteeId} onCreatePoll={createNewPoll} />
            </Modal>
          </TabPane>
        )}
        {!hideGauges && (
          <TabPane
            tab={(
              <Row>
                <Col md={{ span: 10, order: 2 }} xs={{ span: 24, order: 1 }}>
                  <div className={styles.count}>
                    {ratingCount}
                  </div>
                </Col>
                <Col md={{ span: 14, order: 1 }} xs={{ span: 24, order: 2 }}>Ratings</Col>
              </Row>
            )}
            key="ratings"
          >
            <QuteeGauges
              quteeId={quteeId}
              useSimple={useSimpleGauge}
            />
          </TabPane>
        )}
        {userState.isLoggedIn && (userCanEditQutee || userState.role === 'admin') && (
        <TabPane
          tab="Report"
          key="report"
        >
          <InsightsReportView
            quteeId={quteeId}
            getReport={getReport}
            getLatestReport={getLatestReport}
            getReportProgress={getReportProgress}
            generateReport={generateReport}
            totalCommentCount={totalCommentCount}
            navigateToReport={navigateToReport}
          />
        </TabPane>
        )}
      </Tabs>
    </div>
  );
}

Qutee.displayName = 'Qutee';

Qutee.defaultProps = {
  hideBanners: false,
  hideGauges: false,
  hidePolls: false,
  useSimpleGauge: false
};

export default Qutee;
