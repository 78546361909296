import React, { useEffect, useState } from 'react';
import { subject } from '@casl/ability';
import { Dropdown, Modal, Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride';
import styles from '../styles/SAJoyride.module.scss';

function SAJoyride({
  qutee, userState, setResetTourHandler, setJoyrideIndex, joyrideIndex,
  commentDialogStateHandler, repositionJoyride, previewDialogStateHandler
}) {
  const [runJoyride, setRunJoyride] = useState(false);
  const [welcomeEnabled, setWelcomeEnabled] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const [guideCompleted, setGuideCompleted] = useState(true);
  const [key, setKey] = useState(0);

  const incrementKey = () => {
    setKey((value) => value + 1);
  };

  useEffect(() => {
    async function init() {
      const data = await localStorage.getItem('guide-completed');
      const isCompleted = data === 'true';
      setGuideCompleted(isCompleted);
    }
    init();
  }, []);

  useEffect(() => {
    if (guideCompleted) {
      localStorage.setItem('guide-completed', 'true');
    } else {
      localStorage.removeItem('guide-completed');
    }
  }, [guideCompleted]);

  useEffect(() => {
    incrementKey();
  }, [repositionJoyride]);

  const startGuide = () => {
    setWelcomeEnabled(false);
    setRunJoyride(true);
  };

  const markSkipped = () => {
    setGuideCompleted(true);
  };
  const exitWelcome = () => {
    setWelcomeEnabled(false);
    markSkipped();
  };
  const restartTour = () => {
    setGuideCompleted(false);
    setWelcomeEnabled(true);
  };

  useEffect(() => {
    if (typeof setResetTourHandler === 'function') {
      // Note: to store a function as state, use a function that returns your desired function
      setResetTourHandler(() => () => restartTour());
    }
  }, [setResetTourHandler]);

  useEffect(() => {
    if (!qutee) {
      return;
    }
    setShowWelcome(
      !guideCompleted
      && welcomeEnabled
      && userState.isVerified
      && userState.ability.can('update', subject('Qutee', qutee))
    );
  }, [guideCompleted, welcomeEnabled, userState, qutee]);

  const steps = [
    {
      index: 0,
      target: '.CommentEntry_inputContainer__Ujj9S',
      content: (
        <div>
          <h3>Social Asking is about Comments</h3>
          <p className={styles.concept}>
            We&apos;ll leverage comments as the primary data from your audience for AI analysis.
          </p>
          <p className={styles.instruction}>
            Click the comment area
            <br />
            {' '}
            to open the response window.
          </p>
        </div>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      disableOverlayClose: false,
      spotlightClicks: false,
      placement: 'top',
      continuous: true
    },
    {
      target: '#social-asking-embed',
      index: 1,
      content: (
        <div>
          <h3>Loading</h3>
        </div>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      continuous: true,
      spotlightClicks: false
    },
    {
      index: 2,
      target: '.ant-modal-body .CommentBinPrompt_container__LmCIC',
      content: (
        <div>
          <h3>Edit in place.</h3>

          <p className={styles.concept}>
            Look for the small pen icons. &quot;
            <span role="button" aria-label="edit" className="anticon anticon-edit"><svg viewBox="64 64 896 896" focusable="false" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z" /></svg></span>
            &quot;
          </p>
          <p className={styles.clarification}>
            These indicate what elements you may change.
            Click them to edit the preceeding text.
          </p>
          <p className={styles.instruction}>
            Replace the above prompt with one of your own.  When you&apos;re done, continue.
          </p>
          <p className={styles.tip}>
            This prompt should ask the main question you want from your audience.
            Use open-ended questions that encourage people to tell stories. This
            question may be broad. Save simple &quot;yes/no&quot;
            or &quot;choose one&quot; questions for polls.
          </p>

        </div>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      continuous: true,
      spotlightClicks: true,
      placement: 'bottom'
    },
    {
      index: 3,
      target: '.ant-modal-body .LabelSelector_selector__aC3ch',
      content: (
        <div>
          <h3>Introducing Labels.</h3>
          <p className={styles.concept}>
            Labels help organize the conversation. They appear as filters and
            are required to leave a comment.
          </p>
          <p className={styles.tip}>
            This additional metadata will help you understand the audience&apos;s perspective.
            The default labels are set to sentiment (Positive, Negative, Neutral, and Other)
            and are a good starting point if you&apos;re new to this.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideBackButton: false,
      placement: 'left-end',
      styles: {
        options: {
          width: 260
        }
      }
    },
    {
      index: 4,
      target: '.LabelSelector_selector__aC3ch',
      content: (
        <div>
          <h3>Editing Sets.</h3>
          <p className={styles.concept}>
            Look for toggle switches
            &quot;
            <Switch unCheckedChildren="edit" checkedChildren="edit" />
            &quot;
            to edit sets.
          </p>
          <p className={styles.instruction}>
            Customize the labels to match your prompt.
          </p>
          <p className={styles.tip}>
            Use something you would find useful when trying to understand or filter the
            conversation. We recommend keeping Other or another catch-all.
          </p>
          <ul className={styles.examples}>
            <li>Agree / Disagree / Neutral / Other</li>
            <li>Just and Idea / Important / Very Important / Other</li>
          </ul>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      disableOverlay: true,
      spotlightClicks: true,
      placement: 'left-end',
      hideBackButton: false,
      styles: {
        options: {
          width: 260
        }
      }
    },
    {
      index: 5,
      target: '.CommentEntry_modal__OHLyb .ant-modal-close-icon',
      content: (
        <div>
          <h3>Good job.</h3>
          <p>
            You&apos;re getting the hang of it!

            You made your first question and set up labels for it.  Let&apos;s exit this window
            and return to the main engagement.
          </p>
          <p className={styles.instruction}>
            Close this window.
          </p>
          <p className={styles.tip}>
            You can return if you need to make more
            changes later by clicking on the comment entry area again.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      hideCloseButton: true,
      spotlightClicks: false,
      placement: 'bottom',
      hideBackButton: false
    },
    {
      index: 6,
      target: '.CommentBinTitle_container__vvRsd',
      content: (
        <div>
          <h3>Your Question Needs a Title.</h3>
          <p className={styles.concept}>
            Titles are used for navigation and are also displayed at the top of your engagement.
          </p>
          <p className={styles.instruction}>
            Set up a title for the question you created.
          </p>
          <p className={styles.tip}>
            Come up with a short title that represents the question you entered.
            Keep it short and clear. Next we&apos;ll add a subtitle for further clarification.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: true
    },
    {
      index: 7,
      target: '.CommentBinSubtitle_container__09xq4',
      content: (
        <div>
          <h3>Optionally add a subtitle.</h3>
          <p className={styles.concept}>
            Subtitles help clarify
            {' '}
            <br />
            what the title is about or means.
          </p>
          <p className={styles.instruction}>
            Update the subtitle or continue without one.
          </p>
          <p className={styles.tip}>
            This is mostly useful when you set up multiple questions, so if you have just the
            one question, skip it and users won&apos;t see anything here.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: true,
      hideBackButton: false
    },
    {
      index: 8,
      target: '.Quteev1_binSelector__WI-j5 .ant-steps',
      content: (
        <div>
          <h3>Look familiar?</h3>
          <p className={styles.concept}>
            The title and subtitle you set are used to navigate to the question.
            They are especially important if you added more questions.
          </p>
          <p className={styles.tip}>
            Don&apos;t overload your audience with too many questions.  1-5 are recommended.
            If you have more to ask, consider doing more than one engagement.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: false,
      placement: 'left-start',
      hideBackButton: false
    },
    {
      index: 9,
      target: '.Quteev1_createBin__FItxU',
      content: (
        <div>
          <h3>Owner Controls</h3>
          <div className={styles.concept}>
            &quot;
            <Dropdown.Button
              className={styles.exampleButton}
              type="primary"
              size="large"
              icon={<DownOutlined />}
              menu={{
                items: [{
                  key: 'example',
                  label: 'Example'
                }]
              }}
            >
              Add ____________
            </Dropdown.Button>
            &quot;
            <br />
            Look for these to add items including questions, rating gauges and polls.
          </div>
          <p className={styles.instruction}>
            You may add more open-ended questions later. But if this is your first time, you may
            continue with just the one.
          </p>
          <p className={styles.tip}>
            For each question you add, you will need to set the title and labels.  Optionally you
            may also add the subtitle.  You can also delete questions you don&apos;t want by
            clicking the trash icon located next to the title in the navigation section labeled
            &quot;Questions.&quot;
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: false,
      placement: 'left',
      hideBackButton: false
    },
    {
      index: 10,
      target: '.Quteev1_gauges__sJmYX',
      content: (
        <div>
          <h3>Ratings Gauges</h3>
          <p className={styles.concept}>
            Collect granular sentiment of a subject as a whole.
            Users will be able to rate from low to high on this rating scale from 0 to 100.
          </p>
          <p className={styles.tip}>
            We recommend using at least one rating gauge but you can choose to use none by deleting
            the default gauge.
          </p>

        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: false,
      placement: 'left-start',
      hideBackButton: false
    },
    {
      index: 11,
      target: '.GaugeInfo_title__93ck-',
      content: (
        <div>
          <h3>Configure Gauge Title</h3>
          <p className={styles.instruction}>
            Edit the title to fit a good subject you want your audience to rate.
          </p>
          <ul className={styles.ideas}>
            <li>Rate your brand.</li>
            <li>Rate a product concept.</li>
            <li>Rate a key metric you use.</li>
            <li>Rate any person/place/thing.</li>
            <li>Rate their current enthusiasm</li>
          </ul>

        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: 'left',
      disableOverlay: true,
      hideBackButton: false
    },
    {
      index: 12,
      target: '.GaugeControls_GaugeContainer__z3fzi',
      content: (
        <div>
          <h3>Gauge Labels</h3>
          <p className={styles.concept}>
            A label for 0 and another for 100 articulate the extremes on the rating scale.
          </p>
          <p className={styles.instruction}>
            Edit the keys to something appropriate for the high and low end of the rating scale
            based on your gauge title you just set up.
          </p>
          <ul className={styles.examples}>
            <li>Like/Dislike</li>
            <li>Agree/Disagree</li>
            <li>Love it/Not for me</li>
          </ul>
          <p className={styles.tip}>
            You can come back to add more ratings gauges after you finish the tour.
            Try adding a second one later and it will automatically switch to an alternative look.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: 'left',
      hideBackButton: false
    },
    {
      index: 13,
      target: '.Quteev1_createPoll__TavWZ',
      content: (
        <div>
          <h3>Capture additional quantitative data.</h3>
          <p className={styles.concept}>
            The final type of data we collect
            <br />
            {' '}
            is multiple choice polls.
          </p>
          <p className={styles.instruction}>
            Use what you&apos;ve learned so far
            <br />
            to create your first poll.
          </p>
          <p className={styles.tip}>
            Collect extra data and give people something to talk about.
            These are separate from your discussion and can be used to
            ask questions that are not open-ended.
          </p>
          <p className={styles.tip}>
            You may choose between single or multiple choice polls.  Keep options appropriately
            short so that they fit within two lines.  You may add as many polls as you like.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: 'top',
      hideBackButton: false
    },
    {
      index: 14,
      target: '.CommentEntry_commentEntry__CbsGy',
      content: (
        <div>
          <h3>Start the conversation.</h3>
          <p className={styles.concept}>
            A pinned comment that welcomes and guides your audience will
            encourage more participation and lead to success.
          </p>
          <p className={styles.instruction}>
            Be the first to comment!
          </p>
          <p className={styles.tip}>
            Introduce yourself and why you are starting this conversation.
            Tell them about your goals and be friendly.
          </p>
          <p className={styles.tip}>
            Don&apos;t let it be your last! Respond to users throughout your engagement.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: false,
      placement: 'left',
      hideBackButton: false
    },
    {
      index: 15,
      target: '#social-asking-embed',
      content: (
        <div>
          <h3>Loading</h3>
        </div>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      continuous: true
    },
    {
      index: 16,
      target: '.ant-modal-body .CommentEntry_commentEntry__CbsGy',
      content: (
        <div>
          <h3>First!</h3>
          <p className={styles.instruction}>
            Write a comment!
          </p>
          <ul className={styles.examples}>
            <li>
              We can&apos;t wait to hear from you!  Leave a comment and answer the polls
              so we can get to know you better.  We will publish the results next week.  The best
              comments will be entered to win a prize!
            </li>
          </ul>
        </div>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      continuous: true,
      spotlightClicks: true,
      disableOverlay: true,
      placement: 'left'
    },
    {
      index: 17,
      target: '.LabelSelector_selector__aC3ch',
      content: (
        <div>
          <h3>Now that you&apos;ve written something...</h3>
          <p className={styles.concept}>
            Everyone must label their comment to post, including you.
          </p>
          <p className={styles.instruction}>
            Choose a label by selecting one of the available options.
          </p>
          <p className={styles.tip}>
            If you left &quot;Other&quot; as an option
            then choose that as most pinned comments fall under that category.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: true,
      hideBackButton: false
    },
    {
      index: 18,
      target: '.ant-modal-body .CommentEntry_submit__j\\+o7H',
      content: (
        <div>
          <h3>Send it!</h3>
          <p className={styles.instruction}>
            Post your comment before continuing.
          </p>
          <p className={styles.tip}>
            You can edit it later if needed.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      spotlightClicks: true,
      hideBackButton: false
    },
    {
      index: 19,
      target: '.CommentBin_newCommentModal__2iFJQ .ant-modal-close', // todo fix this by finding the right element
      content: (
        <div>
          <h3>Close Preview.</h3>
          <p className={styles.instruction}>
            Review your comment and close this preview to continue.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      hideCloseButton: true,
      spotlightClicks: false
    },
    {
      index: 20,
      target: '.QuteeComment_moreOptions__fBXGA', // todo fix this by finding the right element
      content: (
        <div>
          <h3>Pin the comment.</h3>
          <p className={styles.concept}>
            Pinned comments always show up at the top of the list.
          </p>
          <p className={styles.instruction}>
            Pin your comment via the menu.
          </p>

          <p className={styles.tip}>
            You can also use this to highlight important comments or star
            key comments to include them in your report.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: 'right'
    },
    {
      index: 21,
      target: '.Quteev1_settings__kqpIZ', // todo fix this by finding the right element
      content: (
        <div>
          <h3>Remember this.</h3>
          <p className={styles.concept}>
            Additional settings and configuration options are accessible from this button.
          </p>
          <p className={styles.instruction}>
            Check this out later.  You can continue with the
            default configuration for now.
          </p>
          <p>
            The settings window allows you to lock your engagement, manage access,
            and customize the look and feel of your engagement.
          </p>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true
    },
    {
      index: 22,
      target: 'body',
      content: (
        <div>
          <h3>Invite some people!</h3>
          <p className={styles.concept}>
            Your page is accessible on the web.  You can share the link with anyone on any channel.
          </p>
          <p className={styles.instruction}>Share the link on your preferred channels.</p>
          <ul className={styles.examples}>
            <li>Share it via your Facebook page.</li>
            <li>Send it via your email newsletter.</li>
            <li>Link it from a blog post.</li>
            <li>Share this on any channel you currently use.</li>
          </ul>
          <p className={styles.tip}>
            You can turn this link into a QR code and share it in meatspace.
          </p>

        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideBackButton: false,
      placement: 'bottom',
      floaterProps: {
        styles: {
          arrow: {
            display: 'none'
          }
        }
      }
    },
    {
      target: '.Quteev1_report__uirI2', // todo fix this by finding the right element
      content: (
        <div>
          <h3>Check back soon!</h3>
          <p className={styles.instruction}>
            Once your Sayso has comments, return to download your automated PDF report.
          </p>
          <p className={styles.concept}>
            Congratulations!
            <br />
            {' '}
            You&apos;ve created your first Sayso on Social Asking!
          </p>
          <p className={styles.tip}>
            Don&apos;t forget to go back finalize anything you didn&apos;t fully finish.
          </p>
          <ul className={styles.examples}>
            <li>Add more open-ended questions.</li>
            <li>Add additional rating gauges.</li>
            <li>Add more polls.</li>
            <li>Expand on your pinned comment by editing it.</li>
            <li>Explore the settings configuration options.</li>
          </ul>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideBackButton: false,
      placement: 'left-start'
    }
  ];
  const handleJoyrideCallback = (data) => {
    const {
      action, index, status, type
    } = data;

    if ([ACTIONS.SKIP, ACTIONS.RESET].includes(action)) {
      setJoyrideIndex(0);
      commentDialogStateHandler('restart');
      previewDialogStateHandler('restart');
      setRunJoyride(false);
      return;
    }
    if (index === 1) {
      commentDialogStateHandler('open');
      return;
    }
    if (index === 15) {
      commentDialogStateHandler('open-again');
      return;
    }
    if (index === 6) {
      commentDialogStateHandler('close');
    }
    if (index === 19) {
      commentDialogStateHandler('close-again');
    }
    // TODO add check for preview dialog
    if (index === 20) {
      previewDialogStateHandler('close');
    }
    if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setJoyrideIndex(index + 1);
      return;
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      if (steps[index].spotlightClicks === true) {
        if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action)) {
          // Update state to advance the tour
          setJoyrideIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }
      }
      setJoyrideIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunJoyride(false);
      setJoyrideIndex(0);
      exitWelcome();
    }
  };
  return (
    <div>
      <Modal
        open={showWelcome}
        title="Continue guided tour?"
        onOk={startGuide}
        onCancel={exitWelcome}
        keyboard
        okText="Continue"
        cancelText="No Thanks"
      >
        <p>
          {/* eslint-disable-next-line max-len */}
          Welcome to Social Asking!
          <br />
          <br />
          {' '}
          You have arrived at your first
          {' '}
          <strong>
            &quot;Sayso&quot;
          </strong>
          {' '}
          as in
          {' '}
          <strong>
            &quot;have your say-so.&quot;
          </strong>
          {' '}
          It is the hosted page for the engagement you created.
          <br />
          <br />
          Next we need to customize it. Our guided tour will point out your next steps in
          setting up your &quot;Sayso&quot; and give you tips for success.
        </p>
      </Modal>
      <Joyride
        key={key}
        // @ts-ignore
        steps={steps}
        run={runJoyride}
        showProgress={false}
        showSkipButton
        continuous
        disableOverlayClose
        hideBackButton
        scrollOffset={70}
        stepIndex={joyrideIndex}
        callback={handleJoyrideCallback}
        locale={{
          back: 'Back', close: 'Close', last: 'Finish', next: 'Continue', open: 'Open the dialog', skip: 'Exit Tour'
        }}
        styles={{
          options: {
            zIndex: 200000
          },
          tooltip: {
            padding: 10,
            fontFamily: 'Source Sans Pro'
          },
          tooltipContent: {
            padding: '5px'
          }
        }}
      />
    </div>

  );
}

SAJoyride.defaultProps = {
  setResetTourHandler: () => () => {}
};

export default SAJoyride;
