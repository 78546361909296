import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import * as d3 from 'd3';
import useD3 from '../../common/state/useD3';
import fullHeartIcon from '../../assets/full-heart.png';
import emptyHeartIcon from '../../assets/empty-heart.png';
import thumbUpIcon from '../../assets/thumb-up.png';
import thumbDownIcon from '../../assets/thumb-down.png';
import styles from '../styles/GaugeGraph.module.scss';

const labels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

function GaugeGraph({
  segments, score, showIcons, iconSet
}) {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(500);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth);
    }
  }, [containerRef]);

  const resizeRef = useCallback((node) => {
    if (node !== null) {
      const resizeObserver = new ResizeObserver(() => {
        setWidth(node.clientWidth);
      });
      resizeObserver.observe(node);
    }
  }, []);

  const height = 300;

  const getChart = (svg) => {
    svg.selectAll('text.value').remove();

    const margin = ({
      top: 30, right: 20, bottom: 30, left: 20
    });

    const x = d3.scaleBand()
      .domain(d3.range(segments.length))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const labelScale = d3.scaleLinear()
      .domain([0, labels.length - 1])
      .range([margin.left, width - margin.right]);

    const y = d3.scaleLinear()
      .domain([0, +d3.max(segments, (d) => d.count)]).nice()
      .range([height - margin.bottom, margin.top]);

    const xAxis = (g) => g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(labelScale).tickFormat((i) => labels[i]));

    // If we want to show a y-axis, we can uncomment this
    // const yAxis = (g) => g
    //   .attr('transform', `translate(${margin.left},0)`)
    //   .call(d3.axisLeft(y).ticks())
    //   .call((g) => g.select('.domain').remove())
    //   .call((g) => g.append('text')
    //     .attr('x', -margin.left)
    //     .attr('y', 10)
    //     .attr('fill', 'currentColor')
    //     .attr('text-anchor', 'start')
    //     .text('Number of Ratings'));

    svg.attr('viewbox', `0 0 ${width} ${height}`);
    svg.select('.plot-area')
      .selectAll('rect')
      .data(segments)
      .join('rect')
      .attr('class', (d) => {
        if (d.start_number > 40) {
          return `bar ${styles.highBar}`;
        }
        return `bar ${styles.lowBar}`;
      })
      .attr('x', (d, i) => x(i))
      .attr('y', (d) => y(d.count))
      .attr('height', (d) => y(0) - y(d.count))
      .attr('width', x.bandwidth());

    svg.selectAll('.rect')
      .data(segments)
      .enter()
      .append('text')
      .attr('class', `value ${styles.count}`)
      .attr('text-anchor', 'middle')
      .attr('x', (d, i) => x(i) + (x.bandwidth() / 2))
      .attr('y', (d) => y(d.count) - 10)
      .text((d) => (d.count > 0 ? d.count : null));

    svg.select('.x-axis')
      .call(xAxis);

    // If we want to show a y-axis, we can uncomment this
    // svg.select('.y-axis')
    //   .call(yAxis);

    return svg.node();
  };

  const ref = useD3(getChart, [segments.reduce((acc, segment) => acc + segment.count, 0), width]);

  const getLowIconSrc = () => {
    switch (iconSet) {
      case 'thumbs':
        return thumbDownIcon;
      case 'hearts':
      default:
        return emptyHeartIcon;
    }
  };

  const getHighIconSrc = () => {
    switch (iconSet) {
      case 'thumbs':
        return thumbUpIcon;
      case 'hearts':
      default:
        return fullHeartIcon;
    }
  };

  return (
    <div
      className={styles.container}
      // @ts-ignore
      ref={(el) => { containerRef.current = el; resizeRef(el); }}
    >
      <svg
        ref={ref}
        style={{
          height,
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <g className="plot-area" />
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
      {showIcons && (
        <div className={styles.bottom}>
          <div
            className={styles.icon}
            style={{ backgroundImage: `url(${getLowIconSrc()})` }}
          />
          {score != null ? (
            <div className={styles.scoreContainer}>
              <div className={styles.score}>{score}</div>
              <div className={styles.scoreLabel}>AVERAGE</div>
            </div>
          ) : null}
          <div
            className={styles.icon}
            style={{ backgroundImage: `url(${getHighIconSrc()})` }}
          />
        </div>
      )}
    </div>
  );
}

GaugeGraph.defaultProps = {
  showIcons: false,
  score: null,
  iconSet: 'hearts'
};

export default GaugeGraph;
