import { useEffect, useState } from 'react';
import { App } from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import { anonymousLogin } from './api';

const useAnonymousHandler = () => {
  const { message } = App.useApp();
  const { state: userState, dispatch: userDispatch } = useUserContext();
  const [afterAnon, setAfterAnon] = useState(null);
  const [afterParams, setAfterParams] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const finish = async () => {
      await afterAnon(...afterParams);
      setAfterAnon(null);
      setAfterParams(null);
    };
    if (userState.isLoggedIn && afterAnon && afterParams) {
      finish();
    }
  }, [userState.isLoggedIn, afterAnon, afterParams]);

  const continueAnonymously = async (doAfterContinue, ...params) => {
    if (!userState.isLoggedIn && !loading) {
      const loadingKey = 'continueAnonymouslyRequest';
      message.destroy(loadingKey);

      setLoading(true);
      message.loading({ content: 'Responding anonymously...', key: loadingKey, duration: 0 });
      const userInfo = await anonymousLogin();
      setAfterAnon(() => doAfterContinue);
      let paramList = [];
      if (params) {
        paramList = [...params];
      }
      setAfterParams(paramList);
      message.destroy(loadingKey);
      setLoading(false);
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
    } else if (userState.isLoggedIn) {
      doAfterContinue(...params);
    }
  };

  return continueAnonymously;
};

export default useAnonymousHandler;
