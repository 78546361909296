import React from 'react';
import { useUserContext } from '../../user/providers/UserProvider';
import AuthForm from '../../auth/components/AuthForm';
import VerifyForm from '../../auth/components/VerifyForm';
import AuthInfo from '../../auth/components/AuthInfo';

function AuthContainer() {
  const { state: userState } = useUserContext();
  if (!userState.isLoggedIn) {
    return <AuthForm />;
  }
  if (!userState.isVerified) {
    return <VerifyForm />;
  }
  return <AuthInfo />;
}

export default AuthContainer;
