import useSWR from 'swr';

function useQutee(quteeId, isLoggedIn) {
  const { data, error, mutate } = useSWR(
    quteeId ? [`qutees/${quteeId}?expand=profile,polls,gauges`, isLoggedIn] : null,
    { revalidateIfStale: false }
  );

  return {
    qutee: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useQutee;
