/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useFetch, { CachePolicies } from 'use-http';
import { App, Popover, Typography } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { ApiException, deepCopy } from '../../common/utils';
import Image from '../../common/view/Image';
import { useUserContext } from '../../user/providers/UserProvider';
import useQuteeGauges from '../../qutee/state/useQuteeGauges';
import styles from '../styles/ReorderGauges.module.scss';

const { Text } = Typography;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function ReorderGauges({ quteeId }) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [isLoading, setLoading] = useState(false);
  const [gauges, setGauges] = useState([]);
  const gaugesRef = useRef(gauges);

  const titlePlaceholder = '(No Title)';

  const {
    gauges: gaugesData,
    isLoading: isLoadingGauges,
    mutate: mutateGauges,
    isError: isErrorGauges
  } = useQuteeGauges(quteeId, userState.isLoggedIn ? userState.profile : null);

  const updateGauges = (newGauges) => {
    gaugesRef.current = newGauges;
    setGauges(newGauges);
  };

  useEffect(() => {
    const g = deepCopy(gaugesData);
    updateGauges(g);
  }, [gaugesData]);

  const { patch: repositionGauge, response: repositionGaugeResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const onRepositionGauge = async (gaugeId, position) => {
    const data = {
      // The component internally uses 0-based positions
      // We use 1-based positions in the API
      position: position + 1
    };
    const result = await repositionGauge(`${gaugeId}`, data);
    if (repositionGaugeResponse.ok) {
      mutateGauges();
    } else {
      throw new ApiException(result);
    }
  };

  const dragDisabled = () => isLoadingGauges || isLoading;

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      gaugesRef.current,
      result.source.index,
      result.destination.index
    );
    updateGauges(items);

    const execApi = async (gaugeId, index) => {
      const loadingKey = 'repositionGaugeRequest';
      const successKey = 'repositionGaugeSuccess';
      const errorKey = 'repositionGaugeError';
      message.destroy(loadingKey);
      message.destroy(successKey);
      message.destroy(errorKey);

      setLoading(true);
      message.loading({ content: 'Repositioning gauge...', key: loadingKey });
      try {
        await onRepositionGauge(gaugeId, index);
        message.destroy(loadingKey);
        message.success({ content: 'Gauge successfully repositioned', key: successKey });
      } catch (err) {
        message.destroy(loadingKey);
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      } finally {
        setLoading(false);
      }
    };
    // draggableId is a string version of gaugeId
    execApi(parseInt(draggableId, 10), destination.index);
  };

  const getImage = (gauge, index) => {
    if (!gauge.thumbnail_url && !gauge.image_url) {
      return null;
    }

    const thumbnail = (
      <Image
        className={styles.thumbnail}
        src={gauge.thumbnail_url || gauge.image_url}
        alt={`${gauge.title || `gauge ${index + 1}`} thumbnail`}
        title={`${gauge.title || `gauge ${index + 1}`} thumbnail`}
      />
    );

    if (gauge.image_url) {
      return (
        <Popover
          placement="left"
          trigger="hover"
          content={(
            <Image
              className={styles.image}
              src={gauge.image_url}
              alt={`${gauge.title || `gauge ${index + 1}`} image`}
              title={`${gauge.title || `gauge ${index + 1}`} image`}
            />
          )}
        >
          {thumbnail}
        </Popover>
      );
    }

    return thumbnail;
  };

  const renderGauge = (gauge, index) => (
    <div>
      <Text className={styles.title}>
        {gauge.title || titlePlaceholder}
      </Text>
      {getImage(gauge, index)}
    </div>
  );

  if (isErrorGauges) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`${quteeId}-gauges`}>
        {(provided) => (
          <div
            className={styles.container}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {gaugesRef.current.map((gauge, index) => (
              <Draggable
                key={gauge.gauge_id}
                draggableId={`${gauge.gauge_id}`}
                index={index}
                isDragDisabled={dragDisabled()}
              >
                {(provided) => (
                  <div
                    className={styles.dragContainer}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div className={styles.dragHandle} {...provided.dragHandleProps}>
                      {!dragDisabled() && Array.from(Array(2), (_, i) => (
                        <MoreOutlined key={i} className={styles.dragIcon} />
                      ))}
                    </div>
                    {renderGauge(gauge, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

ReorderGauges.displayName = 'Reorder Gauges';

ReorderGauges.defaultProps = {};

export default ReorderGauges;
