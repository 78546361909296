/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useFetch, { CachePolicies } from 'use-http';
import { App, Typography } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { ApiException, deepCopy } from '../../common/utils';
import { useUserContext } from '../../user/providers/UserProvider';
import useQuteeCommentBins from '../../qutee/state/useQuteeCommentBins';
import styles from '../styles/ReorderCommentBins.module.scss';

const { Text } = Typography;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function ReorderCommentBins({ quteeId }) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [isLoading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const questionsRef = useRef(questions);

  const titlePlaceholder = '(No Title)';
  const subtitlePlaceholder = '(No Subtitle)';

  const {
    commentBins,
    isLoading: isLoadingCommentBins,
    mutate: mutateCommentBins,
    isError: isErrorCommentBins
  } = useQuteeCommentBins(quteeId, userState.isLoggedIn ? userState.profile.profile_id : null);

  const updateQuestions = (newQuestions) => {
    questionsRef.current = newQuestions;
    setQuestions(newQuestions);
  };

  useEffect(() => {
    const bins = deepCopy(commentBins);
    updateQuestions(bins);
  }, [commentBins]);

  const { patch: repositionQuestion, response: repositionQuestionResponse } = useFetch('comment-bins', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const onRepositionQuestion = async (commentBinId, position) => {
    const data = {
      // The component internally uses 0-based positions
      // We use 1-based positions in the API
      position: position + 1
    };
    const result = await repositionQuestion(`${commentBinId}`, data);
    if (repositionQuestionResponse.ok) {
      mutateCommentBins();
    } else {
      throw new ApiException(result);
    }
  };

  const dragDisabled = () => isLoadingCommentBins || isLoading;

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      questionsRef.current,
      result.source.index,
      result.destination.index
    );
    updateQuestions(items);

    const execApi = async (commentBinId, index) => {
      const loadingKey = 'repositionQuestionRequest';
      const successKey = 'repositionQuestionSuccess';
      const errorKey = 'repositionQuestionError';
      message.destroy(loadingKey);
      message.destroy(successKey);
      message.destroy(errorKey);

      setLoading(true);
      message.loading({ content: 'Repositioning question...', key: loadingKey });
      try {
        await onRepositionQuestion(commentBinId, index);
        message.destroy(loadingKey);
        message.success({ content: 'Question successfully repositioned', key: successKey });
      } catch (err) {
        message.destroy(loadingKey);
        message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
      } finally {
        setLoading(false);
      }
    };
    // draggableId is a string version of commentBinId
    execApi(parseInt(draggableId, 10), destination.index);
  };

  const renderQuestion = (question) => (
    <div>
      <div>
        <Text className={styles.title}>
          {question.title || titlePlaceholder}
        </Text>
      </div>
      <div>
        <Text className={styles.subtitle}>
          {question.subtitle || subtitlePlaceholder}
        </Text>
      </div>
    </div>
  );

  if (isErrorCommentBins) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`${quteeId}-bins`}>
        {(provided) => (
          <div
            className={styles.container}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {questionsRef.current.map((question, index) => (
              <Draggable
                key={question.comment_bin_id}
                draggableId={`${question.comment_bin_id}`}
                index={index}
                isDragDisabled={dragDisabled()}
              >
                {(provided) => (
                  <div
                    className={styles.dragContainer}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div className={styles.dragHandle} {...provided.dragHandleProps}>
                      {!dragDisabled() && Array.from(Array(2), (_, i) => (
                        <MoreOutlined key={i} className={styles.dragIcon} />
                      ))}
                    </div>
                    {renderQuestion(question)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

ReorderCommentBins.displayName = 'Reorder Questions';

ReorderCommentBins.defaultProps = {};

export default ReorderCommentBins;
