import { unpackRules } from '@casl/ability/extra';
import {
  EmailAuthProvider, createUserWithEmailAndPassword, updateProfile, setPersistence,
  browserLocalPersistence, signInWithEmailAndPassword, signInAnonymously, applyActionCode,
  getAdditionalUserInfo, confirmPasswordReset, checkActionCode, signInWithPopup,
  linkWithCredential, linkWithPopup, signInWithCustomToken
} from 'firebase/auth';
import {
  auth, googleProvider, facebookProvider
} from '../../common/infra/firebase';
import fetcher from '../../common/infra/fetcher';

// Create User and send verification email
const createUser = async ({
  username, email, password, continueUrl, actionUrl,
  hash = null, quteeId = null
}) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const { user } = userCredential;
  const token = await user.getIdToken();
  // Create profile in API
  const args = {
    username
  };
  if (hash && quteeId) {
    args.hash = hash;
    args.qutee_id = quteeId;
    args.email = email;
  }
  const newProfile = await fetcher(
    'profiles',
    {
      method: 'POST',
      headers: {
        Authorization: token,
        Prefer: 'return=representation'
      },
      credentials: 'include'
    },
    args
  );

  // bypass normal email verification if user has an access code;
  // verification will happen server-side.
  if (!hash || !quteeId) {
    // Send verification email
    await fetcher('auth/verify', {
      method: 'POST',
      headers: {
        Authorization: token
      }
    }, {
      action_url: actionUrl || window.location.href,
      continue_url: continueUrl || window.location.href
    });
  }

  // update firebase display name with api username
  await updateProfile(user, {
    displayName: username
  });

  localStorage.setItem('logged-in-user', JSON.stringify({ username: newProfile.username, show_new_user_onboard: true }));
  return newProfile;
};

// Log in via firebase; use firebase's onAuthStateChanged to handle api login
const firebaseLogin = async ({ email, password }) => {
  await setPersistence(auth, browserLocalPersistence);
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  return userCredential;
};

// Requires firebase auth beforehand. Logs in to the API
const apiLogin = async ({ hash = null, quteeId = null, email = null } = {}) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('Unauthenticated');
  }

  const token = await user.getIdToken(true);
  const loginResponse = await fetcher(
    'auth/login',
    {
      method: 'POST',
      headers: {
        Authorization: token
      },
      credentials: 'include'
    },
    (hash && quteeId && email) ? {
      hash,
      email,
      qutee_id: quteeId
    } : undefined
  );
  const { profile, permissions } = loginResponse;

  const role = profile.account_type;
  const rules = unpackRules(permissions);
  const userData = JSON.parse(localStorage.getItem('logged-in-user'));
  if (userData) {
    userData.username = profile.username;
    localStorage.setItem('logged-in-user', JSON.stringify(userData));
  } else {
    localStorage.setItem('logged-in-user', JSON.stringify({ username: profile.username }));
  }

  return {
    profile,
    role,
    rules
  };
};

const cookieLogin = async () => {
  const customToken = await fetcher('auth/custom-token', {
    method: 'POST',
    credentials: 'include'
  });
  const credential = await signInWithCustomToken(auth, customToken);
  const token = await credential.user.getIdToken(true);
  const loginResponse = await fetcher('auth/login', {
    method: 'POST',
    headers: {
      Authorization: token
    },
    credentials: 'include'
  });
  const { profile, permissions } = loginResponse;

  const role = profile.account_type;
  const rules = unpackRules(permissions);
  const userData = JSON.parse(localStorage.getItem('logged-in-user'));
  if (userData) {
    userData.username = profile.username;
    localStorage.setItem('logged-in-user', JSON.stringify(userData));
  } else {
    localStorage.setItem('logged-in-user', JSON.stringify({ username: profile.username }));
  }

  return {
    profile,
    role,
    rules
  };
};

const fullLogin = async ({
  email, password, hash = null, quteeId = null
}) => {
  await firebaseLogin({ email, password });
  const userInfo = await apiLogin({ email, hash, quteeId });
  return userInfo;
};

const anonymousLogin = async () => {
  const userCredential = await signInAnonymously(auth);
  const { user } = userCredential;
  const additionalUserInfo = getAdditionalUserInfo(userCredential);
  const token = await user.getIdToken(true);
  // Create profile in API if first time
  if (additionalUserInfo.isNewUser) {
    await fetcher(
      'profiles',
      {
        method: 'POST',
        headers: {
          Authorization: token,
          Prefer: 'return=representation'
        },
        credentials: 'include'
      },
      {
        anonymous: true
      }
    );
  }

  const loginResponse = await fetcher('auth/login', {
    method: 'POST',
    headers: {
      Authorization: token
    },
    credentials: 'include'
  });
  const { profile, permissions } = loginResponse;

  const role = profile.account_type;
  const rules = unpackRules(permissions);

  return {
    profile,
    role,
    rules
  };
};

const sendVerificationEmail = (continueUrl, actionUrl) => new Promise((resolve, reject) => {
  const user = auth.currentUser;
  if (user) {
    user.getIdToken(true)
      .then((token) => {
        fetcher('auth/verify', {
          method: 'POST',
          headers: {
            Authorization: token
          }
        }, {
          action_url: actionUrl || window.location.href,
          continue_url: continueUrl || window.location.href
        })
          .then(() => resolve())
          .catch((error) => reject(error));
      });
  } else {
    reject(new Error('not-logged-in'));
  }
});

// Verify user with unique code which sent on email
const verifyEmail = ({ code }) => new Promise((resolve, reject) => {
  applyActionCode(auth, code)
    .then(() => {
      const user = auth.currentUser;
      if (user) {
        // Do another API login call to refresh the auth token
        // such that it recognizes the email being verified.
        user.getIdToken(true)
          .then((token) => {
            fetcher('auth/login', {
              method: 'POST',
              headers: {
                Authorization: token
              }
            })
              .then((result) => {
                const { profile, permissions } = result;

                const role = profile.account_type;
                const rules = unpackRules(permissions);
                localStorage.setItem('logged-in-user', JSON.stringify({ username: profile.username, show_new_user_onboard: true }));
                resolve({
                  profile,
                  role,
                  rules
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => reject(error));
      } else {
        reject(new Error('not-logged-in'));
      }
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
          // Should trigger a prompt to resend email
          reject(new Error('RESEND'));
          break;
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          // User disabled or deleted, do nothing
          reject(error);
          break;
        default:
          // Network error or some other unexpected error
          reject(error);
      }
    });
});

// Sign out functionality
const signOut = () => new Promise((resolve, reject) => {
  localStorage.removeItem('logged-in-user');
  fetcher('auth/logout', {
    method: 'POST',
    credentials: 'include'
  })
    .then((profile) => {
      auth.signOut()
        .then(() => resolve(profile));
    })
    .catch((error) => reject(error));
});

const clientSignOut = async () => {
  await auth.signOut();
};

const sendPasswordResetEmail = (email, continueUrl, actionUrl) => new Promise((resolve, reject) => {
  fetcher('auth/reset-password', {
    method: 'POST'
  }, {
    email,
    action_url: actionUrl || window.location.href,
    continue_url: continueUrl || window.location.href
  })
    .then(() => resolve())
    .catch((error) => reject(error));
});

// confirm password functionality with new password
const confirmUserPasswordReset = ({ code, newPassword }) => new Promise((resolve, reject) => {
  confirmPasswordReset(auth, code, newPassword)
    .then(() => resolve())
    .catch((error) => reject(error));
});

const revokeEmailAddressChange = (code) => new Promise((resolve, reject) => {
  checkActionCode(auth, code)
    .then((info) => {
      const restoredEmail = info.data.email;
      applyActionCode(auth, code)
        .then(() => resolve(restoredEmail))
        .catch((error) => reject(error));
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
          // Code is invalid; should we trigger another email or something?
          reject(new Error('invalid'));
          break;
        default:
          reject(error);
      }
    });
});

// check if current session is active
const getCurrentAuthenticatedUser = () => new Promise((resolve, reject) => {
  fetcher('profiles/me')
    .then((result) => {
      const profile = result;
      const loggedInUser = JSON.parse(localStorage.getItem('logged-in-user'));
      const userData = {
        username: profile.username
      };
      if (loggedInUser && loggedInUser.show_new_user_onboard) {
        userData.show_new_user_onboard = loggedInUser.show_new_user_onboard;
      }
      localStorage.setItem('logged-in-user', JSON.stringify(userData));
      resolve(profile);
    })
    .catch(() => reject(new Error('Unauthenticated')));
});

const getSocialsResponse = async ({ provider, hintEmail = null }) => {
  const handleSignupOrLogin = async (userCredential, provider) => {
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    const { isNewUser } = additionalUserInfo;
    const { user } = userCredential;

    const token = await user.getIdToken();
    if (isNewUser) {
      await fetcher(
        'profiles',
        {
          method: 'POST',
          headers: {
            Authorization: token
          },
          credentials: 'include'
        },
        {
          username: user.displayName
        }
      );
    }

    const result = await fetcher('auth/login', {
      method: 'POST',
      headers: {
        Authorization: token
      },
      credentials: 'include'
    });

    const { profile, permissions } = result;

    const role = profile.account_type;
    const rules = unpackRules(permissions);

    if (isNewUser && provider === 'Facebook') {
      user.reload();
    }

    const data = {
      username: profile.username
    };
    if (isNewUser) {
      data.show_new_user_onboard = true;
    }
    localStorage.setItem('logged-in-user', JSON.stringify(data));

    return {
      profile,
      role,
      rules
    };
  };
  switch (provider) {
    case 'Facebook': {
      const userCredentials = await signInWithPopup(auth, facebookProvider);
      const userInfo = await handleSignupOrLogin(userCredentials, provider);
      return userInfo;
    }
    case 'Google': {
      if (hintEmail) {
        googleProvider.setCustomParameters({ login_hint: hintEmail });
      }
      const userCredentials = await signInWithPopup(auth, googleProvider);
      const userInfo = await handleSignupOrLogin(userCredentials, provider);
      return userInfo;
    }
    default:
      return new Error('Invalid auth provider specified.');
  }
};

const upgradeAnon = async ({
  username, email, password, continueUrl, actionUrl
}) => {
  const credential = await EmailAuthProvider.credential(email, password);
  const userCredential = await linkWithCredential(auth.currentUser, credential);
  const { user } = userCredential;
  const token = await user.getIdToken();
  // Upgrade anonymous profile in API
  const profile = await fetcher(
    'auth/upgrade-anonymous',
    {
      method: 'POST',
      headers: {
        Authorization: token,
        Prefer: 'return=representation'
      },
      credentials: 'include'
    },
    {
      username
    }
  );

  // Send verification email
  await fetcher('auth/verify', {
    method: 'POST',
    headers: {
      Authorization: token
    }
  }, {
    action_url: actionUrl || window.location.href,
    continue_url: continueUrl || window.location.href
  });

  // update firebase display name with api username
  await updateProfile(user, {
    displayName: username
  });

  localStorage.setItem('logged-in-user', JSON.stringify({ username: profile.username, show_new_user_onboard: true }));
  return profile;
};

const upgradeAnonSocial = async ({ provider }) => {
  const handleUpgrade = async (userCredential, provider) => {
    const { user } = userCredential;

    const token = await user.getIdToken();

    let name = user.displayName;
    if (!name) {
      name = user.providerData[0].displayName;
    }

    await fetcher(
      'auth/upgrade-anonymous',
      {
        method: 'POST',
        headers: {
          Authorization: token
        },
        credentials: 'include'
      },
      {
        username: name
      }
    );

    const result = await fetcher('auth/login', {
      method: 'POST',
      headers: {
        Authorization: token
      },
      credentials: 'include'
    });

    const { profile, permissions } = result;

    const role = profile.account_type;
    const rules = unpackRules(permissions);

    if (provider === 'Facebook') {
      user.reload();
    }

    const data = {
      username: profile.username
    };

    localStorage.setItem('logged-in-user', JSON.stringify(data));

    return {
      profile,
      role,
      rules
    };
  };
  switch (provider) {
    case 'Facebook': {
      const userCredentials = await linkWithPopup(auth.currentUser, facebookProvider);
      const userInfo = await handleUpgrade(userCredentials, provider);
      return userInfo;
    }
    case 'Google': {
      const userCredentials = await linkWithPopup(auth.currentUser, googleProvider);
      const userInfo = await handleUpgrade(userCredentials, provider);
      return userInfo;
    }
    default:
      return new Error('Invalid auth provider specified.');
  }
};

export {
  createUser,
  firebaseLogin,
  apiLogin,
  cookieLogin,
  fullLogin,
  sendVerificationEmail,
  verifyEmail,
  signOut,
  clientSignOut,
  sendPasswordResetEmail,
  confirmUserPasswordReset as confirmPasswordReset,
  revokeEmailAddressChange,
  getCurrentAuthenticatedUser,
  getSocialsResponse,
  anonymousLogin,
  upgradeAnon,
  upgradeAnonSocial
};
