import React from 'react';
import { Button, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useQutee from '../../qutee/state/useQutee';
import { useUserContext } from '../../user/providers/UserProvider';
import { isValidHttpUrl } from '../utils';
import styles from './styles/Preheader.module.scss';

function Preheader({
  quteeId
}) {
  const { state: userState } = useUserContext();

  const {
    qutee
  } = useQutee(quteeId, userState.isLoggedIn);

  if (!qutee?.settings?.preheader_main_text) {
    return null;
  }

  return (
    <div className={styles.preheader}>
      {qutee?.settings?.preheader_main_text}
      {qutee?.settings?.preheader_sub_text ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isValidHttpUrl(qutee?.settings?.preheader_sub_text) ? (
            <Button
              className={styles.anchor}
              type="link"
              size="small"
              href={qutee?.settings?.preheader_sub_text}
              target="_blank"
              rel="noreferrer"
              icon={<InfoCircleOutlined />}
            />
          ) : (
            <Popover
              content={qutee?.settings?.preheader_sub_text}
              trigger={['hover', 'click']}
            >
              <span className={styles.popoverInner}>
                <InfoCircleOutlined className={styles.popoverIcon} />
              </span>
            </Popover>
          )}
        </>
      ) : null}
    </div>
  );
}

Preheader.defaultProps = {
  allowAnonymous: false,
  resetTourHandler: null,
  setShowSettingsModal: undefined
};

export default Preheader;
