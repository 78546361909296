import React from 'react';
import queryString from 'query-string';
import ConfirmPassword from '../../auth/components/ConfirmPassword';
import VerifyEmail from '../../auth/components/VerifyEmail';
// import { RecoverEmail } from '../../auth/components/RecoverEmail';

function ActionsContainer({ afterActionHook, notLoggedInHook }) {
  const queryValues = queryString.parse(window.location.search);
  const { mode } = queryValues;
  const actionCode = queryValues.oobCode;

  switch (mode) {
    // case 'recoverEmail':
    //   return <RecoverEmail actionCode={actionCode} afterActionHook={afterActionHook} />;
    case 'resetPassword':
      return <ConfirmPassword actionCode={actionCode} afterActionHook={afterActionHook} />;
    case 'verifyEmail':
      return (
        <VerifyEmail
          actionCode={actionCode}
          afterActionHook={afterActionHook}
          notLoggedInHook={notLoggedInHook}
        />
      );
    default:
      // TODO: Use antd for a better error display
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>No valid action provided.</p>
        </div>
      );
  }
}

ActionsContainer.defaultProps = {
  afterActionHook: () => {},
  notLoggedInHook: () => {}
};

export default ActionsContainer;
