import useSWR from 'swr';

function useReplies(commentBinId, userId, date) {
  const {
    data, error, mutate, isLoading, isValidating
  } = useSWR(
    (commentBinId && userId && date)
      ? [`comment-bins/${commentBinId}/threads?filter[created_date]=gt:${date}&filter[parent_comment.profile_id]=${userId}&expand=parent_comment.profile`]
      : null,
    {
      revalidateIfStale: false
    }
  );

  return {
    replyThreads: data?.results,
    numComments: data?.pageInfo?.total || 0,
    mutate,
    isLoading,
    isValidating,
    isError: error
  };
}

export default useReplies;
