import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import {
  Affix, Alert, Button, Collapse, Drawer, Dropdown,
  FloatButton,
  Modal, Progress, Spin, Tabs, Tooltip, Typography
} from 'antd';
import {
  FilePdfOutlined, LeftCircleOutlined,
  RightCircleOutlined, SettingOutlined, CheckOutlined,
  DownOutlined, ExclamationCircleFilled
} from '@ant-design/icons/lib/icons';
import {
  IconForms, IconEdit, IconGauge, IconCircleCheck, IconSelector
} from '@tabler/icons-react';
import { CSSTransition } from 'react-transition-group';
import { useUserContext } from '../../user/providers/UserProvider';
import useQuteeProgress from '../state/useQuteeProgress';
import useSharingPermissions from '../../user/state/useSharingPermissions';
import EmbedContext from '../../embed/providers/EmbedProvider';
import QuteePolls from './QuteePolls';
import QuteeGauges from './QuteeGauges';
import CommentBin from '../../commentBin/components/CommentBin';
import CommentBinSelector from '../../commentBin/components/CommentBinSelector';
import CreateCommentBin from '../../commentBin/components/CreateCommentBin';
import ReorderCommentBins from '../../commentBin/components/ReorderCommentBins';
import InsightsReportBlurb from './InsightsReportBlurb';
import InsightsReportView from './InsightsReportView';
import InsightsReportButton from './InsightsReportButton';
import { useIsXl } from '../../common/state/responsiveChecks';
import BannerBundle from '../../common/view/BannerBundle';
import CreateGauge from '../../gauge/components/CreateGauge';
import ReorderGauges from '../../gauge/components/ReorderGauges';
import CreatePoll from '../../poll/components/CreatePoll';
import ReorderPolls from '../../poll/components/ReorderPolls';
import QuteeGeneralSettings from './QuteeGeneralSettings';
import CustomStyleEditor from './CustomStyleEditor';
import QuteeImageSettings from './QuteeImageSettings';
import QuteeTeamSettings from './QuteeTeamSettings';
import SharingPermissionsModal from '../../user/components/SharingPermissionsModal';
import Preheader from '../../common/view/Preheader';
import styles from '../styles/Quteev1.module.scss';
import useQutee from '../state/useQutee';
import Image from '../../common/view/Image';
import chatAltIcon from '../../assets/alticon.png';

const { Text } = Typography;

function Quteev1({
  quteeId, quteeTitle, commentBins, isLoadingCommentBins, mutateCommentBins,
  getReport, getLatestReport, getReportProgress, generateReport, createGauge, createPoll,
  userCanInteract, cantInteractReason, userCanEditQutee, labelEditingDisabled,
  checkUserCanEditComment, allowAnonymous, tourHandler,
  hideBanners, hideGauges, hidePolls, useSimpleGauge, gaugeType,
  continueAnonymously, pollCount, gaugeCount, quteeOwnerId, quteeOwnerName, quteeOwnerRole,
  giveawayUrl, setJoyrideIndex, commentDialogStateHandler,
  commentDialogState, triggerRepositionJoyride, previewDialogState
}) {
  const { state: userState } = useUserContext();
  const embedConfig = useContext(EmbedContext);

  const {
    defaultPageMobile, useProgressAffix, affixTargetContainerId, hideGaugesDesktop,
    extraGaugesContent, hideGaugeTitle
  } = embedConfig;

  const getDefaultPage = useCallback(() => {
    if (defaultPageMobile) {
      const page = defaultPageMobile;
      if (page === 'questions' || page === 'polls' || page === 'gauges') {
        return page;
      }
    }
    return 'questions';
  }, [defaultPageMobile]);

  const isLargeScreen = useIsXl();
  const [showQuestionDrawer, setShowQuestionDrawer] = useState(false);
  const [showCreatePollModal, setShowCreatePollModal] = useState(false);
  const [showReorderPollsModal, setShowReorderPollsModal] = useState(false);
  const [showCreateGaugeModal, setShowCreateGaugeModal] = useState(false);
  const [showReorderGaugesModal, setShowReorderGaugesModal] = useState(false);
  const [shouldRefreshPolls, setShouldRefreshPolls] = useState(false);
  const [shouldRefreshGauges, setShouldRefreshGauges] = useState(false);
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState({});
  const [selectedCommentBinId, setSelectedCommentBinId] = useState(null);
  const [showAddBinModal, setShowAddBinModal] = useState(false);
  const [showReorderBinsModal, setShowReorderBinsModal] = useState(false);
  const [nextBinHighlighted, setNextBinHighlighted] = useState(false);
  const [refreshBinData, setRefreshBinData] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [activePage, setActivePage] = useState(getDefaultPage());
  const [isLoadingFilters, setLoadingFilters] = useState(false);
  const [topNavOffset, setTopNavOffset] = useState(59);
  // Due to a deficiency in swr as of 5/15/2023, we can not easily mutate SWRInfinite hooks easily.
  // As a workaround, we will store a state for its bound mutate to access elsewhere
  const [mutateCommentsGlobal, setMutateCommentsGlobal] = useState(() => () => {});
  const [showContinueOnRatings, setShowContinueOnRatings] = useState(true);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const scrollRef = useRef();
  const questionsTransitionRef = useRef();
  const pollsTransitionRef = useRef();
  const gaugesTransitionRef = useRef();
  const reportTransitionRef = useRef();
  const pinpointRef = useRef();
  const [triggerCommentBinPinpoint, setTriggerCommentBinPinpoint] = useState(false);
  const [triggerPollPinpoint, setTriggerPollPinpoint] = useState(false);
  const [triggerGaugePinpoint, setTriggerGaugePinpoint] = useState(false);
  const progressCommentBinRef = useRef();
  const progressPollRef = useRef();
  const progressGaugeRef = useRef();
  const [pinpointTimeoutId, setPinpointTimeoutId] = useState(null);
  const [pinpointAnimation, setPinpointAnimation] = useState(null);

  // Permissions are determined by the sayso owner's role, other than admin
  const canEditStyle = userCanEditQutee
    && (quteeOwnerRole === 'essential-team' || quteeOwnerRole === 'complete-team'
    || quteeOwnerRole === 'admin' || userState.role === 'admin');
  const canEditAccess = userCanEditQutee
    && (quteeOwnerRole === 'complete-team' || quteeOwnerRole === 'admin'
    || userState.role === 'admin');
  const canDownloadReport = userCanEditQutee
    && (quteeOwnerRole === 'limited-team' || quteeOwnerRole === 'essential-team'
    || quteeOwnerRole === 'complete-team' || quteeOwnerRole === 'admin'
    || userState.role === 'admin');
  const displaySharingPermissions = quteeOwnerRole === 'essential-team'
    || quteeOwnerRole === 'complete-team' || quteeOwnerRole === 'admin';
  const canChat = userCanEditQutee
    && (quteeOwnerRole === 'complete-team' || userState.role === 'admin');

  const {
    progress,
    mutate: mutateProgress,
    isLoading: isLoadingProgress,
    isError: isErrorProgress
  } = useQuteeProgress(
    userState.isLoggedIn ? quteeId : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const {
    qutee,
    mutate: quteeMutate
  } = useQutee(quteeId, userState.isLoggedIn);

  const {
    sharingPermissions,
    isLoading: isLoadingSharingPermissions
  } = useSharingPermissions(
    // Need to be logged in, but API call requires the qutee owner's id.
    quteeOwnerId && displaySharingPermissions && userState.isLoggedIn ? quteeOwnerId : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  useEffect(() => {
    if (userState.isLoggedIn && userState.isVerified
      && !isLoadingSharingPermissions && !sharingPermissions
      && quteeOwnerId && (userState?.profile?.profile_id !== quteeOwnerId)
      && displaySharingPermissions) {
      setShowPermissionsModal(true);
    }
  }, [sharingPermissions, isLoadingSharingPermissions, quteeOwnerId, displaySharingPermissions,
    userState.isLoggedIn, userState.isVerified, userState?.profile?.profile_id]);

  const topNavRef = useCallback((node) => {
    if (node !== null) {
      const resizeObserver = new ResizeObserver(() => {
        setTopNavOffset(node.clientHeight);
      });
      resizeObserver.observe(node);
    }
  }, []);

  const getProgressPercent = () => {
    if (!progress) {
      return 0;
    }

    const totalActivities = (progress.comment_bin_count * 5)
      + progress.poll_count + progress.gauge_count;
    const userProgress = (progress.responded_comment_bin_count * 5)
      + progress.responded_poll_count + progress.responded_gauge_count;

    if (totalActivities === 0 || userProgress === 0) {
      return 0;
    }

    const percent = Math.round((userProgress / totalActivities) * 100);
    return percent;
  };

  const getQuestionsProgress = () => {
    if (!progress || !progress.comment_bins) {
      return null;
    }
    const questionProgressMap = {};
    progress.comment_bins.forEach((bin) => {
      questionProgressMap[bin.comment_bin_id] = bin.responded;
    });
    return questionProgressMap;
  };

  const getChatIcon = () => (
    <Image
      className={styles.chatBotIcon}
      src={chatAltIcon}
      alt="Sebastian Alpha Chat Bot"
      title="Sebastian Alpha Chat Bot"
      width={32}
    />
  );
  const getChatDrawer = () => {
    // if logged in and owner of sayso or admin
    if (canChat) {
      let chatLink = '#';
      let badge = { count: '...' };
      if (qutee?.settings.chat_bot_id) {
        chatLink = `https://chat.socialasking.com/bot/${qutee?.settings.chat_bot_id}`;
        badge = { count: 'Chat' };
      } else {
        chatLink = 'https://help.socialasking.com/get-started/setting-up-the-ai-assistant/';
        badge = { count: '1', dot: true, color: 'red' };
      }
      return (
        <FloatButton
          className={styles.chatButton}
          icon={getChatIcon()}
          href={chatLink}
          target="_blank"
          badge={badge}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    setActivePage(getDefaultPage());
  }, [defaultPageMobile, getDefaultPage]);

  useEffect(() => {
    if (scrollRef?.current) {
      // @ts-ignore
      scrollRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [activePage]);

  useEffect(() => {
    if (commentBins && commentBins.length && !selectedCommentBinId) {
      setSelectedCommentBinId(commentBins[0].comment_bin_id);
    }
  }, [commentBins, selectedCommentBinId]);

  useEffect(() => {
    if (!selectedCommentBinId) {
      return;
    }
    if (!Object.prototype.hasOwnProperty.call(filter, selectedCommentBinId)) {
      setFilter((old) => ({
        ...old,
        [selectedCommentBinId]: ''
      }));
    }
    if (!Object.prototype.hasOwnProperty.call(filterData, selectedCommentBinId)) {
      setFilterData((old) => ({
        ...old,
        [selectedCommentBinId]: {
          connections: [], labels: [], topics: [], sentiments: [], search_term: {}
        }
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommentBinId]);

  const changeFilter = (value) => {
    setFilter((old) => ({
      ...old,
      [selectedCommentBinId]: value
    }));
  };

  const changeFilterData = (data) => {
    setFilterData((old) => ({
      ...old,
      [selectedCommentBinId]: data
    }));
  };

  const getSelectedBinIndex = useCallback(
    (
      () => ((commentBins && selectedCommentBinId)
        ? commentBins.map((bin) => bin.comment_bin_id)
          .indexOf(selectedCommentBinId)
        : null)
    ), [commentBins, selectedCommentBinId]
  );

  const getNumBins = useCallback(
    (
      () => (commentBins ? commentBins.length : 0)
    ), [commentBins]
  );

  const getCommentCount = useCallback(() => {
    if (commentBins && selectedCommentBinId) {
      return commentBins[getSelectedBinIndex()]?.comment_count;
    }
    return null;
  }, [commentBins, selectedCommentBinId, getSelectedBinIndex]);

  const selectBin = (binId) => {
    setSelectedCommentBinId(binId);
    setNextBinHighlighted(false);
  };

  const selectPreviousBin = () => {
    const index = getSelectedBinIndex();
    const id = commentBins[index - 1].comment_bin_id;
    setSelectedCommentBinId(id);
    setNextBinHighlighted(false);
  };

  const selectNextBin = () => {
    const index = getSelectedBinIndex();
    const id = commentBins[index + 1].comment_bin_id;
    setSelectedCommentBinId(id);
    setNextBinHighlighted(false);
  };

  const selectNextActivity = () => {
    setNextBinHighlighted(false);
    const index = getSelectedBinIndex();
    if (index === (commentBins.length - 1)) {
      setActivePage('polls');
    } else {
      const id = commentBins[index + 1].comment_bin_id;
      setSelectedCommentBinId(id);
    }
  };

  const hasPreviousBin = commentBins && selectedCommentBinId
    && getSelectedBinIndex() > 0;

  const hasNextBin = commentBins && selectedCommentBinId
    && getSelectedBinIndex() < (commentBins.length - 1);

  const carouselDots = commentBins
    ? commentBins.map((bin) => {
      const dotClassName = (bin.comment_bin_id === selectedCommentBinId)
        ? styles.activeDot : styles.dot;
      return (
        <span
          key={bin.comment_bin_id}
          className={styles.dotContainer}
          onClick={() => selectBin(bin.comment_bin_id)}
          onKeyDown={() => selectBin(bin.comment_bin_id)}
          role="button"
          tabIndex={0}
        >
          <span className={dotClassName} />
        </span>
      );
    })
    : [];

  const getCurrentQuestionStep = () => {
    if (!commentBins || !selectedCommentBinId) {
      return null;
    }
    const index = getSelectedBinIndex() + 1;
    const qp = getQuestionsProgress();
    let responded = false;
    if (progress && qp) {
      responded = qp[selectedCommentBinId];
    }

    const check = <CheckOutlined />;

    return (
      <div className={styles.currentStepItemIcon}>
        <span className={styles.currentStepIcon}>
          {responded ? check : index}
        </span>
      </div>
    );
  };

  const refreshCommentBins = () => {
    mutateCommentBins();
    setRefreshBinData(true);
  };

  const highlightNextCommentBinButton = () => {
    setNextBinHighlighted(true);
  };

  const pinpointSection = (ref, xOffset, yOffset) => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const top = rect.y + window.scrollY;
    // -70 to account for affixed progress plus extra space for easier viewing
    window.scrollTo({ top: top - 70, behavior: 'smooth' });

    if (pinpointTimeoutId) {
      clearTimeout(pinpointTimeoutId);
      if (pinpointAnimation) {
        pinpointAnimation.cancel();
      }
    }
    const timeoutId = setTimeout(() => {
      if (!pinpointRef.current) {
        return;
      }

      const newRect = ref.current.getBoundingClientRect();
      const newTop = newRect.y + window.scrollY;
      const newLeft = newRect.x;
      const particle = pinpointRef.current;
      // @ts-ignore
      particle.style.left = `${newLeft + xOffset}px`;
      // @ts-ignore
      particle.style.top = `${newTop + yOffset}px`;

      // @ts-ignore
      const animation = particle.animate([
        {
          opacity: 1
        },
        {
          opacity: 0.9,
          offset: 0.9
        },
        {
          opacity: 0
        }
      ], {
        duration: 5000,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        delay: 100
      });

      // When the animation is finished, remove the element from the dom
      animation.onfinish = () => {
        // @ts-ignore
        particle.style.left = 0;
        // @ts-ignore
        particle.style.top = 0;
      };
      setPinpointAnimation(animation);
    }, 200);
    setPinpointTimeoutId(timeoutId);
  };

  const getNextActivityClassName = (initialStyle, ignoreCanEditQutee, disableWhenNoNext) => {
    let name = initialStyle;
    if (isLoadingCommentBins
      || ((ignoreCanEditQutee || !userCanEditQutee)
        && (disableWhenNoNext && !hasNextBin))) {
      name = `${name} disabled`;
    } else if (nextBinHighlighted) {
      name = `${name} highlighted`;
    }

    return name;
  };

  const createNewGauge = async (quteeId, title, lowLabel, highLabel) => {
    await createGauge(quteeId, title, lowLabel, highLabel);
    setShowCreateGaugeModal(false);
    setShouldRefreshGauges(true);
  };

  const manageGaugeItems = [
    {
      key: 'reorder',
      label: 'Reorder Gauges'
    }
  ];

  const handleManageGauge = (key) => {
    switch (key) {
      case 'add':
        setShowCreateGaugeModal(true);
        break;
      case 'reorder':
        setShowReorderGaugesModal(true);
        break;
      default:
    }
  };

  const createNewPoll = async (...args) => {
    await createPoll(...args);
    setShowCreatePollModal(false);
    setShouldRefreshPolls(true);
  };

  const managePollItems = [
    {
      key: 'reorder',
      label: 'Reorder Polls'
    }
  ];

  const handleManagePoll = (key) => {
    switch (key) {
      case 'add':
        setShowCreatePollModal(true);
        break;
      case 'reorder':
        setShowReorderPollsModal(true);
        break;
      default:
    }
  };

  const openDrawer = (type) => {
    switch (type) {
      case 'questions':
        setShowQuestionDrawer(true);
        break;
      case 'filters':
        setShowQuestionDrawer(false);
        break;
      case 'gauge':
        setShowQuestionDrawer(false);
        break;
      case 'polls':
        setShowQuestionDrawer(false);
        break;
      case 'report':
        setShowQuestionDrawer(false);
        break;
      default:
        setShowQuestionDrawer(true);
        break;
    }
  };

  const closeDrawer = () => {
    setShowQuestionDrawer(false);
  };

  const manageBinItems = [
    {
      key: 'reorder',
      label: 'Reorder Questions'
    }
  ];

  const handleManageBin = (key) => {
    switch (key) {
      case 'add':
        setShowAddBinModal(true);
        break;
      case 'reorder':
        setShowReorderBinsModal(true);
        break;
      default:
    }
  };

  const getQuestionPanel = () => {
    if (userCanEditQutee || (getNumBins() > 1)) {
      return (
        <div className={styles.bins}>
          <Collapse
            className={styles.binSelector}
            ghost
            defaultActiveKey="questions"
          >
            <Collapse.Panel
              key="questions"
              header="Questions"
              className={styles.binPanel}
            >
              <CommentBinSelector
                commentBins={commentBins}
                selectedCommentBinId={selectedCommentBinId}
                setSelectedCommentBinId={setSelectedCommentBinId}
                loadingInitial={isLoadingCommentBins}
                userCanEditBin={userCanEditQutee}
                mutateCommentBins={refreshCommentBins}
                questionsProgress={getQuestionsProgress()}
                extraSelectedDescription={(canDownloadReport && (commentBins?.length > 1))
                  ? (
                    <div className={styles.reportButton}>
                      <InsightsReportButton
                        commentBinId={selectedCommentBinId}
                        getReport={getReport}
                        getLatestReport={getLatestReport}
                        getReportProgress={getReportProgress}
                        generateReport={generateReport}
                        totalCommentCount={getCommentCount()}
                        reportOwnerRole={quteeOwnerRole}
                        hasChatbot={!!qutee?.settings?.chat_bot_id}
                      />
                    </div>
                  )
                  : undefined}
                triggerCommentBinPinpoint={triggerCommentBinPinpoint}
                setTriggerCommentBinPinpoint={setTriggerCommentBinPinpoint}
                pinpointSection={pinpointSection}
                progress={progress}
                progressCommentBinRef={progressCommentBinRef}
              />
              {userCanEditQutee && (
                <Dropdown.Button
                  className={styles.createBin}
                  type="primary"
                  size="large"
                  icon={<DownOutlined />}
                  onClick={() => handleManageBin('add')}
                  menu={{ items: manageBinItems, onClick: (e) => handleManageBin(e.key) }}
                  trigger={['click']}
                >
                  Add Question
                </Dropdown.Button>
              )}
            </Collapse.Panel>
          </Collapse>
        </div>
      );
    }
    return null;
  };

  // Use a memo-ized object for the "empty" filter data to prevent excess rerenders elsewhere
  const emptyFilterData = useMemo(() => ({
    connections: [], labels: [], topics: [], sentiments: [], search_term: {}
  }), []);

  const getSelectedFilter = useCallback(() => (selectedCommentBinId ? filter[selectedCommentBinId] : ''), [filter, selectedCommentBinId]);

  const getSelectedFilterData = useCallback(() => (selectedCommentBinId
    ? filterData[selectedCommentBinId]
    : emptyFilterData), [filterData, selectedCommentBinId, emptyFilterData]);

  const getSettingsTabs = () => {
    const teamLabel = (
      <Tooltip
        title={!canEditAccess
          ? 'Modifying access control for specific users requires a Complete Team plan for the sayso owner.'
          : null}
      >
        Team Access
      </Tooltip>
    );

    const stylingLabel = (
      <Tooltip
        title={!canEditStyle
          ? 'Custom styling requires an Essential Team or higher plan for the sayso owner.'
          : null}
      >
        Styling
      </Tooltip>
    );

    const tabItems = [];
    tabItems.push({
      label: 'General',
      key: 'general',
      children: (
        <QuteeGeneralSettings
          quteeId={quteeId}
          mutateComments={mutateCommentsGlobal}
          quteeOwnerRole={quteeOwnerRole}
          qutee={qutee}
          quteeMutate={quteeMutate}
        />
      )
    });
    tabItems.push({
      label: 'Image Upload', key: 'image', children: <QuteeImageSettings quteeId={quteeId} />
    });
    if (document.getElementById('sa-custom-style')) {
      tabItems.push({
        label: stylingLabel, key: 'styling', children: <CustomStyleEditor />, disabled: !canEditStyle
      });
    }
    tabItems.push({
      label: teamLabel, key: 'team-access', children: <QuteeTeamSettings quteeId={quteeId} />, disabled: !canEditAccess
    });
    return tabItems;
  };

  const getBannerBundle = () => {
    if (hideBanners) {
      return null;
    }
    return (
      <BannerBundle
        quteeId={quteeId}
        allowAnonymous={allowAnonymous}
        resetTourHandler={tourHandler}
        setShowSettingsModal={setShowSettingsModal}
      />
    );
  };

  const getDesktopProgress = () => {
    if (!userState.isLoggedIn) {
      return null;
    }
    const respondedCommentBins = progress ? progress.responded_comment_bin_count : 0;
    const totalCommentBins = progress ? progress.comment_bin_count : 0;
    const respondedPolls = progress ? progress.responded_poll_count : 0;
    const totalPolls = progress ? progress.poll_count : 0;
    const respondedGauges = progress ? progress.responded_gauge_count : 0;
    const totalGauges = progress ? progress.gauge_count : 0;
    const desktopProgressDisplay = (
      <div className={styles.progress}>
        <span className={styles.progressText}>YOUR PROGRESS</span>
        <div className={styles.progressBar}>
          <Progress
            percent={getProgressPercent()}
            status={getProgressPercent() === 100 ? 'success' : 'active'}
            // eslint-disable-next-line react/no-unstable-nested-components
            format={(percent) => (
              progress ? `${percent}%` : <Spin size="small" />
            )}
          />
        </div>
        <Button
          className={totalCommentBins === respondedCommentBins
            ? styles.progressGroupFinished
            : styles.progressGroup}
          type="text"
          onClick={totalCommentBins !== respondedCommentBins
            ? () => setTriggerCommentBinPinpoint(true)
            : null}
        >
          <IconForms className={styles.interactiveResourceIcon} />
          <span>
            Comment
          </span>
          <span className={styles.progressAmount}>
            <span className={styles.myProgress}>
              {respondedCommentBins}
            </span>
            /
            <span>
              {totalCommentBins}
            </span>
          </span>
          {progress
            && (totalCommentBins === respondedCommentBins)
            && (
              <IconCircleCheck className={styles.progressCheck} />
            )}
        </Button>
        {(!progress || (progress && !!progress.poll_count)) ? (
          <Button
            className={totalPolls === respondedPolls
              ? styles.progressGroupFinished
              : styles.progressGroup}
            type="text"
            onClick={totalPolls !== respondedPolls
              ? () => setTriggerPollPinpoint(true)
              : null}
          >
            <IconEdit className={styles.interactiveResourceIcon} />
            <span>
              Vote
            </span>
            <span className={styles.progressAmount}>
              <span className={styles.myProgress}>
                {respondedPolls}
              </span>
              /
              <span>
                {totalPolls}
              </span>
            </span>
            {progress
              && (totalPolls === respondedPolls)
              && (
                <IconCircleCheck className={styles.progressCheck} />
              )}
          </Button>
        ) : null}
        {(!progress || (progress && progress.gauge_count)) ? (
          <Button
            className={totalGauges === respondedGauges
              ? styles.progressGroupFinished
              : styles.progressGroup}
            type="text"
            onClick={totalGauges !== respondedGauges
              ? () => setTriggerGaugePinpoint(true)
              : null}
          >
            <IconGauge
              className={styles.interactiveResourceIcon}
              rotate={90}
            />
            <span>
              Rate
            </span>
            <span className={styles.progressAmount}>
              <span className={styles.myProgress}>
                {respondedGauges}
              </span>
              /
              <span>
                {totalGauges}
              </span>
            </span>
            {progress
              && (totalGauges === respondedGauges)
              && (
                <IconCircleCheck className={styles.progressCheck} />
              )}
          </Button>
        ) : null}
      </div>
    );

    if (useProgressAffix) {
      return (
        <div className={styles.progressAffix}>
          <Affix
            target={(affixTargetContainerId
              && document.getElementById(affixTargetContainerId))
              ? () => (document.getElementById(affixTargetContainerId))
              : undefined}
          >
            {desktopProgressDisplay}
          </Affix>
        </div>
      );
    }

    return desktopProgressDisplay;
  };

  if (isLargeScreen) {
    return (
      <div>
        <div ref={scrollRef} />
        <div ref={pinpointRef} className={styles.pinpointContainer}>
          <ExclamationCircleFilled className={styles.pinpointIcon} />
        </div>
        {embedConfig.hidePreheader ? null : (
          <div className={styles.bannerPadding}>
            <Preheader quteeId={quteeId} />
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.bannerPadding}>
            {getBannerBundle()}
          </div>
          {getDesktopProgress()}
          <div className={styles.columns}>
            {embedConfig.useFilterSidebar ? (
              <div className={styles.leftSidebar}>
                {userCanEditQutee && (
                  <Button
                    className={styles.settings}
                    type="primary"
                    size="large"
                    shape="round"
                    icon={<SettingOutlined />}
                    onClick={() => setShowSettingsModal(true)}
                  >
                    Settings
                  </Button>
                )}
                {canDownloadReport && (
                <Alert
                  className={styles.report}
                  type="info"
                  message={commentBins?.length === 1
                    ? (
                      <InsightsReportBlurb
                        commentBinId={selectedCommentBinId}
                        getReport={getReport}
                        getLatestReport={getLatestReport}
                        getReportProgress={getReportProgress}
                        generateReport={generateReport}
                        totalCommentCount={getCommentCount()}
                        reportOwnerRole={quteeOwnerRole}
                        hasChatbot={!!qutee?.settings?.chat_bot_id}
                      />
                    )
                    : (
                      <>
                        <div className={styles.sponsored}>
                          <FilePdfOutlined />
                          <Text>Insights Report</Text>
                        </div>
                        <div>
                          { /* eslint-disable-next-line max-len */ }
                          <Text>The insights report offers a qualitative AI-driven analysis of your engagement.</Text>
                        </div>
                        <div>
                          { /* eslint-disable-next-line max-len */ }
                          <Text>There is a separate report for each question.</Text>
                        </div>
                      </>
                    )}
                />
                )}
                {getQuestionPanel()}
                {showAddBinModal && (
                  <Modal
                    title="Add Question"
                    open={showAddBinModal}
                    footer={null}
                    onCancel={() => setShowAddBinModal(false)}
                    destroyOnClose
                  >
                    <CreateCommentBin
                      quteeId={quteeId}
                      mutateCommentBins={mutateCommentBins}
                      setShowCreateModal={setShowAddBinModal}
                    />
                  </Modal>
                )}
                {showReorderBinsModal && (
                  <Modal
                    title="Reorder Questions"
                    open={showReorderBinsModal}
                    footer={null}
                    onCancel={() => setShowReorderBinsModal(false)}
                    destroyOnClose
                  >
                    <ReorderCommentBins quteeId={quteeId} />
                  </Modal>
                )}
                {/* <CommentFilters
                  commentBinId={selectedCommentBinId}
                  filter={getSelectedFilter()}
                  setFilter={changeFilter}
                  filterData={getSelectedFilterData()}
                  setFilterData={changeFilterData}
                  setLoadingFilters={setLoadingFilters}
                /> */}
              </div>
            ) : null}
            <div className={styles.comments}>
              {(commentBins?.length > 1) && (
                <>
                  <div className={styles.carousel}>
                    <div className={styles.switchBinContainer}>
                      <div
                        className={`${styles.previousBin} ${(isLoadingCommentBins || !hasPreviousBin) ? 'disabled' : ''}`}
                        onClick={(!isLoadingCommentBins && hasPreviousBin)
                          ? selectPreviousBin : null}
                        onKeyDown={(!isLoadingCommentBins && hasPreviousBin)
                          ? selectPreviousBin : null}
                        role="button"
                        tabIndex={0}
                      >
                        <LeftCircleOutlined className={styles.previousBinIcon} />
                        <Text className={styles.previousBinText}>
                          {`Previous: ${hasPreviousBin ? (commentBins[getSelectedBinIndex() - 1].title || '(No Title)') : 'None'}`}
                        </Text>
                      </div>
                      <div
                        className={getNextActivityClassName(styles.nextBin, true, true)}
                        onClick={(!isLoadingCommentBins && hasNextBin) ? selectNextBin : null}
                        onKeyDown={(!isLoadingCommentBins && hasNextBin) ? selectNextBin : null}
                        role="button"
                        tabIndex={0}
                      >
                        <Text className={styles.nextBinText}>
                          {`Next: ${hasNextBin ? (commentBins[getSelectedBinIndex() + 1].title || '(No Title)') : 'None'}`}
                        </Text>
                        <RightCircleOutlined className={styles.nextBinIcon} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.dots}>
                    {carouselDots}
                  </div>
                </>
              )}
              <CommentBin
                key={selectedCommentBinId}
                commentBinId={selectedCommentBinId}
                filter={getSelectedFilter()}
                setFilter={changeFilter}
                filterData={getSelectedFilterData()}
                setFilterData={changeFilterData}
                isLoadingFilters={isLoadingFilters}
                setLoadingFilters={setLoadingFilters}
                totalCommentCount={getCommentCount()}
                userCanInteract={userCanInteract}
                cantInteractReason={cantInteractReason}
                userCanEditBin={userCanEditQutee}
                labelEditingDisabled={labelEditingDisabled}
                checkUserCanEditComment={checkUserCanEditComment}
                continueAnonymously={continueAnonymously}
                allowAnonymous={allowAnonymous}
                showTitle
                showSubtitle
                highlightNextCommentBinButton={highlightNextCommentBinButton}
                refreshBinData={refreshBinData}
                setRefreshBinData={setRefreshBinData}
                updateExternalBinData={mutateCommentBins}
                hasNextBin={hasNextBin}
                selectNextActivity={selectNextBin}
                mutateProgress={mutateProgress}
                topNavOffset={topNavOffset}
                allowNextPoll={false}
                setMutateCommentsGlobal={setMutateCommentsGlobal}
                setJoyrideIndex={setJoyrideIndex}
                commentDialogStateHandler={commentDialogStateHandler}
                commentDialogState={commentDialogState}
                triggerRepositionJoyride={triggerRepositionJoyride}
                previewDialogState={previewDialogState}
              />
            </div>
            <div className={styles.rightSidebar}>
              {userCanEditQutee && (
                <Button
                  className={styles.settings}
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<SettingOutlined />}
                  onClick={() => setShowSettingsModal(true)}
                >
                  Settings
                </Button>
              )}
              {canDownloadReport && (
              <Alert
                className={styles.report}
                type="info"
                message={commentBins?.length === 1
                  ? (
                    <InsightsReportBlurb
                      commentBinId={selectedCommentBinId}
                      getReport={getReport}
                      getLatestReport={getLatestReport}
                      getReportProgress={getReportProgress}
                      generateReport={generateReport}
                      totalCommentCount={getCommentCount()}
                      reportOwnerRole={quteeOwnerRole}
                      hasChatbot={!!qutee?.settings?.chat_bot_id}
                    />
                  )
                  : (
                    <>
                      <div className={styles.sponsored}>
                        <FilePdfOutlined />
                        <Text>Insights Report</Text>
                      </div>
                      <div>
                        { /* eslint-disable-next-line max-len */ }
                        <Text>The insights report offers a qualitative AI-driven analysis of your engagement.</Text>
                      </div>
                      <div>
                        { /* eslint-disable-next-line max-len */ }
                        <Text>There is a separate report for each question.</Text>
                      </div>
                    </>
                  )}
              />
              )}
              {getQuestionPanel()}
              {showAddBinModal && (
                <Modal
                  title="Add Question"
                  open={showAddBinModal}
                  footer={null}
                  onCancel={() => setShowAddBinModal(false)}
                  destroyOnClose
                >
                  <CreateCommentBin
                    quteeId={quteeId}
                    mutateCommentBins={mutateCommentBins}
                    setShowCreateModal={setShowAddBinModal}
                  />
                </Modal>
              )}
              {showReorderBinsModal && (
                <Modal
                  title="Reorder Questions"
                  open={showReorderBinsModal}
                  footer={null}
                  onCancel={() => setShowReorderBinsModal(false)}
                  destroyOnClose
                >
                  <ReorderCommentBins quteeId={quteeId} />
                </Modal>
              )}
              <div className={styles.gaugePolls}>
                {!hideGauges && !hideGaugesDesktop && userCanEditQutee && (
                <div className={styles.createGaugeContainer}>
                  <Dropdown.Button
                    className={styles.createGauge}
                    type="primary"
                    size="large"
                    icon={<DownOutlined />}
                    onClick={() => handleManageGauge('add')}
                    menu={{ items: manageGaugeItems, onClick: (e) => handleManageGauge(e.key) }}
                    trigger={['click']}
                  >
                    Add Gauge
                  </Dropdown.Button>
                </div>
                )}
                {!hideGauges && !hideGaugesDesktop && (
                  <div className={styles.gauges}>
                    <QuteeGauges
                      quteeId={quteeId}
                      useSimple={useSimpleGauge}
                      gaugeType={gaugeType}
                      shouldRefreshGauges={shouldRefreshGauges}
                      setShouldRefreshGauges={setShouldRefreshGauges}
                      getExtraContent={extraGaugesContent}
                      hideGaugeTitle={hideGaugeTitle}
                      triggerGaugePinpoint={triggerGaugePinpoint}
                      setTriggerGaugePinpoint={setTriggerGaugePinpoint}
                      pinpointSection={pinpointSection}
                      progress={progress}
                      progressGaugeRef={progressGaugeRef}
                    />
                  </div>
                )}
                {!hidePolls && userCanEditQutee && (
                <div className={styles.createPollContainer}>
                  <Dropdown.Button
                    className={styles.createPoll}
                    type="primary"
                    size="large"
                    icon={<DownOutlined />}
                    onClick={() => handleManagePoll('add')}
                    menu={{ items: managePollItems, onClick: (e) => handleManagePoll(e.key) }}
                    trigger={['click']}
                  >
                    Add Poll
                  </Dropdown.Button>
                </div>
                )}
                {!hidePolls && (
                  <div>
                    <QuteePolls
                      quteeId={quteeId}
                      userCanEdit={userCanEditQutee}
                      showNoPollText
                      shouldRefreshPolls={shouldRefreshPolls}
                      setShouldRefreshPolls={setShouldRefreshPolls}
                      mutateProgress={mutateProgress}
                      triggerPollPinpoint={triggerPollPinpoint}
                      setTriggerPollPinpoint={setTriggerPollPinpoint}
                      pinpointSection={pinpointSection}
                      progress={progress}
                      progressPollRef={progressPollRef}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal
            title="Add Gauge"
            open={showCreateGaugeModal}
            footer={null}
            onCancel={() => setShowCreateGaugeModal(false)}
            destroyOnClose
          >
            <CreateGauge quteeId={quteeId} onCreateGauge={createNewGauge} />
          </Modal>
          <Modal
            title="Reorder Gauges"
            open={showReorderGaugesModal}
            footer={null}
            onCancel={() => setShowReorderGaugesModal(false)}
            destroyOnClose
          >
            <ReorderGauges quteeId={quteeId} />
          </Modal>
          <Modal
            title="Add Poll"
            open={showCreatePollModal}
            footer={null}
            onCancel={() => setShowCreatePollModal(false)}
            destroyOnClose
          >
            <CreatePoll quteeId={quteeId} onCreatePoll={createNewPoll} />
          </Modal>
          <Modal
            title="Reorder Polls"
            open={showReorderPollsModal}
            footer={null}
            onCancel={() => setShowReorderPollsModal(false)}
            destroyOnClose
          >
            <ReorderPolls quteeId={quteeId} />
          </Modal>
          <Modal
            title="Edit Settings"
            open={showSettingsModal}
            onCancel={() => setShowSettingsModal(false)}
            footer={null}
            style={{ top: 20 }}
            width={800}
            keyboard={false}
          >
            <Tabs
              className={styles.settingsTabs}
              type="card"
              size="large"
              centered
              items={getSettingsTabs()}
            />
          </Modal>
          {getChatDrawer()}
          <SharingPermissionsModal
            visible={showPermissionsModal}
            setVisible={setShowPermissionsModal}
            requestingProfileId={quteeOwnerId}
            requestingProfileName={quteeOwnerName}
            firstTime
            giveawayTerms={giveawayUrl}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div ref={scrollRef} />
      {(embedConfig.hidePreheader/* || activePage === 'questions' */) ? null : (
        <div>
          <Preheader quteeId={quteeId} />
        </div>
      )}
      <CSSTransition
        in={(activePage === 'questions')}
        nodeRef={questionsTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.questionsEnter,
          enterActive: styles.questionsEnterActive
        }}
        unmountOnExit
      >
        <div ref={questionsTransitionRef}>
          {commentBins && selectedCommentBinId && (userCanEditQutee
            || (getNumBins() > 1) || (commentBins[getSelectedBinIndex()].title
            || commentBins[getSelectedBinIndex()].subtitle)) && (
            <div>
              <Affix
                target={(affixTargetContainerId
                  && document.getElementById(affixTargetContainerId))
                  ? () => (document.getElementById(affixTargetContainerId))
                  : undefined}
              >
                <div
                  ref={topNavRef}
                  className={`${styles.currentStepContainer} ${!userCanEditQutee
                    && getNumBins() === 1 ? 'single' : ''}`}
                >
                  <Button
                    className={styles.currentStepButton}
                    type="text"
                    onClick={userCanEditQutee || (getNumBins() > 1)
                      ? () => openDrawer('questions')
                      : null}
                  >
                    <div className={styles.currentStep}>
                      {(userCanEditQutee || (getNumBins() > 1)) && (
                        <>
                          <IconSelector className={styles.openIcon} />
                          {getCurrentQuestionStep()}
                        </>
                      )}
                      <div className={styles.description}>
                        <div className={styles.currentTitle}>
                          {commentBins[getSelectedBinIndex()].title || '(No Title)'}
                        </div>
                        <div className={styles.currentSubtitle}>
                          {commentBins[getSelectedBinIndex()].subtitle}
                        </div>
                      </div>
                    </div>
                  </Button>
                  {(userCanEditQutee || (getNumBins() > 1)) && (
                    <Button
                      className={getNextActivityClassName(styles.nextStepButton, false, false)}
                      onClick={selectNextActivity}
                      disabled={isLoadingCommentBins}
                    >
                      <span>Next</span>
                      {hasNextBin
                        ? <IconForms className={styles.nextIcon} />
                        : <IconEdit className={styles.nextIcon} />}
                    </Button>
                  )}
                </div>
              </Affix>
            </div>
          )}
          {/* {(embedConfig.hidePreheader) ? null : (
            <div>
              <Preheader quteeId={quteeId} />
            </div>
          )} */}
          <div className={styles.container}>
            <div className={styles.bannerPaddingMobile}>
              {getBannerBundle()}
            </div>
            <div className={styles.comments}>
              <CommentBin
                key={selectedCommentBinId}
                commentBinId={selectedCommentBinId}
                filter={getSelectedFilter()}
                setFilter={changeFilter}
                filterData={getSelectedFilterData()}
                setFilterData={changeFilterData}
                isLoadingFilters={isLoadingFilters}
                setLoadingFilters={setLoadingFilters}
                totalCommentCount={getCommentCount()}
                userCanInteract={userCanInteract}
                cantInteractReason={cantInteractReason}
                userCanEditBin={userCanEditQutee}
                labelEditingDisabled={labelEditingDisabled}
                checkUserCanEditComment={checkUserCanEditComment}
                continueAnonymously={continueAnonymously}
                allowAnonymous={allowAnonymous}
                showTitle
                showSubtitle
                highlightNextCommentBinButton={highlightNextCommentBinButton}
                refreshBinData={refreshBinData}
                setRefreshBinData={setRefreshBinData}
                updateExternalBinData={mutateCommentBins}
                hasNextBin={hasNextBin}
                selectNextActivity={selectNextActivity}
                mutateProgress={mutateProgress}
                topNavOffset={topNavOffset}
                allowNextPoll={false}
                setMutateCommentsGlobal={setMutateCommentsGlobal}
                setJoyrideIndex={setJoyrideIndex}
                commentDialogStateHandler={commentDialogStateHandler}
                commentDialogState={commentDialogState}
                triggerRepositionJoyride={triggerRepositionJoyride}
                previewDialogState={previewDialogState}
              />
            </div>
          </div>
          {/* <Drawer
            title="Filters"
            placement="bottom"
            open={showFilterDrawer}
            onClose={closeDrawer}
            height="90%"
            forceRender
          >
            <div className={styles.filterTags}>
              <FilterTags
                filterData={getSelectedFilterData()}
                setFilterData={changeFilterData}
                isLoading={isLoadingFilters}
              />
            </div>
            <CommentFilters
              commentBinId={selectedCommentBinId}
              filter={getSelectedFilter()}
              setFilter={changeFilter}
              filterData={getSelectedFilterData()}
              setFilterData={changeFilterData}
              setLoadingFilters={setLoadingFilters}
            />
          </Drawer> */}
          <Drawer
            title="Questions"
            placement="top"
            open={showQuestionDrawer}
            onClose={closeDrawer}
            height="95%"
            forceRender
          >
            <div>
              <CommentBinSelector
                commentBins={commentBins}
                selectedCommentBinId={selectedCommentBinId}
                setSelectedCommentBinId={setSelectedCommentBinId}
                loadingInitial={isLoadingCommentBins}
                userCanEditBin={userCanEditQutee}
                mutateCommentBins={refreshCommentBins}
                questionsProgress={getQuestionsProgress()}
                afterSelectCallback={() => closeDrawer()}
              />
              {userCanEditQutee && (
                <Dropdown.Button
                  className={styles.createBin}
                  type="primary"
                  size="large"
                  icon={<DownOutlined />}
                  onClick={() => handleManageBin('add')}
                  menu={{ items: manageBinItems, onClick: (e) => handleManageBin(e.key) }}
                  trigger={['click']}
                >
                  Add Question
                </Dropdown.Button>
              )}
              {showAddBinModal && (
                <Modal
                  title="Add Question"
                  open={showAddBinModal}
                  footer={null}
                  onCancel={() => setShowAddBinModal(false)}
                  destroyOnClose
                >
                  <CreateCommentBin
                    quteeId={quteeId}
                    mutateCommentBins={mutateCommentBins}
                    setShowCreateModal={setShowAddBinModal}
                  />
                </Modal>
              )}
              {showReorderBinsModal && (
                <Modal
                  title="Reorder Questions"
                  open={showReorderBinsModal}
                  footer={null}
                  onCancel={() => setShowReorderBinsModal(false)}
                  destroyOnClose
                >
                  <ReorderCommentBins quteeId={quteeId} />
                </Modal>
              )}
            </div>
          </Drawer>
        </div>
      </CSSTransition>
      <CSSTransition
        in={(activePage === 'polls') && !hidePolls}
        nodeRef={pollsTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.pollsEnter,
          enterActive: styles.pollsEnterActive
        }}
        unmountOnExit
      >
        <div ref={pollsTransitionRef} className={styles.container}>
          <div className={styles.bannerPaddingMobile}>
            {getBannerBundle()}
          </div>
          <QuteePolls
            quteeId={quteeId}
            userCanEdit={userCanEditQutee}
            showNoPollText
            shouldRefreshPolls={shouldRefreshPolls}
            setShouldRefreshPolls={setShouldRefreshPolls}
            mutateProgress={mutateProgress}
          />
          {userCanEditQutee && (
            <div className={styles.createPollContainer}>
              <Dropdown.Button
                className={styles.createPoll}
                type="primary"
                size="large"
                icon={<DownOutlined />}
                onClick={() => handleManagePoll('add')}
                menu={{ items: managePollItems, onClick: (e) => handleManagePoll(e.key) }}
                trigger={['click']}
              >
                Add Poll
              </Dropdown.Button>
            </div>
          )}
          {(getDefaultPage() !== 'gauges') && (
            <div className={styles.drawerCenter}>
              <Button
                className={styles.drawerContinue}
                type="primary"
                size="large"
                onClick={() => setActivePage('gauges')}
              >
                Continue to Ratings
              </Button>
            </div>
          )}
          <Modal
            title="Add Poll"
            open={showCreatePollModal}
            footer={null}
            onCancel={() => setShowCreatePollModal(false)}
            destroyOnClose
          >
            <CreatePoll quteeId={quteeId} onCreatePoll={createNewPoll} />
          </Modal>
          <Modal
            title="Reorder Polls"
            open={showReorderPollsModal}
            footer={null}
            onCancel={() => setShowReorderPollsModal(false)}
            destroyOnClose
          >
            <ReorderPolls quteeId={quteeId} />
          </Modal>
        </div>
      </CSSTransition>
      <CSSTransition
        in={(activePage === 'gauges') && !hideGauges}
        nodeRef={gaugesTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.gaugesEnter,
          enterActive: styles.gaugesEnterActive
        }}
        unmountOnExit
      >
        <div ref={gaugesTransitionRef} className={styles.container}>
          <div className={styles.bannerPaddingMobile}>
            {getBannerBundle()}
          </div>
          <QuteeGauges
            quteeId={quteeId}
            useSimple={useSimpleGauge}
            gaugeType={gaugeType}
            shouldRefreshGauges={shouldRefreshGauges}
            setShouldRefreshGauges={setShouldRefreshGauges}
            getExtraContent={extraGaugesContent}
            hideGaugeTitle={hideGaugeTitle}
            setShowContinueOnRatings={setShowContinueOnRatings}
          />
          {userCanEditQutee && (
            <div className={styles.createGaugeContainer}>
              <Dropdown.Button
                className={styles.createGauge}
                type="primary"
                size="large"
                icon={<DownOutlined />}
                onClick={() => handleManageGauge('add')}
                menu={{ items: manageGaugeItems, onClick: (e) => handleManageGauge(e.key) }}
                trigger={['click']}
              >
                Add Gauge
              </Dropdown.Button>
            </div>
          )}
          {(getDefaultPage() !== 'questions') && showContinueOnRatings && (
            <div className={styles.drawerCenter}>
              <Button
                className={styles.drawerContinue}
                type="primary"
                size="large"
                onClick={() => setActivePage('questions')}
              >
                {`Continue to ${commentBins?.length > 1 ? 'Questions' : 'Comments'}`}
              </Button>
            </div>
          )}
          <Modal
            title="Add Gauge"
            open={showCreateGaugeModal}
            footer={null}
            onCancel={() => setShowCreateGaugeModal(false)}
            destroyOnClose
          >
            <CreateGauge quteeId={quteeId} onCreateGauge={createNewGauge} />
          </Modal>
          <Modal
            title="Reorder Gauges"
            open={showReorderGaugesModal}
            footer={null}
            onCancel={() => setShowReorderGaugesModal(false)}
            destroyOnClose
          >
            <ReorderGauges quteeId={quteeId} />
          </Modal>
        </div>
      </CSSTransition>
      <CSSTransition
        in={(activePage === 'report') && canDownloadReport}
        nodeRef={reportTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.reportEnter,
          enterActive: styles.reportEnterActive
        }}
        unmountOnExit
      >
        <div ref={reportTransitionRef} className={styles.container}>
          <div className={styles.bannerPaddingMobile}>
            {getBannerBundle()}
          </div>
          <InsightsReportView
            commentBinId={selectedCommentBinId}
            getReport={getReport}
            getLatestReport={getLatestReport}
            getReportProgress={getReportProgress}
            generateReport={generateReport}
            totalCommentCount={getCommentCount()}
            navigateToReport={() => setActivePage('report')}
            reportOwnerRole={quteeOwnerRole}
            hasChatbot={!!qutee?.settings?.chat_bot_id}
          />
        </div>
      </CSSTransition>
      <div>
        <div className={styles.progressNav}>
          {userState.isLoggedIn && (
            <div className={styles.progress}>
              <span className={styles.progressText}>PROGRESS</span>
              <div className={styles.progressBar}>
                <Progress
                  percent={getProgressPercent()}
                  status={getProgressPercent() === 100 ? 'success' : 'active'}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  format={(percent) => (
                    progress ? `${percent}%` : <Spin size="small" />
                  )}
                />
              </div>
            </div>
          )}
          <div className={styles.mobileNav}>
            <div>
              {userState.isLoggedIn && (
                <div className={styles.progressCount}>
                  <span className={styles.myProgress}>
                    {progress ? progress.responded_comment_bin_count : 0}
                  </span>
                  /
                  <span>
                    {progress ? progress.comment_bin_count : 0}
                  </span>
                  {progress
                    && (progress.responded_comment_bin_count === progress.comment_bin_count)
                    && (
                      <IconCircleCheck className={styles.progressCheck} />
                    )}
                </div>
              )}
              <Button
                className={`${styles.questionsNavButton} ${(activePage === 'questions')
                  ? styles.mobileNavButtonSelected : styles.mobileNavButton}`}
                type="text"
                onClick={() => setActivePage('questions')}
              >
                <div>
                  <IconForms className={styles.interactiveResourceIcon} />
                  <div>{commentBins?.length > 1 ? 'Questions' : 'Comments'}</div>
                </div>
              </Button>
            </div>
            {!hidePolls && (userCanEditQutee || pollCount) ? (
              <div>
                {userState.isLoggedIn && (
                  <div className={styles.progressCount}>
                    <span className={styles.myProgress}>
                      {progress ? progress.responded_poll_count : 0}
                    </span>
                    /
                    <span>
                      {progress ? progress.poll_count : 0}
                    </span>
                    {progress
                      && (progress.responded_poll_count === progress.poll_count)
                      && (
                        <IconCircleCheck className={styles.progressCheck} />
                      )}
                  </div>
                )}
                <Button
                  className={`${styles.pollsNavButton} ${(activePage === 'polls')
                    ? styles.mobileNavButtonSelected : styles.mobileNavButton}`}
                  type="text"
                  onClick={() => setActivePage('polls')}
                >
                  <IconEdit className={styles.interactiveResourceIcon} rotate={270} />
                  <div>Polls</div>
                </Button>
              </div>
            ) : null}
            {!hideGauges && (userCanEditQutee || gaugeCount) ? (
              <div>
                {userState.isLoggedIn && (
                  <div className={styles.progressCount}>
                    <span className={styles.myProgress}>
                      {progress ? progress.responded_gauge_count : 0}
                    </span>
                    /
                    <span>
                      {progress ? progress.gauge_count : 0}
                    </span>
                    {progress
                      && (progress.responded_gauge_count === progress.gauge_count)
                      && (
                        <IconCircleCheck className={styles.progressCheck} />
                      )}
                  </div>
                )}
                <Button
                  className={`${userCanEditQutee ? styles.gaugesNavButtonMiddle : styles.gaugesNavButtonEnd} ${
                    (activePage === 'gauges')
                      ? styles.mobileNavButtonSelected : styles.mobileNavButton}`}
                  type="text"
                  onClick={() => setActivePage('gauges')}
                >
                  <IconGauge className={styles.interactiveResourceIcon} rotate={90} />
                  <div>Ratings</div>
                </Button>
              </div>
            ) : null}
            {canDownloadReport && (
              <div>
                <Button
                  className={`${styles.reportNavButton} ${(activePage === 'report')
                    ? styles.mobileNavButtonSelected : styles.mobileNavButton}`}
                  type="text"
                  onClick={() => setActivePage('report')}
                >
                  <FilePdfOutlined className={styles.interactiveResourceIcon} />
                  <div>Report</div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {getChatDrawer()} */}
      <SharingPermissionsModal
        visible={showPermissionsModal}
        setVisible={setShowPermissionsModal}
        requestingProfileId={quteeOwnerId}
        requestingProfileName={quteeOwnerName}
        firstTime
        giveawayTerms={giveawayUrl}
      />
    </>
  );
}

Quteev1.displayName = 'Qutee v1';

Quteev1.defaultProps = {
  hideBanners: false,
  hideGauges: false,
  hidePolls: false,
  useSimpleGauge: false,
  giveawayTerms: null
};

export default Quteev1;
