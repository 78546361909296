import useSWR from 'swr';

function useProfileSettings(userId) {
  const {
    data, error, mutate, isLoading, isValidating
  } = useSWR(userId ? [`profiles/${userId}/settings`, userId] : null);

  return {
    profileSettings: data,
    mutate,
    isLoading,
    isValidating,
    isError: error
  };
}

export default useProfileSettings;
