import React, { useState } from 'react';
import { Tabs, Button, Modal } from 'antd';
import config from '../../common/infra/env.config';
import SignUp from './SignUp';
import SignIn from './SignIn';
import SocialsAuth from './SocialsAuth';
import ForgotPassword from './ForgotPassword';
import styles from '../styles/AuthForm.module.scss';
import Image from '../../common/view/Image';
import logo from '../../assets/sa_block_logo.png';

function AuthForm({
  signInSuccessCallback, signUpSuccessCallback, startingTab,
  forcedEmail, hash, quteeId
}) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [activeTab, setActiveTab] = useState(startingTab || 'signup');

  const goToSignUp = () => {
    setActiveTab('signup');
  };

  const goToSignIn = () => {
    setActiveTab('signin');
  };

  const openTermsOfService = () => {
    const newWindow = window.open(config.site.TERMS_OF_SERVICE_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const openPrivacyPolicy = () => {
    const newWindow = window.open(config.site.PRIVACY_POLICY_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const tabItems = [
    {
      key: 'signup',
      label: 'Sign Up',
      children: (
        <>
          <div className={styles.agree}>
            By signing up, you agree to our privacy policy and terms of service.
          </div>
          <SocialsAuth
            operation="signup"
            successCallback={signInSuccessCallback}
            hintEmail={forcedEmail}
          />
          <SignUp
            successCallback={signUpSuccessCallback}
            goToSignIn={goToSignIn}
            forcedEmail={forcedEmail}
            hash={hash}
            quteeId={quteeId}
          />
        </>
      )
    },
    {
      key: 'signin',
      label: 'Log In',
      children: (
        <>
          <SocialsAuth
            operation="signin"
            successCallback={signInSuccessCallback}
            hintEmail={forcedEmail}
          />
          <SignIn
            successCallback={signInSuccessCallback}
            goToSignUp={goToSignUp}
            forcedEmail={forcedEmail}
            hash={hash}
            quteeId={quteeId}
          />
        </>
      )
    }
  ];

  return (
    <div>
      <div className={styles.logoContainer}>
        <Image className={styles.logo} src={logo} alt="Social Asking Logo" />
      </div>
      <Tabs
        className={styles.tabs}
        activeKey={activeTab}
        onTabClick={(key) => setActiveTab(key)}
        centered
        items={tabItems}
      />
      <div className={styles.remember}>
        <Button
          className={styles.link}
          type="link"
          onClick={() => setShowForgotPassword(true)}
        >
          Lost Your Password?
        </Button>
        <div className={styles.legal}>
          <Button
            className={styles.link}
            type="link"
            onClick={() => openPrivacyPolicy()}
          >
            Privacy
          </Button>
          <Button
            className={styles.link}
            type="link"
            onClick={() => openTermsOfService()}
          >
            Terms of Service
          </Button>
        </div>
      </div>
      <Modal
        width={520}
        open={showForgotPassword}
        footer={null}
        onCancel={() => setShowForgotPassword(false)}
        closable
      >
        <ForgotPassword setVisible={setShowForgotPassword} />
      </Modal>
    </div>
  );
}

AuthForm.defaultProps = {
  signInSuccessCallback: null,
  signUpSuccessCallback: null,
  startingTab: 'signup',
  forcedEmail: null,
  hash: null,
  quteeId: null
};

export default AuthForm;
