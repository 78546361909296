import useSWR from 'swr';

function useQuteePermissions(quteeId) {
  const { data, error, mutate } = useSWR(
    quteeId ? `qutees/${quteeId}/permissions` : null
  );

  return {
    permissions: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useQuteePermissions;
