/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, useRef } from 'react';
import pretty from 'pretty-time';
import {
  Typography, Input, Button, App
} from 'antd';
import { TagTwoTone, EditOutlined } from '@ant-design/icons';
import 'easymde/dist/easymde.min.css';
// @ts-ignore
import cssVariables from '../../common/view/styles/Global.scss';
import styles from '../styles/EditOnlyComment.module.scss';
import Image from '../../common/view/Image';
import verifiedIcon from '../../assets/verified-icon.png';
import incognitoIcon from '../../assets/incognito-icon.png';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const { darkGrayColor } = cssVariables;

/**
 * @typedef {Object} comment A comment for display; may be within a thread
 * @param {*} param0
 */

// TODO: Figure out a cleaner way of handling this variant
// of a comment than using an entirely different component
function EditOnlyComment({
  comment, updateComment, allowAnonymous
}) {
  const {
    comment_id: commentId, comment_text: commentText, author_name: authorName,
    created_date: createdDate, is_anonymous: isAnonymous
  } = comment;

  // States
  const { message } = App.useApp();
  const [isLoading, setLoading] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [text, setText] = useState('');
  const editRef = useRef(null);

  useEffect(() => {
    setText(commentText);
  }, [commentText]);

  useEffect(() => {
    if (editingComment && editRef.current) {
      editRef.current.focus({ cursor: 'end' });
    }
  }, [editingComment]);

  const getLabel = () => {
    if (comment.label && comment.label.label_name) {
      return comment.label.label_name;
    }
    return 'Uncategorized';
  };

  // State Handlers

  const editCommentText = async () => {
    const data = {
      comment_text: text
    };

    const loadingKey = 'updateCommentRequest';
    const successKey = 'updateCommentSuccess';
    const errorKey = 'updateCommentError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating comment...', key: loadingKey });
    try {
      const updatedComment = await updateComment(commentId, data);
      setText(updatedComment.comment_text);
      setEditingComment(false);
      message.destroy(loadingKey);
      message.success({ content: 'Comment successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const getTimeSinceCreation = (timestamp) => {
    const currentTime = new Date().getTime();
    const commentTime = new Date(timestamp).getTime();
    const nanoSecondsSinceCreation = (currentTime - commentTime) * 1000000;
    return `${pretty([0, nanoSecondsSinceCreation])} ago`;
  };

  const updateCommentText = (e) => {
    setText(e.target.value);
  };

  const cancelEditing = () => {
    setText(commentText);
    setEditingComment(false);
  };

  const getDepthLineStyling = () => ({
    left: -43,
    border: '1px solid #00b8ec'
  });

  return (
    <div className={styles.thread}>
      <hr className={styles.depthLine} style={getDepthLineStyling()} />
      <div className={styles.container}>
        <div className={styles.authorContainer}>
          <div className={styles.infoContainer}>
            <div className={styles.nameContainer}>
              <span className={styles.name}>{authorName}</span>
              {allowAnonymous && isAnonymous
                ? <Image className={styles.incognitoIcon} src={incognitoIcon} alt="Anonymous user" title="Anonymous" />
                : <Image className={styles.verifiedIcon} src={verifiedIcon} alt="Verified user" title="Verified" />}
              <span>
                <span className={styles.timestamp}>{getTimeSinceCreation(createdDate)}</span>
              </span>
            </div>
            <div className={styles.labelContainer}>
              <TagTwoTone twoToneColor={darkGrayColor} className={styles.labelIcon} />
              <Text className={styles.label}>{`"${getLabel()}"`}</Text>
            </div>
          </div>
          <div>
            <Button type="text" onClick={() => setEditingComment(true)}>
              <EditOutlined />
              Edit Comment
            </Button>
          </div>
        </div>
        {editingComment
          ? (
            <div className={styles.editorContainer}>
              <TextArea
                ref={editRef}
                value={text}
                onChange={updateCommentText}
                showCount
              />
              <Button
                className={styles.editorSubmit}
                type="primary"
                onClick={editCommentText}
                size="large"
                disabled={isLoading}
              >
                Save
              </Button>
              <Button
                className={styles.editorCancel}
                type="text"
                onClick={cancelEditing}
                size="large"
                disabled={isLoading}
              >
                Cancel
              </Button>
            </div>
          )
          : (
            <Paragraph
              className={styles.text}
              ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}
            >
              {text}
            </Paragraph>
          )}
      </div>
    </div>
  );
}

EditOnlyComment.displayName = 'Edit Only Comment';
// EditOnlyComment.defaultProps = {

// }

export default EditOnlyComment;
