import React, { useState } from 'react';
import {
  App, Typography
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from '../styles/CommentBinTitle.module.scss';

const { Title } = Typography;

function CommentBinTitle({
  commentBinId, title, allowEditing, updateCommentBin
}) {
  const { message } = App.useApp();
  const [isLoading, setLoading] = useState(false);

  const placeholder = 'Add Title';

  const changeCommentTitle = async (title) => {
    const loadingKey = 'updateCommentBinTitleRequest';
    const successKey = 'updateCommentBinTitleSuccess';
    const errorKey = 'updateCommentBinTitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating question title...', key: loadingKey });
    try {
      await updateCommentBin(commentBinId, title);
      message.destroy(loadingKey);
      message.success({ content: 'Question title successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Title
        className={(allowEditing && !title) ? styles.addTitle : styles.title}
        level={4}
        editable={allowEditing && !isLoading ? {
          text: title || '',
          onChange: changeCommentTitle,
          icon: title ? null : <PlusOutlined className={styles.plus} />,
          tooltip: title ? 'Edit Title' : 'Add Title'
        } : false}
      >
        {title || (allowEditing && placeholder)}
      </Title>
    </div>
  );
}

CommentBinTitle.displayName = 'Comment Bin Title';
CommentBinTitle.defaultProps = {
  allowEditing: false,
  isEditMode: false
};

export default CommentBinTitle;
