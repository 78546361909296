import useSWR from 'swr';

function useGauge(gaugeId, initial, profileId) {
  const { data, error, mutate } = useSWR(gaugeId ? [`gauges/${gaugeId}`, profileId] : null, {
    fallbackData: initial,
    revalidateOnMount: !initial
  });

  return {
    gauge: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useGauge;
