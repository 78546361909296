import React, { useContext } from 'react';
import { Result, Button, App } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { sendVerificationEmail, apiLogin } from '../state/api';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';

function VerifyForm({ closeModal }) {
  const { message } = App.useApp();
  const { dispatch: userDispatch } = useUserContext();
  const embedConfig = useContext(EmbedContext);

  const { actionUrl, continueUrl } = embedConfig;

  const resendVerificationEmail = async () => {
    const loadingKey = 'newEmailRequest';
    const successKey = 'newEmailSuccess';
    const errorKey = 'newEmailError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Requesting new email...', key: loadingKey });
    try {
      await sendVerificationEmail(continueUrl, actionUrl);
      message.destroy(loadingKey);
      message.success({ content: 'Verification email successfully sent', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  const navigateToContinueUrl = async () => {
    const loadingKey = 'confirmVerifiedRequest';
    const successKey = 'confirmedVerifiedSuccess';
    const errorKey = 'confirmedVerifiedError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Confirming verification status...', key: loadingKey });
    try {
      const userInfo = await apiLogin();
      message.destroy(loadingKey);
      if (userInfo?.profile?.account_status === 'pending') {
        message.error({ content: 'User is not verified', key: errorKey, duration: 5 });
      } else {
        message.success({ content: 'Verification confirmed', key: successKey });
        userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
        if (userInfo?.profile?.username && continueUrl) {
          const newWindow = window.open(continueUrl, '_blank', 'noopener,noreferrer');
          if (newWindow) {
            newWindow.opener = null;
          }
        }
        closeModal();
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  return (
    <div>
      <Result
        icon={<MailOutlined />}
        title="Please verify your email address."
        subTitle="A verification email has been sent to your address.  Please check your inbox and follow the link.  You may also request the email to be resent."
        extra={[
          <Button key="verified" type="primary" onClick={navigateToContinueUrl}>
            I have verified
          </Button>,
          <Button key="resend" onClick={resendVerificationEmail}>
            Resend
          </Button>
        ]}
      />
    </div>
  );
}

VerifyForm.defaultProps = {
  closeModal: () => {}
};

export default VerifyForm;
