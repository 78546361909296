// @ts-nocheck
import React, {
  forwardRef, useState
} from 'react';
import useFetch, { CachePolicies } from 'use-http';
import {
  Button, Descriptions, App, Popconfirm
} from 'antd';
import {
  DeleteFilled, UploadOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import useQutee from '../state/useQutee';
import { useUserContext } from '../../user/providers/UserProvider';
import { ApiException } from '../../common/utils';
import Image from '../../common/view/Image';
import ImageUploadButton from '../../common/view/ImageUploadButton';
import styles from '../styles/QuteeImageUpload.module.scss';

function QuteeImageUpload({
  quteeId, imageLabel, imageTitle, imageType
}) {
  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const [loading, setLoading] = useState(false);

  const {
    qutee,
    mutate: quteeMutate
  } = useQutee(quteeId, userState.isLoggedIn);

  const { delete: deleteImageRequest, response: deleteImageResponse } = useFetch('qutees', {
    cachePolicy: CachePolicies.NO_CACHE
  });

  const deleteImage = async (fieldName) => {
    const loadingKey = 'deleteImageRequest';
    const successKey = 'deleteImageSuccess';
    const errorKey = 'deleteImageError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: `Deleting ${imageLabel}...`, key: loadingKey });
    setLoading(true);
    try {
      const data = {};
      const mutation = { ...qutee };
      mutation.settings = { ...mutation.settings, ...data };
      mutation.settings[fieldName] = null;
      quteeMutate(mutation, { revalidate: false });
      const response = await deleteImageRequest(`${quteeId}/images/${fieldName}`);
      if (deleteImageResponse.ok) {
        quteeMutate();
        message.destroy(loadingKey);
        message.success({ content: `${imageLabel} successfully deleted`, key: successKey });
      } else {
        throw new ApiException(response);
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const requiredSizes = {
    banner_image: {
      min_width: 1200,
      min_height: 200,
      max_width: 2400,
      max_height: 900
    },
    mobile_banner_image: {
      min_width: 768,
      min_height: 300,
      max_width: 1600,
      max_height: 1200
    },
    square_logo: {
      min_width: 200,
      min_height: 200,
      max_width: 800,
      max_height: 800
    },
    wide_logo: {
      min_width: 50,
      min_height: 32,
      max_width: 450,
      max_height: 64
    },
    background_image: {
      min_width: 0,
      min_height: 0,
      max_width: 2400,
      max_height: 2400
    },
    favicon: {
      min_width: 16,
      min_height: 16,
      max_width: 128,
      max_height: 128
    },
    twitter_social_image: {
      min_width: 600,
      min_height: 335,
      max_width: 1200,
      max_height: 670
    },
    facebook_social_image: {
      min_width: 600,
      min_height: 315,
      max_width: 1200,
      max_height: 630
    }
  };

  const buttonRef = forwardRef(
    (props, ref) => (
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={styles.upload}
        icon={<UploadOutlined color="white" />}
        ref={ref}
        disabled={loading}
      >
        Upload
      </Button>
    )
  );
  buttonRef.displayName = `${imageLabel} Upload Button`;

  return (
    <div>
      {qutee?.settings && qutee.settings[`${imageType}_url`] && (
        <div className={styles.imageContainer}>
          <Image
            className={styles.image}
            src={qutee.settings[`${imageType}_url`]}
            title={imageLabel}
            alt={imageLabel}
            isInternal={false}
          />
        </div>
      )}
      <div className={styles.buttons}>
        {qutee?.settings && qutee.settings[`${imageType}_url`] && (
          <Popconfirm
            title="Delete this image?"
            onConfirm={() => deleteImage(`${imageType}_url`)}
            okText="Delete"
            okButtonProps={{ className: styles.confirmDelete }}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Button
              className={styles.delete}
              icon={<DeleteFilled color="red" />}
              disabled={loading}
            >
              Delete
            </Button>
          </Popconfirm>
        )}
        <ImageUploadButton
          resourceType="qutee"
          resourceId={quteeId}
          imageTitle={imageTitle}
          imageType={imageType}
          buttonRef={buttonRef}
        />
      </div>
      <Descriptions title="Requirements" bordered column={2}>
        <Descriptions.Item label="Formats" span={2}>.png, .jpeg, .jpg, .gif</Descriptions.Item>
        <Descriptions.Item label="Maximum File Size" span={2}>8MB</Descriptions.Item>
        <Descriptions.Item label="Minimum Width">
          {`${requiredSizes[imageType].min_width}px`}
        </Descriptions.Item>
        <Descriptions.Item label="Maximum Width">
          {`${requiredSizes[imageType].max_width}px`}
        </Descriptions.Item>
        <Descriptions.Item label="Minimum Height">
          {`${requiredSizes[imageType].min_height}px`}
        </Descriptions.Item>
        <Descriptions.Item label="Maximum Height">
          {`${requiredSizes[imageType].max_height}px`}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default QuteeImageUpload;
