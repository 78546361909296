import React, { useRef, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { EnterOutlined } from '@ant-design/icons';
import { escFunction } from '../../common/utils';
import styles from '../styles/PollAddOption.module.scss';

// const wrapper = { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' };

function PollAddOption({
  pollId, isEditing, lockEdits, isDisabled,
  isLoading, addOption
}) {
  const [option, changeOption] = useState('');
  const inputRef = useRef(null);

  const addPollOption = async (text) => {
    if (!option.trim()) {
      return;
    }
    await addOption(pollId, text);
    changeOption('');
    if (inputRef?.current) {
      // @ts-ignore
      inputRef?.current?.focus();
    }
  };

  const handleAddOption = (event) => changeOption(event.target.value);

  const cancelInput = () => {
    changeOption('');
  };

  // TODO: This is useful in multiple places; make it re-usable
  // instead of re-declaring it in each place
  const showTooltip = (inner) => (
    <Tooltip
      mouseEnterDelay={0.3}
      title="This cannot be changed after the poll has been published and received any votes"
    >
      {inner}
    </Tooltip>
  );

  const hideAddOption = () => isDisabled || !isEditing;

  const renderAddOption = () => (
    <div className={styles.container}>
      <Input
        ref={inputRef}
        type="text"
        autoFocus
        allowClear
        className={styles.input}
        onChange={handleAddOption}
        // @ts-ignore
        onPressEnter={(event) => addPollOption(event.target.value)}
        value={option}
        disabled={lockEdits || isLoading}
        placeholder="Enter another poll option"
        onKeyDown={(e) => escFunction(e, cancelInput, true)}
        suffix={<EnterOutlined />}
      />
    </div>
  );

  if (hideAddOption()) {
    return null;
  }
  if (lockEdits) {
    return showTooltip(renderAddOption());
  }
  return renderAddOption();
}

PollAddOption.displayName = 'PollAddOption';
PollAddOption.defaultProps = {
  isEditing: false,
  lockEdits: false,
  isDisabled: false
};

export default PollAddOption;
