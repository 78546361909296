import useSWR from 'swr';

function usePollOptions(pollId, initial) {
  const {
    data, isValidating, error, mutate
  } = useSWR(`polls/${pollId}/options`, {
    fallbackData: initial,
    revalidateOnMount: !initial
  });

  return {
    options: data,
    mutate,
    isLoading: isValidating,
    isError: error
  };
}

export default usePollOptions;
