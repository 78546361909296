import React, { useEffect, useState } from 'react';
import {
  Tabs, Row, Col
} from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import SurveyView from './SurveyView';
import QuteeCommentsView from './QuteeCommentsView';
import QuteePolls from './QuteePolls';
import QuteeGauges from './QuteeGauges';
import InsightsReportView from './InsightsReportView';
import BannerBundle from '../../common/view/BannerBundle';
import styles from '../styles/Survey.module.scss';

const { TabPane } = Tabs;

function Survey({
  quteeId, commentBinId, pollIds, pollTitles, gaugeIds, ratingCount, totalCommentCount,
  filterCommentCount, ballotCount, commentPrompt, labelPrompt, labels, threads,
  mutateThreads, labelFilters, mutateLabels, isLoadingLabels,
  topicFilters, mutateTopics, sizeTopics, setSizeTopics, isLoadingTopics,
  isEndTopics, sentimentFilters, mutateSentiments, sizeSentiments, setSizeSentiments,
  isLoadingSentiments, isEndSentiments, setFilter, filterData, setFilterData, filters,
  countComments, sizeComments, setSizeComments, foundNewComments, newCommentsCount,
  hideCommentBinResults, reloadComments, isLoadingComments,
  isEndComments, isRefreshingComments, loadReplies, loadDeeperThread, createComment,
  sort, setSort, updateComment, deleteComment, likeComment, removeCommentLike, dislikeComment,
  removeCommentDislike, updateCommentBin, updateLabelSet, createLabel, updateLabel, deleteLabel,
  createPoll, getReport, getLatestReport, getReportProgress, generateReport,
  userCanInteract, userCanEditQutee, labelEditingDisabled, checkUserCanEditComment,
  allowAnonymous, hideBanners, incrementCreatedComments, cantInteractReason,
  useSimpleGauge, continueAnonymously
}) {
  const { state: userState } = useUserContext();
  const [tabKey, setTabKey] = useState('survey');
  const [resultsTabKey, setResultsTabKey] = useState('comments');
  const [shouldRefreshPolls, setShouldRefreshPolls] = useState(false);

  const createNewPoll = async (quteeId, title, options) => {
    await createPoll(quteeId, title, options);
    setShouldRefreshPolls(true);
  };

  useEffect(() => {
    if (!userState.isLoggedIn && tabKey === 'report') {
      setTabKey('survey');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  const navigateToReport = async () => {
    setTabKey('report');
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.bannerPadding}>
          {!hideBanners && <BannerBundle quteeId={quteeId} allowAnonymous={allowAnonymous} />}
        </div>
        <Tabs
          className={styles.tabs}
          activeKey={tabKey}
          onTabClick={(key) => setTabKey(key)}
          type="card"
          size="large"
        >
          <TabPane
            tab="Survey"
            key="survey"
          >
            <SurveyView
              quteeId={quteeId}
              commentBinId={commentBinId}
              commentPrompt={commentPrompt}
              pollIds={pollIds}
              pollTitles={pollTitles}
              gaugeIds={gaugeIds}
              labelPrompt={labelPrompt}
              labels={labels}
              createComment={createComment}
              updateComment={updateComment}
              updateCommentBin={updateCommentBin}
              updateLabelSet={updateLabelSet}
              createLabel={createLabel}
              updateLabel={updateLabel}
              deleteLabel={deleteLabel}
              createPoll={createNewPoll}
              userCanInteract={userCanInteract}
              userCanEditQutee={userCanEditQutee}
              labelEditingDisabled={labelEditingDisabled}
              allowAnonymous={allowAnonymous}
              useSimpleGauge={useSimpleGauge}
              cantInteractReason={cantInteractReason}
              continueAnonymously={continueAnonymously}
            />
          </TabPane>
          {userCanEditQutee && (
          <TabPane
            tab="Results"
            key="results"
          >
            <Tabs
              className={styles.innerTabs}
              activeKey={resultsTabKey}
              onTabClick={(key) => setResultsTabKey(key)}
              size="large"
            >
              <TabPane
                tab={(
                  <Row>
                    <Col md={{ span: 8, order: 2 }} xs={{ span: 24, order: 1 }}>
                      <div className={styles.count}>
                        {totalCommentCount}
                      </div>
                    </Col>
                    <Col md={{ span: 16, order: 1 }} xs={{ span: 24, order: 2 }}>Comments</Col>

                  </Row>
              )}
                key="comments"
              >
                <QuteeCommentsView
                  quteeId={quteeId}
                  commentBinId={commentBinId}
                  commentPrompt={commentPrompt}
                  labelPrompt={labelPrompt}
                  labels={labels}
                  threadList={threads}
                  totalCommentCount={totalCommentCount}
                  filterCommentCount={filterCommentCount}
                  mutateThreads={mutateThreads}
                  labelFilters={labelFilters}
                  mutateLabels={mutateLabels}
                  isLoadingLabels={isLoadingLabels}
                  topicFilters={topicFilters}
                  mutateTopics={mutateTopics}
                  sizeTopics={sizeTopics}
                  setSizeTopics={setSizeTopics}
                  isLoadingTopics={isLoadingTopics}
                  isEndTopics={isEndTopics}
                  sentimentFilters={sentimentFilters}
                  mutateSentiments={mutateSentiments}
                  sizeSentiments={sizeSentiments}
                  setSizeSentiments={setSizeSentiments}
                  isLoadingSentiments={isLoadingSentiments}
                  isEndSentiments={isEndSentiments}
                  setFilter={setFilter}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  filters={filters}
                  countComments={countComments}
                  hideCommentBinResults={hideCommentBinResults}
                  foundNewComments={foundNewComments}
                  newCommentsCount={newCommentsCount}
                  incrementCreatedComments={incrementCreatedComments}
                  sizeComments={sizeComments}
                  setSizeComments={setSizeComments}
                  reloadComments={reloadComments}
                  isLoadingComments={isLoadingComments}
                  isEndComments={isEndComments}
                  isRefreshingComments={isRefreshingComments}
                  loadReplies={loadReplies}
                  loadDeeperThread={loadDeeperThread}
                  createComment={createComment}
                  sort={sort}
                  setSort={setSort}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  likeComment={likeComment}
                  removeCommentLike={removeCommentLike}
                  dislikeComment={dislikeComment}
                  removeCommentDislike={removeCommentDislike}
                  updateCommentBin={updateCommentBin}
                  updateLabelSet={updateLabelSet}
                  createLabel={createLabel}
                  updateLabel={updateLabel}
                  deleteLabel={deleteLabel}
                  userCanInteract={userCanInteract}
                  cantInteractReason={cantInteractReason}
                  userCanEditQutee={userCanEditQutee}
                  labelEditingDisabled={labelEditingDisabled}
                  checkUserCanEditComment={checkUserCanEditComment}
                  resultsOnly
                  allowAnonymous={allowAnonymous}
                  continueAnonymously={continueAnonymously}
                />
              </TabPane>
              <TabPane
                tab={(
                  <Row>
                    <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
                      <div className={styles.count}>
                        {ballotCount}
                      </div>
                    </Col>
                    <Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>Votes</Col>

                  </Row>
              )}
                key="votes"
              >
                <QuteePolls
                  quteeId={quteeId}
                  mode="only_results"
                  userCanEdit={userCanEditQutee}
                  showNoPollText
                  shouldRefreshPolls={shouldRefreshPolls}
                  setShouldRefreshPolls={setShouldRefreshPolls}
                />
              </TabPane>
              <TabPane
                tab={(
                  <Row>
                    <Col md={{ span: 10, order: 2 }} xs={{ span: 24, order: 1 }}>
                      <div className={styles.count}>
                        {ratingCount}
                      </div>
                    </Col>
                    <Col md={{ span: 14, order: 1 }} xs={{ span: 24, order: 2 }}>Ratings</Col>
                  </Row>
              )}
                key="ratings"
              >
                <div className={styles.gauge}>
                  <QuteeGauges quteeId={quteeId} />
                </div>
              </TabPane>
            </Tabs>
          </TabPane>
          )}
          {userCanEditQutee && (
          <TabPane
            tab="Report"
            key="report"
          >
            <InsightsReportView
              quteeId={quteeId}
              getReport={getReport}
              getLatestReport={getLatestReport}
              getReportProgress={getReportProgress}
              generateReport={generateReport}
              totalCommentCount={totalCommentCount}
              navigateToReport={navigateToReport}
            />
          </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
}

Survey.displayName = 'Survey';
Survey.defaultProps = {
  useSimpleGauge: true
};

export default Survey;
