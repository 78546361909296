import useSWR from 'swr';

function useCommentBinVisit(commentBinId, profileId) {
  const {
    data, error, mutate, isLoading
  } = useSWR(commentBinId
    ? [`comment-bins/${commentBinId}/visit/me`, profileId] : null);

  return {
    commentBinVisit: data,
    mutate,
    isLoading,
    isError: error
  };
}

export default useCommentBinVisit;
