import React, { useState, useContext } from 'react';
import {
  Form, Input, Button, App
} from 'antd';
import AuthLoader from './AuthLoader';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import { fullLogin } from '../state/api';
import styles from '../styles/Auth.module.scss';

function SignIn({
  successCallback, goToSignUp, forcedEmail, hash, quteeId
}) {
  const { message } = App.useApp();
  const { dispatch: userDispatch } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState(forcedEmail || '');
  const [form] = Form.useForm();

  const { continueUrl } = embedConfig;

  const resetForm = () => {
    setEmail(forcedEmail || '');
    form.resetFields();
  };

  const handleLoginError = (err, key) => {
    console.log(err);
    switch (err.code) {
      case 'auth/network-request-failed':
        message.error({ content: 'A network error occurred. Please try again.', key, duration: 5 });
        break;
      case 'auth/too-many-requests':
        message.error({
          content: 'You have made too many auth requests. Please wait a few minutes and try again.',
          key,
          duration: 5
        });
        break;
      case 'auth/user-not-found':
        message.error({ content: 'There is no user identified by the provided email.', key, duration: 5 });
        break;
      case 'auth/wrong-password':
        message.error({ content: 'The password is incorrect.', key, duration: 5 });
        break;
      case 'auth/invalid-email':
        message.error({ content: 'The email address is badly formatted.', key, duration: 5 });
        break;
      case 'auth/user-disabled':
        message.error({ content: 'The user account has been disabled.', key, duration: 5 });
        break;
      default:
        message.error({ content: err?.details?.issue || err.message, key, duration: 5 });
        break;
    }
  };

  const handleSubmit = async (values) => {
    const { email, password } = values;

    const loadingKey = 'signInRequest';
    const successKey = 'signInSuccess';
    const errorKey = 'signInError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Signing in...', key: loadingKey });
    try {
      const userInfo = await fullLogin({
        email, password, hash, quteeId
      });
      message.destroy(loadingKey);
      message.success({ content: 'Successfully signed in', key: successKey });
      resetForm();
      if (continueUrl) {
        const curUrl = new URL(window.location.toString());
        const contUrl = new URL(continueUrl);
        const checkBase = `${curUrl.origin}${curUrl.pathname}`;
        const contBase = `${contUrl.origin}${contUrl.pathname}`;
        if (checkBase !== contBase) {
          const newWindow = window.open(continueUrl, '_blank', 'noopener,noreferrer');
          if (newWindow) {
            newWindow.opener = null;
          }
        }
      }
      if (typeof successCallback === 'function') {
        successCallback();
      }
      setLoading(false);
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
    } catch (err) {
      message.destroy(loadingKey);
      handleLoginError(err, errorKey);
      setLoading(false);
    }
  };

  const changeEmail = (event) => {
    if (!forcedEmail) {
      setEmail(event.target.value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.instruction}>
        Log in with your email and password
      </div>
      <Form name="login" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="email"
          initialValue={forcedEmail || ''}
        >
          <Input
            autoComplete="off"
            placeholder="Email"
            disabled={!!forcedEmail}
            value={email}
            onChange={changeEmail}
          />
        </Form.Item>
        <Form.Item name="password">
          <Input.Password
            autoComplete="off"
            placeholder="Password"
          />
        </Form.Item>
        {isLoading && <AuthLoader />}
        <Form.Item className={styles.submitForm}>
          <Button
            className={styles.submit}
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading}
          >
            Log In
          </Button>
        </Form.Item>
      </Form>
      <Button
        className={styles.switchAuth}
        type="text"
        onClick={() => goToSignUp()}
      >
        Sign up with new account instead
      </Button>
    </div>
  );
}

SignIn.defaultProps = {
  successCallback: null,
  forcedEmail: null,
  hash: null,
  quteeId: null
};

export default SignIn;
