import React, { useEffect, useState } from 'react';
import {
  Button, Row, Col, Drawer, Checkbox,
  Typography, Switch, App
} from 'antd';
import { MenuOutlined, UpOutlined } from '@ant-design/icons';
import SurveySteps from './SurveySteps';
import QuteeGauges from './QuteeGauges';
import CommentEntry from '../../commentBin/components/CommentEntry';
import PollContainer from '../../embed/components/PollContainer';
import { useUserContext } from '../../user/providers/UserProvider';
import { useIsXl } from '../../common/state/responsiveChecks';
import AuthForm from '../../auth/components/AuthForm';
import { signOut } from '../../auth/state/api';
import EditOnlyComment from './EditOnlyComment';
import AnonymousBanner from '../../common/view/AnonymousBanner';
import styles from '../styles/SurveyView.module.scss';

const { Title } = Typography;

function SurveyView({
  quteeId, commentBinId, pollIds, pollTitles, gaugeIds, commentPrompt,
  labelPrompt, labels, createComment, updateComment, updateCommentBin,
  updateLabelSet, createLabel, updateLabel, deleteLabel, createPoll,
  userCanInteract, userCanEditQutee, labelEditingDisabled, allowAnonymous,
  useSimpleGauge, cantInteractReason, continueAnonymously
}) {
  const { message } = App.useApp();
  const { state: userState, dispatch } = useUserContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepDescription, setCurrentStepDescription] = useState('');
  const [currentPoll, setCurrentPoll] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState(null);
  const [rateNextEnabled, setRateNextEnabled] = useState(false);
  const [respondNextEnabled, setRespondNextEnabled] = useState(false);
  const [voteNextEnabledList, setVoteNextEnabledList] = useState([]);
  const [autoAdvancePolls, setAutoAdvancePolls] = useState(false);
  const isLargeScreen = useIsXl();

  useEffect(() => {
    if (!userState.isLoggedIn) {
      setCurrentStep(0);
    }
  }, [userState]);

  useEffect(() => {
    let description1 = '1. Register';
    if (userState.isLoggedIn) {
      description1 = userState.profile.display_name;
    } else if (allowAnonymous) {
      description1 = '1. Agree';
    }
    switch (currentStep) {
      case 0:
      // eslint-disable-next-line default-case-last, no-fallthrough
      default:
        setCurrentStepDescription(description1);
        break;
      case 1:
        setCurrentStepDescription('2. Rate');
        break;
      case 2:
        setCurrentStepDescription('3. Respond');
        break;
      case 3:
        setCurrentStepDescription('4. Vote');
        break;
      case 4:
        setCurrentStepDescription('5. Finished');
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    const newList = [...voteNextEnabledList];
    for (let i = newList.length - 1; i < pollIds.length; i += 1) {
      newList.push(false);
    }
    setVoteNextEnabledList([...newList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollIds]);

  const createNewComment = async (quteeId, entryText, labelId, authorName) => {
    const comment = await createComment(quteeId, entryText, labelId, null, authorName);
    setNewComment(comment);
  };

  const changeCommentPrompt = async (prompt) => {
    const loadingKey = 'updateCommentPromptRequest';
    const successKey = 'updateCommentPromptSuccess';
    const errorKey = 'updateCommentPromptError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Updating comment prompt...', key: loadingKey });
    try {
      await updateCommentBin(commentBinId, prompt);
      message.destroy(loadingKey);
      message.success({ content: 'Comment prompt successfully updated', key: successKey });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    const loadingKey = 'logoutRequest';
    const successKey = 'logoutSuccess';
    const errorKey = 'logoutError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Logging out...', key: loadingKey });
    setLoading(true);
    try {
      await signOut();
      message.destroy(loadingKey);
      message.success({ content: 'Successfully logged out', key: successKey });
      dispatch({ type: 'LOGOUT_SUCCESS' });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const nextPoll = () => {
    if (currentPoll === pollIds.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentPoll(currentPoll + 1);
    }
  };

  const afterRateHook = () => {
    setRateNextEnabled(true);
  };

  const afterRespondHook = () => {
    setRespondNextEnabled(true);
  };

  const afterVoteHook = (index) => {
    const newList = [...voteNextEnabledList];
    newList[index] = true;
    setVoteNextEnabledList([...newList]);

    if (autoAdvancePolls) {
      setTimeout(() => {
        nextPoll();
      }, 500);
    }
  };

  const authSuccessCallback = () => {
    setCurrentStep(1);
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 0:
      // eslint-disable-next-line default-case-last, no-fallthrough
      default:
        if (!userState.isLoggedIn) {
          if (!userState.isPreFirstLoad
            && (allowAnonymous && !userState.isLoggedIn)) {
            return (
              <AnonymousBanner
                signInSuccessCallback={authSuccessCallback}
                signUpSuccessCallback={authSuccessCallback}
              />
            );
          }
          return (
            <div className={!isLargeScreen ? styles.authMobile : styles.auth}>
              <AuthForm
                signInSuccessCallback={authSuccessCallback}
                signUpSuccessCallback={authSuccessCallback}
              />
            </div>
          );
        }
        return (
          <div className={styles.loggedInContainer}>
            <div className={styles.loggedIn}>
              <Button
                className={styles.loggedInButton}
                type="default"
                size="large"
                onClick={() => setCurrentStep(1)}
              >
                {userState.isAnonymous
                  ? 'Continue anonymously'
                  : `Continue as ${userState.profile.display_name}`}
              </Button>
              {!userState.isAnonymous && (
                <>
                  <div className={styles.or}>
                    or
                  </div>
                  <Button
                    className={styles.logOutButton}
                    type="link"
                    size="large"
                    onClick={handleSignOut}
                  >
                    {'This isn\'t me'}
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div className={!isLargeScreen ? styles.rateContainerMobile : styles.rateContainer}>
            <QuteeGauges
              quteeId={quteeId}
              useSimple={useSimpleGauge}
              hideUserRating
              afterRateHook={afterRateHook}
            />
            <div className={styles.nextContainer}>
              <Button
                className={styles.nextButton}
                size="large"
                type="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={!rateNextEnabled}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={!isLargeScreen ? styles.respondContainerMobile : styles.respondContainer}>
            <div className={styles.promptContainer}>
              <Title
                className={isEditMode ? styles.promptEdit : styles.prompt}
                level={4}
                editable={isEditMode && !isLoading ? { onChange: changeCommentPrompt } : false}
              >
                {commentPrompt}
              </Title>
              {userCanEditQutee && (
              <Switch
                className={styles.switch}
                checked={isEditMode}
                checkedChildren="edit mode"
                unCheckedChildren="edit mode"
                onChange={(checked) => setEditMode(checked)}
              />
              )}
            </div>
            <div className={styles.content}>
              <CommentEntry
                quteeId={quteeId}
                commentBinId={commentBinId}
                labelPrompt={labelPrompt}
                labels={labels}
                createComment={createNewComment}
                allowEditing={userCanEditQutee}
                updateLabelSet={updateLabelSet}
                createLabel={createLabel}
                updateLabel={updateLabel}
                deleteLabel={deleteLabel}
                userCanInteract={userCanInteract}
                cantInteractReason={cantInteractReason}
                labelEditingDisabled={labelEditingDisabled}
                afterSubmit={afterRespondHook}
                allowAnonymous={allowAnonymous}
                continueAnonymously={continueAnonymously}
                stayOpen
              />
              {!!newComment
                && (
                <EditOnlyComment
                  comment={newComment}
                  updateComment={updateComment}
                  allowAnonymous={allowAnonymous}
                />
                )}
              <div className={styles.nextContainer}>
                <Button
                  className={styles.nextButton}
                  size="large"
                  type="primary"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  disabled={!respondNextEnabled}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={!isLargeScreen ? styles.voteContainerMobile : styles.voteContainer}>
            <PollContainer
              key={pollIds[currentPoll]}
              pollId={pollIds[currentPoll]}
              mode="no_results"
              afterVoteHook={() => afterVoteHook(currentPoll)}
              disableVoteMessage={autoAdvancePolls}
            />
            <div className={styles.nextContainer}>
              <Checkbox
                className={styles.nextAutoadvance}
                onChange={(e) => setAutoAdvancePolls(e.target.checked)}
              >
                Auto-advance
              </Checkbox>
              <Button
                className={styles.nextButton}
                size="large"
                type="primary"
                onClick={nextPoll}
                disabled={!voteNextEnabledList[currentPoll]}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.finishedContainer}>
            <div className={styles.finished}>
              Thanks for completing the survey!
              <br />
            </div>
          </div>
        );
    }
  };

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  // Mobile view
  if (!isLargeScreen) {
    return (
      <>
        <div>
          {getStepContent()}
        </div>
        <div className={styles.currentStepContainer}>
          <Button className={styles.currentStepButton} type="text" onClick={openDrawer}>
            <div className={styles.currentStep}>
              <MenuOutlined className={styles.menuIcon} />
              <span className={styles.description}>{currentStepDescription}</span>
              <UpOutlined className={styles.openIcon} />
            </div>
          </Button>
        </div>
        <Drawer
          title="Survey Steps"
          placement="bottom"
          open={showDrawer}
          onClose={closeDrawer}
          height={512}
        >
          <SurveySteps
            quteeId={quteeId}
            commentPrompt={commentPrompt}
            pollTitles={pollTitles}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            currentPoll={currentPoll}
            setCurrentPoll={setCurrentPoll}
            createPoll={createPoll}
            userCanEditQutee={userCanEditQutee}
            allowAnonymous={allowAnonymous}
          />
        </Drawer>
      </>
    );
  }

  return (
    <Row>
      <Col className={styles.column} span={6}>
        <SurveySteps
          quteeId={quteeId}
          commentPrompt={commentPrompt}
          pollTitles={pollTitles}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          currentPoll={currentPoll}
          setCurrentPoll={setCurrentPoll}
          createPoll={createPoll}
          userCanEditQutee={userCanEditQutee}
          allowAnonymous={allowAnonymous}
        />
      </Col>
      <Col span={18}>
        {getStepContent()}
      </Col>
    </Row>
  );
}

SurveyView.displayName = 'Survey View';
SurveyView.defaultProps = {
  useSimpleGauge: true
};

export default SurveyView;
