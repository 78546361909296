import React, { useContext, useEffect } from 'react';
import { Button, Result } from 'antd';
import config from '../../common/infra/env.config';
import EmbedContext from '../providers/EmbedProvider';
import { useUserContext } from '../../user/providers/UserProvider';
import Nav from '../../common/view/Nav';
import VerificationBanner from '../../common/view/VerificationBanner';
import Image from '../../common/view/Image';
import PollContainer from './PollContainer';
import QuteeContainer from './QuteeContainer';
import Quteev1Container from './Quteev1Container';
import SurveyContainer from './SurveyContainer';
import GaugeContainer from './GaugeContainer';
import AuthContainer from './AuthContainer';
import ActionsContainer from './ActionsContainer';
import SurveyStartButton from './SurveyStartButton';
import SurveySlideBanner from './SurveySlideBanner';
import BannerBundle from '../../common/view/BannerBundle';
import BannerImage from '../../common/view/BannerImage';
import SaysoProgressText from '../../qutee/components/SaysoProgressText';
import Preheader from '../../common/view/Preheader';
import errorLogo from '../../assets/disconnect-ohno.png';

function EmbedPage({
  isMainEngagement, mainEngagementId, setMainEngagementId,
  resetTourHandler, setResetTourHandler, allowAnonymous, setAllowAnonymous,
  hasInviteCode
}) {
  const embedConfig = useContext(EmbedContext);
  const { state: user } = useUserContext();

  const {
    id, embedType, liveFrequency, hideBanners, simpleGauge, hideGauges,
    hidePolls, hideBannerImage, contentOnly, hideGaugeTitle, gaugeType
  } = embedConfig;

  const support = () => {
    const newWindow = window.open(config.site.SUPPORT_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  useEffect(() => {
    const setQuteeId = () => {
      // We assume there will only be one main engagement (aka one qutee)
      if (typeof setMainEngagementId === 'function' && id) {
        setMainEngagementId(id);
      }
    };
    switch (embedType) {
      case 'social-asking-tabbed':
      case 'qutee':
      case 'social-asking-sidebars':
      case 'quteev1':
      case 'social-asking-survey':
      case 'survey':
      case 'social-asking-survey-button':
      case 'survey-button':
      case 'social-asking-survey-banner':
      case 'survey-banner':
        setQuteeId();
        break;
      default:
    }
  }, [id, embedType, setMainEngagementId]);

  const getEmbed = () => {
    const errorResult = (
      <Result
        status="error"
        title="Unable to load embed"
        subTitle="The embed has been improperly configured. Please contact the website administrator."
        extra={<Button type="primary" onClick={support}>Contact Support</Button>}
        icon={<Image src={errorLogo} width="400" />}
      />
    );

    if (!embedType) {
      return errorResult;
    }

    // TODO: Remove all props related to embedConfig and just use the embed context within
    // the other components
    switch (embedType) {
      // case 'social-asking-tabbed':
      // case 'qutee':
      //   return (
      //     <QuteeContainer
      //       quteeId={embedConfig.id}
      //       liveFrequency={embedConfig.liveFrequency}
      //       hideBanners={embedConfig.hideBanners}
      //       useSimpleGauge={embedConfig.simpleGauge}
      //       isMainEngagement={isMainEngagement}
      //       setAllowAnonymous={setAllowAnonymous}
      //       hideGauges={embedConfig.hideGauges}
      //       hidePolls={embedConfig.hidePolls}
      //       hideBannerImage={embedConfig.hideBannerImage}
      //     />
      //   );
      case 'social-asking-sidebars':
      case 'quteev1':
      case 'social-asking-tabbed':
      case 'qutee':
      case 'social-asking-survey':
      case 'survey':
      case 'social-asking-survey-button':
      case 'survey-button':
      case 'social-asking-survey-banner':
      case 'survey-banner':
        return (
          <Quteev1Container
            quteeId={id}
            liveFrequency={liveFrequency}
            hideBanners={hideBanners}
            useSimpleGauge={simpleGauge}
            gaugeType={gaugeType}
            resetTourHandler={resetTourHandler}
            setResetTourHandler={setResetTourHandler}
            isMainEngagement={isMainEngagement}
            setAllowAnonymous={setAllowAnonymous}
            hideGauges={hideGauges}
            hidePolls={hidePolls}
            hideBannerImage={hideBannerImage}
            hasInviteCode={hasInviteCode}
          />
        );
      // case 'social-asking-survey':
      // case 'survey':
      //   return (
      //     <>
      //       {user.isLoggedIn && user.isPending && <VerificationBanner />}
      //       <SurveyContainer
      //         quteeId={embedConfig.id}
      //         liveFrequency={embedConfig.liveFrequency}
      //         useSimpleGauge={embedConfig.simpleGauge}
      //         isMainEngagement={isMainEngagement}
      //         setAllowAnonymous={setAllowAnonymous}
      //         hideBannerImage={embedConfig.hideBannerImage}
      //       />
      //     </>
      //   );
      // case 'social-asking-survey-button':
      // case 'survey-button':
      //   return (
      //     <>
      //       {user.isLoggedIn && user.isPending && <VerificationBanner />}
      //       <SurveyStartButton
      //         quteeId={embedConfig.id}
      //         liveFrequency={embedConfig.liveFrequency}
      //         useSimpleGauge={embedConfig.simpleGauge}
      //         isMainEngagement={isMainEngagement}
      //         setAllowAnonymous={setAllowAnonymous}
      //         hideBannerImage={embedConfig.hideBannerImage}
      //       />
      //     </>
      //   );
      // case 'social-asking-survey-banner':
      // case 'survey-banner':
      //   return (
      //     <>
      //       {user.isLoggedIn && user.isPending && <VerificationBanner />}
      //       <SurveySlideBanner
      //         quteeId={embedConfig.id}
      //         liveFrequency={embedConfig.liveFrequency}
      //         useSimpleGauge={embedConfig.simpleGauge}
      //         isMainEngagement={isMainEngagement}
      //         setAllowAnonymous={setAllowAnonymous}
      //         hideBannerImage={embedConfig.hideBannerImage}
      //       />
      //     </>
      //   );
      case 'gauge':
        return (
          <>
            {!contentOnly && <Nav />}
            {user.isLoggedIn
              && user.isPending
              && !contentOnly
              && <VerificationBanner />}
            <GaugeContainer
              gaugeId={id}
              hideTitle={hideGaugeTitle === null
                ? undefined : hideGaugeTitle}
              useSimple={simpleGauge}
              isMainEngagement={isMainEngagement}
              setAllowAnonymous={setAllowAnonymous}
            />
          </>
        );
      case 'poll':
        return (
          <>
            <Nav />
            {user.isLoggedIn && user.isPending && <VerificationBanner />}
            <PollContainer
              pollId={id}
              isMainEngagement={isMainEngagement}
              setAllowAnonymous={setAllowAnonymous}
            />
          </>
        );
      case 'auth':
        return (
          <>
            {user.isLoggedIn && user.isPending && <VerificationBanner />}
            <AuthContainer />
          </>
        );
      case 'actions':
        return (
          <>
            {user.isLoggedIn && user.isPending && <VerificationBanner />}
            <ActionsContainer />
          </>
        );
      case 'auth-banners':
      case 'auth-banner':
        return (
          <BannerBundle
            quteeId={mainEngagementId}
            resetTourHandler={resetTourHandler}
            allowAnonymous={allowAnonymous}
          />
        );
      case 'banner-image':
        return <BannerImage quteeId={mainEngagementId} />;
      case 'sayso-progress-summary':
        return <SaysoProgressText saysoId={id} />;
      case 'preheader':
        return <Preheader quteeId={mainEngagementId} />;
      default:
        return errorResult;
    }
  };

  return (
    <div>
      {getEmbed()}
    </div>
  );
}

export default EmbedPage;
