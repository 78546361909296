import React from 'react';
import { Button, App } from 'antd';
import { useUserContext } from '../../user/providers/UserProvider';
import { signOut } from '../state/api';

function AuthInfo() {
  const { message } = App.useApp();
  const { state: userState, dispatch: userDispatch } = useUserContext();

  const handleSignOut = async () => {
    const loadingKey = 'signOutRequest';
    const successKey = 'signOutSuccess';
    const errorKey = 'signOutError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    message.loading({ content: 'Signing out...', key: loadingKey });
    try {
      await signOut();
      message.destroy(loadingKey);
      message.success({ content: 'Signed out', key: successKey });
      userDispatch({ type: 'LOGOUT_SUCCESS' });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    }
  };

  return (
    <div className="auth-info">
      <div className="auth-info__text">
        {`You are logged in as ${userState.profile.username}`}
      </div>
      <Button
        className="auth-info__logout"
        type="primary"
        onClick={() => handleSignOut()}
      >
        Log out
      </Button>
    </div>
  );
}

export default AuthInfo;
