import React from 'react';
import config from '../infra/env.config';

function Image({
  className, src, alt, title, width, isInternal, onClick
}) {
  // eslint-disable-next-line no-useless-escape
  const dataUriRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  const isDataURL = (s) => !!s.match(dataUriRegex);

  const getDomain = () => {
    let domain = config.site.DOMAIN;
    if (domain.endsWith('/')) {
      domain = domain.slice(0, -1);
    }
    return domain;
  };

  const getSource = () => {
    let source = src;
    if (typeof source !== 'string') {
      return source;
    }
    if (source.startsWith('/')) {
      source = source.slice(1);
    }
    return source;
  };

  if (src && (isDataURL(src) || src.startsWith('blob:') || src.startsWith('http'))) {
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <img
        className={className}
        src={src}
        alt={alt}
        title={title}
        width={width}
        onClick={onClick}
      />
    );
  }
  return (
    <img
      className={className}
      src={isInternal ? `${getDomain()}/${getSource()}` : src}
      alt={alt}
      title={title}
      width={width}
    />
  );
}

Image.defaultProps = {
  className: null,
  alt: null,
  title: null,
  width: null,
  isInternal: true,
  onClick: null
};

export default Image;
