import React, {
  forwardRef, useCallback, useEffect, useRef, useState
} from 'react';
import {
  motion, useDragControls, useMotionValue, useTransform
} from 'framer-motion';
import useFetch, { CachePolicies } from 'use-http';
import { subject } from '@casl/ability';
import {
  Button, App, Popconfirm, Popover, Skeleton,
  Slider, Switch, Tooltip, Typography
} from 'antd';
import {
  PlusOutlined, UploadOutlined, DeleteOutlined, QuestionCircleOutlined,
  LeftOutlined, RightOutlined
} from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { useUserContext } from '../../user/providers/UserProvider';
import { useIsXl } from '../../common/state/responsiveChecks';
import useQutee from '../../qutee/state/useQutee';
import useQuteeProgress from '../../qutee/state/useQuteeProgress';
import useGaugeTallies from '../state/useGaugeTallies';
import Image from '../../common/view/Image';
import { ApiException } from '../../common/utils';
import ImageUploadButton from '../../common/view/ImageUploadButton';
import AnonPopconfirm from '../../auth/components/AnonPopconfirm';
import GaugeGraph from './GaugeGraph';
import fullHeartIcon from '../../assets/full-heart.png';
import emptyHeartIcon from '../../assets/empty-heart.png';
import tinyarrow from '../../assets/tinyarrow.png';
import handswipe from '../../assets/handswipe.png';
import styles from '../styles/SwipeGaugeList.module.scss';

const { Text, Title } = Typography;

const PLACEHOLDER_IMAGE_URL = 'https://images.socialasking.com/image_placeholder_square.png';

function convertRange(value, r1, r2) {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

// Separate out the slider so we can have an individual load state for each one
function OverviewSlider({
  gaugeId, rating, userState, isLoadingInitial,
  isValidating, disabled, setShowConfirm, saveRating,
  value, setValue
}) {
  const [isLoading, setLoading] = useState(false);

  const handleSave = async (gaugeId, value) => {
    setLoading(true);
    await saveRating(gaugeId, value);
    setLoading(false);
  };

  return (
    <Slider
      className={`${styles.slider}${rating != null ? ` ${styles.ratedSlider}` : ''}`}
      defaultValue={rating != null ? rating : 50}
      value={value != null ? value : 50}
      onChange={(v) => {
        if (!disabled && !userState.isLoggedIn) {
          setShowConfirm(true);
        } else {
          setValue(v);
        }
      }}
      onAfterChange={(v) => handleSave(gaugeId, v)}
      disabled={isLoadingInitial || isValidating || disabled || isLoading}
    />
  );
}

function GaugeOverview({
  gauge, rating, userState, isLargeScreen, isLoadingInitial,
  isValidating, disabled, setShowConfirm, saveRating, externalRef
}) {
  const [value, setValue] = useState(rating);

  useEffect(() => {
    setValue(rating);
  }, [rating]);

  const { title, gauge_id: gaugeId } = gauge;
  const displayPercent = (rating != null ? `${rating}%` : '?');
  const thumbnailSrc = gauge?.thumbnail_url || gauge?.image_url;
  const imageSrc = gauge?.image_url;
  const content = (
    <div ref={externalRef} key={gaugeId} className={styles.gaugeOverview}>
      {thumbnailSrc ? (
        <div
          className={styles.thumbnail}
          style={{ backgroundImage: `url(${thumbnailSrc})` }}
        />
      ) : null}
      <div className={styles.gauge}>
        <div className={styles.gaugeInfo}>
          <div className={styles.gaugeTitle}>{title}</div>
          <div className={styles.gaugeRating}>{displayPercent}</div>
        </div>
        <div>
          <OverviewSlider
            gaugeId={gaugeId}
            rating={rating}
            userState={userState}
            isLoadingInitial={isLoadingInitial}
            isValidating={isValidating}
            disabled={disabled}
            setShowConfirm={setShowConfirm}
            saveRating={saveRating}
            value={value}
            setValue={setValue}
          />
        </div>
      </div>
    </div>
  );

  if (imageSrc && isLargeScreen) {
    return (
      <Popover
        key={gaugeId}
        placement="left"
        trigger="hover"
        content={(
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${imageSrc})` }}
          />
        )}
      >
        {content}
      </Popover>
    );
  }
  return content;
}

function SwipeGaugeList({
  quteeId, gauges, ratings, scores, mutateGauges, afterRateHook,
  isLoadingInitial, isValidating, setShouldRefreshGauges, continueAnonymously,
  setShowContinueOnRatings, nextUnfinishedId, progressGaugeRef
}) {
  const CARD_OFFSET = 10;
  const SCALE_FACTOR = 0.03;

  const { message } = App.useApp();
  const { state: userState } = useUserContext();
  const isLargeScreen = useIsXl();
  const [showOverview, setShowOverview] = useState(isLargeScreen);
  const [cards, setCards] = useState([]);
  const [finishedCards, setFinishedCards] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [movingPercent, setMovingPercent] = useState(50);
  const [motionSnap, setMotionSnap] = useState(true);
  const [dragStart, setDragStart] = useState({
    animation: { x: 0 },
    transition: { ease: [0.6, 0.05, -0.01, 0.9], duration: 0.5 }
  });
  const [showSliderTooltip, setShowSliderTooltip] = useState(false);
  const [dragSource, setDragSource] = useState('slider');
  const sliderRef = useRef(null);
  const dragControls = useDragControls();
  const [isEditing, setEditing] = useState(false);
  const [currentGauge, setCurrentGauge] = useState(null);
  const [currentRating, setCurrentRating] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const isFirstRun = useRef(true);
  const resultsTransitionRef = useRef();
  const overviewTransitionRef = useRef();
  const cardsTransitionRef = useRef();
  const particleHolderRef = useRef();
  const emptyHeartRef = useRef();
  const filledHeartRef = useRef();
  const [heightRefs, setHeightRefs] = useState([]);
  const [wrapperTopPadding, setWrapperTopPadding] = useState(0);

  const {
    qutee
  } = useQutee(quteeId, userState.isLoggedIn);

  const canEdit = userState.isLoggedIn && userState.isVerified
    && userState.ability.can('update', subject('Qutee', qutee));

  const allowAnonymous = qutee
    ? (qutee.settings && !qutee.settings.prevent_anonymous_response)
    : true;
  const isInactive = qutee && qutee.status === 'inactive';

  const canUserInteract = () => {
    if (userState.isPending) {
      return false;
    }
    if (!allowAnonymous && (userState.isAnonymous || !userState.profile)) {
      return false;
    }
    if (isInactive) {
      return false;
    }
    return true;
  };

  const {
    mutate: mutateProgress
  } = useQuteeProgress(
    userState.isLoggedIn ? quteeId : null,
    userState.isLoggedIn ? userState.profile.profile_id : null
  );

  const {
    tallies
  } = useGaugeTallies(canEdit, gauges ? gauges.map((g) => g.gauge_id) : null);

  const { post: postRating, response: postRatingResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const { patch: patchGaugeTitleRequest, response: patchGaugeTitleResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const { patch: patchGaugeLabelRequest, response: patchGaugeLabelResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const { delete: deleteGaugeRequest, response: deleteGaugeResponse } = useFetch('gauges', {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Prefer: 'return=representation'
    }
  });

  const mutateSingleGauge = (gaugeId, data) => {
    mutateGauges(
      (orig) => {
        const results = orig.results.map((g) => (g.gauge_id === gaugeId ? {
          ...g,
          ...data
        } : g));
        return {
          ...orig,
          results
        };
      },
      { revalidate: false }
    );
  };

  const mutateAllGauges = (data) => {
    mutateGauges(
      (orig) => {
        const results = orig.results.map((g) => ({
          ...g,
          ...data
        }));
        return {
          ...orig,
          results
        };
      },
      { revalidate: false }
    );
  };

  const updateGaugeTitle = async (gaugeId, title) => {
    const data = {
      title
    };
    const updatedGauge = await patchGaugeTitleRequest(`${gaugeId}`, data);
    if (patchGaugeTitleResponse.ok) {
      mutateSingleGauge(gaugeId, { title });
    } else {
      throw new ApiException(updatedGauge);
    }
  };

  const updateGaugeLabel = async (gaugeId, labelType, label) => {
    const data = {
      [labelType]: label
    };
    const updatedGauge = await patchGaugeLabelRequest(`${gaugeId}`, data);
    if (patchGaugeLabelResponse.ok) {
      mutateSingleGauge(gaugeId, { [labelType]: label });
    } else {
      throw new ApiException(updatedGauge);
    }
  };

  const deleteGauge = async (gaugeId) => {
    const deletedGauge = await deleteGaugeRequest(`${gaugeId}`);
    if (deleteGaugeResponse.ok) {
      setShouldRefreshGauges(true);
    } else {
      throw new ApiException(deletedGauge);
    }
  };

  const x = useMotionValue(0);
  const rotate = useTransform(x, [-30, 30], [-12, 12]);
  const rate = useTransform(x, [-100, 100], [0, 100]);

  const getRandomTransformOrigin = () => {
    const value = (16 + 40 * Math.random()) / 100;
    const value2 = (15 + 36 * Math.random()) / 100;
    return {
      originX: value,
      originY: value2
    };
  };

  const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

  const randomDuration = () => Math.random() * 0.07 + 0.23;

  const createParticle = (x, y, isLow) => {
    if (particleHolderRef.current) {
      const particle = document.createElement('particle');
      particle.className = `${styles.heartParticle} ${isLow ? 'low' : 'high'}`;
      // @ts-ignore
      particleHolderRef.current.appendChild(particle);

      // Calculate a random size from 5px to 25px
      const size = Math.floor(Math.random() * 12 + 12);
      particle.style.width = `${size}px`;
      particle.style.fontSize = `${size}px`;
      particle.style.height = `${size}px`;
      particle.style.backgroundImage = isLow
        ? 'url(https://static.socialasking.com/empty-heart.png)'
        : 'url(https://static.socialasking.com/full-heart.png)';
      particle.style.backgroundSize = 'contain';
      particle.style.backgroundRepeat = 'no-repeat';

      // Generate a random x & y destination within a distance of 75px from the coordinate arguments
      const destinationX = isLow
        ? x + (Math.random() - 0.5) * 2 * 40
        : x + (Math.random() - 0.5) * 2 * 40;
      const destinationY = isLow
        ? y - Math.random() * 35
        : y - Math.random() * 85;

      const animation = particle.animate([
        {
          // Set the origin position of the particle
          // We offset the particle with half its size to center it around the coordinate arguments
          transform: `translate(${x - (size / 2)}px, ${y - (size / 2)}px)`,
          opacity: 1
        },
        {
          opacity: 0.6
        },
        {
          // We define the final coordinates as the second keyframe
          transform: `translate(${destinationX}px, ${destinationY}px)`,
          opacity: 0
        }
      ], {
        // Set a random duration from 1500 to 2500ms
        duration: isLow
          ? 3000 + Math.random() * 2000
          : 1500 + Math.random() * 1000,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        // Delay every particle with a random value from 0ms to 200ms
        delay: 100 + Math.random() * 200
      });

      // When the animation is finished, remove the element from the dom
      animation.onfinish = () => {
        particle.remove();
      };
    }
  };

  const melt = (x, y, count) => {
    for (let i = 0; i < count; i += 1) {
      createParticle(x, y, true);
    }
  };

  const pop = (x, y, count) => {
    for (let i = 0; i < count; i += 1) {
      createParticle(x, y, false);
    }
  };

  const getRoundedRate = () => Math.round(rate.get());

  const getParticleCount = (rating) => {
    if (rating > 80) {
      return convertRange(rating, [81, 100], [9, 30]);
    }
    if (rating > 50) {
      return convertRange(rating, [51, 80], [1, 8]);
    }
    if (rating < 20) {
      return convertRange(rating, [0, 19], [30, 9]);
    }
    if (rating < 50) {
      return convertRange(rating, [20, 49], [8, 1]);
    }
    return 0;
  };

  const getHeartSize = (rating) => {
    if (rating > 50) {
      return convertRange(rating, [51, 100], [1, 1.3]);
    }
    if (rating < 50) {
      return convertRange(rating, [0, 49], [1.3, 1]);
    }
    return 1;
  };

  const updateDragStart = ({
    animation = { x: 0 },
    transition = { ease: [0.6, 0.05, -0.01, 0.9], duration: 0.5 }
  }) => {
    setDragStart({ animation, transition });
  };

  const animateSwipe = (animation) => {
    updateDragStart({ ...dragStart, animation });
  };

  const restart = useCallback(() => {
    setShowOverview(false);
    if (typeof setShowContinueOnRatings === 'function') {
      setShowContinueOnRatings(false);
    }
    setFinishedCards([]);
    const ids = gauges.reduce((acc, cur) => {
      acc.push(cur.gauge_id);
      return acc;
    }, []);
    setCards(ids);
  }, [gauges]);

  const previousCard = () => {
    const previous = [...finishedCards.slice(0, 1)];
    setCards([...previous, ...cards]);
    setFinishedCards([...finishedCards.slice(1, finishedCards.length)]);
  };

  const animatePreviousCard = () => {
    setMotionSnap(false);
    previousCard();
    updateDragStart({
      // @ts-ignore
      animation: { y: -500 },
      transition: { ease: [0.6, 0.05, -0.01, 0.9], duration: 0 }
    });
    setTimeout(() => {
      updateDragStart({
        // @ts-ignore
        animation: { y: 0 },
        transition: { ease: [0.6, 0.05, -0.01, 0.9], duration: 0.5 }
      });
      // x.set(0);
      setMotionSnap(true);
    }, 1);
  };

  const nextCard = (isInstructionCard) => {
    const cardsLeft = cards.length - 1;
    const swipedCard = [...cards.slice(0, 1)];
    setCards([...cards.slice(1, cards.length)]);
    if (!isInstructionCard) {
      setFinishedCards([...swipedCard, ...finishedCards]);
    }
    if (cardsLeft === 0) {
      setTimeout(() => {
        if (typeof setShowContinueOnRatings === 'function') {
          setShowContinueOnRatings(true);
        }
        setShouldRefreshGauges(true);
        setShowOverview(true);
      }, 400);
    }
  };

  const animateNextCard = (isInstructionCard) => {
    setMotionSnap(false);
    animateSwipe({ y: -500 });
    setTimeout(() => {
      updateDragStart({ animation: { x: 0 } });
      x.set(0);
      nextCard(isInstructionCard);
      setMotionSnap(true);
    }, 300);
  };

  useEffect(() => {
    if (isFirstRun.current && gauges?.length) {
      isFirstRun.current = false;
      const completedRatings = ratings?.reduce((acc, cur) => {
        if (cur) {
          acc.push(cur);
        }
        return acc;
      }, []);
      if ((completedRatings?.length > 0)) {
        if (typeof setShowContinueOnRatings === 'function') {
          setShowContinueOnRatings(true);
        }
        setShowOverview(true);
      } else {
        if (typeof setShowContinueOnRatings === 'function') {
          setShowContinueOnRatings(false);
        }
        const ids = gauges.reduce((acc, cur) => {
          acc.push(cur.gauge_id);
          return acc;
        }, []);
        // indicator for instruction card
        ids.unshift(-1);
        setCards(ids);
      }
    }
  }, [gauges, ratings, setShowContinueOnRatings]);

  useEffect(() => {
    isFirstRun.current = true;
  }, [userState?.profile?.profile_id]);

  const lowLabel = (gauges?.length) ? gauges[0].low_label : null;
  const highLabel = (gauges?.length) ? gauges[0].high_label : null;

  useEffect(() => {
    const currentGaugeIndex = (gauges && cards.length && (cards[0] !== -1))
      ? gauges.map((g) => g.gauge_id).indexOf(cards[0]) : null;
    const curGauge = (currentGaugeIndex != null) ? gauges[currentGaugeIndex] : null;
    const curRating = (currentGaugeIndex != null) ? ratings[currentGaugeIndex] : null;
    const percent = (curRating != null) ? curRating.rating : 50;

    setCurrentGauge(curGauge);
    setCurrentRating(curRating?.rating);
    setMovingPercent(percent);
  }, [cards, cards.length, gauges, ratings]);

  useEffect(() => {
    // add or remove refs
    setHeightRefs((refs) => Array(cards.length)
      .fill()
      .map((_, i) => refs[i] || React.createRef()));
  }, [cards.length]);

  useEffect(() => {
    if (heightRefs.length) {
      const highest = heightRefs.reduce((acc, cur) => {
        if (!cur.current) {
          return acc;
        }
        const box = cur.current.getBoundingClientRect();
        const height = box.height > 54 ? 54 : box.height;
        return height > acc ? height : acc;
      }, 0);
      setWrapperTopPadding(highest - 28);
    }
  }, [heightRefs]);

  const startEditing = () => {
    setEditing(true);
    restart();
  };

  const finishEditing = () => {
    setShowOverview(true);
    if (typeof setShowContinueOnRatings === 'function') {
      setShowContinueOnRatings(true);
    }
    setEditing(false);
  };

  // const Card = ({ gauge, rating, score, style, onDragStart, onDragEnd, animate }) => {

  // };

  const refreshProgress = async () => {
    if (typeof mutateProgress === 'function') {
      mutateProgress();
    }
  };

  const onSubmitRating = async (gaugeId, rating) => {
    const data = {
      rating
    };
    const gaugeIndex = gauges.map((g) => g.gauge_id).indexOf(gaugeId);
    const origRating = ratings[gaugeIndex];
    const newRating = await postRating(`${gaugeId}/ratings`, data);
    if (postRatingResponse.ok) {
      mutateSingleGauge(gaugeId, { rating: { ...origRating, rating } });
    } else {
      throw new ApiException(newRating);
    }
  };

  const saveRating = async (gaugeId, percent) => {
    setLoading(true);
    const loadingKey = 'saveRatingRequest';
    const successKey = 'saveRatingSuccess';
    const errorKey = 'saveRatingError';
    message.destroy(loadingKey);
    message.destroy(errorKey);
    message.destroy(successKey);
    message.loading({ key: loadingKey, content: 'Saving your gauge rating...' });
    try {
      await onSubmitRating(gaugeId, percent);
      setLoading(false);
      refreshProgress();
      if (typeof afterRateHook === 'function') {
        afterRateHook();
      }
      message.destroy(loadingKey);
      message.success({ key: successKey, content: `Saved your rating of ${percent}%` });
    } catch (err) {
      setLoading(false);
      message.destroy(loadingKey);
      message.error({ key: errorKey, content: err?.details?.issue || err.message, duration: 5 });
    }
  };

  const changeGaugeTitle = async (gaugeId, title) => {
    setLoading(true);
    const loadingKey = 'updateGaugeTitleRequest';
    const successKey = 'updateGaugeTitleSuccess';
    const errorKey = 'updateGaugeTitleError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);
    message.loading({ key: loadingKey, content: 'Updating gauge title...' });
    try {
      await updateGaugeTitle(gaugeId, title);
      message.destroy(loadingKey);
      message.success({ key: successKey, content: 'Gauge title successfully updated' });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ key: errorKey, content: err?.details?.issue || err.message, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const changeGaugeLabel = async (labelType, labelText) => {
    if (!labelText || !labelText.length) {
      message.error('Label may not be blank', 5);
      return;
    }
    setLoading(true);
    const loadingKey = 'updateGaugeLabelRequest';
    const successKey = 'updateGaugeLabelSuccess';
    const errorKey = 'updateGaugeLabelError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);
    message.loading({ key: loadingKey, content: 'Updating gauge label...' });
    try {
      const updates = [];
      gauges.forEach((gauge) => {
        const p = updateGaugeLabel(gauge.gauge_id, labelType, labelText);
        updates.push(p);
      });
      const results = await Promise.allSettled(updates);
      const errors = results.filter((p) => p.status === 'rejected');
      message.destroy(loadingKey);
      if (errors.length) {
        // @ts-ignore
        message.error({ key: errorKey, content: errors[0].reason, duration: 5 });
      } else {
        mutateAllGauges({ [labelType]: labelText });
        message.success({
          key: successKey,
          content: `Gauge's ${labelType === 'low_label' ? 'low' : 'high'} label successfully updated`
        });
      }
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ key: errorKey, content: err?.details?.issue || err.message, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const removeGauge = async (gaugeId, title) => {
    setLoading(true);
    const loadingKey = 'deleteGaugeRequest';
    const successKey = 'deleteGaugeSuccess';
    const errorKey = 'deleteGaugeError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);
    message.loading({ key: loadingKey, content: `Deleting gauge${title ? ` '${title}'` : ''}...` });
    try {
      await deleteGauge(gaugeId);
      message.destroy(loadingKey);
      message.success({ key: successKey, content: `Gauge${title ? ` '${title}'` : ''} deleted...` });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ key: errorKey, content: err?.details?.issue || err.message, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  const resetRating = async () => {
    let newPercent = 50;
    if ((currentRating != null)) {
      newPercent = currentRating;
    }
    setMovingPercent(newPercent);
  };

  const selectRating = async (gaugeId, value) => {
    if (!userState.isLoggedIn) {
      animateSwipe({ x: 0 });
      updateDragStart({ animation: { x: 0 } });
      return;
    }
    const xDestination = value >= 50 ? 450 : -450;
    animateSwipe({ x: xDestination });
    if (gaugeId) {
      await saveRating(gaugeId, value);
    }
    setTimeout(() => {
      updateDragStart({ animation: { x: 0 } });
      x.set(0);
      nextCard(gaugeId === null);
      setMotionSnap(true);
    }, 100);
  };

  const confirmAnonymous = async () => {
    setShowConfirm(false);
    continueAnonymously();
  };

  const cancelRating = async () => {
    setShowConfirm(false);
    resetRating();
  };

  const handleDragStart = () => {
    setDragSource('motion');
    setShowSliderTooltip(true);
    if (canUserInteract() && !userState.isLoggedIn) {
      setShowConfirm(true);
    }
  };

  const handleDrag = () => {
    if (dragSource === 'motion') {
      setMovingPercent(getRoundedRate());
    }
  };

  const handleDragEnd = async (info, gaugeId, isInstructionCard) => {
    if (!userState.isLoggedIn) {
      animateSwipe({ x: 0 });
      updateDragStart({ animation: { x: 0 } });
      return;
    }
    if (dragSource === 'motion') {
      // drag quick enough to count as a flick
      if (Math.abs(info.velocity.x) > 350) {
        // flick right for 100
        if (info.offset.x >= 0) {
          setMovingPercent(100);
          setMotionSnap(false);
          animateSwipe({ x: 450 });
          if (!isInstructionCard) {
            await saveRating(gaugeId, 100);
            if (filledHeartRef.current) {
              // @ts-ignore
              const rect = filledHeartRef.current.getBoundingClientRect();
              const xval = rect.x + (rect.width / 2.0);
              const yval = rect.y;
              pop(xval, yval, getParticleCount(100));
            }
          }
          setTimeout(() => {
            updateDragStart({ animation: { x: 0 } });
            x.set(0);
            nextCard(isInstructionCard);
            setMotionSnap(true);
          }, 100);
          // flick left for 0
        } else if (info.offset.x < 0) {
          setMovingPercent(0);
          setMotionSnap(false);
          animateSwipe({ x: -450 });
          if (!isInstructionCard) {
            await saveRating(gaugeId, 0);
            if (emptyHeartRef.current) {
              // @ts-ignore
              const rect = emptyHeartRef.current.getBoundingClientRect();
              const xval = rect.x + (rect.width / 2.0);
              const yval = rect.y;
              melt(xval, yval, getParticleCount(0));
            }
          }
          setTimeout(() => {
            updateDragStart({ animation: { x: 0 } });
            x.set(0);
            nextCard(isInstructionCard);
            setMotionSnap(true);
          }, 100);
        }
        // non-flick precision rating
      } else {
        const movedRight = info.offset.x > 0;
        setMovingPercent(getRoundedRate());
        setMotionSnap(false);
        animateSwipe({ x: movedRight ? 500 : -500 });
        if (!isInstructionCard) {
          const roundedRate = getRoundedRate();
          const count = getParticleCount(roundedRate);
          await saveRating(gaugeId, roundedRate);
          if (roundedRate > 50) {
            if (filledHeartRef.current) {
              // @ts-ignore
              const rect = filledHeartRef.current.getBoundingClientRect();
              const xval = rect.x + (rect.width / 2.0);
              const yval = rect.y;
              pop(xval, yval, count);
            }
          }
          if (roundedRate < 50) {
            if (emptyHeartRef.current) {
              // @ts-ignore
              const rect = emptyHeartRef.current.getBoundingClientRect();
              const xval = rect.x + (rect.width / 2.0);
              const yval = rect.y;
              melt(xval, yval, count);
            }
          }
        }
        setTimeout(() => {
          updateDragStart({ animation: { x: 0 } });
          x.set(0);
          nextCard(isInstructionCard);
          setMotionSnap(true);
        }, 100);
      }
      setShowSliderTooltip(false);
    }
  };

  const resetDrag = () => {
    updateDragStart({ ...dragStart, ...{ x: 0 } });
    x.set(0);
  };

  const handleSliderChange = (value) => {
    if (canUserInteract() && !userState.isLoggedIn) {
      setShowConfirm(true);
    }
    setDragSource('slider');
    setMovingPercent(value);
    if (!showSliderTooltip) {
      setShowSliderTooltip(true);
    }
  };

  const startControlledDrag = (event) => {
    dragControls.start(event, { snapToCursor: true });
  };

  const handleSliderAfterChange = async (gaugeId, value) => {
    const count = getParticleCount(value);
    await selectRating(gaugeId, value);
    setShowSliderTooltip(false);
    if (value > 50) {
      if (filledHeartRef.current) {
        // @ts-ignore
        const rect = filledHeartRef.current.getBoundingClientRect();
        const xval = rect.x + (rect.width / 2.0);
        const yval = rect.y;
        pop(xval, yval, count);
      }
    }
    if (value < 50) {
      if (emptyHeartRef.current) {
        // @ts-ignore
        const rect = emptyHeartRef.current.getBoundingClientRect();
        const xval = rect.x + (rect.width / 2.0);
        const yval = rect.y;
        melt(xval, yval, count);
      }
    }
  };

  const getToggleButtonText = () => {
    if (!showResults) {
      return 'See Results  *Not Public';
    }

    return 'Back To Ratings Overview';
  };

  const handleResultsToggle = () => {
    if (showResults) {
      // @ts-ignore
      resultsTransitionRef?.current?.scrollIntoView();
    } else {
      // @ts-ignore
      overviewTransitionRef?.current?.scrollIntoView();
    }
    setShowResults(!showResults);
  };

  const getInputSlider = (gaugeId) => {
    let tooltipTitle = null;
    const defaultDisabledText = 'Gauge interactions are disabled.';
    const disabled = !canUserInteract();
    const disabledOrLoading = disabled || isLoadingInitial
      || isValidating || isLoading;
    if (disabled) {
      tooltipTitle = defaultDisabledText;
    } else if (isLoadingInitial || isValidating || isLoading) {
      tooltipTitle = 'Loading data';
    }

    const handleLowClick = async () => {
      if (!disabledOrLoading) {
        if (!userState.isLoggedIn) {
          setShowConfirm(true);
        }
        setMovingPercent(0);
        await selectRating(gaugeId, 0);
        if (emptyHeartRef.current) {
          // @ts-ignore
          const rect = emptyHeartRef.current.getBoundingClientRect();
          const xval = rect.x + (rect.width / 2.0);
          const yval = rect.y;
          melt(xval, yval, getParticleCount(0));
        }
      }
    };

    const handleHighClick = async () => {
      if (!disabledOrLoading) {
        if (!userState.isLoggedIn) {
          setShowConfirm(true);
        }
        setMovingPercent(100);
        await selectRating(gaugeId, 100);
        if (filledHeartRef.current) {
          // @ts-ignore
          const rect = filledHeartRef.current.getBoundingClientRect();
          const xval = rect.x + (rect.width / 2.0);
          const yval = rect.y;
          pop(xval, yval, getParticleCount(100));
        }
      }
    };

    return (
      <Tooltip
        mouseEnterDelay={0.3}
        title={tooltipTitle}
      >
        <div
          // @ts-ignore
          ref={(el) => { sliderRef.current = el; }}
          className={styles.sliderWithIcons}
        >
          <div ref={particleHolderRef} />
          <div className={styles.roundedShadows}>
            <motion.div
              ref={emptyHeartRef}
              className={styles.emptyHeartAnim}
              initial={{
                scale: 1, rotate: 0, originX: 0, originY: 0
              }}
              style={(x.get() < 0)
                ? { scale: getHeartSize(movingPercent) }
                : { scale: 1 }}
              animate={x.get() < 0 ? {
                rotate: [-5, 5, 0],
                ...getRandomTransformOrigin()
              } : { rotate: 0, originX: 0, originY: 0 }}
              transition={x.get() < 0 ? {
                delay: getRandomDelay(),
                repeat: Infinity,
                duration: randomDuration()
              } : {
                delay: 0, repeat: 0, duration: 0
              }}
            >
              <div
                className={`${styles.emptyHeart} ${disabledOrLoading ? styles.disabledHearts : ''}`}
                style={{ backgroundImage: `url(${emptyHeartIcon})` }}
                onClick={handleLowClick}
                onKeyDown={handleLowClick}
                role="button"
                tabIndex={0}
              />
            </motion.div>
          </div>
          <div className={styles.sliderContainer}>
            <AnonPopconfirm
              disabled={disabledOrLoading || userState.isLoggedIn}
              onConfirm={() => confirmAnonymous()}
              onCancel={() => cancelRating()}
              open={showConfirm}
              overwriteText="Rating requires agreement to our terms of service and privacy policy."
              okText="Agree and continue"
            >
              <div onPointerDown={startControlledDrag}>
                <Slider
                  className={`${styles.slider}${(currentRating != null) ? ` ${styles.ratedSlider}` : ''}`}
                  defaultValue={(currentRating != null) ? currentRating : 50}
                  value={movingPercent}
                  onChange={(value) => handleSliderChange(value)}
                  onAfterChange={(value) => handleSliderAfterChange(gaugeId, value)}
                  disabled={disabledOrLoading}
                  tooltip={{
                    open: showSliderTooltip || (currentRating != null),
                    placement: 'top',
                    getPopupContainer: () => sliderRef?.current,
                    autoAdjustOverflow: false,
                    zIndex: 10
                  }}
                />
              </div>
            </AnonPopconfirm>
          </div>
          <div className={styles.roundedShadows}>
            <motion.div
              ref={filledHeartRef}
              className={styles.filledHeartAnim}
              initial={{
                scale: 1, rotate: 0, originX: 0, originY: 0
              }}
              style={(x.get() > 0)
                ? { scale: getHeartSize(movingPercent) }
                : { scale: 1 }}
              animate={x.get() > 0 ? {
                rotate: [5, -5, 0],
                ...getRandomTransformOrigin()
              } : { rotate: 0, originX: 0, originY: 0 }}
              transition={x.get() > 0 ? {
                delay: getRandomDelay(),
                repeat: Infinity,
                duration: randomDuration()
              } : {
                delay: 0, repeat: 0, duration: 0
              }}
            >
              <div
                className={`${styles.filledHeart} ${disabledOrLoading ? styles.disabledHearts : ''}`}
                style={{ backgroundImage: `url(${fullHeartIcon})` }}
                onClick={handleHighClick}
                onKeyDown={handleHighClick}
                role="button"
                tabIndex={0}
              />
            </motion.div>
          </div>
        </div>
      </Tooltip>
    );
  };

  const getInstructionsContent = (lowLabel, highLabel) => (
    <div
      className={styles.instructionsCard}
      style={{ backgroundImage: 'url(https://static.socialasking.com/sa-logo-icon.png)' }}
    >
      <div className={styles.instructionsTitle}>
        Instructions
      </div>
      <div className={styles.instructionsContent}>
        <div className={styles.instructionsRate}>
          <div className={styles.instructionsLow}>
            <div>
              <Image
                className={styles.instructionsLeftArrow}
                src={tinyarrow}
                alt="Left"
                title="Left"
              />
              <div
                className={styles.instructionsHeart}
                style={{ backgroundImage: `url(${emptyHeartIcon})` }}
              />
            </div>
            <div className={styles.instructionsLowText}>
              {lowLabel}
            </div>
          </div>
          <div className={styles.instructionsSwipe}>
            <Image
              className={styles.instructionsSwipeImage}
              src={handswipe}
              alt="Swipe"
              title="Swipe"
            />
          </div>
          <div className={styles.instructionsHigh}>
            <div>
              <div
                className={styles.instructionsHeart}
                style={{ backgroundImage: `url(${fullHeartIcon})` }}
              />
              <Image
                className={styles.instructionsRightArrow}
                src={tinyarrow}
                alt="Right"
                title="Right"
              />
            </div>
            <div className={styles.instructionsHighText}>
              {highLabel}
            </div>
          </div>
        </div>
        <div className={styles.instructionsInfo}>
          <div className={styles.instructionsInfoTitle}>Ready?</div>
          <div className={styles.instructionsInfoDescription}>
            Swipe a card or drag the handle below to rate items
          </div>
        </div>
      </div>
    </div>
  );

  const exampleGauge = (
    <div className={styles.ratingExample}>
      <span className={styles.exampleRate}>RATE</span>
      <div className={styles.exampleContent}>
        <div className={styles.exampleRow1}>
          <Text
            className={styles.exampleLabelLow}
            editable={canEdit && isEditing && !isLoading ? {
              text: lowLabel,
              onChange: (value) => changeGaugeLabel('low_label', value),
              icon: null,
              tooltip: 'Edit Low Label'
            } : false}
          >
            {lowLabel}
          </Text>
          <div className={styles.example50}>50</div>
          <Text
            className={styles.exampleLabelHigh}
            editable={canEdit && isEditing && !isLoading ? {
              text: highLabel,
              onChange: (value) => changeGaugeLabel('high_label', value),
              icon: null,
              tooltip: 'Edit High Label'
            } : false}
          >
            {highLabel}
          </Text>
        </div>
        <div className={styles.exampleRow2}>
          <div
            className={styles.exampleHeart}
            style={{ backgroundImage: `url(${emptyHeartIcon})` }}
          />
          <div className={styles.exampleSlider}>
            <div className={styles.exampleBar} />
            <span className={styles.exampleBarLine} />
          </div>
          <div
            className={styles.exampleHeart}
            style={{ backgroundImage: `url(${fullHeartIcon})` }}
          />
        </div>
      </div>
    </div>
  );

  if (isEditing) {
    // eslint-disable-next-line react/no-unstable-nested-components
    const imageUploadButtonRef = forwardRef(
      (props, ref) => (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          className={styles.upload}
          icon={<UploadOutlined color="white" />}
          ref={ref}
          disabled={isLoading}
        >
          Upload Image
        </Button>
      )
    );
    imageUploadButtonRef.displayName = 'Gauge Image Upload Button';

    // eslint-disable-next-line react/no-unstable-nested-components
    const thumbnailUploadButtonRef = forwardRef(
      (props, ref) => (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          className={styles.thumbnailUpload}
          icon={<UploadOutlined color="white" />}
          ref={ref}
          disabled={isLoading}
        >
          Upload Thumbnail
        </Button>
      )
    );
    thumbnailUploadButtonRef.displayName = 'Gauge Thumbnail Upload Button';

    return (
      <div>
        <div className={styles.edit}>
          <Switch
            className={styles.editSwitch}
            checkedChildren="Edit Gauges"
            unCheckedChildren="Edit Gauges"
            checked={isEditing}
            onClick={(checked) => (checked ? startEditing() : finishEditing())}
            disabled={isLoadingInitial || isLoading || isValidating}
          />
        </div>
        <div className={styles.editExample}>
          {exampleGauge}
        </div>
        <div className={styles.editWrapper}>
          <ul className={styles.listWrapper}>
            {gauges.map((gauge, index) => {
              const gaugeId = gauge.gauge_id;
              const { title } = gauge || '';
              return (
                <li
                  key={gaugeId}
                  className={styles.editBox}
                >
                  <Title
                    className={(canEdit && !title) ? styles.addTitle : styles.title}
                    level={4}
                    editable={canEdit && !isLoading ? {
                      text: title || '',
                      onChange: (value) => changeGaugeTitle(gaugeId, value),
                      icon: title ? null : <PlusOutlined className={styles.plus} />,
                      tooltip: title ? 'Edit Title' : 'Add Title'
                    } : false}
                  >
                    {title || (canEdit && 'Add Title')}
                  </Title>
                  <div className={styles.delete}>
                    <Popconfirm
                      title="Delete this gauge?"
                      onConfirm={() => removeGauge(gaugeId)}
                      okText="Delete"
                      okButtonProps={{ className: styles.confirmDelete }}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <DeleteOutlined className={styles.deleteIcon} />
                    </Popconfirm>
                  </div>
                  <div className={styles.listedCard}>
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${Date.now()}-${index}-image`}
                      className={styles.image}
                      style={{ backgroundImage: `url(${gauge?.image_url || PLACEHOLDER_IMAGE_URL})` }}
                    />
                    <ImageUploadButton
                      resourceType="gauge"
                      resourceId={gaugeId}
                      imageTitle={null}
                      imageType="image"
                      buttonRef={imageUploadButtonRef}
                      successCallback={
                        (src) => { mutateSingleGauge(gaugeId, { image_url: src }); }
                      }
                    />
                  </div>
                  <div className={styles.thumbnailWrapper}>
                    <div className={styles.gaugeOverview}>
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${Date.now()}-${index}-thumbnail`}
                        className={styles.thumbnail}
                        style={{ backgroundImage: `url(${gauge?.thumbnail_url || gauge?.image_url || PLACEHOLDER_IMAGE_URL})` }}
                      />
                      <div className={styles.gauge}>
                        <ImageUploadButton
                          resourceType="gauge"
                          resourceId={gaugeId}
                          imageTitle={null}
                          imageType="thumbnail"
                          buttonRef={thumbnailUploadButtonRef}
                          successCallback={
                            (src) => { mutateSingleGauge(gaugeId, { thumbnail_url: src }); }
                          }
                        />
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  if (isLoadingInitial) {
    return <Skeleton />;
  }
  return (
    <>
      <CSSTransition
        in={!showOverview && !showResults && !isLargeScreen}
        nodeRef={cardsTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.cardsEnter,
          enterActive: styles.cardsEnterActive
        }}
        unmountOnExit
      >
        <div ref={cardsTransitionRef}>
          {canEdit && (
            <div className={styles.edit}>
              <Switch
                checkedChildren="Edit Gauges"
                unCheckedChildren="Edit Gauges"
                checked={isEditing}
                onClick={(checked) => (checked ? startEditing() : finishEditing())}
                disabled={isLoadingInitial || isLoading || isValidating}
              />
            </div>
          )}
          <div className={styles.previousNext}>
            <Button
              className={styles.previous}
              type="default"
              onClick={() => animatePreviousCard()}
              disabled={cards.length >= gauges.length}
            >
              Previous
            </Button>
            <Button
              className={styles.next}
              type="default"
              onClick={() => {
                if (!userState.isLoggedIn) {
                  setShowConfirm(true);
                } else {
                  animateNextCard(cards.length > gauges.length);
                }
              }}
              disabled={!canUserInteract()}
            >
              Next
            </Button>
          </div>
          <div
            className={styles.wrapper}
            style={{ paddingTop: 40 + wrapperTopPadding, paddingBottom: 3 * cards.length + 20 }}
          >
            <ul className={styles.cardWrapper}>
              {cards.map((gaugeId, index) => {
                const isInstructionCard = (gaugeId === -1);
                const gaugeIndex = (gauges && !isInstructionCard)
                  ? gauges.map((g) => g.gauge_id).indexOf(gaugeId) : null;
                const gauge = (gaugeIndex !== null) ? gauges[gaugeIndex] : null;

                const src = gauge?.image_url || PLACEHOLDER_IMAGE_URL;

                const canDrag = (index === 0);

                const stackAnimation = {
                  bottom: index * -CARD_OFFSET,
                  zIndex: gauges.length - index
                };
                if (canDrag) {
                  stackAnimation.scale = 1 - index * SCALE_FACTOR;
                } else {
                  stackAnimation.transform = `scale(${1 - index * SCALE_FACTOR})`;
                }
                if (canDrag) {
                  return (
                    <motion.li
                      key={isInstructionCard ? 'instruction' : gaugeId}
                      className={
                        isInstructionCard ? styles.stackedInstructionCard : styles.stackedCard
                      }
                      style={{
                        cursor: 'grab',
                        zIndex: gauges.length - index,
                        x,
                        rotate,
                        display: 'block'
                      }}
                      initial={{ x: 0, scale: 1 - SCALE_FACTOR, bottom: -CARD_OFFSET }}
                      animate={{
                        ...stackAnimation,
                        ...dragStart.animation
                        // ...rotate
                      }}
                      whileTap={{ cursor: 'grabbing' }}
                      drag={canUserInteract() ? 'x' : false}
                      // dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={1}
                      // dragDirectionLock
                      dragSnapToOrigin={motionSnap}
                      // transition={{ ease: [0.6, 0.05, -0.01, 0.9], duration: 0.5 }}
                      transition={{
                        ...dragStart.transition,
                        scale: { duration: 0.2 },
                        bottom: { duration: 0.2 }
                      }}
                      onTapStart={() => resetDrag()}
                      onDragStart={() => handleDragStart()}
                      onDrag={() => handleDrag()}
                      onDragEnd={(e, info) => handleDragEnd(info, gaugeId, isInstructionCard)}
                      dragControls={dragControls}
                    >
                      {isInstructionCard ? getInstructionsContent(lowLabel, highLabel) : (
                        <div className={styles.cardContent}>
                          <div
                            className={styles.stackedImage}
                            style={{ backgroundImage: `url(${src})` }}
                          />
                          <div
                            className={styles.titleContainer}
                          >
                            {gauge?.title
                              ? (
                                <h3
                                  ref={heightRefs[index]}
                                  className={styles.title}
                                >
                                  {gauge.title}
                                </h3>
                              )
                              : null}
                          </div>
                        </div>
                      )}
                    </motion.li>
                  );
                }
                return (
                  <li
                    key={gaugeId}
                    className={styles.stackedCard}
                    style={stackAnimation}
                  >
                    <div className={styles.cardContent}>
                      <div
                        className={styles.stackedImage}
                        style={{ backgroundImage: `url(${src})` }}
                      />
                      <div
                        ref={heightRefs[index]}
                        className={styles.titleContainer}
                      >
                        {gauge?.title
                          ? (
                            <h3
                              ref={heightRefs[index]}
                              className={styles.title}
                            >
                              {gauge.title}
                            </h3>
                          )
                          : null}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {getInputSlider(currentGauge?.gauge_id)}
        </div>
        {/* ) : null} */}
      </CSSTransition>
      <CSSTransition
        in={canEdit && showResults}
        nodeRef={resultsTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.resultsEnter,
          enterActive: styles.resultsEnterActive
        }}
        unmountOnExit
      >
        <div ref={resultsTransitionRef}>
          {(gauges?.length > 1) ? (
            <div className={styles.switchContainer}>
              <Button
                className={styles.switch}
                type="text"
                onClick={() => handleResultsToggle()}
              >
                {showResults && <LeftOutlined />}
                {getToggleButtonText()}
                {!showResults && <RightOutlined />}
              </Button>
            </div>
          ) : null}
          <div>
            {gauges.map((gauge) => {
              const { gauge_id: gaugeId, title } = gauge;
              const tallyMatch = tallies?.filter((t) => t && (t.gauge_id === gaugeId));
              const scoreMatch = scores?.filter((s) => s && (s.gauge_id === gaugeId));
              const src = gauge?.thumbnail_url || gauge?.image_url;
              return (
                <div className={styles.results} key={gaugeId}>
                  <div className={styles.resultsTitleContainer}>
                    <Title
                      className={styles.resultsTitle}
                      level={3}
                    >
                      {title}
                    </Title>
                    {src ? (
                      <div
                        className={styles.thumbnail}
                        style={{ backgroundImage: `url(${src})` }}
                      />
                    ) : null}
                  </div>
                  <GaugeGraph
                    key={gaugeId}
                    segments={tallyMatch?.length ? tallyMatch[0].segments : null}
                    score={scoreMatch?.length ? Math.round(scoreMatch[0].average_score) : null}
                    showIcons
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.switchContainer}>
            <Button
              className={styles.switch}
              type="text"
              onClick={() => handleResultsToggle()}
            >
              {showResults && <LeftOutlined />}
              {getToggleButtonText()}
              {!showResults && <RightOutlined />}
            </Button>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={!showResults && showOverview}
        nodeRef={overviewTransitionRef}
        timeout={{
          appear: 400,
          enter: 400,
          exit: 0
        }}
        classNames={{
          enter: styles.overviewEnter,
          enterActive: styles.overviewEnterActive
        }}
        unmountOnExit
      >
        <div ref={overviewTransitionRef}>
          {canEdit && (
            <div className={styles.edit}>
              <Switch
                checkedChildren="Edit Gauges"
                unCheckedChildren="Edit Gauges"
                checked={isEditing}
                onClick={(checked) => (checked ? startEditing() : finishEditing())}
                disabled={isLoadingInitial || isLoading || isValidating}
              />
            </div>
          )}
          <div className={styles.overviewHeader}>
            <div className={styles.yourRatings}>Your Ratings</div>
            {!isLargeScreen && (
              <div>
                <Button
                  className={styles.restart}
                  type="default"
                  onClick={() => restart()}
                >
                  Restart
                </Button>
              </div>
            )}
          </div>
          <div className={styles.reviewPrompt}>Review or update your ratings</div>
          <AnonPopconfirm
            disabled={!canUserInteract() || userState.isLoggedIn}
            onConfirm={() => confirmAnonymous()}
            onCancel={() => cancelRating()}
            open={showConfirm}
          />
          <div className={styles.overview}>
            {exampleGauge}
            {gauges.map((gauge, index) => {
              const rating = ratings[index];
              return (
                <GaugeOverview
                  externalRef={nextUnfinishedId === gauge.gauge_id ? progressGaugeRef : undefined}
                  key={gauge.gauge_id}
                  gauge={gauge}
                  rating={rating?.rating}
                  userState={userState}
                  isLargeScreen={isLargeScreen}
                  isLoadingInitial={isLoadingInitial}
                  isValidating={isValidating}
                  disabled={!canUserInteract()}
                  setShowConfirm={setShowConfirm}
                  saveRating={saveRating}
                />
              );
            })}
          </div>
          {canEdit && (
            <div className={styles.switchContainer}>
              <Button
                className={styles.switch}
                type="text"
                onClick={() => handleResultsToggle()}
              >
                {showResults && <LeftOutlined />}
                {getToggleButtonText()}
                {!showResults && <RightOutlined />}
              </Button>
            </div>
          )}
        </div>
      </CSSTransition>
    </>
  );
}

SwipeGaugeList.displayName = 'Swipe Gauge List';
SwipeGaugeList.defaultProps = {
  hideTitle: false,
  hideUserRating: false,
  afterRateHook: null,
  isLoadingInitial: false,
  isValidating: false,
  getExtraContent: null,
  setShouldRefreshGauges: () => {},
  nextUnfinishedId: undefined,
  progressGaugeRef: undefined
};

export default SwipeGaugeList;
