import React, { useState, useContext } from 'react';
import {
  Input, Form, App, Button
} from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar';
import AuthLoader from './AuthLoader';
import { useUserContext } from '../../user/providers/UserProvider';
import EmbedContext from '../../embed/providers/EmbedProvider';
import { createUser, upgradeAnon } from '../state/api';
import styles from '../styles/Auth.module.scss';

const validateEmail = (value) => {
  const input = document.createElement('input');
  input.type = 'email';
  input.value = value;
  return input.checkValidity();
};

function SignUp({
  successCallback, goToSignIn, forcedEmail,
  hash, quteeId
}) {
  const { message } = App.useApp();
  const { state: userState, dispatch: userDispatch } = useUserContext();
  const embedConfig = useContext(EmbedContext);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState(forcedEmail || '');
  const [password, setPassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);
  const [form] = Form.useForm();

  const { actionUrl, continueUrl } = embedConfig;

  const resetForm = () => {
    form.resetFields();
    setEmail(forcedEmail || '');
    setPassword('');
    setPasswordScore(0);
  };

  const handleSignUpError = (err, key) => {
    console.log(err);
    switch (err.code) {
      case 'auth/network-request-failed':
        message.error({ content: 'A network error occurred. Please try again.', key, duration: 5 });
        break;
      case 'auth/too-many-requests':
        message.error({
          content: 'You have made too many auth requests. Please wait a few minutes and try again.',
          key,
          duration: 5
        });
        break;
      case 'auth/invalid-email':
        message.error({ content: 'The email address is badly formatted.', key, duration: 5 });
        break;
      case 'auth/email-already-in-use':
        message.error({ content: 'An account with that email address already exists.', key, duration: 5 });
        break;
      case 'auth/weak-password':
        message.error({ content: 'The password is too weak. Please use a stronger password.', key, duration: 5 });
        break;
      case 'auth/operation-not-allowed':
        message.error({ content: 'Email/password accounts are not enabled.', key, duration: 5 });
        break;
      default:
        message.error({ content: err?.details?.issue || err.message, key, duration: 5 });
        break;
    }
  };

  const handleSubmit = async (values) => {
    const { username, email, password } = values;

    const loadingKey = 'newUserRequest';
    const successKey = 'newUserSuccess';
    const errorKey = 'newUserError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Creating account...', key: loadingKey });
    try {
      let profile;
      const args = {
        username,
        email,
        password,
        continueUrl,
        actionUrl
      };
      if (hash) {
        args.hash = hash;
      }
      if (quteeId) {
        args.quteeId = quteeId;
      }
      if (userState?.isAnonymous) {
        profile = await upgradeAnon(args);
      } else {
        profile = await createUser(args);
      }
      message.destroy(loadingKey);
      message.success({ content: 'Successfully signed up', key: successKey });
      userDispatch({ type: 'LOGIN_SUCCESS', payload: { profile } });
      resetForm();
      if (typeof successCallback === 'function') {
        successCallback();
      }
    } catch (err) {
      message.destroy(loadingKey);
      handleSignUpError(err, errorKey);
    } finally {
      setLoading(false);
    }
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const changeEmail = (event) => {
    if (!forcedEmail) {
      setEmail(event.target.value);
    }
  };

  const changePasswordScore = (score) => {
    setPasswordScore(score);
  };

  return (
    <div className={styles.container}>
      {!userState.isAnonymous && (
        <div className={styles.instruction}>
          Sign up with a username, email and password
        </div>
      )}
      <Form name="register" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your username'
            },
            {
              validator: (_, value) => {
                if (value && validateEmail(value.trim())) {
                  return Promise.reject(new Error('Please do not use an email address as your username.'));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            autoComplete="new-password"
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email'
            },
            {
              type: 'email',
              message: 'Use a valid email address format (test@example.com)'
            }
          ]}
          initialValue={forcedEmail || ''}
        >
          <Input
            autoComplete="new-password"
            placeholder="Email"
            disabled={!!forcedEmail}
            value={email}
            onChange={changeEmail}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                validator: () => {
                  if (!password) {
                    return Promise.reject(new Error('Please enter your password'));
                  }
                  if (passwordScore >= 2) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Your password is too weak'));
                }
              }
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="Password"
              onChange={changePassword}
            />
          </Form.Item>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            onChangeScore={changePasswordScore}
          />
        </Form.Item>
        {isLoading && <AuthLoader />}
        <Form.Item className={styles.submitForm}>
          <Button
            className={styles.submit}
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading}
          >
            {userState.isAnonymous ? 'Upgrade' : 'Sign Up'}
          </Button>
        </Form.Item>
      </Form>
      {!userState.isAnonymous && (
        <Button
          className={styles.switchAuth}
          type="text"
          onClick={() => goToSignIn()}
        >
          Log in with existing account instead
        </Button>
      )}
    </div>
  );
}

SignUp.defaultProps = {
  successCallback: () => {},
  goToSignIn: () => {},
  upgradeAnonymous: false,
  forcedEmail: null,
  hash: null,
  quteeId: null
};

export default SignUp;
