import React, { useState } from 'react';
import {
  Button, Drawer
} from 'antd';
import { FilterOutlined } from '@ant-design/icons/lib/icons';
import CommentEntry from '../../commentBin/components/CommentEntry';
import CommentFilters from '../../commentBin/components/CommentFilters';
import CommentList from '../../commentBin/components/CommentList';
import CommentBinPrompt from '../../commentBin/components/CommentBinPrompt';
import ThreadModal from '../../commentBin/components/ThreadModal';
import { useIsLg } from '../../common/state/responsiveChecks';
import styles from '../styles/QuteeCommentsView.module.scss';

function QuteeCommentsView({
  quteeId, commentBinId, commentPrompt, labelPrompt, labels, threadList,
  totalCommentCount, filterCommentCount, mutateThreads, labelFilters,
  mutateLabels, isLoadingLabels, topicFilters, mutateTopics, sizeTopics,
  setSizeTopics, isLoadingTopics, isEndTopics, sentimentFilters,
  mutateSentiments, sizeSentiments, setSizeSentiments, isLoadingSentiments,
  isEndSentiments, setFilter, filterData, setFilterData, filters, countComments,
  sizeComments, setSizeComments, hideCommentBinResults, foundNewComments,
  newCommentsCount, reloadComments,
  isLoadingComments, isEndComments, isRefreshingComments, loadReplies,
  loadDeeperThread, createComment, sort, setSort, updateComment, deleteComment,
  likeComment, removeCommentLike, dislikeComment, removeCommentDislike,
  updateCommentBin, updateLabelSet, createLabel, updateLabel, deleteLabel,
  userCanInteract, cantInteractReason, userCanEditQutee, labelEditingDisabled,
  checkUserCanEditComment, resultsOnly, allowAnonymous, incrementCreatedComments,
  hideSearchFilter, hideLabelFilter, hideTopicFilter, hideSentimentFilter, hideAllFilters,
  continueAnonymously
}) {
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [newComment, setNewComment] = useState(null);
  const [newCommentVisible, setNewCommentVisible] = useState(false);
  const isLargeScreen = useIsLg();

  const getQuteeFiltersComponent = () => (
    <CommentFilters
      commentBinId={commentBinId}
      labels={labelFilters}
      mutateLabels={mutateLabels}
      isLoadingLabels={isLoadingLabels}
      topics={topicFilters}
      mutateTopics={mutateTopics}
      sizeTopics={sizeTopics}
      setSizeTopics={setSizeTopics}
      isLoadingTopics={isLoadingTopics}
      isEndTopics={isEndTopics}
      sentiments={sentimentFilters}
      mutateSentiments={mutateSentiments}
      sizeSentiments={sizeSentiments}
      setSizeSentiments={setSizeSentiments}
      isLoadingSentiments={isLoadingSentiments}
      isEndSentiments={isEndSentiments}
      setFilter={setFilter}
      filterData={filterData}
      setFilterData={setFilterData}
      countComments={countComments}
      hideSearch={hideSearchFilter}
      hideLabels={hideLabelFilter}
      hideTopics={hideTopicFilter}
      hideSentiments={hideSentimentFilter}
      hideAllFilters={hideAllFilters}
    />
  );

  // TODO: Further flesh this out to allow loading additional comments scoped to
  // the current parentId, if there are more than pageSize of comments
  const continueThread = async (parentId) => {
    const comments = await loadDeeperThread(commentBinId, parentId);
    const list = (
      <CommentList
        quteeId={quteeId}
        commentBinId={commentBinId}
        threadList={comments}
        labelPrompt={labelPrompt}
        labels={labels}
        filters={filters}
        filterData={filterData}
        sizeComments={sizeComments}
        setSizeComments={setSizeComments}
        isLoadingComments={isLoadingComments}
        isEndComments
        isRefreshingComments={isRefreshingComments}
        totalCommentCount={totalCommentCount}
        filterCommentCount={filterCommentCount}
        sort={sort}
        setSort={setSort}
        loadReplies={loadReplies}
        continueThread={continueThread}
        createComment={createComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        likeComment={likeComment}
        removeCommentLike={removeCommentLike}
        dislikeComment={dislikeComment}
        removeCommentDislike={removeCommentDislike}
        userCanInteract={userCanInteract}
        cantInteractReason={cantInteractReason}
        userCanEditQutee={userCanEditQutee}
        checkUserCanEditComment={checkUserCanEditComment}
        showHeading={false}
        allowAnonymous={allowAnonymous}
        foundNewComments={false}
        hideCommentBinResults={false}
        continueAnonymously={continueAnonymously}
      />
    );
    return list;
  };

  const createTopLevelComment = async (quteeId, entryText, labelId, authorName) => {
    const comment = await createComment(quteeId, entryText, labelId, null, authorName);
    mutateThreads();
    incrementCreatedComments();
    const list = (
      <CommentList
        quteeId={quteeId}
        commentBinId={commentBinId}
        threadList={[comment]}
        labelPrompt={labelPrompt}
        labels={labels}
        filters={filters}
        filterData={filterData}
        sizeComments={sizeComments}
        setSizeComments={setSizeComments}
        isLoadingComments={isLoadingComments}
        isEndComments
        isRefreshingComments={isRefreshingComments}
        totalCommentCount={totalCommentCount}
        filterCommentCount={filterCommentCount}
        sort={sort}
        setSort={setSort}
        loadReplies={loadReplies}
        continueThread={continueThread}
        createComment={createComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        likeComment={likeComment}
        removeCommentLike={removeCommentLike}
        dislikeComment={dislikeComment}
        removeCommentDislike={removeCommentDislike}
        userCanInteract={userCanInteract}
        cantInteractReason={cantInteractReason}
        userCanEditQutee={userCanEditQutee}
        checkUserCanEditComment={checkUserCanEditComment}
        showHeading={false}
        allowAnonymous={allowAnonymous}
        foundNewComments={false}
        hideCommentBinResults={false}
        continueAnonymously={continueAnonymously}
      />
    );

    setNewComment(list);
    setNewCommentVisible(true);
  };

  return (
    <div>
      <div className={styles.title}>
        <CommentBinPrompt
          commentBinId={commentBinId}
          commentPrompt={commentPrompt}
          allowEditing={!resultsOnly && userCanEditQutee}
          updateCommentBin={updateCommentBin}
        />
      </div>
      {!resultsOnly && (
      <CommentEntry
        quteeId={quteeId}
        commentBinId={commentBinId}
        labelPrompt={labelPrompt}
        labels={labels}
        createComment={createTopLevelComment}
        allowEditing={userCanEditQutee}
        updateLabelSet={updateLabelSet}
        createLabel={createLabel}
        updateLabel={updateLabel}
        deleteLabel={deleteLabel}
        userCanInteract={userCanInteract}
        cantInteractReason={cantInteractReason}
        labelEditingDisabled={labelEditingDisabled}
        allowAnonymous={allowAnonymous}
        continueAnonymously={continueAnonymously}
        stayOpen
      />
      )}
      { !isLargeScreen ? (
        <Button
          className={styles.showFilters}
          type="primary"
          onClick={() => setShowFilterDrawer(true)}
        >
          <FilterOutlined />
          Show Comment Filters
        </Button>
      ) : null}
      <div className={styles.filtersComments}>
        { !isLargeScreen
          ? (
            <Drawer
              title="Filters"
              placement="left"
              closable
              getContainer={false}
              open={showFilterDrawer}
              onClose={() => setShowFilterDrawer(false)}
              width="80%"
            >
              {getQuteeFiltersComponent()}
            </Drawer>
          )
          : (
            <CommentFilters
              commentBinId={commentBinId}
              labels={labelFilters}
              mutateLabels={mutateLabels}
              isLoadingLabels={isLoadingLabels}
              topics={topicFilters}
              mutateTopics={mutateTopics}
              sizeTopics={sizeTopics}
              setSizeTopics={setSizeTopics}
              isLoadingTopics={isLoadingTopics}
              isEndTopics={isEndTopics}
              sentiments={sentimentFilters}
              mutateSentiments={mutateSentiments}
              sizeSentiments={sizeSentiments}
              setSizeSentiments={setSizeSentiments}
              isLoadingSentiments={isLoadingSentiments}
              isEndSentiments={isEndSentiments}
              setFilter={setFilter}
              filterData={filterData}
              setFilterData={setFilterData}
              countComments={countComments}
              hideSearch={hideSearchFilter}
              hideLabels={hideLabelFilter}
              hideTopics={hideTopicFilter}
              hideSentiments={hideSentimentFilter}
              hideAllFilters={hideAllFilters}
            />
          )}
        <CommentList
          quteeId={quteeId}
          commentBinId={commentBinId}
          threadList={threadList}
          labelPrompt={labelPrompt}
          labels={labels}
          filters={filters}
          filterData={filterData}
          sizeComments={sizeComments}
          setSizeComments={setSizeComments}
          reloadComments={reloadComments}
          isLoadingComments={isLoadingComments}
          isEndComments={isEndComments}
          isRefreshingComments={isRefreshingComments}
          totalCommentCount={totalCommentCount}
          filterCommentCount={filterCommentCount}
          sort={sort}
          setSort={setSort}
          loadReplies={loadReplies}
          continueThread={continueThread}
          createComment={createComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          likeComment={likeComment}
          removeCommentLike={removeCommentLike}
          dislikeComment={dislikeComment}
          removeCommentDislike={removeCommentDislike}
          userCanInteract={userCanInteract}
          cantInteractReason={cantInteractReason}
          userCanEditQutee={userCanEditQutee}
          checkUserCanEditComment={checkUserCanEditComment}
          showHeading
          allowAnonymous={allowAnonymous}
          hideCommentBinResults={hideCommentBinResults}
          foundNewComments={foundNewComments}
          newCommentsCount={newCommentsCount}
          continueAnonymously={continueAnonymously}
        />
      </div>
      <ThreadModal
        visible={newCommentVisible}
        setVisible={setNewCommentVisible}
      >
        {newComment}
      </ThreadModal>
    </div>
  );
}

QuteeCommentsView.displayName = 'Qutee Comments View';
QuteeCommentsView.defaultProps = {
  resultsOnly: false,
  hideSearchFilter: false,
  hideLabelFilter: false,
  hideTopicFilter: false,
  hideSentimentFilter: false,
  hideAllFilters: false
};

export default QuteeCommentsView;
