import React, { useState } from 'react';
import { Avatar, Button, App } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useUserContext } from '../../user/providers/UserProvider';
import { signOut } from '../../auth/state/api';
import AuthHandler from '../../auth/components/AuthHandler';
import styles from './styles/Nav.module.scss';

function Nav() {
  const { message } = App.useApp();
  const { state, dispatch } = useUserContext();
  const [isSigningOut, setSigningOut] = useState(false);

  const handleSignOut = async () => {
    const loadingKey = 'signOutRequest';
    const successKey = 'signOutSuccess';
    const errorKey = 'signOutError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setSigningOut(true);
    message.loading({ content: 'Signing out...', key: loadingKey });
    try {
      await signOut();
      message.destroy(loadingKey);
      message.success({ content: 'Successfully signed out', key: successKey });
      dispatch({ type: 'LOGOUT_SUCCESS' });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setSigningOut(false);
    }
  };

  // The author info should be pulled from an API call and passed in.
  return (
    <nav className={styles.nav}>
      <div className={styles.container}>
        <div className={styles.userInfo}>
          <span
            className={styles.avatarContainer}
          >
            <Avatar size={60} icon={<UserOutlined />} />
          </span>
          <div className={styles.username}>
            {state.profile ? state.profile.username : null}
          </div>
        </div>
        <AuthHandler>
          <Button
            className={styles.auth}
            type="primary"
            loading={isSigningOut}
            onClick={state.profile ? handleSignOut : null}
          >
            {state.profile ? 'Log Out' : 'Log In'}
          </Button>
        </AuthHandler>
      </div>
    </nav>
  );
}

export default Nav;
