import useSWR from 'swr';

function useFirstLoadPoll(pollId, initial, profile) {
  const { data, error, mutate } = useSWR(pollId && !initial
    ? [`polls/${pollId}?expand=options,ballot`, profile ? profile.profile_id : null]
    : null);

  return {
    poll: data,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useFirstLoadPoll;
