export const isEmptyObject = (obj) => (
  Object.entries(obj).length === 0 && obj.constructor === Object
);

export const moveCaretAtEnd = (event) => {
  const tempValue = event.target.value;
  event.target.value = '';
  event.target.value = tempValue;
};

export const escFunction = async (event, cancelFunction, preventDefault) => {
  if (event.keyCode === 27) {
    if (preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
    await cancelFunction();
  }
};

export const deepCopy = (inObject) => {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {};
  let value;
  let key;

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value);
  }

  return outObject;
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export function ApiException(errorObject) {
  this.name = errorObject.name;
  this.message = errorObject.message;
  this.details = errorObject.details;
  this.debug_id = errorObject.debug_id;
}

export function getReportStepDescription(step, includesSummary) {
  switch (step) {
    case 0:
      return 'Gathering data';
    case 1:
      return 'Analyzing polls';
    case 2:
      return 'Analyzing gauges';
    case 3:
      return 'Analyzing respondants';
    case 4:
      return 'Analyzing topics';
    case 5:
      return 'Analyzing descriptors';
    case 6:
      return 'Analyzing comments';
    case 7: {
      if (includesSummary) {
        return 'Generating Summary';
      }
      return 'Configuring report';
    }
    case 8:
      return 'Generating report';
    case 9:
      return 'Saving report';
    case 10:
      return 'Retrieving download link';
    default:
      return 'Processing data';
  }
}
