import useSWR from 'swr';

function useMyPollBallot(pollId, initialBallots, profileId) {
  const {
    data, error, isValidating, mutate
  } = useSWR(profileId ? [`polls/${pollId}/ballots/me`, profileId] : null, {
    fallbackData: initialBallots,
    revalidateOnMount: !initialBallots,
    onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
      if (retryCount >= 4) return;
      if (error.status === 404) {
        // Don't retry if it's a 404.
        // TODO: Can we clear data/the cache from SWR here?
        // maybe raise it as an issue in the SWR github.
        // I tried various manipulations but it didn't seem to work.
        return;
      }
      setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
    },
    onError: (error) => {
      if (error.status === 404) {
        mutate(null, false);
      }
    }
  });

  return {
    myBallots: data,
    mutate,
    isLoading: isValidating,
    isError: error
  };
}

export default useMyPollBallot;
