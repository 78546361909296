import useSWR from 'swr';

function useQuteeCommentBins(quteeId, profileId) {
  const { data, error, mutate } = useSWR(
    quteeId ? [`comment-bins?filter[qutee_id]=${quteeId}`, profileId] : null
  );

  return {
    commentBins: data ? data.results : null,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}

export default useQuteeCommentBins;
