import React, { useReducer, useContext } from 'react';
import { userReducer, initialUserState } from '../state/reducer';

const UserContext = React.createContext({});

const factoryUseContext = (name, context) => () => {
  const ctx = useContext(context);
  if (ctx === undefined) {
    throw new Error(`use${name}Context must be used within a ${name}ContextProvider.`);
  }
  return ctx;
};

export const useUserContext = factoryUseContext('User', UserContext);

function UserContextProvider(props) {
  const [state, dispatch] = useReducer(userReducer, initialUserState);
  const { children } = props;

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
