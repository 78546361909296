import React, { useState } from 'react';
import { Button, App, Spin } from 'antd';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { getSocialsResponse, upgradeAnonSocial } from '../state/api';
import { useUserContext } from '../../user/providers/UserProvider';
import styles from '../styles/SocialsAuth.module.scss';

function SocialsAuth({ operation, successCallback, hintEmail }) {
  const { message } = App.useApp();
  const { state: userState, dispatch: userDispatch } = useUserContext();
  const [loading, setLoading] = useState(false);

  const signInWithGoogle = async () => {
    const loadingKey = 'googleLoginRequest';
    const successKey = 'googleLoginSuccess';
    const errorKey = 'googleLoginError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Logging in via Google...', key: loadingKey });
    try {
      let userInfo;
      if (userState?.isAnonymous && operation === 'signup') {
        userInfo = await upgradeAnonSocial({ provider: 'Google' });
      } else {
        userInfo = await getSocialsResponse({ provider: 'Google', hintEmail });
      }
      message.destroy(loadingKey);
      message.success({ content: 'Login successful', key: successKey });
      successCallback();
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };
  const signInWithFacebook = async () => {
    const loadingKey = 'facebookLoginRequest';
    const successKey = 'facebookLoginSuccess';
    const errorKey = 'facebookLoginError';
    message.destroy(loadingKey);
    message.destroy(successKey);
    message.destroy(errorKey);

    setLoading(true);
    message.loading({ content: 'Logging in via Facebook...', key: loadingKey });
    try {
      let userInfo;
      if (userState?.isAnonymous && operation === 'signup') {
        userInfo = await upgradeAnonSocial({ provider: 'Facebook' });
      } else {
        userInfo = await getSocialsResponse({ provider: 'Facebook', hintEmail });
      }
      message.destroy(loadingKey);
      message.success({ content: 'Login successful', key: successKey });
      successCallback();
      userDispatch({ type: 'LOGIN_SUCCESS', payload: userInfo });
    } catch (err) {
      message.destroy(loadingKey);
      message.error({ content: err?.details?.issue || err.message, key: errorKey, duration: 5 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading} tip="Awaiting response from social login...">
      <div className={styles.socials}>
        <Button className={styles.facebook} onClick={signInWithFacebook}>
          <FacebookOutlined className={styles.icon} />
          <span className={styles.text}>Continue with Facebook</span>
        </Button>
        <Button className={styles.google} onClick={signInWithGoogle}>
          <GoogleOutlined className={styles.icon} />
          <span className={styles.text}>Continue with Google</span>
        </Button>
        <span className={styles.or}>or</span>
      </div>
    </Spin>
  );
}

SocialsAuth.defaultProps = {
  successCallback: () => {},
  hintEmail: null
};

export default SocialsAuth;
