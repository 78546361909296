import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { Provider as FetchProvider } from 'use-http';
import queryString from 'query-string';
import { App as AntdApp, ConfigProvider } from 'antd';
import config, { getApiTenant } from './common/infra/env.config';
import { auth, setTenant } from './common/infra/firebase';
import UserContextProvider, { useUserContext } from './user/providers/UserProvider';
import history from './history';
import AppSetup from './AppSetup';
import { swrFetcher } from './common/infra/fetcher';
import AppError from './common/view/AppError';

function AppProviders() {
  const { dispatch: userDispatch } = useUserContext();
  const queryValues = queryString.parse(window.location.search);

  const getTenant = () => {
    const main = document.getElementById('social-asking-embed');
    const options = main.dataset;
    if (options && typeof options.tenant !== 'undefined') {
      return options.tenant;
    }
    // @ts-ignore
    let { tenant } = window; // external js config
    if (!tenant && queryValues.tenant) {
      tenant = queryValues.tenant;
      if (Array.isArray(tenant)) {
        [tenant] = tenant;
      }
    }
    if (!tenant) {
      tenant = null;
    }
    return tenant;
  };
  setTenant(getTenant());

  const url = `${config.api.BASE_URL}/${config.api.TENANT_ID}/`;
  const options = {
    interceptors: {
      request: async ({ options }) => {
        if (options.headers['Content-Type'] !== 'text/css') {
          options.headers['Content-Type'] = 'application/json';
        }

        // Always send token when logged in and using a tenant
        const user = auth.currentUser;
        if (user && !options.headers.Authorization
        ) {
          const token = await user.getIdToken();
          options.headers.Authorization = token;
        }
        options.credentials = options.headers.Authorization ? 'same-origin' : 'include';
        return options;
      },
      response: async ({ response }) => {
        const res = response;
        if (res.status === 401) {
          userDispatch({ type: 'UNAUTHENTICATED' });
        }
        return res;
      }
    }
  };

  if (getApiTenant()) {
    return (
      <UserContextProvider>
        <SWRConfig
          value={{ fetcher: swrFetcher, revalidateOnFocus: false, shouldRetryOnError: false }}
        >
          <FetchProvider url={url} options={options}>
            {/* @ts-ignore */}
            <BrowserRouter history={history}>
              <AppError>
                <ConfigProvider
                  theme={{
                    hashed: false,
                    token: {
                      fontFamily: 'Source Sans Pro'
                    }
                  }}
                >
                  <AntdApp>
                    <AppSetup />
                  </AntdApp>
                </ConfigProvider>
              </AppError>
            </BrowserRouter>
          </FetchProvider>
        </SWRConfig>
      </UserContextProvider>
    );
  }

  return null;
}

export default AppProviders;
